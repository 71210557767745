/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip as TooltipOnLine,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import { formatIntNumberAndAddPercentSign } from '../../../../shared/helpers/WVFormatter';

const colorOptions = [
  '#13c450',
  '#e6194B',
];

const chartMargins = {
  top: 5,
  right: 20,
  left: 0,
  bottom: 40,
};

const CustomizedTooltip = (props) => {
  const getText = (name, value) => `${name}: ${formatIntNumberAndAddPercentSign(value, 0)}`;
  if (props.active && props.payload && props.payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: 'hsla(0, 0%, 100%, .9)',
          border: '1px solid #f5f5f5',
          lineHeight: '24px',
          margin: '0',
          padding: '10px',
        }}
      >
        <p className="bold-text">{props.label}</p>
        <ul
          style={{
            listStyle: 'none',
            padding: '0',
            margin: '0',
          }}
        >
          {props.payload && (
            <>
              <li
                key="positivePercentage"
                style={{
                  color: colorOptions[0],
                }}
              >
                {getText('Positive', props.payload[0].value)}
              </li>
              <li
                key="negativePercentage"
                style={{
                  color: colorOptions[1],
                }}
              >
                {getText('Negative', props.payload[1].value)}
              </li>
            </>
          )}
        </ul>
      </div>
    );
  }
  return null;
};

const CustomizedXAxisTick = ({ x, y, payload }) => {
  const values = [16, 26, 40];
  const children = payload && payload.value && payload.value?.includes('-')
    ? payload.value.split(' ').map(((string, index) => (
      <text
        key={`textKey${new Date().getTime()}-${string}`}
        x={0}
        y={0}
        dy={values[index]}
        textAnchor="middle"
        fill="#666"
      >
        {string}
      </text>
    ))) : (
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
        {payload.value}
      </text>
    );
  return (
    <g transform={`translate(${x},${y})`}>
      {children}
    </g>
  );
};

const CustomizedYAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={0} textAnchor="end" fill="#666">
      {`${formatIntNumberAndAddPercentSign(payload?.value)}`}
    </text>
  </g>
);

const CustomizedLabel = ({
  x, y, stroke, value,
}) => (
  <text x={x} y={y} dy={-4} fill={stroke} fontSize={13} textAnchor="middle" fontWeight="bold">
    {value > 0 ? formatIntNumberAndAddPercentSign(value) : ''}
  </text>
);

const ChartComponentCommentsPercentage = ({
  periodsData, showLabels,
}) => {
  const [opacity, setOpacity] = useState({
    positivePercentage: 1,
    negativePercentage: 1,
  });

  const handleMouseEnter = (dataKey) => {
    const localOpacity = { ...opacity };
    // eslint-disable-next-line no-return-assign
    Object.keys(localOpacity).forEach(companyName => localOpacity[companyName] = companyName === dataKey ? 1 : 0.2);
    setOpacity(localOpacity);
  };

  const handleMouseLeave = () => {
    const localOpacity = { ...opacity };
    // eslint-disable-next-line no-return-assign
    Object.keys(localOpacity).forEach(companyName => localOpacity[companyName] = 1);
    setOpacity(localOpacity);
  };

  return (
    <Row>
      <Col>
        <Row>
          <Col md={10} style={{ paddingRight: '0' }}>
            <ResponsiveContainer
              width="100%"
              height={400}
              debounce={1}
            >
              <LineChart
                width="100%"
                height="100%"
                data={periodsData}
                margin={chartMargins}
              >
                <Line
                  key="linePositive"
                  type="monotone"
                  dataKey="positivePercentage"
                  stroke={colorOptions[0]}
                  strokeOpacity={opacity.positivePercentage || 1}
                  strokeWidth={2}
                  dot={showLabels}
                  label={showLabels ? <CustomizedLabel /> : null}
                />
                <Line
                  key="lineNegative"
                  type="monotone"
                  dataKey="negativePercentage"
                  stroke={colorOptions[1]}
                  strokeOpacity={opacity.negativePercentage || 1}
                  strokeWidth={2}
                  dot={showLabels}
                  label={showLabels ? <CustomizedLabel /> : null}
                />
                <XAxis
                  dataKey="label"
                  padding={{ left: 0, right: 8 }}
                  tick={<CustomizedXAxisTick />}
                  interval="preserveStartEnd"
                />
                <YAxis
                  padding={{ top: 8, bottom: 0 }}
                  tick={<CustomizedYAxisTick />}
                  domain={['dataMin', 'dataMax']}
                  interval="preserveStartEnd"
                  allowDecimals
                />
                <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                <TooltipOnLine content={<CustomizedTooltip />} />
              </LineChart>
            </ResponsiveContainer>
          </Col>
          <Col
            md={2}
            className="d-flex align-items-center justify-content-center"
          >
            <div>
              <ul
                style={{
                  listStyle: 'none',
                  paddingLeft: '0',
                }}
              >
                <li
                  key="legendPositive"
                  style={{
                    color: colorOptions[0],
                    cursor: 'pointer',
                    fontWeight: '600',
                  }}
                  onMouseEnter={() => handleMouseEnter('positivePercentage')}
                  onMouseLeave={handleMouseLeave}
                >
                  &#9903;  Positive
                </li>
                <li
                  key="legendNegative"
                  style={{
                    color: colorOptions[1],
                    cursor: 'pointer',
                    fontWeight: '600',
                  }}
                  onMouseEnter={() => handleMouseEnter('negativePercentage')}
                  onMouseLeave={handleMouseLeave}
                >
                  &#9903;  Negative
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

ChartComponentCommentsPercentage.propTypes = {
  periodsData: PropTypes.arrayOf(PropTypes.any),
  showLabels: PropTypes.bool.isRequired,
};

ChartComponentCommentsPercentage.defaultProps = {
  periodsData: [],
};

export default ChartComponentCommentsPercentage;
