import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ButtonToolbar,
  ModalBody,
  Button,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import { hideModalById } from '../../../../../../../redux/reducers/TableReducer';
import withNotice from '../../../../../../App/store/with-notice';

export const modalId = 'refreshYoutubeDialog';

const RefreshYoutubeDialog = ({
  afterSaveFunc,
  addNotice,
}) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  let dealName = '';
  let dealId = '';
  const modalInfo = useSelector(state => state.table.modals[modalId]);
  const dispatch = useDispatch();
  const close = () => dispatch(hideModalById(modalId));

  // if (!modalInfo) return false;
  const { data: { record }, isShow } = modalInfo || { data: { record: null }, isShow: false };

  dealId = record?.params?.deal;
  dealName = record?.populated?.deal?.params?.dealname;

  const fetchDealData = async () => {
    const response = await axios.get(`/api/portal/compliance/get-fetch-deal-data?id=${dealId}`);
    if (response.data?.success) {
      setName(response.data.deal.dealname);
    }
  };

  useEffect(async () => {
    if (isShow) {
      if (!dealName) {
        await fetchDealData();
      } else {
        setName(dealName);
      }
    }
  }, [isShow]);

  const deleteRecord = async () => {
    try {
      setLoading(true);
      const res = await axios.post('/api/portal/compliance/post-refresh-media-content-youtube', {
        id: record.id,
      });
      setLoading(false);
      close();
      if (!res.data.success) throw new Error(res.data.error);
      addNotice({
        message: 'The Media Content has been updated successfully',
        type: 'success',
      });
      afterSaveFunc();
    } catch (e) {
      addNotice({
        message: 'Unable to get video data from YouTube with the provided URL. Please verify the URL is valid.',
        type: 'warning',
      });
      console.log(e);
      setLoading(false);
    }
  };
  return (
    <Modal
      size="sm"
      isOpen={isShow}
      toggle={close}
      style={{ minWidth: '385px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Refresh YouTube Data
      </ModalHeader>
      <ModalBody>
        Are you sure you want to refresh YouTube data for <strong>{'"'}{name || ''}{'"'}</strong>?<br />
        We will look for updates to:
        <ul className="text-left list-items-color-blue">
          <li>Video Title</li>
          <li>Video Description</li>
          <li>Privacy Status</li>
          <li>Publish Date</li>
        </ul>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button color="primary" onClick={deleteRecord} disabled={loading || !record}>Refresh</Button>&nbsp;&nbsp;
        <Button color="secondary" onClick={close}>Cancel</Button>

      </ButtonToolbar>
    </Modal>
  );
};


RefreshYoutubeDialog.propTypes = {
  // showDeleteDialog: PropTypes.bool.isRequired,
  // closeFormDialog: PropTypes.func.isRequired,
  // record: PropTypes.objectOf(PropTypes.any),
  afterSaveFunc: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};
export default withNotice(RefreshYoutubeDialog);
