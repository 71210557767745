import React from 'react';
import { Row, Col } from 'reactstrap';

// See Start Date component

export default () => (
  <Row>
    <Col md={6}>
      &nbsp;&nbsp;
    </Col>
  </Row>
);
