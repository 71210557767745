import React from 'react';
// import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { currentTimezoneAggr } from '../../../../../shared/helpers/Dates';
import WVFormatter from '../../../../../shared/helpers/WVFormatter';
import VideoTitle from './table/VideoTitle';
// import CopyToClipboard from '../../../../../../shared/components/CopyToClipboard';
import ContextMenuInList from './table/ContextMenuInList';
import Comment from './table/Comment';

export default function TableColumns() {
  console.log('Icon test');
  return [
    {
      Header: 'Video Details',
      accessor: () => null,
      id: 'videoTitle',
      Cell: record => (
        <div>
          <VideoTitle record={record} />
          {/* <span>{record.row.original.params?.videoTitle.length > 40 ? `${record.row.original.params.videoTitle}...` : record.row.original.params.videoTitle}</span> */}
        </div>
      ),
      className: 'align-middle',
      disableSortBy: true,
      width: 200,
    },
    {
      Header: `Publish Date (${currentTimezoneAggr()})`,
      accessor: p => (WVFormatter.getServerDateTime(p.params?.videoPublishDate)),
      id: 'videoPublishDate',
      Cell: value => (
        <div>
          <span>{WVFormatter.getServerDateTime(value.row.original.params?.videoPublishDate)}</span>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: false,
      width: 170,
    },
    {
      Header: 'Creator',
      accessor: p => (p.params?.creatorName),
      id: 'creatorName',
      Cell: (record) => {
        const channelId = record.row.original.populated?.influencer?.params?.mediaChannels?.youtube_channel_url || null;
        return channelId ? (
          <div style={{ width: '150px', paddingRight: '10px' }}>
            <a
              target="_blank"
              rel="noreferrer"
              href={channelId}
            >
              {record.row.original.params.creatorName}
            </a>
          </div>
        ) : record.row.original.params.creatorName;
      },
      disableSortBy: true,
      className: 'align-middle',
      width: 150,
    },
    {
      Header: 'Comment Author',
      accessor: p => (p.params?.authorDisplayName),
      id: 'authorDisplayName',
      Cell: record => (
        <div style={{ width: '150px', paddingRight: '10px' }}>
          <span>{record.row.original.params?.authorDisplayName || ''}</span>&nbsp;&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href={record.row.original.params.authorChannelUrl}
          >
            <FontAwesomeIcon icon={['fa', 'user']} />
          </a>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: true,
      width: 150,
    },
    {
      Header: 'Comment',
      accessor: () => null,
      id: 'textDisplay',
      width: 250,
      Cell: record => (
        <div style={{ width: '250px' }}>
          <Comment record={record} />
        </div>
      ),
      // className: 'align-middle',
      disableSortBy: true,
    },
    {
      Header: `Comment Date (${currentTimezoneAggr()})`,
      accessor: p => (WVFormatter.getServerDateTime(p.params?.createdAt)),
      id: 'publishedAt',
      Cell: value => (
        <div>
          <span>{WVFormatter.getServerDateTime(value.row.original.params?.publishedAt)}</span>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: false,
      width: 170,
    },
    {
      Header: 'Sentiment',
      accessor: () => null,
      id: 'sentimentLabel.value',
      Cell: record => (
        <span>{record.row.original.params?.sentimentLabel?.value || ''}</span>
      ),
      className: 'align-middle',
      disableSortBy: false,
      width: 120,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'comments_actions',
      width: 50,
      Cell: p => (
        <ContextMenuInList
          record={p.row.original}
        />
      ),
      disableSortBy: true,
      className: 'd-flex align-items-center align-middle',
    },
  ];
}
