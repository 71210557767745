/* eslint-disable filenames/match-exported */
import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input } from 'reactstrap';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(() => ({
  formLabel: {
    marginLeft: '20px',
  },
}));

const FTCCompliant = ({
  isFTCCompliant,
  disable,
  handleChangeFTPCompliant,
}) => {
  const classes = useStyles();

  return (
    <FormGroup>
      <FormControlLabel
        className={classes.formLabel}
        control={
          <Input
            disabled={disable}
            type="radio"
            name="handleChangeFTC"
            value="1"
            className="mt-0"
            checked={isFTCCompliant === true}
            onChange={handleChangeFTPCompliant}
          />
        }
        label="Yes"
      />&nbsp;&nbsp;
      <FormControlLabel
        className={classes.formLabel}
        control={
          <Input
            disabled={disable}
            type="radio"
            name="handleChangeFTC"
            value="0"
            className="mt-0"
            checked={isFTCCompliant === false}
            onChange={handleChangeFTPCompliant}
          />
        }
        label="No"
      />
    </FormGroup>
  );
};
FTCCompliant.propTypes = {
  isFTCCompliant: PropTypes.bool,
  handleChangeFTPCompliant: PropTypes.func,
  disable: PropTypes.bool,
};

FTCCompliant.defaultProps = {
  disable: false,
  isFTCCompliant: null,
  handleChangeFTPCompliant: () => {},
};
export default FTCCompliant;

