/* eslint react/prop-types: 0 */
import React from 'react';
import { uid } from 'react-uid';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';


export default ({ record }) => {
  const { params: { _id, lastRunDatetime, lastRunSucceed } } = record;
  let status = 'No data of last run success.';
  let color = '#646777';
  if (lastRunSucceed === true) {
    status = 'Last run succeed.';
    color = '#4be2b7';
  }
  if (lastRunSucceed === false) {
    status = 'Last run failed. Check the Logs.';
    color = '#eb2970';
  }
  let value = '';
  if (lastRunDatetime) {
    const date = new Date(lastRunDatetime);
    value = date.toLocaleString();
  }

  return (
    <Tooltip
      title={status}
      key={`lastRunStatus${uid(_id)}`}
    >
      <span
        style={{ color }}
      >
        {value}
      </span>
    </Tooltip>
  );
};
