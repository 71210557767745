import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonToolbar,
  FormGroup,
  InputGroup,
  Label,
  Modal,
} from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from 'mdi-react/EditIcon';
import Tooltip from '@material-ui/core/Tooltip';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import { Input } from '@material-ui/core';
import axios from 'axios';
import ReactTags from 'react-tag-autocomplete';
import WVValidator from '../helpers/WVValidator';


class EditJobRunFrequencyButton extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    record: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { record } = this.props;
    let name = '';
    let months = 0;
    let days = 0;
    let hours = 0;
    let minutes = 0;
    if (record && record.params) {
      name = record.params.name || '';
      months = record.params['frequency.months'] || 0;
      days = record.params['frequency.days'] || 0;
      hours = record.params['frequency.hours'] || 0;
      minutes = record.params['frequency.minutes'] || 0;
    }
    this.state = {
      modal: false,
      loading: false,
      errors: {},
      name,
      months,
      days,
      hours,
      minutes,
      useBrands: ['sendStatsLoggedInNotification', 'sendStatsMediaContentByDayNotification'].includes(record.params.identifier),
      brands: [],
      brandsAll: null,
      brandsSuggestions: null,
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.onAdditionTagBrand = this.onAdditionTagBrand.bind(this);
    this.onDeleteTagBrand = this.onDeleteTagBrand.bind(this);
  }

  onAdditionTagBrand(tag) {
    const { brands } = this.state;
    const brandsNew = [].concat(brands, tag);
    this.setState({
      brands: brandsNew,
      brandsSuggestions: this.brandsSuggestions(brandsNew),
    });
  }

  onDeleteTagBrand(i) {
    const { brands } = this.state;
    const brandsNew = brands.slice(0);
    brandsNew.splice(i, 1);
    this.setState({
      brands: brandsNew,
      brandsSuggestions: this.brandsSuggestions(brandsNew),
    });
  }

  brandsSuggestions(brands, brandsAll = null) {
    const all = brandsAll || this.state.brandsAll;
    return all.filter(row => !brands.map(b => b.id).includes(row.id));
  }

  loadBrands() {
    const { identifier } = this.props.record.params;
    axios.get('/api/portal/scheduled-jobs/get-brands', { params: { identifier } }).then((response) => {
      if (response.data.success) {
        const { brands, suggestions } = response.data;
        this.setState({
          brands,
          brandsAll: suggestions,
          brandsSuggestions: this.brandsSuggestions(brands, suggestions),
        });
      }
    });
  }

  toggle() {
    const { useBrands, brandsSuggestions } = this.state;
    if (useBrands && !brandsSuggestions) {
      this.loadBrands();
    }
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  handleChange(event) {
    const { name, value } = event.target;
    const state = {
      ...this.state,
      [name]: value,
    };
    if (name in state.errors) {
      delete state.errors[name];
    }
    this.setState(state);
  }

  validateErrors() {
    const errors = {};
    const {
      name,
    } = this.state;
    if (WVValidator.isEmpty(name)) {
      errors.name = 'Please fill Name.';
    }
    this.setState({ errors });
    return Object.entries(errors).length === 0;
  }

  submit() {
    if (!this.validateErrors()) { return false; }
    this.setState({ loading: true });
    const { record } = this.props;
    const {
      name,
      months,
      days,
      hours,
      minutes,
      useBrands,
      brands,
    } = this.state;

    const data = {
      _id: record.id,
      name,
      frequency: {
        months,
        days,
        hours,
        minutes,
      },
    };

    if (useBrands) {
      data.brands = brands.map(row => row.id);
    }

    axios.post('/api/portal/scheduled-jobs/post-save', data).then(() => {
      this.setState({ loading: false });
      this.toggle();
      window.location.reload();
    });
    return true;
  }

  render() {
    const {
      modal,
      loading,
      errors,
      name,
      months,
      days,
      hours,
      minutes,
      useBrands,
      brands,
      brandsSuggestions,
    } = this.state;

    return (
      <div style={{ display: 'inline' }}>
        <Tooltip title="Edit">
          <IconButton
            size="small"
            onClick={this.toggle}
            className="material-table__toolbar-button"
          >
            <EditIcon size="15" />
          </IconButton>
        </Tooltip>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          modalClassName="ltr-support"
          className="modal-dialog"
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
          </div>
          <div className="modal__body">
            <Row>
              <Col>
                <FormGroup>
                  <Label for="nameInput">Name</Label>
                  <Input
                    id="nameInput"
                    name="name"
                    value={name}
                    onChange={this.handleChange}
                    disabled={loading}
                    style={{ width: '100%' }}
                  />
                  {errors.name && (<div className="form__form-group-error">{errors.name}</div>)}
                </FormGroup>
              </Col>
            </Row>
            {useBrands && brandsSuggestions && (
              <Row className="mt-3 mb-3">
                <Col md={3} className="pt-1">
                  Included Brands
                </Col>
                <Col md={9}>
                  <ReactTags
                    ref={React.createRef()}
                    tags={brands}
                    suggestions={brandsSuggestions}
                    onDelete={this.onDeleteTagBrand}
                    onAddition={this.onAdditionTagBrand}
                    delimiters={['Enter', ',']}
                    placeholderText="Brand name(s)"
                    classNames={{ root: 'MuiInput-underline position-relative' }}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <h4>Run Frequency</h4>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label for="monthsInput"><small>Months</small></Label>
                  <InputGroup size="md">
                    <Input
                      id="monthsInput"
                      name="months"
                      value={months}
                      type="number"
                      onChange={this.handleChange}
                      disabled={loading}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="daysInput"><small>Days</small></Label>
                  <InputGroup size="md">
                    <Input
                      id="daysInput"
                      name="days"
                      value={days}
                      type="number"
                      onChange={this.handleChange}
                      disabled={loading}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="hoursInput"><small>Hours</small></Label>
                  <InputGroup size="md">
                    <Input
                      id="hoursInput"
                      name="hours"
                      value={hours}
                      type="number"
                      onChange={this.handleChange}
                      disabled={loading}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="minutesInput"><small>Minutes</small></Label>
                  <InputGroup size="md">
                    <Input
                      id="minutesInput"
                      name="minutes"
                      value={minutes}
                      type="number"
                      onChange={this.handleChange}
                      disabled={loading}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <ButtonToolbar className="modal__footer">
            <Button className="modal_ok btn-sm" color="primary" onClick={this.submit}>
              {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
              <span>Save</span>
            </Button>{' '}
            <Button className="modal_cancel btn-sm" onClick={this.toggle}>Cancel</Button>
          </ButtonToolbar>
        </Modal>
      </div>
    );
  }
}

export default EditJobRunFrequencyButton;
