import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const CreateDealButton = (props) => {
  const { agent } = props;
  return (
    <span>
      <Tooltip title="Create agent Deal">
        <Link to={`/resources/AgentUser/agent-deal-editor/${agent.id}`} target="_blank">
          <IconButton size="small">
            <i className="fab fa-hubspot" style={{ fontSize: '0.8em' }} />
          </IconButton>
        </Link>
      </Tooltip>
    </span>
  );
};

CreateDealButton.propTypes = {
  agent: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default CreateDealButton;
