import { useSelector, useDispatch } from 'react-redux';
import { hideModalById } from '../../redux/reducers/ModalsReducer';

const useModalHook = (mId) => {
  const modalInfo = useSelector(state => state.modals.modals[mId]);
  const dispatch = useDispatch();
  if (!modalInfo) return { data: {}, showModal: false, close: () => {} };
  const { data, isShow: showModal } = modalInfo;
  const close = () => dispatch(hideModalById(mId));
  return { data: data || {}, showModal: showModal || false, close };
};

export default useModalHook;
