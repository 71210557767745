import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input } from 'reactstrap';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(() => ({
  formLabel: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  formGroup: {
    marginTop: '5px',
    marginBottom: '0px',
  },
}));

const BinaryAnswerOptions = ({
  answer,
  disable,
  handleChangeBinaryAnswer,
  questionId,
}) => {
  const classes = useStyles();

  return (
    <FormGroup className={classes.formGroup}>
      <FormControlLabel
        className={classes.formLabel}
        control={
          <Input
            disabled={disable}
            type="radio"
            name={questionId}
            value="true"
            className="mt-0"
            checked={answer === true}
            onChange={() => { console.log(questionId); handleChangeBinaryAnswer(questionId, true); }}
          />
        }
        label="Yes"
      />&nbsp;&nbsp;
      <FormControlLabel
        className={classes.formLabel}
        control={
          <Input
            disabled={disable}
            type="radio"
            name={questionId}
            value="false"
            className="mt-0"
            checked={answer === false}
            onChange={() => { handleChangeBinaryAnswer(questionId, false); }}
          />
        }
        label="No"
      />
    </FormGroup>
  );
};
BinaryAnswerOptions.propTypes = {
  answer: PropTypes.bool,
  handleChangeBinaryAnswer: PropTypes.func,
  questionId: PropTypes.string,
  disable: PropTypes.bool,
};

BinaryAnswerOptions.defaultProps = {
  disable: false,
  answer: null,
  handleChangeBinaryAnswer: () => {},
  questionId: null,
};
export default BinaryAnswerOptions;

