import StatusInList from './StatusInList';
import EventDateInList from './EventDatesInList';

export default {
  edit: {},
  list: {
    status: StatusInList,
    occurredAt: EventDateInList,
  },
};
