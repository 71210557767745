// import { useCallback, useMemo } from 'react';
import _axios from 'axios';
// import Hook from '@/shared/hooks';
// import { useDispatch } from 'react-redux';

const message = 'Unable to get a response. Please check your network connection.'


const showNoticeEvent = (level, message) => {
  console.log(level, message,'level, message')
  const even = new CustomEvent('ShowNoticeEvent', { detail: { level, message } });
  window.dispatchEvent(even);
}
export const checkResponse = (response, e) => {

  const loginUrl = [window.location.origin, window?.REDUX_STATE?.paths?.loginPath || '/login'].join(''); // if response has redirect to loginUrl
  if ((response.request.responseURL && response.request.responseURL.match(loginUrl)) || response.status === 401) {
    // alert('Your session expired. You will be redirected to login screen');
    // lobalAny.location.assign(loginUrl);
    const event = new CustomEvent('InvalidLoginSession', { detail: { response } });
    window.dispatchEvent(event);
    // eslint-disable-next-line no-undef
    
  } else if([401, 500].includes(response.status)) {
    showNoticeEvent('error', response?.data?.error || message)
  } else if(e && e.message.includes('Network Error')) {

  }
};
/**
 * Extends {@link AxiosRequestConfig}
 *
 * @alias ActionAPIParams
 * @memberof ApiClient
 * @property {any}   ...    any property supported by {@link AxiosRequestConfig}
 */
async function  axios (...vars) {
  try {
    const resp = await _axios(...vars)
    checkResponse(resp);
    return resp
  } catch(e) {
    if (e.response){
      checkResponse(e.response, e);
    } else {
      showNoticeEvent('error', message)
    }
    if([401].includes(e.response?.status)) {
      return { data: {}};
    }
    if(![401].includes(e.response?.status)) {
      throw e;
    }
  }
}
axios.get = async(...vars) => {
  try {
    const resp = await _axios.get(...vars);
    checkResponse(resp);
    return resp
  } catch(e) {
    if (e.response){
      checkResponse(e.response, e);
    } else {
      showNoticeEvent('error', message)
    }
    if([401].includes(e.response?.status)) {
      return { data: {}};
    }
    if(![401].includes(e.response?.status)) {
      throw e;
    }
  }
};
axios.request = async(...vars) => {
  try {
    const resp = await _axios.request(...vars);
    checkResponse(resp);
    return resp
  } catch(e) {
    if (e.response){
      checkResponse(e.response, e);
    } else {
      showNoticeEvent('error', message)
    }
    if([401].includes(e.response?.status)) {
      return { data: {}};
    }
    if(![401].includes(e.response?.status)) {
      throw e;
    }
  }
};
axios.post = async(...vars) => {
  // const { showError } = Hook.useNoticeHook()
  try {
      const resp = await _axios.post(...vars);
      checkResponse(resp);
      return resp;
  } catch(e) {
    if (e.response){
      checkResponse(e.response, e);
    } else {
      showNoticeEvent('error', message)
    }
    if([401].includes(e.response?.status)) {
      return { data: {}};
    }
    if(![401].includes(e.response?.status)) {
      throw e;
    }
  }
};

axios.getSafety = async(...vars) => {
  try {
    const resp = await _axios.get(...vars);
    checkResponse(resp);
    return resp.data || {};
  } catch(e) {
    if (e.response && [401].includes(e.response?.status)){
      checkResponse(e.response, e);
      return {};
    }
    showNoticeEvent('error', e.response?.data?.error || message)
    return {};
  }
};
axios.postSafety = async(...vars) => {
  // const { showError } = Hook.useNoticeHook()
  try {
      const resp = await _axios.post(...vars);
      checkResponse(resp);
      return resp.data || {};
  } catch(e) {
    if (e.response && [401].includes(e.response?.status)){
      checkResponse(e.response, e);
      return {};
    }
    showNoticeEvent('error', e.response?.data?.error || message)
    return {};
  }
};

/**
 * Client which access the admin API.
 * Use it to fetch data from auto generated AdminBro API.
 *
 * In the backend it uses [axios](https://github.com/axios/axios) client
 * library.
 *
 * Usage:
 * ```javascript
 * import { ApiClient } from 'admin-bro'
 *
 * const api = new ApiClient()
 * // fetching all records
 * api.resourceAction({ resourceId: 'Comments', actionName: 'list' }).then(results => {...})
 * ```
 * @see https://github.com/axios/axios
 * @hideconstructor
 */
export default class ApiClient {
  constructor() {
    this.baseURL = ApiClient.getBaseUrl();
    this.client = axios;
  }

  static getBaseUrl() {
    if (typeof window === 'undefined') {
      return '';
    }

    return `${window.location.origin}${window?.REDUX_STATE?.paths.rootPath || ''}`;
  }

  async searchRecords({ resourceId, query }) {
    if (typeof window === 'undefined') {
      return [];
    }

    const actionName = 'search';
    console.log({ resourceId, actionName, query });
    const response = await this.resourceAction({ resourceId, actionName, query });
    checkResponse(response);
    return response.data.records;
  }

  async resourceAction(options) {
    console.log(options)
    const { resourceId, actionName, data, query, ...axiosParams } = options;
    let url = `/api/resources/${resourceId}/actions/${actionName}`;

    if (query) {
      url = `${url}/${encodeURIComponent(query)}`;
    }

    const response = await this.client.request({
      url,
      method: data ? 'POST' : 'GET',
      data,
      ...axiosParams
    });
    checkResponse(response);
    return response;
  }

  async recordAction(options) {
    const { resourceId, recordId, actionName, data, ...axiosParams } = options;

    const response = await this.client.request({
      url: `/api/resources/${resourceId}/records/${recordId}/${actionName}`,
      method: data ? 'POST' : 'GET',
      data,
      ...axiosParams
    });
    checkResponse(response);
    return response;
  }

  async bulkAction(options) {
    const { resourceId, recordIds, actionName, data, ...axiosParams } = options;

    const params = new URLSearchParams();
    params.set('recordIds', (recordIds || []).join(','));
    const response = await this.client.request({
      url: `/api/resources/${resourceId}/bulk/${actionName}`,
      method: data ? 'POST' : 'GET',
      data,
      params,
      ...axiosParams
    });
    checkResponse(response);
    return response;
  }
}


/* 
export const useAxios = () => {
  const { addError } = Hook.useErrorHook();
  // const [showModal] = Hook.useShowModalHook('SuccessModal');
  const dispatch = useDispatch();

  const get = useCallback(async (...vars) => {
    try {
      const resp = await _axios.get(...vars);
      checkResponse(resp);
      return resp;
    } catch (e) {
      addError(e, 'Issue In get query');
      throw e;
    }
  }, [dispatch]);
  const post = useCallback(async (...vars) => {
    try {
      const resp = await _axios.post(...vars);
      checkResponse(resp);
      return resp;
    } catch (e) {
      addError(e, 'Issue In post query');
      throw e;
    }
  }, [dispatch])
  const getSafety = useCallback( async (...vars) => {
    try {
      const resp = await _axios.get(...vars);
      checkResponse(resp);
      return resp.data;
    } catch (e) {
      addError(e, 'Issue In get query');
    }
    return {};
  }, [dispatch]);
  const postSafety= useCallback(async (...vars) => {
    try {
      const resp = await _axios.post(...vars);
      checkResponse(resp);
      if (resp.status !== 200) {
        throw new Error('Unable to execute request')
      }
      return resp.data;
    } catch (e) {
      addError(e, 'Issue In post query');
    }
    return {};
  }, [dispatch]);
  const ApiClientCreated = useMemo(() => {
    const api = new ApiClient(dispatch);
    return api;
  } , [dispatch])

  return { get, post, getSafety, postSafety, ApiClient: ApiClientCreated }
};
*/
export { axios };

