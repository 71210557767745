exports.setUrl = ({
  history, removeBeforeSet = false,
  search, selectedButton,
  publishDateFrom, publishDateTo, publishDateFilterType,
  selectedCreators, publishVideoDateFrom, publishVideoDateTo, singlePublishVideoDate, sentiment, enablePublishDateFilter,
  publishAtDateFilterType, singlePublishDate, enablePublishAtDateFilter,
}) => {
  let searchUrl = new URLSearchParams(window.location.search);
  const oldUrl = `${history.location.pathname}?${searchUrl.toString()}`;

  if (removeBeforeSet) {
    searchUrl = new URLSearchParams();
  }

  if (search) {
    searchUrl.set('filters.fullSearchName', search);
  } else {
    searchUrl.delete('filters.fullSearchName');
  }

  console.log({ publishVideoDateFrom, publishVideoDateTo, singlePublishVideoDate });

  searchUrl.delete('filters.publishedAt~~from');
  searchUrl.delete('filters.publishedAt~~to');
  searchUrl.delete('filters.videoPublishDate~~from');
  searchUrl.delete('filters.videoPublishDate~~to');
  searchUrl.delete('range');
  searchUrl.append('range', selectedButton);

  // searchUrl.delete('filters.publishedAt~~from');
  // searchUrl.delete('filters.publishedAt~~to');
  searchUrl.delete('publishedDateType');

  if (publishDateFrom && publishDateFrom !== '') {
    searchUrl.delete('filters.publishedAt~~from');
    searchUrl.append('filters.publishedAt~~from', publishDateFrom.toISOString());
  }
  if (publishDateTo && publishDateTo !== '') {
    searchUrl.delete('filters.publishedAt~~to');
    searchUrl.append('filters.publishedAt~~to', publishDateTo.toISOString());
  }
  if (singlePublishDate && singlePublishDate !== '' && publishAtDateFilterType.value && enablePublishAtDateFilter) {
    searchUrl.delete('filters.publishedAt~~from');
    searchUrl.delete('filters.publishedAt~~to');
    if (publishAtDateFilterType.value === '>' || publishAtDateFilterType.value === '>=') {
      searchUrl.append('filters.publishedAt~~from', singlePublishDate.toISOString());
    }
    if (publishAtDateFilterType.value === '<' || publishAtDateFilterType.value === '<=') {
      searchUrl.append('filters.publishedAt~~to', singlePublishDate.toISOString());
    }
    if (publishAtDateFilterType.value === '=') {
      searchUrl.append('filters.publishedAt~~from', singlePublishDate.toISOString());
      const nextDay = new Date(singlePublishDate);
      nextDay.setDate(singlePublishDate.getDate() + 1);
      searchUrl.append('filters.publishedAt~~to', nextDay.toISOString());
    }
  }
  if (!enablePublishDateFilter) {
    searchUrl.delete('filters.videoPublishDate~~from');
    searchUrl.delete('filters.videoPublishDate~~to');
  }
  if (publishVideoDateFrom && publishDateFrom !== '' && enablePublishDateFilter) {
    searchUrl.delete('filters.videoPublishDate~~from');
    searchUrl.append('filters.videoPublishDate~~from', publishVideoDateFrom.toISOString());
  }
  if (publishVideoDateTo && publishVideoDateTo !== '' && enablePublishDateFilter) {
    searchUrl.delete('filters.videoPublishDate~~to');
    searchUrl.append('filters.videoPublishDate~~to', publishVideoDateTo.toISOString());
  }

  if (singlePublishVideoDate && singlePublishVideoDate !== '' && publishDateFilterType.value && enablePublishDateFilter) {
    searchUrl.delete('filters.videoPublishDate~~from');
    searchUrl.delete('filters.videoPublishDate~~to');
    if (publishDateFilterType.value === '>' || publishDateFilterType.value === '>=') {
      searchUrl.append('filters.videoPublishDate~~from', singlePublishVideoDate.toISOString());
    }
    if (publishDateFilterType.value === '<' || publishDateFilterType.value === '<=') {
      searchUrl.append('filters.videoPublishDate~~to', singlePublishVideoDate.toISOString());
    }
    if (publishDateFilterType.value === '=') {
      searchUrl.append('filters.videoPublishDate~~from', singlePublishVideoDate.toISOString());
      const nextDay = new Date(singlePublishVideoDate);
      nextDay.setDate(singlePublishVideoDate.getDate() + 1);
      searchUrl.append('filters.videoPublishDate~~to', nextDay.toISOString());
    }
  }
  if (publishDateFilterType.value && ((publishDateFrom && publishDateTo) || singlePublishVideoDate)) {
    // setEnablePublishDateFilter(true);
    searchUrl.append('publishedDateType', publishDateFilterType.value);
  }

  const filterCreators = searchUrl.getAll('filters.creator');
  let deleteFilterCreators = false;

  // console.log('SELECTED: ', selectedCreators);
  if (!selectedCreators || selectedCreators.length === 0) {
    searchUrl.delete('filters.creator');
  }
  if (selectedCreators) {
    filterCreators.forEach((creator) => {
      if (selectedCreators.filter(item => item.value === creator).length === 0) {
        deleteFilterCreators = true;
      }
    });
    if (deleteFilterCreators) {
      searchUrl.delete('filters.creator');
    }
    selectedCreators.forEach((creator) => {
      if (creator.value !== '' && (!filterCreators.includes(creator.value) || deleteFilterCreators)) {
        searchUrl.append('filters.creator', creator.value);
      }
    });
  }
  searchUrl.delete('filters.sentiment');

  if (sentiment) {
    Object.keys(sentiment).forEach((type) => {
      if (sentiment[type]) {
        searchUrl.append('filters.sentiment', type);
      }
    });
  }
  let newUrl = `${history.location.pathname}?${searchUrl.toString()}`;
  if (oldUrl !== newUrl) {
    searchUrl.set('page', '1');
    newUrl = `${history.location.pathname}?${searchUrl.toString()}`;
    history.push(newUrl);
  }
};
