import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DataBase from '../helpers/indexDB';
import { transformPathToKey } from '../../redux/reducers/TableReducerDefault';


const useSelectedRecordsTableHook = () => {
  const [records, setRecords] = useState([]);
  // const records = useSelector(state => state.tableRedux.records);
  const selectedIds = useSelector(state => state.tableRedux.selectedRecordsIdsMultiselect);
  useEffect(async () => {
    const db = await DataBase(transformPathToKey(window.location.pathname));
    const docs = await db.getAllDocumentsByIds(selectedIds);
    setRecords(docs);
  }, [selectedIds]);
  return [
    records,
  ];
};

export default useSelectedRecordsTableHook;
