import PropTypes from 'prop-types';
import React from 'react';
import TableCellContextMenu from '../../../../../admin/Influencer/Research/Prospects/components/custom/TableCellContextMenu';

const ContextMenuInList = ({
  record,
}) => {
  const videoLink = `https://www.youtube.com/watch?v=${record.params.videoId}`;

  const handleClickViewYouTubeVideo = () => {
    if (videoLink) {
      window.open(videoLink, '_blank').focus();
    }
  };


  const handleClickCopyToClipboard = () => {
    if (record.params?.textOriginal) {
      const textField = document.createElement('textarea');
      textField.innerText = record.params.textOriginal;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
    }
  };

  const options = [
    { label: 'View YouTube Video', handler: handleClickViewYouTubeVideo },
    { label: 'Copy Comment To Clipboard', handler: handleClickCopyToClipboard },
  ];
  return (
    <div className="text-right">
      <TableCellContextMenu options={options} />
    </div>
  );
};
ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ContextMenuInList;
