import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Button,
} from 'reactstrap';

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.objectOf(PropTypes.any),
    portal: PropTypes.string,
    partial: PropTypes.bool,
    tiny: PropTypes.bool,
  }
  static defaultProps = {
    children: null,
    portal: 'admin',
    partial: false,
    tiny: false,
  }
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  componentDidCatch(error, info) {
    const { portal } = this.props;
    console.error(error, info);
    this.setState({ hasError: true });

    const errorMessage = `${error.message}  email: ${window.REDUX_STATE && window.REDUX_STATE.session ? window.REDUX_STATE.session.email : ''} url: ${window.location.href}`;
    axios.post(
      '/api/utilities/add-error',
      {
        type: 'error',
        portal,
        error: errorMessage,
        stack: error.stack,
        pageUrl: window.location.href,
        info,
      },
    ).catch((e) => {
      console.log(e);
    });
  }

  render() {
    const { partial, tiny } = this.props;
    if (this.state.hasError) {
      if (tiny) return ('Ooops! You just ran into an error we did not expect. We have been alerted and will work on making this right as soon as possible.');
      return (
        <div className="text-center">
          <div className="not-found__content">
            <h1 className="not-found__info mt-5">Ooops!</h1>
            <h3 className=" mb-3">You just ran into an error we did not expect.</h3>
            <h4 className=" mb-3">We have been alerted and will work on making this right as soon as possible.<br />
              Apologies for any inconvenience this has caused and we appreciate your patience.
            </h4>
            {!partial ? <a href="/" className="btn btn-primary radius-button">Back Home</a> : null}
            {!partial ? <Button color="primary" onClick={() => window.location.reload()} className="btn btn-primary radius-button ml-2" >Reload</Button> : null}
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
