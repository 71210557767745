// import { unflatten } from 'flat';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from 'reactstrap';
import _ from 'lodash';
import CheckboxFilter from '../../../../../shared/components/CheckboxFilter';

const Actions = React.memo(({
  brandFilters,
  setBrandFilters,
  //  programFilters,
  creatorTypeFilters,
  // setProgramFilters,
  setCreatorType,
  // matchFilters,
  // setMatchFilters,
  onSearchChange,
  setShowBulkEditModal,
  setShowRefreshModal,
  contentTypesFilters,
  setContentTypesFilters,
  searchQuery,
  searchValue,
}) => {
  const [search, setSearch] = useState(searchValue);
  const [dateSearch, setDateSearch] = useState(null);
  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);
  // console.log('RERENDER Action');
  return (
    <div>
      <Row>
        <Col md={6}>
          <div className="float-left my-1" style={{ width: '3px' }} />
          <CheckboxFilter
            filterProp={brandFilters}
            filterTitle="&nbsp;&nbsp;Brand Status"
            filterAction={({ data }) => setBrandFilters(data)}
          />
          <CheckboxFilter
            filterProp={creatorTypeFilters}
            filterTitle="Prospect Type"
            filterAction={({ data }) => setCreatorType(data)}
          />
          <CheckboxFilter
            filterProp={contentTypesFilters}
            filterTitle="Content Type"
            filterAction={({ data }) => setContentTypesFilters(data)}
          />
          {/* <CheckboxFilter */}
          {/*  filterProp={matchFilters} */}
          {/*  filterTitle="InfluenceLogic Contact" */}
          {/*  filterAction={({ data }) => setMatchFilters(data)} */}
          {/* /> */}
        </Col>
        <Col>
          <UncontrolledButtonDropdown className="mt-2 float-right">
            <DropdownToggle caret className="mr-2" color="primary">
              Actions
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => setShowBulkEditModal(true)}>Update Status</DropdownItem>
              <DropdownItem onClick={() => setShowRefreshModal(true)}>Refresh Statistics</DropdownItem>
              <DropdownItem onClick={() => window.open(`/resources/prospects/download-csv?${searchQuery}`, '_blank').focus()}>Download CSV</DropdownItem>

            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <InputGroup>
            <Input
              className="form-control-sm form-control ml-3"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search First Name, Last Name, Email, YouTube Channel"
              value={search}
              onChange={p => setSearch(p.target.value)}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </Row>
    </div>
  );
}, (a, a1) =>
  a.searchValue === a1.searchValue &&
  a.searchQuery === a1.searchQuery &&
  _.isEqual(a.creatorTypeFilters, a1.creatorTypeFilters) &&
  _.isEqual(a.contentTypesFilters, a1.contentTypesFilters) &&
  _.isEqual(a.brandFilters, a1.brandFilters));

Actions.propTypes = {
  brandFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setBrandFilters: PropTypes.func.isRequired,
  // programFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  // setProgramFilters: PropTypes.func.isRequired,
  creatorTypeFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setCreatorType: PropTypes.func.isRequired,
  // matchFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  // setMatchFilters: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  setShowBulkEditModal: PropTypes.func.isRequired,
  setShowRefreshModal: PropTypes.func.isRequired,
  contentTypesFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setContentTypesFilters: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  searchQuery: PropTypes.string.isRequired,
};
export default Actions;
