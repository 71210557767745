/* eslint react/prop-types: 0 */
import React from 'react';

const SubtypeInList = (props) => {
  const { record, property } = props;
  const value = record.params[property.name];

  let colorClass = '';
  if (value.includes('fail') || value.includes('cancel')) {
    colorClass = 'text-danger';
  }

  return (
    <span className={colorClass}>{value}</span>
  );
};

export default SubtypeInList;
