import CreatorInList from './CreatorInList';
import AskingAmountInList from './AskingAmountInList';
import OfferAmountInList from './OfferAmountInList';

export default {
  list: {
    researchCreatorAccountInfo: CreatorInList,
    askingAmount: AskingAmountInList,
    offerAmount: OfferAmountInList,
  },
};
