// import { unflatten } from 'flat';
import React from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import { parseUrl } from '../helpers/WVUtilities';


const HighLight = ({
  children,
  search,
  searchParam,

}) => {
  /* const searchList = search.split(' ');
  const textToTransform
  searchList.forEach(searchItem => {
    const reg = new RegExp(`(${searchItem})`, 'gi');
    // const componentNew = children.replace(reg, <span className="highlight">{search}</span>);
    console.log(children.split(reg));
    const componentsNew = children.split(reg).map((ea) => {
      if (ea.toLowerCase() === search.toLowerCase()) {
        return `<span className="highlight">${ea}</span>`;
      }
      return ea;
    });
  });
  */
  const filters = parseUrl();
  const searchItem = search || filters[searchParam] || '';
  // console.log(search);
  return (
    <>
      <Highlighter
        className="react-table__highlighter"
        searchWords={searchItem.split(' ')}
        autoEscape
        textToHighlight={children.toString()}
      />
    </>
  );
};

HighLight.propTypes = {
  search: PropTypes.string,
  searchParam: PropTypes.string,
  children: PropTypes.string.isRequired,
};
HighLight.defaultProps = {
  searchParam: '',
  search: '',
};
export default HighLight;
