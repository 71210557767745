import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  InputGroup,
  InputGroupAddon,
  PopoverHeader,
  PopoverBody, Popover,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import { axios } from 'ApiClient';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import EyeIcon from 'mdi-react/EyeIcon';
import Alert from '../../../shared/easydev2/shared/components/Alert';
import WVValidator from '../../../shared/helpers/WVValidator';
import config from '../../../shared/config/config';
import { initializeSession } from '../../App/store/store';
import Footer from '../LogIn/components/Footer';

const recaptchaRef = React.createRef();

class ResetPassword extends PureComponent {
  constructor() {
    super();

    this.state = {
      newPassword: '',
      confirmPassword: '',
      showNewPassword: false,
      showConfirmPassword: false,
      recaptchaToken: '',
      errors: {},
      isLoadingData: false,
      userHasLogined: false,
      popoverOpen: false,
      acceptTerms: false,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    document.title = (match.params.firstLogin === 'false') ? 'IL Brand Portal: Reset Password' : 'IL Brand Portal: Set Password';
  }

  onRecaptchaChange = (value) => {
    this.setState({
      recaptchaToken: value,
    });
  }


  onDataChanged = (event) => {
    const { name, value } = event.target;
    const state = {
      ...this.state,
      [name]: value,
    };

    delete state.errors[name]; // clear errors

    this.setState(state);
  }

  onSubmit = () => {
    if (this.state.isLoadingData) { return false; }
    if (!this.validateErrors()) { return false; }

    const { dispatch } = this.props;

    this.setState({
      isLoadingData: true,
    });

    axios({
      method: 'post',
      url: '/api/brand/reset-password',
      data: {
        newPassword: this.state.newPassword,
        confirmPassword: this.state.confirmPassword,
        token: this.props.match.params.token,
        recaptchaToken: this.state.recaptchaToken,
      },
    }).then((response) => {
      // console.log("SUCCESS RESPONSE", response);

      if (response.data.success === true) {
        dispatch(initializeSession(response.data.user));
        this.setState({
          userHasLogined: true,
        });
      } else {
        this.handleSubmitError(response.data.error);
      }
    }).catch((error) => {
      // console.log('CATCH ERROR: ',error);
      this.handleSubmitError(error.message);
    });
    return null;
  }

  handleSubmitError(error) {
    recaptchaRef.current.reset();

    const state = {
      ...this.state,
      isLoadingData: false,
    };

    state.errors.global = error;

    this.setState(state);
  }

  showNewPassword = () => {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  };

  showConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  };

  validateErrors() {
    const errors = {};

    if (WVValidator.isEmpty(this.state.newPassword)) {
      errors.newPassword = 'Please provide your new password!';
    } else if (!WVValidator.isValidPassword(this.state.newPassword)) {
      errors.newPassword = 'This password does not meet requirements!';
    } else if (WVValidator.isEmpty(this.state.confirmPassword)) {
      errors.confirmPassword = 'Please confirm your password!';
    } else if (this.state.newPassword !== this.state.confirmPassword) {
      errors.global = 'Passwords do not match!';
    } else if (WVValidator.isEmpty(this.state.recaptchaToken)) {
      errors.global = 'Please verify that you are not a robot!';
    }
    this.setState({
      ...this.state,
      errors,
    });

    return Object.entries(errors).length === 0;
  }

  togglePopover = () => {
    const { popoverOpen } = this.state;
    this.setState(prevState => ({ ...prevState, popoverOpen: !popoverOpen }));
  };

  handleChange = () => {
    const { acceptTerms } = this.state;
    this.setState(prevState => ({ ...prevState, acceptTerms: !acceptTerms }));
  }

  render() {
    const {
      userHasLogined,
      newPassword,
      confirmPassword,
      showNewPassword,
      showConfirmPassword,
      errors,
      isLoadingData,
      popoverOpen,
      acceptTerms,
    } = this.state;
    const { match } = this.props;
    return (
      <div>
        <div className="account account--not-photo login-brands-bg">
          <div className="account__wrapper">
            <div className="account__card" style={{ minWidth: '330px' }}>
              {userHasLogined && <Redirect to="/" />}
              <div className="account__head-login" style={{ marginBottom: 5 }}>
                <img src="/images/general/logo_dark_small.png" alt="IL Brand Portal" />
              </div>
              <h2 style={{ textAlign: 'center' }}>Brand Portal</h2>
              <h4 style={{ textAlign: 'center' }}>{ (match.params.firstLogin === 'false') ? 'Reset' : 'Set'} Password</h4>
              <hr />
              <FormGroup>
                <Label for="newPasswordInput">{ (match.params.firstLogin === 'false') ? 'New ' : ''}Password</Label>
                <InputGroup size="lg">
                  <Input
                    id="newPasswordInput"
                    name="newPassword"
                    type={showNewPassword ? 'text' : 'password'}
                    bsSize="lg"
                    invalid={Object.prototype.hasOwnProperty.call(errors, 'newPassword')}
                    onChange={this.onDataChanged}
                    value={newPassword}
                  />
                  <Popover
                    id="popover-new-password"
                    placement="left"
                    isOpen={popoverOpen}
                    target="newPasswordInput"
                    trigger="focus"
                    toggle={this.togglePopover}
                  >
                    <PopoverHeader>Password requirements:</PopoverHeader>
                    <PopoverBody>
                      <p>
                        at least 1 Upper Case letter<br />
                        at least 1 Lower Case letter<br />
                        at least 1 number<br />
                        at least 1 special character ($.,)(!%^*#)<br />
                        at least 8 characters in length<br />
                        New Password and Confirm Password should match
                      </p>
                    </PopoverBody>
                  </Popover>
                  <InputGroupAddon addonType="append">
                    <button
                      className={`form__form-group-button${showNewPassword ? ' active' : ''}`}
                      onClick={e => this.showNewPassword(e)}
                    ><EyeIcon />
                    </button>
                  </InputGroupAddon>
                  <FormFeedback>{errors.newPassword}</FormFeedback>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label for="confirmPassword">Confirm { (match.params.firstLogin === 'false') ? 'New ' : ''}Password</Label>
                <InputGroup size="lg">
                  <Input
                    name="confirmPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    bsSize="lg"
                    invalid={Object.prototype.hasOwnProperty.call(errors, 'confirmPassword')}
                    onChange={this.onDataChanged}
                    value={confirmPassword}
                  />
                  <InputGroupAddon addonType="append">
                    <button
                      className={`form__form-group-button${showConfirmPassword ? ' active' : ''}`}
                      onClick={e => this.showConfirmPassword(e)}
                    ><EyeIcon />
                    </button>
                  </InputGroupAddon>
                  <FormFeedback>{errors.confirmPassword}</FormFeedback>
                </InputGroup>
              </FormGroup>
              {(match.params.firstLogin === 'true') && (
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="checkbox"
                      onChange={this.handleChange}
                      value={acceptTerms}
                      name="acceptTerms"
                      className="checkbox-input-static"
                    />
                    <span>
                      Click here to indicate that you have read and agree to the InfluenceLogic&nbsp;
                      <a href="https://www.influencelogic.com/privacy-policy" target="blank">Privacy Policy</a>, &&nbsp;
                      <a href="https://www.influencelogic.com/terms-of-service" target="blank">Terms of Service</a>
                    </span>
                  </Label>
                </FormGroup>
              )}
              <FormGroup className="captcha-div">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={config().recaptcha.siteKey}
                  onChange={this.onRecaptchaChange}
                />
              </FormGroup>
              {
                Object.prototype.hasOwnProperty.call(errors, 'global') &&
                <Alert color="danger">
                  <p>{errors.global}</p>
                </Alert>
              }
              <Button
                color="primary"
                size="lg"
                block
                onClick={this.onSubmit}
                style={{ marginTop: '15px' }}
                disabled={(match.params.firstLogin === 'true') ? !acceptTerms : acceptTerms}
              >
                {isLoadingData && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
                <span>{(match.params.firstLogin === 'false') ? 'Reset Password and Login' : 'Complete Setup and Login'}</span>
              </Button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

ResetPassword.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'reset_password',
})(ResetPassword);
