import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { NoResourceError } from '../../../shared/components/components/ui/error404';
import MediaContentApprovalsContainer from './components/custom/MediaContentApprovalsContainer';


const ResourceAction = (props) => {
  const {
    resources,
    history,
    location,
  } = props;
  const [tag, setTag] = useState('');
  const resource = resources.find(r => r.id === 'MediaContent');
  if (!resource) {
    return (<NoResourceError resourceId="MediaContent" />);
  }
  const action = null;
  
  return (
    <Container >
      <Row>
        <Col>
          <h2 className="my-3">Content Approvals</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <MediaContentApprovalsContainer
            tag={tag}
            setTag={setTag}
            resource={resource}
            action={action}
            date={new Date()}
            history={history}
            location={location}
          />
        </Col>
      </Row>
    </Container>
  );
};
ResourceAction.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
