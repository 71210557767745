/* eslint-disable no-param-reassign */
import ApiClient from 'ApiClient';
import { createSlice } from '@reduxjs/toolkit';
import { addNotice } from '../../../containers/App/store/store';

/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const scoringReducer = createSlice({
  name: 'scoring',
  initialState: {
    loading: {
      fetchMediaContent: false,
      saveMediaContent: false,
      deleteMediaContent: false,
      saveScorecardAnswersSheetContent: false,
      fetchScorecardAnswersContent: false,
      fetchScorecardQuestionsContent: false,
      fetchScorecardContent: false,
      saveScorecardContent: false,
    },
    error: null,
    lastFetched: new Date(),
    mediaContent: null,
    mediaContentId: null,
    mediaContentStatus: null,
    mediaContentNotes: null,
    mediaContentScorecards: null,
    answers: null,
    scorecard: null,
    scorecardItem: null,
    newMediaContentData: false,
    showDeleteDialog: false,
    showChangeStatusDialog: false,
    showChangeApprovedStatusDialog: false,
    showHistoryStatusDialog: false,
    showHistoryUrlStatusDialog: false,
    showHistoryReviewStatusDialog: false,
    existContentWithUrl: null,
  },
  reducers: {
    getFetchMediaContentStart(state) {
      state.loading = {
        ...state.loading,
        fetchMediaContent: true,
      };
    },
    getFetchMediaContentError(state) {
      state.loading = {
        ...state.loading,
        fetchMediaContent: false,
      };
    },
    getFetchMediaContentSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        fetchMediaContent: false,
      };
      state.mediaContent = data;
    },
    getFetchScorecardAnswersContentStart(state) {
      state.loading = {
        ...state.loading,
        fetchScorecardAnswersContent: true,
      };
      state.error = null;
      state.answers = null;
    },
    getFetchScorecardAnswersContentSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        fetchScorecardAnswersContent: false,
      };
      state.error = null;
      state.answers = data;
    },
    getFetchScorecardAnswersDataSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        fetchScorecardAnswersContent: false,
      };
      state.error = null;
      state.answers = data;
    },
    getFetchScorecardAnswersContentError(state) {
      state.loading = {
        ...state.loading,
        fetchScorecardAnswersContent: false,
      };
    },
    getFetchScorecardQuestionsStart(state) {
      state.loading = {
        ...state.loading,
        fetchScorecardQuestionsContent: true,
      };
      state.error = null;
      state.scorecard = null;
    },
    getFetchScorecardQuestionsSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        fetchScorecardQuestionsContent: false,
      };
      state.error = null;
      state.scorecard = data;
    },
    getFetchScorecardQuestionsError(state) {
      state.loading = {
        ...state.loading,
        fetchScorecardQuestionsContent: false,
      };
    },
    saveMediaContentRecordStart(state) {
      state.loading = {
        ...state.loading,
        saveMediaContent: true,
      };
      state.error = null;
      state.mediaContent = null;
    },
    saveMediaContentRecordSuccess(state) {
      state.loading = {
        ...state.loading,
        saveMediaContent: false,
      };
      state.error = null;
    },
    saveMediaContentRecordError(state, action) {
      const { record } = action.payload;
      state.loading = {
        ...state.loading,
        saveMediaContent: false,
      };
      state.mediaContent = record;
    },
    deleteMediaContentRecordStart(state) {
      state.loading = {
        ...state.loading,
        deleteMediaContent: true,
      };
      state.error = null;
      state.mediaContentId = null;
    },
    deleteMediaContentRecordSuccess(state) {
      state.loading = {
        ...state.loading,
        deleteMediaContent: false,
      };
      state.error = null;
    },
    deleteMediaContentRecordError(state) {
      state.loading = {
        ...state.loading,
        deleteMediaContent: false,
      };
      state.mediaContentId = null;
    },
    showDeleteDialog(state, action) {
      state.showDeleteDialog = action.payload.show;
      if (!action.payload.show) {
        state.showDeleteDialog = false;
        state.mediaContent = null;
      } else {
        state.mediaContent = action.payload.record;
        state.loading.fetchScorecardContent = true;
      }
    },
    setMediaContentScorecards(state, action) {
      state.mediaContentScorecards = action.payload.scorecards;
      state.loading.fetchScorecardContent = false;
    },
    showChangeStatusDialog(state, action) {
      state.showChangeStatusDialog = action.payload.show;
      if (!action.payload.show) {
        state.mediaContent = null;
      } else {
        state.mediaContent = action.payload.mediaContentData;
      }
    },
    showChangeApprovedStatusDialog(state, action) {
      state.showChangeApprovedStatusDialog = action.payload.show;
      if (!action.payload.show) {
        state.showChangeApprovedStatusDialog = false;
        state.mediaContentId = null;
        state.mediaContentStatus = null;
        state.mediaContent = null;
        state.mediaContentNotes = null;
      } else {
        state.loading.fetchScorecardContent = true;
        state.mediaContent = {
          params: {
            name: action.payload.mediaContentData.name,
            id: action.payload.mediaContentData.id,
            status: action.payload.mediaContentData.status,
            notes: action.payload.mediaContentData.notes,
          },
        };
      }
    },
    showHistoryStatusDialog(state, action) {
      state.showHistoryStatusDialog = action.payload.show;
      if (!action.payload.show) {
        state.showHistoryStatusDialog = false;
        state.mediaContentId = null;
        state.mediaContent = null;
      } else {
        state.mediaContentId = action.payload.mediaContentData.id;
        state.mediaContent = action.payload.mediaContentData;
      }
    },
    showHistoryUrlStatusDialog(state, action) {
      state.showHistoryUrlStatusDialog = action.payload.show;
      if (!action.payload.show) {
        state.showHistoryUrlStatusDialog = false;
        state.mediaContentId = null;
        state.mediaContent = null;
      } else {
        state.mediaContentId = action.payload.mediaContentData.id;
        state.mediaContent = action.payload.mediaContentData;
      }
    },
    showHistoryReviewStatusDialog(state, action) {
      state.showHistoryReviewStatusDialog = action.payload.show;
      if (!action.payload.show) {
        state.showHistoryReviewStatusDialog = false;
        state.mediaContentId = null;
        state.mediaContent = null;
      } else {
        state.mediaContentId = action.payload.mediaContentData.id;
        state.mediaContent = action.payload.mediaContentData;
      }
    },
    saveScorecardAnswersSheetRecordStart(state) {
      state.loading = {
        ...state.loading,
        saveScorecardAnswersSheetContent: true,
      };
      state.error = null;
    },
    saveScorecardAnswersSheetRecordSuccess(state) {
      state.loading = {
        ...state.loading,
        saveScorecardAnswersSheetContent: false,
      };
      state.error = null;
    },
    saveScorecardAnswersSheetRecordError(state) {
      state.loading = {
        ...state.loading,
        saveScorecardAnswersSheetContent: false,
      };
    },

    getFetchScorecardStart(state) {
      state.loading = {
        ...state.loading,
        fetchScorecardContent: true,
      };
    },
    getFetchScorecardError(state) {
      state.loading = {
        ...state.loading,
        fetchScorecardContent: false,
      };
    },
    getFetchScorecardSuccess(state, action) {
      const { data } = action.payload;
      try {
        data.scorecard.params.questions.forEach((category) => {
          category.reactId = category._id;
        });
      } catch (e) {
        console.log(e);
      }
      state.scorecardItem = data.scorecard;
      state.loading = {
        ...state.loading,
        fetchScorecardContent: false,
      };
      state.error = null;
    },
    updateScorecardProperty(state, action) {
      const { data } = action.payload;
      state.scorecardItem.params[data.property] = data.value;
    },
    errorScorecardProperty(state, action) {
      const { data } = action.payload;
      state.scorecardItem.errors[data.property] = data.error;
    },
    truncateScorecardItemErrors(state) {
      state.scorecardItem.errors = {};
    },
    saveScorecardStart(state) {
      state.loading = {
        ...state.loading,
        saveScorecardContent: true,
      };
    },
    saveScorecardError(state) {
      state.loading = {
        ...state.loading,
        saveScorecardContent: false,
      };
    },
    saveScorecardSuccess(state) {
      state.loading = {
        ...state.loading,
        saveScorecardContent: false,
      };
      state.error = null;
    },
    addNewCategory(state, action) {
      const { data } = action.payload;
      if (typeof state.scorecardItem.params.questions === 'undefined') {
        state.scorecardItem.params.questions = [];
      }
      state.scorecardItem.params.questions.push({
        categoryName: data.categoryName,
        questions: [],
        reactId: 1 + (Math.random() * (99999999 - 1)), // we need to have id for frontend to attach question to such category
      });
    },
    addQuestionToCategory(state, action) {
      const { data } = action.payload;
      state.scorecardItem.params.questions.forEach((category) => {
        if (category.reactId === data.categoryReactId) {
          if (typeof category.questions === 'undefined') {
            category.questions = [];
          }
          category.questions.push(data.question);
        }
      });
    },
    deleteQuestionFromCategory(state, action) {
      const { data } = action.payload;
      state.scorecardItem.params.questions.forEach((category) => {
        if (category.reactId === data.categoryReactId) {
          category.questions.splice(data.questionIndex, 1);
        }
      });
    },
    getFetchMediaContentThumbnailSuccess(state, action) {
      const { data } = action.payload;
      state.newMediaContentData = data;
    },
    getFetchMediaContentDupeByUrlSuccess(state, action) {
      const { existContent } = action.payload;
      state.existContentWithUrl = existContent;
    },
  },
});
const fetchMediaContentApi = async (id) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/scorecard/get-media-content?id=${id}`);
};

const fetchScorecardAnswersContentApi = async (id) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/scorecard/answers/get-scorecard-answers?id=${id}`);
};

const fetchScorecardAnswersDataApi = async (id) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/scorecard/answers/get-answers?id=${id}`);
};

const fetchScorecardQuestionsApi = async (type) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/scorecard/get-scorecard-questions?type=${type}`);
};

const saveMediaContentApi = async (data) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/scorecard/media-content/save-new', data);
};

const getMediaContentScorecards = async (id) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/scorecard/answers/get-scorecard-answers?id=${id}`);
};

const updateMediaContentApi = async (data) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/scorecard/media-content/update-property', data);
};

const deleteMediaContentApi = async (data) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/scorecard/media-content/delete', data);
};

const saveScorecardAnswersSheetApi = async (data) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/scorecard/answers/save', data);
};

const fetchScorecardApi = async (scorecardId) => {
  if (scorecardId) {
    const api = new ApiClient();
    return api.client.get(`/api/portal/scorecard/get-data?id=${scorecardId}`);
  }
  return { data: { scorecard: { params: {}, errors: {} } } };
};

const saveScorecardItemForm = async (data) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/scorecard/save', data);
};

const fetchMediaContentThumbnailApi = async (type, url) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/scorecard/get-media-content-thumbnail?type=${type}&url=${url}`);
};

const fetchMediaContentDupeByUrl = async (id, url, type) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/scorecard/get-check-media-content-duplicate?id=${id}&url=${url}&type=${type}`);
};

export const {
  getFetchMediaContentStart,
  getFetchMediaContentError,
  getFetchMediaContentSuccess,
  // get answers
  getFetchScorecardAnswersContentStart,
  getFetchScorecardAnswersContentSuccess,
  getFetchScorecardAnswersDataSuccess,
  getFetchScorecardAnswersContentError,
  // get questions for latest version type of scorecard
  getFetchScorecardQuestionsStart,
  getFetchScorecardQuestionsSuccess,
  getFetchScorecardQuestionsError,
  // save Media Content
  saveMediaContentRecordStart,
  saveMediaContentRecordSuccess,
  saveMediaContentRecordError,
  // delete Media Content
  deleteMediaContentRecordStart,
  deleteMediaContentRecordSuccess,
  deleteMediaContentRecordError,
  showDeleteDialog,
  // Change Media content status
  showChangeStatusDialog,
  showChangeApprovedStatusDialog,
  // Show History Modal
  showHistoryStatusDialog,
  showHistoryUrlStatusDialog,
  showHistoryReviewStatusDialog,
  // save Scorecard Answers Sheet Content
  saveScorecardAnswersSheetRecordStart,
  saveScorecardAnswersSheetRecordSuccess,
  saveScorecardAnswersSheetRecordError,
  // get Scorecards
  getFetchScorecardStart,
  getFetchScorecardError,
  getFetchScorecardSuccess,
  updateScorecardProperty,
  errorScorecardProperty,
  truncateScorecardItemErrors,
  saveScorecardStart,
  saveScorecardError,
  saveScorecardSuccess,
  addNewCategory,
  addQuestionToCategory,
  deleteQuestionFromCategory,
  setMediaContentScorecards,
  // fetch Media Content Thumbnail
  getFetchMediaContentThumbnailSuccess,
  // fetch Media Content Duplicate
  getFetchMediaContentDupeByUrlSuccess,
} = scoringReducer.actions;

export const fetchMediaContent = id => async (dispatch) => {
  try {
    dispatch(getFetchMediaContentStart());
    const response = await fetchMediaContentApi(id);
    dispatch(getFetchMediaContentSuccess({ data: response.data }));
  } catch (err) {
    dispatch(getFetchMediaContentError(err));
    dispatch(addNotice({
      message: 'There was an error fetching records, Check out console to see more information.',
      type: 'error',
    }));
  }
};
// get Scorecard Answers Data from Media Content Id
export const getScorecardAnswers = id => async (dispatch) => {
  try {
    dispatch(getFetchScorecardAnswersContentStart());
    const response = await fetchScorecardAnswersContentApi(id);
    if (response.data) {
      dispatch(getFetchScorecardAnswersContentSuccess({ data: response.data.data }));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(getFetchScorecardAnswersContentError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const getScorecardAnswersData = id => async (dispatch) => {
  try {
    dispatch(getFetchScorecardAnswersContentStart());
    const response = await fetchScorecardAnswersDataApi(id);
    if (response.data) {
      dispatch(getFetchScorecardAnswersDataSuccess({ data: response.data.scorecardAnswers }));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(getFetchScorecardAnswersContentError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const getScorecardQuestions = type => async (dispatch) => {
  try {
    dispatch(getFetchScorecardQuestionsStart());
    const response = await fetchScorecardQuestionsApi(type);
    if (response.data.success) {
      dispatch(getFetchScorecardQuestionsSuccess({ data: response.data }));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(getFetchScorecardQuestionsError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const saveMediaContent = data => async (dispatch) => {
  let result = {};
  try {
    dispatch(saveMediaContentRecordStart());
    const response = await saveMediaContentApi(data);
    dispatch(saveMediaContentRecordSuccess());
    if (response.data.success) {
      dispatch(addNotice({
        message: 'All data has been successfully saved',
        type: 'success',
      }));
    }
    result = response.data;
  } catch (err) {
    dispatch(saveMediaContentRecordError(err));
    dispatch(addNotice({
      message: 'There was an error while saving data, Check out console to see more information.',
      type: 'error',
    }));
  }
  return result;
};

export const showDeleteMediaContentDialog = (value, record) => async (dispatch) => {
  try {
    dispatch(showDeleteDialog({ show: value, record }));
    if (record?.params?._id) {
      const response = await getMediaContentScorecards(record?.params?._id);
      if (response.data.success) {
        dispatch(setMediaContentScorecards({ scorecards: response.data.data }));
      }
    }
  } catch (err) {
    dispatch(showDeleteDialog({ show: false }));
    dispatch(getFetchScorecardError());
    dispatch(addNotice({
      message: 'There was an error while showing delete dialog, Check out console to see more information.',
      type: 'error',
    }));
  }
  return { success: true };
};

export const showChangeMediaContentStatusDialog = (value, mediaContentData) => async (dispatch) => {
  try {
    dispatch(showChangeStatusDialog({ show: value, mediaContentData }));
  } catch (err) {
    dispatch(showChangeStatusDialog({ show: false }));
    dispatch(addNotice({
      message: 'There was an error while showing change status dialog, Check out console to see more information.',
      type: 'error',
    }));
  }
  return { success: true };
};

export const showChangeMediaContentApprovedStatusDialog = (value, mediaContentData) => async (dispatch) => {
  try {
    dispatch(showChangeApprovedStatusDialog({ show: value, mediaContentData }));
    if (mediaContentData?.id) {
      const response = await getMediaContentScorecards(mediaContentData.id);
      if (response.data.success) {
        dispatch(setMediaContentScorecards({ scorecards: response.data.data }));
      }
    }
  } catch (err) {
    dispatch(showChangeApprovedStatusDialog({ show: false }));
    dispatch(addNotice({
      message: 'There was an error while showing change status dialog, Check out console to see more information.',
      type: 'error',
    }));
  }
  return { success: true };
};

export const setShowStatusHistoryDialog = (value, mediaContentData) => async (dispatch) => {
  try {
    dispatch(showHistoryStatusDialog({ show: value, mediaContentData }));
  } catch (err) {
    dispatch(showHistoryStatusDialog({ show: false }));
    dispatch(addNotice({
      message: 'There was an error while showing change history dialog, Check out console to see more information.',
      type: 'error',
    }));
  }
  return { success: true };
};

export const setShowUrlStatusHistoryDialog = (value, mediaContentData) => async (dispatch) => {
  try {
    dispatch(showHistoryUrlStatusDialog({ show: value, mediaContentData }));
  } catch (err) {
    dispatch(showHistoryUrlStatusDialog({ show: false }));
    dispatch(addNotice({
      message: 'There was an error while showing change history dialog, Check out console to see more information.',
      type: 'error',
    }));
  }
  return { success: true };
};

export const setShowReviewStatusHistoryDialog = (value, mediaContentData) => async (dispatch) => {
  try {
    dispatch(showHistoryReviewStatusDialog({ show: value, mediaContentData }));
  } catch (err) {
    dispatch(showHistoryReviewStatusDialog({ show: false }));
    dispatch(addNotice({
      message: 'There was an error while showing change history dialog, Check out console to see more information.',
      type: 'error',
    }));
  }
  return { success: true };
};

export const deleteMediaContent = recordId => async (dispatch) => {
  let result = {};
  try {
    dispatch(deleteMediaContentRecordStart());
    const response = await deleteMediaContentApi({ id: recordId });
    dispatch(deleteMediaContentRecordSuccess());
    if (response.data.success) {
      dispatch(addNotice({
        message: 'Media content with related scorecards were successfully deleted',
        type: 'success',
        duration: 5,
      }));
    }
    result = response.data;
  } catch (err) {
    dispatch(deleteMediaContentRecordError(err));
    dispatch(addNotice({
      message: 'There was an error while removing data, Check out console to see more information.',
      type: 'error',
    }));
  }
  return result;
};

export const updateMediaContentStatus = (recordId, data, prevStatus) => async (dispatch) => {
  let result = {};
  try {
    dispatch(saveMediaContentRecordStart());
    const response = await updateMediaContentApi({ id: recordId, data, prevStatus });
    dispatch(saveMediaContentRecordSuccess());
    if (response.data.success) {
      dispatch(addNotice({
        message: 'Media content status has been successfully updated',
        type: 'success',
      }));
    }
    result = response.data;
  } catch (err) {
    dispatch(saveMediaContentRecordError(err));
    dispatch(addNotice({
      message: 'There was an error while updating data, Check out console to see more information.',
      type: 'error',
    }));
  }
  return result;
};

export const saveScorecardAnswersSheet = data => async (dispatch) => {
  let result = {};
  try {
    dispatch(saveScorecardAnswersSheetRecordStart());
    const response = await saveScorecardAnswersSheetApi(data);
    dispatch(saveScorecardAnswersSheetRecordSuccess());
    if (response.data.success) {
      dispatch(addNotice({
        message: 'All data has been successfully saved',
        type: 'success',
      }));
    }
    result = response.data;
  } catch (err) {
    dispatch(saveScorecardAnswersSheetRecordError(err));
    dispatch(addNotice({
      message: 'There was an error while saving data, Check out console to see more information.',
      type: 'error',
    }));
  }
  return result;
};

export const fetchScorecards = scorecardId => async (dispatch) => {
  try {
    dispatch(getFetchScorecardStart());
    const response = await fetchScorecardApi(scorecardId);
    if (response.data) {
      dispatch(getFetchScorecardSuccess({ data: response.data }));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(getFetchScorecardError(err));
    dispatch(addNotice({
      message: 'There was an error fetching records, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const updateScorecardItem = (property, value) => async (dispatch) => {
  dispatch(updateScorecardProperty({ data: { property, value } }));
};

export const addNewScorecardCategory = categoryName => async (dispatch) => {
  dispatch(addNewCategory({ data: { categoryName } }));
};

export const errorInScorecardItem = (property, error) => async (dispatch) => {
  dispatch(errorScorecardProperty({ data: { property, error } }));
};

export const clearScorecardItemErrors = () => async (dispatch) => {
  dispatch(truncateScorecardItemErrors());
};

export const saveScorecardItem = data => async (dispatch) => {
  try {
    dispatch(saveScorecardStart());
    const response = await saveScorecardItemForm(data);
    if (response.data.success) {
      dispatch(addNotice({
        message: 'Scorecard Definition has been successfully saved',
        type: 'success',
      }));
      dispatch(saveScorecardSuccess());
    } else {
      throw new Error('There was an error while saving data, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(saveScorecardError(err));
    dispatch(addNotice({
      message: 'There was an error while saving data, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export const addQuestionToScorecardCategory = (categoryReactId, question) => async (dispatch) => {
  dispatch(addQuestionToCategory({ data: { categoryReactId, question } }));
};

export const deleteQuestionFromScorecardCategory = (categoryReactId, questionIndex) => async (dispatch) => {
  dispatch(deleteQuestionFromCategory({ data: { categoryReactId, questionIndex } }));
};

export const fetchMediaContentThumbnail = (type, url) => async (dispatch) => {
  try {
    const response = await fetchMediaContentThumbnailApi(type, url);
    if (response.data.success) {
      dispatch(getFetchMediaContentThumbnailSuccess({ data: response.data.data }));
    }
  } catch (err) {
    console.log('Error while fetching thumbnail', err);
  }
};

export const fetchMediaContentDuplicate = (id, url, type) => async (dispatch) => {
  try {
    const response = await fetchMediaContentDupeByUrl(id, url, type);
    if (response.data.success) {
      dispatch(getFetchMediaContentDupeByUrlSuccess({ existContent: response.data.existContent }));
    }
  } catch (err) {
    console.log('Error while fetching Media Content duplicate', err);
  }
};

export default scoringReducer.reducer;
