import React, { useState, useMemo, useEffect } from 'react';
import { useFlexLayout } from 'react-table';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Progress, Col } from 'reactstrap';
import ViewHelpers from '@/shared/ViewHelpers';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import PropertyType from '../../../shared/components/components/property-type';
import AdminBroServerSide from './AdminBroServerSide';
import AdminActions from '../materialTable/components/AdminActions';
import { removeSelectedRecord, setSelectedRecord, selectAll, diselectAll, setUrlParamsForTable } from '../../../redux/reducers/TableReducer';

const ProgressObj = () => {
  const [value, setValue] = useState(0);
  useEffect(() => {
    let cleanupFunction = false;
    setTimeout(() => {
      if (!cleanupFunction) setValue(value + 3);
    }, 100);
    return () => {
      cleanupFunction = true;
      return true;
    };
  }, [value]);
  return (<Progress animated value={value} />);
};
const CustomCheckbox = withStyles({
  root: {
    color: '#70bbfd',
    '&$checked': {
      color: '#70bbfd',
    },
  },
  checked: {},
})(React.forwardRef((props, ref) => <Checkbox color="default" size="small" {...props} ref={ref} />));

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    if (resolvedRef.current) resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);
  // console.log(rest);
  return (
    <div style={{ paddingTop: '4px' }}>
      <CustomCheckbox
        name={Math.random().toString(36).slice(2)}
        label=""
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        checked={!rest.disabled && rest.checked}
        onClick={e => !rest.disabled && e.target && rest.onChange(e)}
        ref={resolvedRef}
        disabled={rest.disabled}
      />
    </div>
  );
});
IndeterminateCheckbox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  indeterminate: PropTypes.any.isRequired,
};

const CheckBox = React.memo(({
  dispatch, id, selected, selectAllCheckbox, disabled, record,
}) => {
  const defaultRef = React.useRef();
  const onChange = ({ target }) => {
    if (target.checked) {
      if (selectAllCheckbox) {
        dispatch(selectAll());
      } else {
        dispatch(setSelectedRecord({ selectedItemNumber: id, record }));
      }
    } else if (selectAllCheckbox) {
      dispatch(diselectAll());
    } else {
      dispatch(removeSelectedRecord(id));
    }
  };
  return (
    <div style={{ paddingTop: '4px' }}>
      <CustomCheckbox
        name={Math.random().toString(36).slice(2)}
        label=""
        className="myCheckBox"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        checked={selected}
        onClick={e => !disabled && e.target && onChange(e)}
        ref={defaultRef}
        disabled={disabled}
      />
    </div>
  );
});
CheckBox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.any,
  selectAllCheckbox: PropTypes.bool,
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
};
CheckBox.defaultProps = {
  record: null,
  disabled: false,
  id: null,
  selectAllCheckbox: false,
};
const ConnecteCheckBox = connect((state, props) => {
  let isSelected = false;
  if (state.table.records.length > 0) {
    isSelected = props.selectAllCheckbox ?
      state.table.records.length === state.table?.selectedRecords.length :
      state.table?.selectedRecords?.includes(props.id);
  }
  // console.log(state.table?.selectedRecordsIdsMultiselect);
  if (props.original?.id && state.table.useMultiPageCheckboxes) {
    isSelected = state.table?.selectedRecordsIdsMultiselect?.includes(props.original?.id);
  }
  // console.log(props);
  return {
    selected: isSelected,
    record: props.original,
  };
})(CheckBox);

const AdminBroTable = React.memo(({
  resource,
  records,
  direction,
  sortBy,
  isLoading,
  page,
  perPage,
  total,
  location,
  history,
  UserPropertyType,
  noActions,
  deleteItem,
  meta,
  tableConfig,
  updateDraggableData,
  updateEditableData,
  radioButtonHandler,
  useCheckboxes,
  checkboxHandler,
  customColumns,
  columnsSettings,
  displayProgressBar,
  pseudoUrlFiltering,
  pseudoUrl,
  updatePseudoUrl,
  updateRecordFunction,
  enabledStyles,
  enableWarning,
  notFoundProps,
}) => {
  // const [loadingStart, setLoadingStart] = useState(false);
  const [selected, setSelected] = useState(null);
  const [columnWidths, setColumnWidths] = useState({});
  // const [selectedItems, setSelectedItems] = useState([]);

  // useEffect(() => {
  //   setSelectedItems([]);
  // }, [records]);

  // useEffect(() => {
  //   if (useCheckboxes && checkboxHandler) checkboxHandler(selectedItems);
  // }, [selectedItems]);
  // useEffect(() => {
  //   if (isLoading === false) setLoadingStart(false);
  // }, [isLoading]);
  // console.log('RERENDER');
  const generateTableCell = (record, col) => {
    const Action = UserPropertyType?.list && UserPropertyType.list[col.name]
      ? UserPropertyType.list[col.name]
      : PropertyType;
    return (
      <div key={(new Date()).toString()}>
        <Action
          key={col.name}
          where="list"
          property={col}
          resource={resource}
          record={record}
        />
      </div>);
  };

  const h = useMemo(() => new ViewHelpers(), [resource]);
  const handleClickAction = (action, id) => {
    if (action === 'edit') {
      const recordActionUrl = h.recordActionUrl({ resourceId: resource.id, recordId: id, actionName: 'edit' });
      return history.push(recordActionUrl);
    }
    if (action === 'show') {
      const recordActionUrl = h.recordActionUrl({ resourceId: resource.id, recordId: id, actionName: 'show' });
      return history.push(recordActionUrl);
    }
    return '';
  };

  const deleteItemFunc = (id) => {
    deleteItem(id);
  };
  const generateTableCellIcons = (d) => {
    const Actions = UserPropertyType && UserPropertyType.actions ? UserPropertyType.actions : null;
    return Actions ? (
      <div key={(new Date()).toString()}>
        <Actions
          property="Actions"
          record={d}
          meta={meta}
        />
      </div>) : d.recordActions.map(action =>
      (<AdminActions
        key={`${action.name}${d.id}`}
        action={action}
        parentItem={d}
        meta={meta}
        deleteItemFunc={deleteItemFunc}
        handleClickAction={handleClickAction}
      />));
  };
  const generateTableCellRadio = d => (
    <Radio
      checked={selected === d.id}
      className="material-table__checkbox"
      onClick={() => setSelected(selected === d.id ? null : d.id) || radioButtonHandler(d.id) || console.log(d.id)}
      size="small"
    />
  );

  /* const selectCheckbox = (id, value) => {
    const s = [...selectedItems];
    if (value) s.push(id);
    else {
      const index = selectedItems.indexOf(id);
      if (index > -1) s.splice(index, 1);
    }
    setSelectedItems(s);
  }; */

  const columnTitles = resource.listProperties;
  const columns = useMemo(() => {
    let ret = [];
    if (customColumns && customColumns.length > 0) {
      ret = customColumns.map((customColumn) => {
        const column = { ...customColumn };
        const width = columnWidths[column.id] || (columnsSettings && columnsSettings[column.id] ? columnsSettings[column.id].width : null);
        if (width) column.width = width;
        return (column);
      });
    } else {
      ret = columnTitles.map((col) => {
        // console.log(col);
        let className = '';
        switch (col.propertyPath) {
          case 'researchCreatorAccountInfo':
            className = 'research-creator-account-info-grid-cell';
            break;
          default:
            className = '';
        }
        const column = {
          Header: col?.custom?.label || col.label,
          accessor: () => null,
          id: col.propertyPath,
          className,
          width: 150,
          Cell: p => useMemo(() => generateTableCell(p.row.original, col, updateRecordFunction), [p.row.original, col, columnWidths]),
          ...columnsSettings && columnsSettings[col.propertyPath],
        };
        const width = columnWidths[col.propertyPath] || (columnsSettings && columnsSettings[col.propertyPath] ? columnsSettings[col.propertyPath].width : null);
        if (width) column.width = width;
        return (column);
      });
    }
    if (!noActions) {
      const width = columnsSettings && columnsSettings?.actions?.width ? columnsSettings?.actions?.width : null;
      ret.push({
        Header: '',
        accessor: () => null,
        id: 'actions',
        className: '',
        Cell: p => useMemo(() => generateTableCellIcons(p.row.original, updateRecordFunction), [p.row.original]),
        disableSortBy: true,
        minWidth: 45,
        ...width && { width },
      });
    }
    if (radioButtonHandler) {
      ret.unshift({
        Header: '',
        accessor: () => null,
        id: 'select',
        className: 'custom-td-width',
        Cell: p => useMemo(() => generateTableCellRadio(p.row.original), [p.row.original, selected]),
        disableSortBy: true,
        maxWidth: 70,
      });
    }
    /* if (useCheckboxes && checkboxHandler) {
      ret.unshift({
        Header: () => (
          <CustomCheckbox
            name="selectAll"
            label=""
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            checked={records && records.length > 0 && selectedItems.length === records.length}
            onClick={({ target }) => {
              if (!target) return false;
              if (target.checked) {
                setSelectedItems(records.map(account => (account.id || '')));
              } else {
                setSelectedItems([]);
              }
              return true;
            }}
          />
        ),
        accessor: () => null,
        id: 'select',
        maxWidth: 30,
        Cell: record => (
          <div style={{ paddingTop: '4px' }}>
            <CustomCheckbox
              name={`check${record.row.original.id}`}
              label=""
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              checked={selectedItems.includes(record.row.original.id)}
              onClick={({ target }) => target && (selectCheckbox(record.row.original.id, target.checked))}
            />
          </div>
        ),
        disableSortBy: true,
        disableFilters: true,
      });
    } */
    return ret;
  }, [
    columnTitles,
    columnsSettings,
    customColumns,
    selected,
    columnWidths,
  ]);
  const data = records;
  const {
    isEditable,
    isResizable,
    isSortable,
    withDragAndDrop,
    useFlex,
    // withPagination,
    // withSearchEngine,
    // manualPageSize,
  } = tableConfig;
  // const [filterValue, setFilterValue] = useState(null);
  const tableLocation = useSelector(s => s.table.pageLocation);
  const tableOptions = {
    columns,
    data,
    updateDraggableData,
    updateEditableData,
    // setFilterValue,
    defaultColumn: {},
    isEditable,
    withDragAndDrop: withDragAndDrop || false,
    dataLength: total,
    autoResetPage: false,
    disableSortBy: !isSortable,
    disableSortRemove: true, // remove third click action on Sort arrow icon in table header
    manualSortBy: true,
    manualGlobalFilter: true,
    manualPagination: true,
    initialState: {
      pageIndex: (page - 1),
      pageSize: perPage,
      // globalFilter: withSearchEngine && filterValue ? filterValue : undefined,
      sortBy: [{ id: sortBy, desc: direction === 'desc' }],
      allowChange: false,
    },
    pageCount: Math.ceil(Number(total || 0) / Number(perPage || 0)),
  };
  // console.log(tableOptions);
  const dispatch = useDispatch();
  const generateUrl = (o) => {
    if (window.location.pathname === tableLocation) {
      console.log(o, 'Set additional');
      dispatch(setUrlParamsForTable({
        page: ((o.pageIndex || 0) + 1),
        perPage: o.pageSize.toString(),
        direction: o.sortBy[0]?.desc === false ? 'asc' : 'desc',
        sortBy: o.sortBy[0]?.id,
      }));
      return false;
    }
    console.log(o, 'Set New');
    const search = new URLSearchParams(pseudoUrlFiltering ? pseudoUrl : location.search);
    search.set('page', ((o.pageIndex || 0) + 1));
    search.set('perPage', o.pageSize.toString());
    search.set('direction', o.sortBy[0]?.desc === false ? 'asc' : 'desc');
    search.set('sortBy', o.sortBy[0]?.id);
    if (pseudoUrlFiltering) {
      updatePseudoUrl(search.toString());
    } else {
      history.push(`${location.pathname}?${search.toString()}`);
    }
    return true;
  };
  let tableOptionalHook = [];
  if (isResizable || useFlex) tableOptionalHook = [useFlexLayout];
  if (useCheckboxes && checkboxHandler) {
    tableOptionalHook.push((hooks) => {
      hooks.visibleColumns.push(columnsAll => [
        // Let's make a column for selection
        {
          minWidth: 60,
          width: 60,
          maxWidth: 60,
          id: 'selection1',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          // eslint-disable-next-line react/prop-types
          Header: props => (
            <div>
              <ConnecteCheckBox props={{ ...props }} selectAllCheckbox />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          // eslint-disable-next-line react/prop-types
          Cell: ({ row }) => (
            <div>
              {/* eslint-disable-next-line react/prop-types */}
              <ConnecteCheckBox id={Number(row.id)} disabled={row?.original?.checkboxDisable || false} original={row?.original} />
            </div>
          ),
          className: (useFlex ? 'd-flex align-items-center' : ''),
        },
        ...columnsAll,
      ]);
    });
  }

  return (
    <Col>
      {displayProgressBar && isLoading ? <ProgressObj /> : null}
      <AdminBroServerSide
        key={isResizable || isEditable ? 'modified' : 'common'}
        onFetchData={props => generateUrl(props)}
        tableConfig={tableConfig}
        tableOptions={tableOptions}
        tableOptionalHook={tableOptionalHook}
        isLoading={isLoading}
        pageIndex={(page - 1)}
        pageSize={perPage}
        updateRecordFunction={updateRecordFunction}
        // updateSelect={(e) => {
        //   const keys = Object.keys(e).map(k => records[k].id);
        //   if (checkboxHandler) checkboxHandler(keys);
        // } /* checkboxHandler */}
        enabledStyles={enabledStyles}
        enableWarning={enableWarning}
        onColumnResize={receivedColumnWidths => setColumnWidths({ ...columnWidths, ...receivedColumnWidths })}
        notFoundProps={notFoundProps}
      />
    </Col>
  );
}, (a, a1) => _.isEqual(a.records, a1.records)
&& _.isEqual(a.customColumns, a1.customColumns)
&& _.isEqual(a.columnsSettings, a1.columnsSettings)
&& _.isEqual(a.resource, a1.resource)
&& _.isEqual(a.version, a1.version)
&& a.isLoading === a1.isLoading);

AdminBroTable.propTypes = {
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  UserPropertyType: PropTypes.objectOf(PropTypes.any).isRequired,
  records: PropTypes.arrayOf(PropTypes.any).isRequired,
  meta: PropTypes.objectOf(PropTypes.any),
  direction: PropTypes.string,
  sortBy: PropTypes.string,
  deleteItem: PropTypes.func,
  tableConfig: PropTypes.shape({
    isEditable: PropTypes.bool,
    isResizable: PropTypes.bool,
    useFlex: PropTypes.bool,
    isSortable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    withPagination: PropTypes.bool,
    withSearchEngine: PropTypes.bool,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
  }),
  page: PropTypes.number,
  perPage: PropTypes.number,
  total: PropTypes.number,
  updateDraggableData: PropTypes.func,
  updateEditableData: PropTypes.func,
  noActions: PropTypes.bool,
  isLoading: PropTypes.bool,
  radioButtonHandler: PropTypes.func,
  useCheckboxes: PropTypes.bool,
  checkboxHandler: PropTypes.func,
  columnsSettings: PropTypes.objectOf(PropTypes.any),
  customColumns: PropTypes.arrayOf(PropTypes.any),
  displayProgressBar: PropTypes.bool,
  pseudoUrlFiltering: PropTypes.bool,
  pseudoUrl: PropTypes.string,
  updatePseudoUrl: PropTypes.func,
  updateRecordFunction: PropTypes.func,
  enabledStyles: PropTypes.bool,
  enableWarning: PropTypes.bool,
  notFoundProps: PropTypes.objectOf(PropTypes.any),
};

AdminBroTable.defaultProps = {
  meta: {},
  direction: 'asc',
  sortBy: 'id',
  isLoading: false,
  tableConfig: {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withDragAndDrop: false,
    withPagination: true,
    withSearchEngine: false,
    useFlex: false,
    manualPageSize: [],
  },
  total: 2,
  page: 1,
  perPage: 10,
  noActions: false,
  updateDraggableData: () => {},
  updateEditableData: () => {},
  pseudoUrlFiltering: false,
  pseudoUrl: '',
  updatePseudoUrl: () => {},
  deleteItem: () => {},
  radioButtonHandler: null,
  useCheckboxes: false,
  checkboxHandler: null,
  columnsSettings: {},
  customColumns: [],
  displayProgressBar: true,
  updateRecordFunction: () => console.log('function Not Defined'),
  enabledStyles: true,
  enableWarning: true,
  notFoundProps: {},
};

export default AdminBroTable;
