/* eslint react/prop-types: 0 */
import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  BarChart,
  Bar,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import { formatNumber } from '../../../../shared/helpers/WVFormatter';

const colorOptions = [
  '#e6194B',
];

const chartMargins = {
  top: 5,
  right: 0,
  left: 0,
  bottom: 40,
};

const CustomizedTooltip = ({ active, payload }) => {
  const getText = payloadIn => `${formatNumber(payloadIn.negativePercentage, 0)}% - ${payloadIn.negativeCount} of ${payloadIn.count} comment${payloadIn.count > 1 ? 's' : ''}`;
  if (active && payload && payload.length && payload[0].payload) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: 'hsla(0, 0%, 100%, .9)',
          border: '1px solid #f5f5f5',
          lineHeight: '24px',
          margin: '0',
          padding: '10px',
        }}
      >
        <p className="bold-text">{payload[0].payload.name}</p>
        <span className="bold-text">{payload[0].payload.title}</span>
        <ul
          style={{
            listStyle: 'none',
            padding: '0',
            margin: '0',
          }}
        >
          {payload && payload[0] && (
            <li
              key="negativePercentage"
              style={{
                color: colorOptions[0],
              }}
            >
              {getText(payload[0].payload)}
            </li>
          )}
        </ul>
      </div>
    );
  }
  return null;
};

const CustomizedXAxisTick = ({ x, y, payload }) => {
  let nameAbr = '';
  if (payload.value && payload.value?.includes(' ')) {
    payload.value.split(' ').forEach((string) => {
      nameAbr += (string[0] === '(' && string.length > 1) ? string[1] : string[0];
    });
  } else {
    nameAbr = payload.value;
  }
  nameAbr = nameAbr ? nameAbr.substring(0, 7) : '';
  const children = (
    <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
      {nameAbr}
    </text>
  );
  // const values = [16, 30, 44, 58, 72, 86];
  // const children = payload && payload.value && payload.value.includes(' ')
  //   ? payload.value.split(' ').map(((string, index) => (
  //     <text
  //       key={`textKey${new Date().getTime()}-${string}`}
  //       x={0}
  //       y={0}
  //       dy={values[index]}
  //       textAnchor="middle"
  //       fill="#666"
  //     >
  //       {string}
  //     </text>
  //   ))) : (
  //     <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
  //       {payload.value}
  //     </text>
  //   );
  return (
    <g transform={`translate(${x},${y})`}>
      {children}
    </g>
  );
};

const CustomizedYAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={0} textAnchor="end" fill="#666">
      {`${formatNumber(payload?.value, 0)}%`}
    </text>
  </g>
);

const ChartComponentVideosNegative = ({
  periodsData,
}) => (
  <Row>
    <Col>
      {periodsData.length ? (
        <Row>
          <Col md={10} style={{ paddingRight: '0' }}>
            <ResponsiveContainer
              width="100%"
              height={400}
              debounce={1}
            >
              <BarChart
                width="100%"
                height="90%"
                data={periodsData}
                margin={chartMargins}
              >
                <XAxis
                  dataKey="name"
                  padding={{ left: 0, right: 8 }}
                  tick={<CustomizedXAxisTick />}
                  interval={0}
                />
                <YAxis
                  padding={{ top: 8, bottom: 0 }}
                  tick={<CustomizedYAxisTick />}
                  domain={['dataMin', 'dataMax']}
                  interval="preserveStartEnd"
                  allowDecimals={false}
                />
                <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                <Tooltip content={<CustomizedTooltip />} />
                <Bar
                  key="barNegative"
                  stackId="name"
                  dataKey="negativePercentage"
                  fill={colorOptions[0]}
                />
              </BarChart>
            </ResponsiveContainer>
          </Col>
        </Row>
      ) : (
        <span className="mt-2">No data to chart</span>
      )}
    </Col>
  </Row>
);

ChartComponentVideosNegative.propTypes = {
  periodsData: PropTypes.arrayOf(PropTypes.any),
};

ChartComponentVideosNegative.defaultProps = {
  periodsData: [],
};

export default ChartComponentVideosNegative;
