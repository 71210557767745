import { unflatten } from 'flat';
import React from 'react';
import PropTypes from 'prop-types';

const InfluencerCompanyTuneId = ({ record }) => {
  const recortUnflatten = unflatten(record);
  const affiliateTuneId = recortUnflatten.params.affiliateDataObject
    ? recortUnflatten.params.affiliateDataObject.tune_id : '';
  const companyUrl = affiliateTuneId
    ? `https://influencelogic.hasoffers.com/admin/affiliates/view/${affiliateTuneId}` : '';
  return (
    <span>
      {affiliateTuneId ? (
        <a href={companyUrl} target="_blank" rel="noopener noreferrer">{affiliateTuneId}</a>
      ) : ''}
    </span>
  );
};
InfluencerCompanyTuneId.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default InfluencerCompanyTuneId;
