import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { axios } from 'ApiClient';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import 'bootstrap/dist/css/bootstrap.css';
// import RecordsTable from 'admin-bro/lib/frontend/components/app/records-table';
import '../../scss/app.scss';
import '../../scss/brands.scss';
import Router from './Router/RouterBrands';
import store from './store/brandStore';
import ScrollToTop from './ScrollToTop';
import ErrorBoundary from '../../shared/components/ErrorBoundary';
import { activateHideNavigationMenuOnClick } from '../../shared/helpers/MenuAndNavigation';

const AppBrand = () => {
  const [loading] = useState(true);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const load = async () => {
      const res = await axios('/api/brand/session-info');
      if(res.data.store) {
        window.REDUX_STATE = res.data.store;
        window.constants = res.data.constants;
        window.process = { env: { NODE_ENV: res.data.NODE_ENV } };
        window.portal = 'brand';
        setLoaded(true)
      }
    }
    load();
    activateHideNavigationMenuOnClick();
  }, []);


  if(!window.REDUX_STATE) return (
    <div className={`load${loading ? '' : ' loaded'}`}>
      <div className="load__icon-wrap">
        <svg className="load__icon" data-testid="loading-circle">
          <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
        </svg>
      </div>
    </div>
  );
  const reduxStore = store(window.REDUX_STATE);
  return (
    <Provider store={reduxStore}>
      <BrowserRouter>
        <ScrollToTop>
          <ThemeProvider
            theme={{
              mode: 'light',
              direction: 'ltr',
              shadow: 'on',
            }}
          >
            <div>
              {!loaded &&
              <div className={`load${loading ? '' : ' loaded'}`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </div>
              }
              <div>
                <ErrorBoundary portal="brand">
                  <Router />
                </ErrorBoundary>
              </div>
            </div>
          </ThemeProvider>
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  );
};

export default AppBrand;

