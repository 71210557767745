/* eslint react/prop-types: 0 */
import React from 'react';
import Select from 'react-select';
import ApiClient from 'ApiClient';

const mapStatusArray = {
  1: 'New',
  2: 'Already Contacted',
  3: 'Not Relevant',
};
const mapStatus = [
  {
    value: '1', label: 'New',
  },
  {
    value: '2', label: 'Already Contacted',
  },
  {
    value: '3', label: 'Not Relevant',
  },
];

export default class PodcastUserStatusInList extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.api = new ApiClient();
    const { record } = this.props;
    const status = record.populated.influencer ? record.populated.influencer.params?.contactStatus : null;
    this.state = {
      selected: { value: status ? status.toString() : status, label: mapStatusArray[status] },
      loading: false,
      status,
    };
  }

  handleSelectChange(selected) {
    this.setState({ selected });
    this.setState({ loading: true });
    const { record } = this.props;
    const value = selected ? selected.value : '';
    if (value === '') {
      return false;
    }
    const { _id } = record.populated.influencer.params;
    const data = new FormData();
    data.append('contactStatus', value);
    this.api.recordAction({
      resourceId: 'Influencer',
      recordId: _id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(() => {
      this.setState({ loading: false });
    });
    return true;
  }

  render() {
    // const { record } = this.props;
    const { selected, loading, status } = this.state;
    // const status = record.params.status;
    // console.log(mapStatus, status);
    return status === null ? 'Unable To find Influencer' : (
      <div style={{ minWidth: '150px' }}>
        <Select
          isDisabled={loading}
          value={selected}
          options={mapStatus}
          onChange={this.handleSelectChange}
          menuPlacement="auto"
          menuPortalTarget={document.body}
        />
      </div>
    );
  }
}
