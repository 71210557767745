import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DataBase from '../helpers/indexDB';
import { transformPathToKey } from '../../redux/reducers/TableReducerDefault';


const useTableHook = () => {
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const records = useSelector(state => state.tableRedux.records);
  const loading = useSelector(state => state.tableRedux.loading);
  const recordIds = useSelector(state => state.tableRedux.records);
  const page = useSelector(state => state.tableRedux.page);
  const perPage = useSelector(state => state.tableRedux.perPage);
  const resourceId = useSelector(state => state.tableRedux.resourceId);
  const direction = useSelector(state => state.tableRedux.direction);
  const sortBy = useSelector(state => state.tableRedux.sortBy);
  const total = useSelector(state => state.tableRedux.total);
  const lastFetched = useSelector(state => state.tableRedux.lastFetched);

  useEffect(async () => {
    const db = await DataBase(transformPathToKey(window.location.pathname));
    const docs = await db.getAllDocuments();

    const docsSorted = recordIds?.map(id => docs.find(d => d.id === id.id)).filter(a => a);
    setRecords(docsSorted || []);
  }, [lastFetched]);
  useEffect(() => {
    if (!loading) {
      setIsLoading(false);
    }
  }, [records]);
  useEffect(() => {
    if (loading) setIsLoading(true);
  }, [loading]);
  return {
    records, loading: isLoading, page, perPage, resourceId, direction, sortBy, total, lastFetched,
  };
};

export default useTableHook;
