/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import PropTypes from 'prop-types';
import CopyToClipboard from '../../../../../shared/components/CopyToClipboard';

const VideoDescription = ({ record }) => {
  const [openPopover, setOpenPopover] = useState(false);
  const [popoverTargetId] = useState(`descriptionPopoverTargetId_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10)}`);
  const wrapperRef = useRef(null);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        // console.log(ref.current);
        if (ref.current && !ref.current?.contains(event.target)) {
          setOpenPopover(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  const toggle = () => {
    setOpenPopover(!openPopover);
  };
  return (
    <div style={{ width: '240px' }}>
      {record.row.original.params?.contentText?.length > 90 ?
        <div>
          <Popover
            id={`selfId_${popoverTargetId}`}
            placement="right"
            target={popoverTargetId}
            isOpen={openPopover}
          >
            <PopoverBody
              style={{
                fontSize: '12px',
                maxHeight: '500px',
                overflowY: 'auto',
              }}
            >
              <span
                ref={wrapperRef}
                dangerouslySetInnerHTML={{ __html: `${record.row.original.params?.contentText || ''}` }}
              />
            </PopoverBody>
          </Popover>
          <div
            id={popoverTargetId}
            style={{ textAlign: 'left' }}
          >
            <CopyToClipboard value={record.row.original.params?.contentText || ''}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
              <a role="button" tabIndex={0} onClick={toggle}>
                <span dangerouslySetInnerHTML={{ __html: `${record.row.original.params?.contentText.toString().substring(0, 90) || ''}...  ` }} />
              </a>
            </CopyToClipboard>
          </div>
        </div> :
        <CopyToClipboard value={record.row.original.params?.contentText || ''}>
          <span dangerouslySetInnerHTML={{ __html: `${record.row.original.params?.contentText || ''}  ` }} />
        </CopyToClipboard>
      }
    </div>
  );
};

VideoDescription.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default VideoDescription;
