/* eslint react/prop-types: 0 */
import React from 'react';
import { Link } from 'react-router-dom';
import ViewHelpers from '@/shared/ViewHelpers';

const ReferenceValue = (props) => {
  const { property, record } = props;

  const h = new ViewHelpers();
  const refId = record.params[property.name];
  const populated = record.populated[property.name];
  const value = (populated && populated.title) || refId;

  if (!property.reference) {
    throw new Error(`property: "${property.name}" does not have a reference`);
  }

  if (populated && populated.recordActions.find(a => a.name === 'show')) {
    const href = h.recordActionUrl({
      resourceId: property.reference, recordId: refId, actionName: 'show',
    });
    return (
      <Link to={href}>{value}</Link>
    );
  }
  return (
    <span>{value}</span>
  );
};

export default ReferenceValue;
