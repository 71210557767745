import React from 'react';

export default (props) => {
  const { record } = props;
  console.log(record.populated);
  const url = `${window.location.href.substring(0, window.location.href.indexOf('resources'))}influencer/profile/${record.params.influencer}`;
  // eslint-disable-next-line no-unused-vars
  const result = ((typeof record.populated.influencer !== 'undefined' && record.populated.influencer !== null)
    ? (
      <a href={url} target="_blank" rel="noreferrer">
        {`${record.populated.influencer.params.first_name} ${record.populated.influencer.params.last_name}`}
      </a>
    )
    : '');
  return '';
};
