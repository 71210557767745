import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const getName = (record) => {
  console.log(record);
  const name = `${record.influencer?.first_name || ''} ${record.influencer?.last_name || ''}`;
  if (record.influencer?._id && name) {
    // eslint-disable-next-line react/jsx-no-target-blank
    return (
      <div
        className=""
      >
        <Link
          to={`/creator/${record.influencer._id}`}
          rel="noreferrer"
        >
          {name}
        </Link>
      </div>
    );
  } else if (name) {
    return (name);
  }
  return null;
};

export default class CreatorNameInList extends React.Component {
  static propTypes = {
    record: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { record } = this.props;
    const companyName = (`${record.influencer?.affiliateDataObject?.company ?
      `${record.influencer?.affiliateDataObject.company || ''} ` :
      ''}`);
    return (
      <div>
        <b>{companyName}</b><br />
        {getName(record)}
      </div>
    );
  }
}
