import React from 'react';
import PropTypes from 'prop-types';
import SendIcon from 'mdi-react/SendIcon';
import Tooltip from '@material-ui/core/Tooltip';

export default class ScheduledJobsNotificationSettingsButton extends React.Component {
  static propTypes = {
    jobId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.showNotificationSettings = this.showNotificationSettings.bind(this);
  }

  showNotificationSettings = () => {
    const { jobId } = this.props;
    if (jobId) {
      const win = window.open(`/job-notifications/${jobId}`, '_self');
      win.focus();
    }
  };

  render() {
    return (
      <Tooltip
        title="Job Notification Settings"
      >
        <button
          className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall"
          onClick={this.showNotificationSettings}
        >
          <SendIcon
            style={{ width: '15px', height: '15px' }}
          />
        </button>
      </Tooltip>
    );
  }
}

