import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFilterProperty, setTableProperty } from '../../redux/reducers/TableReducerDefault';


const useTableFilterHook = (id, prefill) => {
  const state = useSelector(s => s.tableRedux.tableActions[id]);
  const properties = useSelector(s => s.tableRedux.tablesProperties);
  const dispatch = useDispatch();
  const setState = value => dispatch(setFilterProperty(id, value));
  const property = useSelector(s => s.tableRedux.tablesProperties || []).find(d => String(d.filterName) === id);
  // console.log(useSelector(s => s.table.tablesProperties || []));
  // console.log([state, setState, property], id);
  useEffect(() => {
    const pr = properties?.find(p => String(p.filterName) === String(id));

    if (prefill && !pr) {
      dispatch(setTableProperty({ property: prefill }));
    }
  }, [properties]);
  return [state, setState, property || { options: [] }];
};

export default useTableFilterHook;
