/* eslint react/prop-types: 0 */
import React from 'react';
import { Row, Col, CustomInput } from 'reactstrap';
import { flatten, unflatten } from 'flat';

export default class PermissionsInEdit extends React.Component {
  constructor(props) {
    super(props);

    this.arrayRemove = this.arrayRemove.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.getPermissions = this.getPermissions.bind(this);
    this.setNewPermissionsToRecord = this.setNewPermissionsToRecord.bind(this);

    this.state = {
      permissions: this.getPermissions(),
      loading: false,
    };
  }

  getPermissions() {
    const { record } = this.props;
    const paramsObject = unflatten(record.params);
    const { permissions } = paramsObject;
    if ('_id' in paramsObject) {
      if (permissions && Array.isArray(permissions)) {
        return permissions;
      }
      return [];
    }
    const { property: { availableValues } } = this.props;
    const newPermissions = availableValues.map(item => item.value);
    this.setNewPermissionsToRecord(newPermissions);
    return newPermissions;
  }

  setNewPermissionsToRecord(newPermissions) {
    const { onChange, record, property: { availableValues } } = this.props;
    const values = availableValues.map(item => item.value);
    const params = unflatten(record.params);
    params.permissions = newPermissions.filter(newPermission => values.includes(newPermission));
    record.params = flatten(params);
    onChange(record);
  }

  isChecked(value) {
    const { permissions } = this.state;
    if (permissions && Array.isArray(permissions)) {
      return permissions.includes(value);
    }
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  arrayRemove(arr, value) {
    return arr.filter(ele => ele !== value);
  }

  handleSwitchChange(event) {
    const { property: { availableValues } } = this.props;
    const { name: permission, checked } = event.target;
    this.setState({ loading: true });
    const { permissions } = this.state;
    let newPermissions = [];
    const selectAlso = availableValues.filter(item => item.value.substr(0, permission.length + 4) === `${permission}----` && item.value !== permission).map(item => item.value);
    console.log(selectAlso);
    console.log(availableValues.filter(item => item.value.split('----')[0] === permission));
    if (checked) {
      newPermissions = [...permissions, ...selectAlso, permission];
    } else {
      newPermissions = this.arrayRemove(permissions, permission);
      selectAlso.forEach((item) => {
        newPermissions = this.arrayRemove(newPermissions, item);
      });
    }
    this.setState(prevState => ({ ...prevState, permissions: newPermissions }));

    this.setNewPermissionsToRecord(newPermissions);

    this.setState({ loading: false });
    return true;
  }

  renderItem(item) {
    const { loading } = this.state;
    const { value, label } = item;
    const id = `${value}Switch`;
    const items = value.split('----');
    return (
      <Row key={value} >
        <Col xs={12}>{items.length === 1 ? (<hr />) : null}</Col>
        <Col xs="2" className="d-flex justify-content-center">
          <CustomInput
            id={id}
            type="switch"
            name={value}
            disabled={loading}
            checked={this.isChecked(value)}
            onChange={this.handleSwitchChange}
          />
        </Col>
        <Col xs="10" className="mr-auto">
          <h4 className={`py-2 pl-${2 + (items.length - 1)}`}>{label}</h4>
        </Col>
      </Row>
    );
  }

  renderInputs() {
    const { property: { availableValues } } = this.props;
    return availableValues.map(item => this.renderItem(item));
  }

  render() {
    const {
      property: {
        label,
      },
    } = this.props;

    return (
      <section style={{ marginBottom: '16px' }}>
        <h4 className="il-color-deep-blue font-weight-bold mt-5 mb-4">{label}</h4>
        <Row>
          <Col xs="2" className="text-left">
            <h4 className="font-weight-bold">Allow access?</h4>
          </Col>
          <Col xs="10" className="mr-auto">
            <h4 className="font-weight-bold">Module Name</h4>
          </Col>
        </Row>
        {this.renderInputs()}
      </section>
    );
  }
}
