import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import {
  getBrandUsers,
} from '../../../../../redux/reducers/brand/CreatorsReducer';
import Table from './Table';
import Actions from './Actions';

const version = (new Date()).toString();

const ProspectContainer = ({
  setTag,
  action,
  resource,
  dispatch,
  history,
  brand,
}) => {
  useEffect(() => {
    // dispatch(getTotalStats());
    dispatch(getBrandUsers());
  }, []);
  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Actions
                history={history}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Table
                setTag={setTag}
                action={action}
                resource={resource}
                version={version}
                brand={brand}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};


ProspectContainer.propTypes = {
  setTag: PropTypes.func.isRequired,
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  brand: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(state => ({
  creators: state.creators,
  loading: state.creators.loading,
  brand: state.session,
}))(ProspectContainer);
