/* eslint-disable react/prop-types */
/* eslint react/no-array-index-key: 0 */
import React from 'react';
import PropertyInShow from '../../ui/property-in-show';
import convertParamsToArrayItems from './convert-params-to-array-items';
import StyledSection from '../../ui/styled-section';

export default class Show extends React.PureComponent {
  render() {
    const { property, record, ItemComponent } = this.props;

    const items = convertParamsToArrayItems(property, record);

    return (
      <PropertyInShow property={property}>
        <StyledSection>
          {items.map((item, i) => (
            <ItemComponent
              {...this.props}
              key={i}
              property={{
                ...property,
                name: `${property.name}.${i}`,
                label: `[${i + 1}]`,
                isArray: false,
              }}
            />
          ))}
        </StyledSection>
      </PropertyInShow>
    );
  }
}
