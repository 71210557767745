import React from 'react';
import { Modal, Spinner, ButtonToolbar, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import '../../../../src/scss/component/email-template-preview-modal.scss';

const EmailTemplatePreviewModal = ({
  modal,
  loading,
  closeDialog,
  subject,
  body,
}) => (
  <Modal
    isOpen={modal}
    toggle={closeDialog}
  >
    <div className="modal__header">
      <h4
        className="text-modal modal__subject"
      >
        {subject}
      </h4>
    </div>
    <div
      className="modal__body"
    >
      {!loading ?
        (
          // eslint-disable-next-line react/no-danger
          <div dangerouslySetInnerHTML={{ __html: body }} />
        )
      : (<div><Spinner color="primary" /></div>)
      }
    </div>
    {!loading && (
    <ButtonToolbar className="modal__footer">
      <Button className="modal_cancel btn-sm" color="primary" onClick={closeDialog}>Close</Button>
    </ButtonToolbar>
      )
    }
  </Modal>
);
EmailTemplatePreviewModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  subject: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,

};
export default EmailTemplatePreviewModal;
