import React from 'react';
import PropTypes from 'prop-types';
import mapMediaTypeIcons from '../../../admin/Scorecard/MediaContent/components/custom/mapMediaTypeIcons';

const mapType = mapMediaTypeIcons();

const TypeInList = ({ record }) => {
  const { type } = record;
  return (
    <span>
      <img style={{ width: '30px' }} src={`/images/mediacontent/${mapType[type]}.png`} alt={type} title={type} />
      &nbsp;
    </span>
  );
};
TypeInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default TypeInList;
