/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { axios } from 'ApiClient';
import ApiClient from 'ApiClient';

const AffiliateManagerIdInEdit = ({ resource, record, onChange }) => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [employees, setEmployees] = useState([]);

  const loadUsers = () => {
    setLoading(true);
    axios.get('/api/portal/finance/get-employees').then((resp) => {
      setLoading(false);
      setEmployees(resp.data.employees.map(e => ({ label: `${e.firstName || ''} ${e.lastName || ''}`, value: e.tuneId })));
    }).catch(() => {
      setLoading(false);
    });
  };
  useEffect(() => {
    loadUsers();
  }, []);
  useEffect(() => {
    if (employees.length > 0) {
      employees.forEach((item) => {
        if (record.params.affiliateManagerId === item.value) {
          setSelected(item);
        }
      });
    }
  }, [employees]);

  const handleSelectChange = (selectedOption) => {
    setSelected(selectedOption);
    setLoading(true);
    let action = 'edit';
    if (selectedOption === null) {
      selectedOption = selected;
      action = 'delete';
    }
    const value = selectedOption ? selectedOption.value : '';

    if (typeof record.id !== 'undefined') {
      setLoading(true);
      const data = new FormData();
      data.append('affiliateManagerId', value);
      data.append('action', action);
      const api = new ApiClient();
      api.recordAction({
        resourceId: resource.id,
        recordId: record.id,
        actionName: 'edit',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        setLoading(false);
        onChange('affiliateManagerId', value);
        // eslint-disable-next-line
        if (window.REDUX_STATE.session && window.REDUX_STATE.session['_id'] === record.id) {
          window.location.reload();
        }
      });
    } else {
      onChange('affiliateManagerId', value);
    }
    return true;
  };
  return (
    <div className="form-group" style={{ minWidth: '100px' }}>
      Account Manager Id
      <Select
        isDisabled={loading}
        value={selected}
        options={employees}
        onChange={handleSelectChange}
        isClearable
      />
    </div>
  );
};
export default AffiliateManagerIdInEdit;
