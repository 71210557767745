import React from 'react';

export const CopyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="20 30 200 200" className="svg-inline--fa fa-w-16">
    {/* eslint-disable-next-line max-len */}
    <path fill="currentColor" d="M101.1 44.8c-1.9.9-4.4 3-5.5 4.5-2 2.8-2.1 4.1-2.4 52.5-.2 45.8-.1 50 1.5 53.1 1 1.9 3.1 4.4 4.6 5.5 2.8 2 4 2.1 38.7 2.1s35.9-.1 38.7-2.1c6.2-4.6 6.3-5 6.3-47.5V74.3l-2.9-3.9c-3.3-4.5-22.4-23.3-26.1-25.8-2.1-1.4-5.8-1.6-26-1.6-20.4 0-24 .2-26.9 1.8z" />
    {/* eslint-disable-next-line max-len */}
    <path fill="currentColor" d="M51.1 84.8c-1.9.9-4.4 3-5.5 4.5-2.1 2.8-2.1 3.8-2.1 53.7 0 49.9 0 50.9 2.1 53.7 4.6 6.2 5 6.3 47.4 6.3 34.7 0 38.8-.2 41.9-1.8 6.3-3.1 7.6-6 7.9-17.8l.4-10.4H123v10H63v-80h20V83H68.8c-11.6 0-14.9.3-17.7 1.8z" />
  </svg>);

export const ShieldIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 180 180" className="svg-inline--fa fa-hubspot fa-w-16">
    {/* eslint-disable-next-line max-len */}
    <path fill="currentColor" d="M59 32.5c-17.3 7.4-32.6 14.3-33.9 15.3-1.4 1-3.6 3.8-4.9 6.3-2.2 4.1-2.4 5.1-1.8 15.4 1.9 38.1 19.6 74 46.3 94.1C75.2 171.5 88 178 93 178s17.8-6.5 28.3-14.4c26.7-20.1 44.5-56.3 46.4-94.6.5-9.7.3-10.8-1.9-14.9-1.3-2.5-3.5-5.3-4.9-6.3-3.5-2.7-65.7-28.8-68.2-28.7-1.2 0-16.4 6.1-33.7 13.4zm69.2 41.2c1.1 1 1.8 3 1.8 5.2 0 3.3-1.5 5.1-21.7 25.3-27.3 27.3-24.1 26.5-40.6 10C57.4 103.8 56 102.1 56 99.1c0-4.6 2.4-7.1 6.9-7.1 3.1 0 4.6 1 11.8 8.2l8.3 8.3 18.3-18.3C117.8 73.7 119.8 72 122.9 72c1.9 0 4.3.7 5.3 1.7z" />
  </svg>
);

export const PickIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="80 50 180 180" className="svg-inline--fa fa-hubspot fa-w-16">
    {/* eslint-disable-next-line max-len */}
    <path fill="currentColor" d="M148.3 57.5c-14.7 2.7-21.4 6.4-19.7 10.9.4.9 5.9 4.2 12.3 7.3 38.5 18.8 68.2 48.4 87.2 87 6.1 12.4 7.9 14.4 11.7 12.4 2.2-1.2 5.6-10.7 7.3-20.7 1.4-8.1.7-25.4-1.5-33.4-2.4-9-5.4-16.3-9.5-23.1l-3.9-6.4 2.9-3.3c2.2-2.4 2.9-4.3 2.9-7.3 0-3.5-.7-4.8-4.8-9-3.7-4-5.6-5.1-8.8-5.6-3.5-.4-4.5-.1-7.6 2.6l-3.5 3.1-7.9-4.5c-17.3-9.9-37.8-13.5-57.1-10z" />
    <path fill="currentColor" d="M132.6 155.9C109 180.6 89.3 201.8 88.8 203c-2.9 7.8 6.6 15.8 13.8 11.7 2.5-1.4 90.4-85.7 90.4-86.6 0-.8-16.3-17.1-17-17-.3 0-19.8 20.2-43.4 44.8z" />
  </svg>
);

export const AddressIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="50 100 650 500" className="svg-inline--fa fa-hubspot fa-w-16">
    {/* eslint-disable-next-line max-len */}
    <path fill="currentColor" d="M138.8 26.6c-10.5 1.9-20.7 5.6-29.7 10.7l-7.4 4.2-7.9 7.4c-13.1 12.3-20.8 25-26 42.6l-2.3 8-.3 226-.3 226 1.2 9 1.1 9 2.8 8.2c1.5 4.5 4.7 11.7 7.1 16l4.4 7.8 10 10 10 10 7.5 4.2c9.5 5.4 19.5 8.9 30.7 10.8l8.8 1.5 315-.2 315-.3 8-2.3c17.1-5 29.9-12.7 41.6-24.8 11.5-12.1 18-23.9 22.2-40.1l2.2-8.8v-461l-2.3-8.5c-4.4-16.5-12.4-30.2-24.8-42.1-11.6-11.1-23.3-17.7-38.9-22.1l-8-2.3-316-.2-316-.1-7.7 1.4zM323 158.9l8.5 1.9 8.5 3.7c25.4 11.2 43 31.6 50.1 58l2.2 8v27l-2.2 8c-8.6 32-34.2 56.2-67.1 63.6l-8.5 1.9h-16.4l-7.3-1.5c-7.9-1.6-9.6-2.2-20.3-6.8-16.6-7.1-34-23.1-42-38.6-3.1-6.1-7.3-19.4-8.5-27.4l-1.3-7.8.6-9.7.6-9.7 2.1-8c8-30.7 34-55.1 66.5-62.5l8-1.8 9-.1 9-.1 8.5 1.9zm432.9 45.4 4.3 2.8 2.1 4.7 2.1 4.7v12.1l-2.5 4.4-2.4 4.4-3.3 2.3-3.3 2.2-5.2 1.1-5.3 1.1-89.4-.3-89.5-.3-4.5-2.1c-2.5-1.2-5.5-3.2-6.7-4.5-2.9-3.2-5.3-10.1-5.3-15.3v-4.1l2.4-5.1 2.4-5.1 3.1-2.2c1.8-1.3 4.4-2.7 5.9-3.1l2.7-.8 94.1.1 94 .2 4.3 2.8zm-5.2 85.2 5.1 1.5 3.5 3.9 3.5 3.9 1.2 5.5 1.3 5.5-1.2 4.9c-.7 2.6-2.2 6.3-3.4 8.1l-2.2 3.3-5.1 2.4-5 2.5-93.5-.2-93.4-.3-4.2-2.2-4.2-2.3-2.6-4.4-2.6-4.4-.5-4.8-.6-4.9 1.1-4.3 1.2-4.3 3.6-4 3.5-3.9 4.7-1.4 4.6-1.5h90.1l90-.1 5.1 1.5zm-382.1 88c28.2 5 52.6 19.5 68.3 40.8 12.9 17.6 20.9 41.3 21.3 63.4l.2 7.8-2.1 4.5-2 4.6-3.2 2.7c-1.8 1.4-4.9 3.1-7.1 3.7l-3.8 1-136.4-.2-136.3-.3-4-2.3-4-2.3-2.3-3.3-2.2-3.3-1.2-5.4-1.1-5.4 1.6-12 1.5-12 2.7-8.5c3.8-11.9 9.8-23.6 17.2-33.4l6.3-8.4 6.3-5.5c16.2-14.1 33.2-22.2 54.7-26.1l7.5-1.4 54.6-.1 54.6-.1 8.9 1.5zm387.3 1.8 4.3 2.8 2.1 4.7 2.1 4.7v12.1l-2.5 4.4-2.4 4.4-3.3 2.3-3.3 2.2-5.2 1.1-5.3 1.1-89.4-.3-89.5-.3-4.5-2.1c-2.5-1.2-5.5-3.2-6.7-4.5-2.9-3.2-5.3-10.1-5.3-15.3v-4.1l2.4-5.1 2.4-5.1 3.1-2.2c1.8-1.3 4.4-2.7 5.9-3.1l2.7-.8 94.1.1 94 .2 4.3 2.8z" />
  </svg>
);

export const PencilIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="35 20 140 157" className="svg-inline--fa fa-hubspot fa-w-16">
    {/* eslint-disable-next-line max-len */}
    <path fill="currentColor" d="M139.3 18c-2.4 1-8.2 5.7-13 10.4l-8.8 8.5 20.2 20.3L158 77.5l8.6-8.5c9.5-9.3 12.3-14.2 12.4-20.9 0-6.6-3.2-12-12.1-20.6-11.9-11.3-18.6-13.6-27.6-9.5zm-67 64.2c-22.4 22.5-37.9 38.9-39.1 41.3-2.6 5.3-13.2 41.6-13.2 45.4 0 2.9 3.8 7.1 6.5 7.1 2.3 0 42.1-12.3 46-14.2 2.5-1.2 17.8-15.8 41.4-39.5l37.4-37.5-19.9-19.9C120.5 54 111 45 110.5 45c-.6 0-17.8 16.8-38.2 37.2zm46.2-13.8c1.2 1.8 1.4 3 .6 4.3-1.9 3.5-47.7 48.3-49.4 48.3-2 0-5.7-3.4-5.7-5.2 0-.7 10.9-12.1 24.2-25.5 25.5-25.6 27.1-26.7 30.3-21.9zM55 132.5c0 7.6 1 8.5 8.9 8.5 2.9 0 5.1.5 5.1 1 0 2.5-3.1 6.8-5.7 7.8-4.5 1.8-24.5 7.4-24.9 7-.4-.5 6.4-23.6 7.7-26.1.9-1.6 6-4.5 8.2-4.7.4 0 .7 2.9.7 6.5z" />
  </svg>
);

export const ShieldExclamationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="50 35 110 130" className="svg-inline--fa fa-hubspot fa-w-16">
    {/* eslint-disable-next-line max-len */}
    <path fill="currentColor" d="M68 30.5c-17.3 7.4-32.6 14.3-34 15.3-1.3 1-3.5 3.8-4.8 6.3-2.2 4.1-2.4 5.1-1.8 15.9 2.2 45.7 26.2 85.2 63.4 104.5 10.6 5.5 15.6 4.6 32-6.1 17-11.1 31.8-28.5 41.1-48.5C171.1 102.5 176 81 176 64.8c0-9.9-1.3-13.8-5.8-18.1-2.9-2.7-65.7-29.7-68.9-29.6-1 0-16 6.1-33.3 13.4zm38.5 29C109 61.9 109 62 109 82s0 20.1-2.5 22.5c-1.3 1.4-3.6 2.5-5 2.5s-3.7-1.1-5-2.5C94 102.1 94 102 94 82s0-20.1 2.5-22.5c1.3-1.4 3.6-2.5 5-2.5s3.7 1.1 5 2.5zm-.1 58.6c2.4 1.1 5.6 6.2 5.6 8.9 0 .7-.7 2.7-1.5 4.5-4.5 9.4-18.5 6-18.5-4.5 0-7.7 7.2-12.1 14.4-8.9z" />
  </svg>
);

export default {};
