// import { unflatten } from 'flat';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import MatTableList from '../../../../../shared/tables/materialTable/MatTableList';
import UserPropertyType from '../../../Prospects/components/custom';
import CreatorInList from './CreatorInList';
import TableCellContextMenu from './TableCellContextMenu';
import ShieldComponent from './ShieldComponent';


const Table = React.memo(({
  action,
  resource,
  setTag,
  version,
  brand,
}) => {
  const history = useHistory();
  return (
    <MatTableList
      action={action}
      resource={resource}
      setTag={setTag}
      version={version}
      UserPropertyType={UserPropertyType}
      showFilters={false}
      showTitle={false}
      sortBy="createdAt"
      direction="desc"
      useFlex
      v={2}
      noActions
      isResizable={false}
      columnsSettings={{
        fullName: {
          Header: () => 'Name',
          Cell: p => useMemo(() => (<CreatorInList record={p.row.original} brand={brand} />), [p.row.original.id]),
          width: 250,
        },
        isInternal: {
          Header: () => 'Creator Type',
          accessor: p => p.params.isInternal,
          Cell: p => useMemo(() => {
            const message = p.value ? 'InfluenceLogic' : 'Standard';
            return (<div className="pl-3">{message}</div>);
          }, [p.row.original.id]),
          width: 100,
        },
        compliant: {
          Header: () => 'Compliant?',
          accessor: p => p.params['hsContactObject.ftc_compliance_status'] === 'Compliant',
          Cell: p => useMemo(() => (<ShieldComponent record={p.row.original} />), [p.row.original.id]),
          width: 100,
        },
        'hsContactObject.hs_lastmodifieddate': {
          Header: () => 'Last Modified Date',
          accessor: p => p.params['hsContactObject.hs_lastmodifieddate'],
          Cell: p => useMemo(() => <div className="text-center">{(p.value ? moment(p.value).format('MM/DD/YY') : '---')}</div>, [p.row.original.id]),
          width: 120,
        },
        'hsContactObject.date_of_compliance_review': {
          Header: () => 'Date Of Compliance Review',
          accessor: p => p.params['hsContactObject.date_of_compliance_review'],
          Cell: p => useMemo(() => <div className="text-center">{(p.value ? moment(p.value).format('MM/DD/YY') : '---')}</div>, [p.row.original.id]),
          width: 120,
        },
        totalContent: {
          Header: () => 'Total Content',
          accessor: p => p.params.totalContent,
          Cell: p => useMemo(() => (<div className="pl-5">{p.value}</div>), [p.row.original.id]),
          width: 100,
          className: 'pl-5',
        },
        actionInfluencer: {
          Header: '',
          maxWidth: 30,
          width: 30,
          Cell: (p) => {
            const contextMenuOptions = [
              { label: 'Profile', handler: () => history.push(`/creator/${p.row.original.id}`) },
            ];
            return (
              <div style={{ paddingTop: '8px' }} className="float-right">
                <TableCellContextMenu options={contextMenuOptions} />
              </div>
            );
          },
          disableSortBy: true,
          className: 'align-right text-center',
        },
      }}
    />
  );
}, (a, a1) => a.version === a1.version && a.searchTerms?.length === a1.searchTerms?.length);

Table.propTypes = {
  setTag: PropTypes.func.isRequired,
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  brand: PropTypes.objectOf(PropTypes.any).isRequired,
  version: PropTypes.string.isRequired,
};
export default Table;
