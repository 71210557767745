import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import withNotice from '../../../../App/store/with-notice';
import { hideModalById } from '../../../../../redux/reducers/TableReducer';
import { formatCurrency } from '../../../../../shared/helpers/WVFormatter';

export const modalId = 'refreshBillModalQuestion';

const RefreshBillModal = ({ addNotice, updateTable }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dataForBill, setDataForBill] = useState(null);
  const modalInfo = useSelector(state => state.table.modals[modalId]);
  const { data: { record }, isShow } = modalInfo || { data: { record: null }, isShow: false };
  const onGetChangesForBill = async () => {
    try {
      setLoading(true);
      const response = await axios.post('/api/portal/bill/post-check-bill-data', {
        id: record.params.deal,
      });
      if (response.data.success) {
        setDataForBill(response.data);
      } else {
        addNotice({
          message: response.data.error,
          type: 'error',
          duration: 10,
        });
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
    return true;
  };

  useEffect(() => {
    if (isShow === true) {
      onGetChangesForBill();
    } else {
      setDataForBill(null);
    }
  }, [isShow]);

  const close = () => dispatch(hideModalById(modalId));
  if (!record) return false;
  const onRefreshFunc = async () => {
    try {
      setLoading(true);
      const response = await axios.post('/api/portal/bill/post-refresh-data', {
        id: record.id,
      });
      if (response.data.success) {
        addNotice({
          message: 'Bill has been Successfully refreshed',
          type: 'success',
        });
      } else {
        addNotice({
          message: response.data.error,
          type: 'error',
          duration: 10,
        });
      }
      setLoading(false);
      updateTable();
      close();
    } catch (e) {
      console.log(e);
    }
    return true;
  };
  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      modalClassName="ltr-support"
      className="modal-dialog font-weight-normal"
    >
      <ModalHeader toggle={close} tag="h4">
        Update Bill {record?.params?.invoiceNumber || ''}
      </ModalHeader>
      <ModalBody
        className="mt-3 mb-3"
      >
        <p>
          The following properties will be updated:
          <ul>
            {dataForBill ? [
              <li className="my-2">
                <strong>Creator</strong>:&nbsp;
                {record.populated?.influencer?.params._id ?
                  <a href={`/influencer/profile/${record.populated?.influencer?.params._id}`}>{record.populated?.influencer?.params.first_name} {record.populated?.influencer?.params.last_name}</a> : 'None'}
                &nbsp;&nbsp;<i className="fa fa-arrow-right" aria-hidden="true" />&nbsp;&nbsp;
                {dataForBill?.influencer?._id ? <a href={`/influencer/profile/${dataForBill.influencer._id}`}>{dataForBill.influencer.first_name} {dataForBill.influencer.last_name}</a> : 'None'}
              </li>,
              <li className="my-2">
                <strong>Agent</strong>:&nbsp;
                {record.populated?.agent?.params._id ? <a href={`/influencer/profile/${record.populated.agent.params.id}`}>{record.populated.agent.params.first_name} {record.populated.agent.params.last_name}</a> : 'None'}
                &nbsp;&nbsp;<i className="fa fa-arrow-right" aria-hidden="true" />&nbsp;&nbsp;
                {dataForBill?.agent?._id ? <a href={`/influencer/profile/${dataForBill.agent._id}`}>{dataForBill.agent.first_name} {dataForBill.agent.last_name}</a> : 'None'}
              </li>,
              <li className="my-2">
                <strong>Amount</strong>:&nbsp;
                {formatCurrency(record.params._id ? Number(record.params.amount) : 0)}
                &nbsp;&nbsp;<i className="fa fa-arrow-right" aria-hidden="true" />&nbsp;&nbsp;
                {formatCurrency(dataForBill?.amount ? Number(dataForBill.amount) : 0)}
              </li>] : null}
          </ul>
        </p>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button
          disabled={loading}
          color="primary"
          onClick={() => {
            onRefreshFunc(record.id);
            // close();
            // setSelectedOption({ value: false, label: 'No, do not overwrite' });
          }}
        >
          Update
        </Button>
        <Button color="secondary" onClick={close}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

RefreshBillModal.propTypes = {
  updateTable: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};


export default withNotice(RefreshBillModal);
