import ThumbTackLeadLeadIDInList from './ThumbTackLeadLeadIDInList';
import ThumbTackLeadStatusInList from './ThumbTackLeadStatusInList';
import ThumbTackLeadButtonsInList from './ThumbTackLeadButtonsInList';
import ThumbTackLeadWhoRepliedInList from './ThumbTackLeadWhoRepliedInList';
import ThumbTackLeadTitleInList from './ThumbTackLeadTitleInList';
import FollowWithSequenceInList from './FollowWithSequenceInList';
import formater from '../../../../../../shared/helpers/WVFormatter';
import ActionsInList from './ThumbTackLeadActionsInList';

export default {
  show: {
  //  status: CampaignStatusInShow,
  //  status: CampaignStatusInList,
  },
  list: {
    leadID: ThumbTackLeadLeadIDInList,
    replyStatus: ThumbTackLeadStatusInList,
    whoReplied: ThumbTackLeadWhoRepliedInList,
    title: ThumbTackLeadTitleInList,
    buttons: ThumbTackLeadButtonsInList,
    followWithSequence: FollowWithSequenceInList,
    timeReceived: ({ record }) => formater.getServerDateTime(record.params.timeReceived),
  },
  actions: ActionsInList,
};
