import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Icon from 'mdi-react/LinkOffIcon';
import Tooltip from '@material-ui/core/Tooltip';
import DisConnectModal from './DisConnectModal';

const DisConnectButton = (props) => {
  const { agent, meta, type } = props;
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  return (
    <span>
      <Tooltip title="Disconnect Account">
        <button className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall" onClick={() => setShowSuccessDialog(true)}>
          <Icon style={{ width: '15px', height: '15px' }} />
        </button>
      </Tooltip>
      <DisConnectModal
        modal={showSuccessDialog}
        toggle={() => setShowSuccessDialog(false)}
        agent={agent}
        meta={meta}
        type={type}
      />
    </span>
  );
};

DisConnectButton.propTypes = {
  agent: PropTypes.objectOf(PropTypes.any).isRequired,
  meta: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired,
};
export default DisConnectButton;
