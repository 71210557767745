/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import NotificationSystem from 'rc-notification';
// import Topbar from './topbar/Topbar';
import TopbarWithNavigation from './topbar_with_navigation/TopbarWithNavigation';
// import Sidebar from './sidebar/Sidebar';
import SidebarMobile from './topbar_with_navigation/sidebar_mobile/SidebarMobile';
// import Customizer from './customizer/Customizer';
// import { BasicNotification } from '../../shared/components/Notification';
import { changeMobileSidebarVisibility, changeSidebarVisibility } from '../../../redux/actions/sidebarActions';
import {
  changeThemeToDark, changeThemeToLight,
} from '../../../redux/actions/themeActions';
import Notice from '../../../shared/components/components/app/notice';
import {
  changeDirectionToRTL, changeDirectionToLTR,
} from '../../../redux/actions/rtlActions';
import { changeBorderRadius, toggleBoxShadow, toggleTopNavigation } from '../../../redux/actions/customizerActions';
import {
  CustomizerProps, SidebarProps,
} from '../../../shared/prop-types/ReducerProps';
// import CheckCreator from './CheckCreator';
import SuccessModal from '../../../shared/components/modals/SuccessModal';
import DeleteModal from '../../../shared/components/modals/DeleteModal';
import ConfirmModal from '../../../shared/components/modals/ConfirmModal';
import ShowExpiredModal from '../../../shared/ApiClient/ShowExpiredModal';


class Layout extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,
    customizer: CustomizerProps.isRequired,
    session: PropTypes.objectOf(PropTypes.any).isRequired,
    paths: PropTypes.objectOf(PropTypes.any).isRequired,
    // history: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  componentDidMount() {
    // const { rtl } = this.props;
    // NotificationSystem.newInstance({ style: { top: 65 } }, n => notification = n);
    // setTimeout(() => showNotification(rtl.direction), 700);
  }

  componentWillUnmount() {
    // notification.destroy();
  }

  changeSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeMobileSidebarVisibility());
  };

  changeToDark = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToLight());
  };

  changeToRTL = () => {
    const { dispatch } = this.props;
    dispatch(changeDirectionToRTL());
  };

  changeToLTR = () => {
    const { dispatch } = this.props;
    dispatch(changeDirectionToLTR());
  };

  toggleTopNavigation = () => {
    const { dispatch } = this.props;
    dispatch(toggleTopNavigation());
  };

  changeBorderRadius = () => {
    const { dispatch } = this.props;
    dispatch(changeBorderRadius());
  };

  toggleBoxShadow = () => {
    const { dispatch } = this.props;
    dispatch(toggleBoxShadow());
  };

  render() {
    const {
      customizer, sidebar, session, paths, /* history, */
    } = this.props;
    // console.log(session, 'new Session');
    const layoutClass = classNames({
      layout: true,
      'layout--collapse': sidebar.collapse,
      'layout--top-navigation': customizer.topNavigation,
    });
    customizer.topNavigation = true;

    return (
      <div className={layoutClass}>
        <Notice />
        <SuccessModal />
        <DeleteModal />
        <ConfirmModal />
        <ShowExpiredModal />
        <TopbarWithNavigation
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          session={session}
          paths={paths}
        />
        <SidebarMobile
          sidebar={sidebar}
          changeToDark={this.changeToDark}
          changeToLight={this.changeToLight}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
        />
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  customizer: state.customizer,
  sidebar: state.sidebar,
  theme: state.theme,
  rtl: state.rtl,
  user: state.user,
  session: state.session,
  
}))(Layout));
