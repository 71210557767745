/* eslint react/prop-types: 0 */
import React from 'react';
import { Link } from 'react-router-dom';
import ViewHelpers from '@/shared/ViewHelpers';


export default class List extends React.PureComponent {
  render() {
    const { property, record, resource } = this.props;
    const showAction = record.recordActions.find(a => a.name === 'show');
    const value = record.params[property.name];

    if (resource.titleProperty.name === property.name && showAction) {
      const h = new ViewHelpers();
      const href = h.recordActionUrl({
        resourceId: resource.id, recordId: record.id, actionName: 'show',
      });
      return (
        <Link to={href}>{value}</Link>
      );
    }

    const className = property.availableValues ? 'tag' : '';

    return typeof value !== 'undefined' ? (<span className={className}>{value}</span>) : '';
  }
}
