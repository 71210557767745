import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input } from 'reactstrap';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(() => ({
  formLabel: {
    marginLeft: '20px',
  },
}));

const OscarWorthy = ({
  isOscar,
  disable,
  handleChangeOscarWorthy,
}) => {
  const classes = useStyles();

  return (
    <FormGroup>
      <FormControlLabel
        className={classes.formLabel}
        control={
          <Input
            disabled={disable}
            type="radio"
            name="handleChangeOscarWorthy"
            value="1"
            className="mt-0"
            checked={isOscar === true}
            onChange={handleChangeOscarWorthy}
          />
        }
        label="Yes"
      />&nbsp;&nbsp;
      <FormControlLabel
        className={classes.formLabel}
        control={
          <Input
            disabled={disable}
            type="radio"
            name="handleChangeOscarWorthy"
            value="0"
            className="mt-0"
            checked={isOscar === false}
            onChange={handleChangeOscarWorthy}
          />
        }
        label="No"
      />
    </FormGroup>
  );
};
OscarWorthy.propTypes = {
  isOscar: PropTypes.bool,
  handleChangeOscarWorthy: PropTypes.func,
  disable: PropTypes.bool,
};

OscarWorthy.defaultProps = {
  disable: false,
  isOscar: null,
  handleChangeOscarWorthy: () => {},
};
export default OscarWorthy;

