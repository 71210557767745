import { unflatten } from 'flat';
import React from 'react';
import PropTypes from 'prop-types';

const InfluencerStatus = ({ record }) => {
  const recordUnflatten = unflatten(record);
  const status = recordUnflatten.params.status
    ? recordUnflatten.params.status : '';
  let statusHTML = '-';
  if (status === 'deleted') {
    statusHTML = (<span className="text-danger">Deleted</span>);
  }
  if (status === 'active') {
    statusHTML = (<span className="text-success">Active</span>);
  }
  if (status === 'blocked') {
    statusHTML = (<span className="text-secondary">Blocked</span>);
  }
  return (
    <span style={{
      display: 'block',
      textAlign: 'center',
      marginRight: '1rem',
    }}
    >
      {statusHTML}
    </span>
  );
};
InfluencerStatus.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default InfluencerStatus;
