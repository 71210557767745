const generateCSV = (fileName, data) => {
  let csvContent = '';
  data.forEach((infoArray, index) => {
    let dataString = '';
    const value = infoArray;
    value.forEach((inValue, j) => {
      // console.log(inValue);
      const innerValue = (inValue === null || inValue === undefined) ? '' : inValue.toString();
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)|(\b0)/g) >= 0) {
        result = `"${result}"`;
      }
      if (j > 0) {
        dataString += ',';
      }
      dataString += result;
    });
    csvContent += index < data.length ? `${dataString}\n` : dataString;
  });
  const content = csvContent;
  let mimeType = 'text/csv;encoding:utf-8';

  const a = document.createElement('a');
  mimeType = mimeType || 'application/octet-stream';

  if (navigator.msSaveBlob) { // IE10
    navigator.msSaveBlob(new Blob([content], {
      type: mimeType,
    }), fileName);
  } else if (URL && 'download' in a) { // html5 A[download]
    a.href = URL.createObjectURL(new Blob([content], {
      type: mimeType,
    }));
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    window.location.href = `data:application/octet-stream,${encodeURIComponent(content)}`; // only this mime type is supported
  }
};

const downloadObjectCSV = (data, filename) => {
  const keys = Object.keys(data[0]);
  const dataArray = [keys];
  data.forEach((item) => {
    dataArray.push(keys.map(key => item[key]));
  });
  generateCSV(filename, dataArray);
};

const downloadObjectColumndsCSV = (data, columns, filename) => {
  const keys = Object.keys(columns);
  const dataArray = [];
  dataArray.push(keys.map(key => columns[key]));
  data.forEach((item) => {
    dataArray.push(keys.map(key => (item[key] || '')));
  });
  generateCSV(filename, dataArray);
};
const downloadArrayCSV = (data, columnName, filename) => {
  const dataArray = [[columnName]];
  data.forEach((item) => {
    dataArray.push([item]);
  });
  generateCSV(filename, dataArray);
};

const generateWithColumns = ({ csvArrayColumns, records, fileName }) => {
  const data = [csvArrayColumns.map(column => column.label)];
  records.forEach((record) => {
    const itemRow = [];
    csvArrayColumns.forEach((column) => {
      itemRow.push(column.func(record));
    });
    data.push(itemRow);
  });
  generateCSV(fileName, data);
};

exports.generateCSV = generateCSV;
exports.downloadArrayCSV = downloadArrayCSV;
exports.downloadObjectCSV = downloadObjectCSV;
exports.downloadObjectColumndsCSV = downloadObjectColumndsCSV;
exports.generateWithColumns = generateWithColumns;
