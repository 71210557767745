import React, { useEffect, useState } from 'react';
import { unflatten } from 'flat';
import PropTypes from 'prop-types';
import { Modal, ButtonToolbar, Button, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { axios } from 'ApiClient';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import formater from '../../../../../../shared/helpers/WVFormatter';
import NewInvoicePayout from './NewInvoicePayout';
import TableCellContextMenu from '../../../../Influencer/Research/Prospects/components/custom/TableCellContextMenu';
import EditInvoice from './NewInvoiceComponent';

const columnTransform = {
  startDate: {
    title: 'Accounts Payable Name',
    valueFunc: record => (record.accountsPayableO ? record.accountsPayableO.name : ''),
    sort: record => (record.accountsPayableO ? record.accountsPayableO.name : ''),
  },
  amount: {
    title: 'Amount',
    valueFunc: record => formater.formatCurrency(Number(record.amount || 0)),
    sort: record => Number(record.amount || 0),
  },
  notes: {
    title: 'Notes',
    valueFunc: record => (record.notes || ''),
    sort: record => record.notes,
  },
};

const ContextMenuInList = ({
  record,
  meta,
}) => {
  const invoice = unflatten(record);
  const [hsLink, setHsLink] = useState({});
  const [showHistory, setShowHistory] = useState(false);
  const [showPayout, setShowPayout] = useState(false);
  const [showRemoveInvoice, setShowRemoveInvoice] = useState(false);
  const [showEditInvoice, setShowEditInvoice] = useState(false);

  useEffect(() => {
    const {
      dealObject,
    } = invoice.params;
    setHsLink(typeof dealObject !== 'undefined' ? `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${dealObject.hs_object_id}` : null);
  }, [invoice]);

  const handleDeleteDialogSubmit = () => {
    axios({
      method: 'post',
      url: '/api/portal/finance/post-delete-invoice',
      data: {
        id: record.id,
      },

    }).then(() => {
      if (meta && meta.closeFunction) {
        meta.closeFunction();
      }
    }).catch((error) => {
      console.error(error);
    });
    setShowRemoveInvoice(!showRemoveInvoice);
  };

  const goToPage = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  const contextMenuOptions = [
    {
      label: 'Send a Payment',
      handler: () => setShowPayout(true),
    },
    {
      label: 'Edit Invoice',
      handler: () => setShowEditInvoice(!showEditInvoice),
    },
    {
      label: 'Show Payment History',
      handler: () => setShowHistory(!showHistory),
    },
  ];

  if (hsLink) {
    contextMenuOptions.push({
      label: 'Show HubSpot Deal',
      handler: () => goToPage(hsLink),
    });
  }

  if (invoice.params.payments.length === 0) {
    contextMenuOptions.push({
      label: 'Delete Invoice',
      handler: () => setShowRemoveInvoice(!showRemoveInvoice),
    });
  }

  return (
    <>
      <TableCellContextMenu options={contextMenuOptions} />

      <Modal
        isOpen={showHistory}
        toggle={() => setShowHistory(!showHistory)}
      >
        <div className="modal__header">
          <h4 className="text-modal  modal__title">Payment History</h4>
        </div>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="modal__body ltr-support theme-light">
          {showHistory ? (
            <MatTableForRedux
              dataArray={invoice.params.payments}
              selectable={false}
              columns={columnTransform}
              version={(new Date()).toString()}
              rowsPerPage={5}
            />
          ) : null}
        </div>
        <ButtonToolbar className="modal__footer">
          <Button color="danger" onClick={() => setShowHistory(!showHistory)}>Close</Button>
        </ButtonToolbar>
      </Modal>

      {showEditInvoice ? (
        <EditInvoice
          modalTitle="Edit Invoice"
          modal={showEditInvoice}
          invoice={invoice}
          closeDialog={(isUpdate) => {
            setShowEditInvoice(false);
            if (meta && meta.closeFunction && isUpdate) {
              meta.closeFunction();
            }
          }}
        />) : null}

      <Modal isOpen={showRemoveInvoice} toggle={() => setShowRemoveInvoice(!showRemoveInvoice)} className="delete-modal" size="sm">
        <ModalHeader toggle={() => setShowRemoveInvoice(!showRemoveInvoice)} >
          Delete Invoice
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete the selected Invoice?<br />

        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-sm"
            color="danger"
            onClick={handleDeleteDialogSubmit}
          >
            Delete
          </Button>{' '}
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => setShowRemoveInvoice(!showRemoveInvoice)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <NewInvoicePayout
        modal={showPayout}
        invoice={record}
        modalTitle="Send a Payment"
        closeDialog={(isUpdate) => {
          setShowPayout(false);
          if (meta && meta.closeFunction && isUpdate) {
            meta.closeFunction();
          }
        }}
      />
    </>
  );
};

ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  meta: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ContextMenuInList;
