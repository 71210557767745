import { useSelector } from 'react-redux';


const useSelectedIdsTableHook = () => {
  // const records = useSelector(state => state.tableRedux.records);
  const selectedIds = useSelector(state => state.tableRedux.selectedRecordsIdsMultiselect);
  return [
    selectedIds,
  ];
};

export default useSelectedIdsTableHook;
