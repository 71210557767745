import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  bar: {
    width: '100%',
    padding: '10px 10px 10px 0px',
  },
  percentText: {
    paddingTop: '5px',
  },
});

function ProgressBarWithObject({
  topic,
  afterFunction,
  receiveResults,
  showAsDefault,
  isShow,
  identity,
}) {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  // const [identity, setIdentity] = useState(null);

  useEffect(() => {
    const socket = io({
      transports: ['websocket'],
    });
    // socket.emit('connectInit', identity);
    socket.on(topic + identity, (data) => {
      const res = JSON.parse(data);
      // console.log('Socket Results: ', res);
      const percent = Number(res.percent || 0) * 100;
      if (receiveResults) {
        receiveResults(res);
      }
      setProgress(percent);
      if (percent === 100 && afterFunction) {
        setTimeout(() => afterFunction(), 2000);
      }
    });
    return function cleanup() {
      console.log('run cleanup() function');
      socket.close();
    };
  }, [identity]);
  // console.log(isShow, progress);
  if (((progress === 0 && !showAsDefault) && !isShow) || !isShow) {
    return null;
  }
  // console.log(identity, progress);

  return (
    <div className={classes.root}>
      <div className={classes.bar}>
        <LinearProgress color="primary" variant="determinate" value={progress} />
      </div>
      <div className={classes.percentText} id="progressBarPercentValue">
        <Typography variant="caption" component="div" color="textSecondary">
          {`${Math.round(progress)}%`}
        </Typography>
      </div>
    </div>
  );
}

ProgressBarWithObject.propTypes = {
  /**
   * Subscription topic to listen on
   */
  topic: PropTypes.string.isRequired,
  afterFunction: PropTypes.func,
  receiveResults: PropTypes.func,
  showAsDefault: PropTypes.bool,
  isShow: PropTypes.bool,
  identity: PropTypes.string,
};
ProgressBarWithObject.defaultProps = {
  /**
   * Some function to run after progress reaches 100%
   */
  showAsDefault: false,
  isShow: true,
  afterFunction: null,
  receiveResults: null,
  identity: '',
};

export default ProgressBarWithObject;
