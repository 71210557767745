/* eslint react/prop-types: 0 */
import React from 'react';
import Select from 'react-select/async';
import ApiClient from 'ApiClient';
import PropertyInEdit from '../../ui/property-in-edit';
// import selectStyles from '../../../styles/selectStyles';
// import theme from '../../../../../theme';

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.selected = null;
    this.loadOptions = this.loadOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selected) {
    const { onChange, property } = this.props;
    if (selected) {
      this.selected = selected.record;
      onChange(property.name, selected.value, selected.record);
    } else {
      onChange(property.name, '');
    }
  }

  async loadOptions(inputValue) {
    const { property } = this.props;
    const api = new ApiClient();

    const records = await api.searchRecords({
      resourceId: property.reference,
      query: inputValue,
    });
    return records.map(record => ({
      value: record.id,
      label: record.title,
      record,
    }));
  }

  render() {
    const { property, record } = this.props;
    const error = record.errors && record.errors[property.name];

    const reference = record.populated && record.populated[property.name];
    let selectedOption = reference && {
      value: reference.id,
      label: reference.title,
    };
    // const styles = selectStyles(theme);

    if (this.selected) {
      selectedOption = {
        value: this.selected.id,
        label: this.selected.title,
      };
    }

    return (
      <PropertyInEdit property={property} error={error}>
        <Select
          cacheOptions
          value={selectedOption}
          defaultOptions
          loadOptions={this.loadOptions}
          onChange={this.handleChange}
          isDisabled={property.isDisabled}
        />
      </PropertyInEdit>
    );
  }
}

export default Edit;
