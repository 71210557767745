import React, { PureComponent } from 'react';
import { BreadcrumbItem, Breadcrumb, Button, Row, Col } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import BackIcon from 'mdi-react/ArrowBackIcon';

class BreadCrumbs extends PureComponent {
  static propTypes = {
    links: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    isBackButton: PropTypes.bool,
    backButtomClassName: PropTypes.string,
    backButtonUrl: PropTypes.string,
    ComponentInCenter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  };

  static defaultProps = {
    isBackButton: false,
    backButtonUrl: null,
    backButtomClassName: '',
    ComponentInCenter: false,
  };

  goBack = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      links,
      isBackButton,
      backButtomClassName,
      backButtonUrl,
      ComponentInCenter,
    } = this.props;
    let { path } = links[links.length - 1];
    if (path === null) {
      path = links[links.length - 2].path ? links[links.length - 2].path : null;
    }
    return (
      <Row>
        {isBackButton && backButtonUrl ? (
          <Col xs="12" md="4" className="back-button-col">
            
              <Button
                onClick={this.goBack}
                className={`float-left mt-1 ${backButtomClassName}`}
                color="primary"
                outline
              >
                <BackIcon size={18} />  Back
              </Button>
          </Col>
        ) : null}
        <Col>
          {ComponentInCenter && (
            <ComponentInCenter />
          )}
        </Col>
        <Col xs="12" md="auto">
          <Breadcrumb className="d-flex justify-content-end" >
            {links.map(link => (<BreadcrumbItem key={link.title} className={link.className ? link.className : ''} >{link.path ? (<Link key={link.title} to={link.path}>{link.title}</Link>) : link.title}</BreadcrumbItem>))}
          </Breadcrumb>
        </Col>
      </Row>
    );
  }
}

export default withRouter(BreadCrumbs);
