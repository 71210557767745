import React, { PureComponent } from 'react';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
// import { Link } from 'react-router-dom';

class CopyToClipboard extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.string,
    ]),
    value: PropTypes.string.isRequired,
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    title: PropTypes.string,
  };
  static defaultProps = {
    className: '',
    iconClassName: '',
    children: null,
    title: 'Copy',
  };
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  handleCopy = (e, value) => {
    const textField = document.createElement('textarea');
    textField.innerText = value;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    this.setState({ anchorEl: e.currentTarget });
    setTimeout(() => this.setState({ anchorEl: null }), 300);
  };

  render() {
    const {
      children,
      value,
      title,
      className,
      iconClassName,
    } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
      <span className={className}  style={{maxWidth: '100%'}}>
        {children}
        {value && value !== '' ? (
          <IconButton
            title={title}
            size="small"
            className="material-table__toolbar-button"
            onClick={e => this.handleCopy(e, value)}
          >
            <span className={`far fa-copy ${iconClassName}`} style={{ fontSize: '13px' }} />
          </IconButton>
        ) : null}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <span className="px-2 text-success">Copied</span>
        </Popover>
      </span>
    );
  }
}

export default CopyToClipboard;
