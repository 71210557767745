import { unflatten } from 'flat';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DropZoneField from '../../../../../../../shared/components/form/DropZone';

const ImageUrl = (props) => {
  const { record, onChange } = props;
  const recortUnflatten = unflatten(record);
  const rowData = recortUnflatten.params;
  const key = 'imageUrl';

  let val = [];

  if (typeof rowData !== 'undefined') {
    if (typeof rowData[key] !== 'undefined' && rowData[key] !== null && rowData[key]) {
      val = [{ preview: rowData[key], name: rowData[key] }];
    }
  }

  const [value, setValue] = useState(val);

  const handleChange = (files) => {
    const file = files.length ? files[0] : '';
    setValue(files);
    onChange('file', file);
  };

  return (
    <section>
      <h4 className="il-color-deep-blue font-weight-bold mt-5">Notification Image</h4>
      <hr className="mt-3 mb-4" />
      <DropZoneField
        input={{
          name: key,
          id: key,
          value,
          onChange: handleChange,
        }}
      />
    </section>
  );
};

ImageUrl.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ImageUrl;
