import React from 'react';
import PropTypes from 'prop-types';
import { unflatten } from 'flat';
import WVFormatter from '../../../../../../shared/helpers/WVFormatter';

const LastConversionDateInList = (props) => {
  const { params } = unflatten(props.record);
  if (params?.dealTerms?.date_of_last_conversion) {
    return (
      <span>{ WVFormatter.getFormatedDate(params.dealTerms.date_of_last_conversion)}</span>
    );
  }
  return '';
};
LastConversionDateInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default LastConversionDateInList;
