/* eslint react/prop-types: 0 */
import React, { useRef } from 'react';
import { unflatten } from 'flat';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';
import TabsOrModal from './TabsOrModal';
// import { checkIfImageExists } from '../../../../../shared/helpers/WVUtilities';

export default React.memo((props) => {
  const record = unflatten(props.record);
  const {
    setPortal,
    setCurrentProspect,
    setShowNotesModal,
    setDisabledNotes,
  } = props;
  // const [showModal, setShowModal] = useState(false);
  const ref = useRef(null);
  // console.log(ref, ref.current);

  return (
    <div style={{ maxWidth: '80px' }}>
      <div style={{ maxWidth: '40px', float: 'left' }}>
        <Tooltip title="Safety Check">
          <IconButton onClick={() => ref.current.openNewTabs()}><i className="fa fa-shield-alt" /></IconButton>
        </Tooltip>
        <TabsOrModal record={record} ref={ref} searchTerms={props.searchTerms} />
      </div>
      <div style={{ minWidth: '40px', maxWidth: '40px', float: 'right' }}>
        {record.params.approvalStatus?.influencelogic?.notes && (
          <Tooltip title="InfluenceLogic Notes">
            <IconButton
              onClick={() => { setPortal('influenceLogic'); setCurrentProspect(props.record); setDisabledNotes(true); setShowNotesModal(true); }}
              size="small"
              style={{ marginTop: '4px' }}
            >
              <img src="/images/brands/influenceLogic-Notes-icon.png" alt="InfluenceLogic Notes" style={{ padding: '5px' }} />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));
