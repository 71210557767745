import PermissionsInShow from './PermissionsInShow';
import RoleInList from './RoleInList';
import PasswordInEdit from './PasswordInEdit';
import PermissionsInEdit from './PermissionsInEdit';
import HsUserIdInEdit from './HsUserIdInEdit';
import SlackHandleInEdit from './SlackHandleInEdit';
import AffiliateManagerIdInEdit from './AffiliateManagerIdInEdit';
import ContextMenuInList from './ContextMenuInList';

export default {
  list: {
    role: RoleInList,
  },
  show: {
    permissions: PermissionsInShow,
  },
  edit: {
    password: PasswordInEdit,
    permissions: PermissionsInEdit,
    hsUserId: HsUserIdInEdit,
    slackId: SlackHandleInEdit,
    affiliateManagerId: AffiliateManagerIdInEdit,
    slackUserId: () => '',
  },
  actions: ContextMenuInList,
};
