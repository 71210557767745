import React from 'react';
import PropTypes from 'prop-types';
import { unflatten } from 'flat';

const InfluencerInList = ({ record }) => {
  const { params } = unflatten(record);

  const name = params.influencerObject && params?.ilDealData?.influencer
    ? `${params.influencerObject.first_name} ${params.influencerObject.last_name}` : '';
  return (
    <a
      href={`/influencer/profile/${params?.ilDealData?.influencer || 0}`}
      target="_blank"
      rel="noreferrer noopener"
      title={name}
    >
      {name}
    </a>
  );
};
InfluencerInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default InfluencerInList;

