/* eslint react/prop-types: 0 */
import React from 'react';

const DetailsInShow = (props) => {
  const { record, property } = props;
  const value = record.params[property.name];

  // console.log('LSD rec');
  return (
    <pre>{value}</pre>
  );
};

export default DetailsInShow;
