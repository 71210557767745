import React from 'react';
import AccountKeyIcon from 'mdi-react/AccountKeyIcon';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';

export default class SpyLogInButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  spyLogin = () => {
    // eslint-disable-next-line react/prop-types
    const { userId, url } = this.props;
    this.setState({ loading: true });
    axios.post(
      url,
      {
        userId,
      },
    ).then((response) => {
      const { spyLoginToken, baseUrl } = response.data;
      if (spyLoginToken && baseUrl) {
        const win = window.open(`${baseUrl}/login-secret/${spyLoginToken}`, '_blank');
        win.focus();
      }
      this.setState({ loading: false });
    }).catch((error) => {
      console.log(error);
      this.setState({ loading: false });
    });
  };

  render() {
    const { loading } = this.state;
    return loading ? (
      <div
        className="spinner-border"
        style={
          {
            width: '12px',
            height: '12px',
            marginLeft: '3px',
            marginRight: '6px',
            color: '#646777',
          }
        }
      />
    ) : (
      <Tooltip className="spyLoginButton" title={'"Spy" Log In'}>
        <button
          className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall"
          onClick={this.spyLogin}
        >
          <AccountKeyIcon
            style={{ width: '15px', height: '15px' }}
          />
        </button>
      </Tooltip>
    );
  }
}

