/* eslint react/prop-types: 0 */
import React from 'react';
import { Badge } from 'reactstrap';

export default ({ record }) => {
  let colorClass = 'secondary';
  let text = 'UNDEFINED';
  if (record && record.params) {
    switch (record.params.replyStatus) {
      case 'ourself':
        colorClass = 'primary';
        text = 'BOT';
        break;
      case 'user' || 'adminUser':
        colorClass = 'success';
        text = 'USER';
        break;
      case 'sequencer':
        colorClass = 'info';
        text = 'SEQUENCER';
        break;
      default:
        colorClass = 'secondary';
        text = 'UNDEFINED';
    }
  }

  return (
    <Badge
      color={colorClass}
      style={{
        padding: '6px 10px 6px 10px',
        marginTop: '4px',
        borderRadius: '15px',
      }}
    >
      {text}
    </Badge>
  );
};
