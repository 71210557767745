/* eslint react/prop-types: 0 */
import React from 'react';
import {
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

export default (props) => {
  const { record, onChange } = props;
  return (
    <FormGroup>
      <Label for="emailAddress">Value</Label>
      <Input
        id="value"
        name="value"
        type="textarea"
        value={record.params.value}
        onChange={e => onChange('value', e.target.value)}
        style={{ width: '100%' }}
      />
    </FormGroup>
  );
};

