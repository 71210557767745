import React from 'react';
import PropTypes from 'prop-types';
import StaticFilterCustom from './StaticFilterCustom';

const StaticFilterResource = ({ resource, additionalActions, showSearch }) => {
  const properties = resource.filterProperties
    .filter(p => p.type === 'string' || p.type === 'number')
    .map(({ name, label, custom }) => ({ name, label: custom?.label || label }));
  return (
    <StaticFilterCustom
      columns={properties}
      additionalActions={additionalActions}
      showSearch={showSearch}
    />
  );
};
StaticFilterResource.propTypes = {
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  additionalActions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  showSearch: PropTypes.bool,
};

StaticFilterResource.defaultProps = {
  additionalActions: null,
  showSearch: true,
};

export default StaticFilterResource;
