import React, { useEffect, useRef, useState } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import PropTypes from 'prop-types';
import CopyToClipboard from '../../../../../../shared/components/CopyToClipboard';

const Comment = ({ record }) => {
  const [openPopover, setOpenPopover] = useState(false);
  const [popoverTargetId] = useState(`commentPopoverTargetId_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10)}`);
  const wrapperRef = useRef(null);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        // console.log(ref.current);
        if (ref.current && !ref.current?.contains(event.target)) {
          setOpenPopover(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(wrapperRef);

  const toggle = () => {
    setOpenPopover(!openPopover);
  };
  return (
    <div>
      {record.row.original.params?.textDisplay.length > 110 ?
        <div>
          <Popover
            id={`selfId_${popoverTargetId}`}
            placement="right"
            target={popoverTargetId}
            isOpen={openPopover}
          >
            <PopoverBody style={{ fontSize: '12px' }} >
              {/* eslint-disable-next-line react/no-danger */}
              <span ref={wrapperRef} dangerouslySetInnerHTML={{ __html: `${record.row.original.params?.textDisplay || ''}` }} />
            </PopoverBody>
          </Popover>
          <div
            id={popoverTargetId}
          >
            <CopyToClipboard value={record.row.original.params?.textOriginal || ''}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
              <a role="button" tabIndex={0} onClick={toggle}>
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: `${record.row.original.params?.textDisplay.toString().substring(0, 110) || ''}...  ` }} />
              </a>
            </CopyToClipboard>
          </div>
        </div> :
        <CopyToClipboard value={record.row.original.params?.textOriginal || ''}>
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: `${record.row.original.params?.textDisplay || ''}  ` }} />
        </CopyToClipboard>
      }
    </div>
  );
};

Comment.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Comment;
