import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Button, Label, FormGroup,
} from 'reactstrap';
import { connect, useSelector } from 'react-redux';
import Select from 'react-select';
import { useAsyncDebounce } from 'react-table';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import TuneIcon from 'mdi-react/TuneIcon';
import moment from 'moment';
import { getCookie, setCookie } from '../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../shared/components/CheckBox';
import TopButtonsPeriod from '../../../../admin/Reports/TimePeriodFilterExtended';
import { setUrl } from './setUrl';
import AccessComponent from '../../../../../shared/components/auth/AccessComponent';
import Filter from '../../../../../shared/components/filter/TimePeriodFilter';

/* import {
  updateCreatorFilterItemUpdate,
  updateTopMenuCreatorLink,
} from '../../../../../redux/reducers/brand/CreatorsReducer'; */

const compare = (a, b) => {
  const keyA = a.label;
  const keyB = b.label;
  if (keyA < keyB) return -1;
  if (keyA > keyB) return 1;
  return 0;
};

const typeFilterOptions = [
  { label: 'YouTube', value: 'YouTube' },
  { label: 'YouTube Description Text', value: 'YouTube Description Text' },
  { label: 'Podcast', value: 'Podcast' },
  { label: 'Podcast Script Text', value: 'Podcast Script Text' },
  { label: 'Instagram Story', value: 'Instagram Story' },
  { label: 'Instagram Post', value: 'Instagram Post' },
  { label: 'Instagram TV Video', value: 'Instagram TV Video' },
  { label: 'TikTok', value: 'TikTok' },
  { label: 'Twitter Post', value: 'Twitter Post' },
  { label: 'Facebook Post', value: 'Facebook Post' },
  { label: 'Facebook Watch Video', value: 'Facebook Watch Video' },
  { label: 'LinkTree', value: 'LinkTree' },
  { label: 'Website', value: 'Website' },
  { label: 'Email', value: 'Email' },
  { label: 'Text', value: 'Text' },
];

const statusFilter = {
  'In Review': true,
  'Pending Edits': true,
  Approved: false,
};

const Actions = ({
  creators,
  loadingCreators,
  showTable,
}) => {
  const session = useSelector(state => state.session);
  let selectedStartDateInitialState = session.role === 'auditor' ? moment().subtract(8, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss') : moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
  let selectedEndDateInitialState = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
  const selectedTypesInitialState = [];
  let selectedInitialRange = session.role === 'auditor' ? '7_days' : 'year_to_date';

  const initialSearch = new URLSearchParams(window.location.search);
  if (initialSearch.has('filters.createdAt~~from') && initialSearch.getAll('filters.createdAt~~from').length > 0) {
    initialSearch.getAll('filters.createdAt~~from').forEach((from) => {
      selectedStartDateInitialState = moment(from).format('YYYY-MM-DD HH:mm:ss');
    });
  }
  if (initialSearch.has('filters.createdAt~~to') && initialSearch.getAll('filters.createdAt~~to').length > 0) {
    initialSearch.getAll('filters.createdAt~~to').forEach((to) => {
      selectedEndDateInitialState = moment(to).format('YYYY-MM-DD HH:mm:ss');
    });
  }
  if (initialSearch.has('filters.type') && initialSearch.getAll('filters.type').length > 0) {
    initialSearch.getAll('filters.type').forEach((type) => {
      selectedTypesInitialState.push({ value: type, label: type });
    });
  }
  if (initialSearch.has('range') && initialSearch.getAll('range').length > 0) {
    initialSearch.getAll('range').forEach((range) => {
      selectedInitialRange = range;
    });
  }
  if (initialSearch.has('filters.brandStatus') && initialSearch.getAll('filters.brandStatus').length > 0) {
    Object.keys(statusFilter).map((key) => {
      statusFilter[key] = false;
      return true;
    });
    initialSearch.getAll('filters.brandStatus').forEach((status) => {
      statusFilter[status] = true;
    });
  }
  const [search, setSearch] = useState('');
  const [dateSearch, setDateSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(getCookie('contentApprovalsShowFilters') === 'true');
  const [creatorOptions, setCreatorOptions] = useState([]);
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [selectedContentTypes, setSelectedContentTypes] = useState(selectedTypesInitialState);
  const [filtersBrandStatus, setFiltersBrandStatus] = useState(statusFilter);

  const [dateFrom, setDateFrom] = useState(selectedStartDateInitialState);
  const [dateTo, setDateTo] = useState(selectedEndDateInitialState);
  const [selectedButton, setSelectedButton] = useState(selectedInitialRange);
  const history = useHistory();
  const firstUpdate = useRef(true);

  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl({
      history,
      removeBeforeSet: false,
      search,
      dateFrom,
      dateTo,
      selectedButton,
      filtersBrandStatus,
      selectedCreators,
      selectedContentTypes,
    });
  }, 800);

  useEffect(() => {
    setCreatorOptions(creators
      .map(creator => ({ value: creator._id, label: `${creator.first_name} ${creator.last_name}`.trim() }))
      .filter(option => option.label.length > 0)
      .sort(compare));
  }, [creators]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setUrlDebounced();
  }, [
    search,
    dateFrom,
    dateTo,
    selectedButton,
    selectedCreators,
    selectedContentTypes,
    filtersBrandStatus,
  ]);

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      setTimeout(() => showTable(true));
    }, 1000));
  }, [search]);

  useEffect(() => {
    setCookie('contentApprovalsShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  const handleChangeStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersBrandStatus({
        ...filtersBrandStatus,
        [name]: checked,
      });
    }
  };

  const resetFilters = () => {
    setSearch('');
    setSelectedCreators([]);
    setSelectedContentTypes([]);
    setFiltersBrandStatus({
      'In Review': true,
      'Pending Edits': true,
      Approved: false,
    });
  };

  return (
    <div className="mb-1">
      <AccessComponent showWhenRole={['owner']}>
        <TopButtonsPeriod
          last7DaysIncludingToday
          selectedButtonInitial={selectedButton}
          startDateInitial={selectedStartDateInitialState}
          endDateInitial={selectedEndDateInitialState}
          setValue={(param, value, range) => {
            if (param === 'startDate') {
              setDateFrom(moment(value).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
            }
            if (param === 'endDate') {
              setDateTo(moment(value).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
            }
            setSelectedButton(range);
          }}
        />
      </AccessComponent>
      <AccessComponent showWhenRole={['auditor']}>
        <Filter
          startDateInitial={selectedStartDateInitialState}
          endDateInitial={selectedEndDateInitialState}
          selectedButtonInitial={selectedButton}
          setValue={(param, value, range) => {
            if (param === 'startDate') {
              setDateFrom(moment(value).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
            }
            if (param === 'endDate') {
              setDateTo(moment(value).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
            }
            setSelectedButton(range);
          }}
          options={[
            { value: '30_days', label: '30 Days', border: '5px 0 0 5px' },
            { value: '7_days', label: 'Last 7 Days' },
            {
              value: 'today', label: 'Today', border: '0 5px 5px 0', lastElement: true,
            },
          ]}
          selectDateRangeFunction={(range) => {
            let dFrom;
            let dTo;
            let showCustom = false;
            switch (range) {
              case '30_days':
                showCustom = false;
                dFrom = moment().subtract(31, 'days').startOf('day').toDate();
                dTo = moment().subtract(1, 'day').endOf('day').toDate();
                break;
              case '7_days':
                showCustom = false;
                dFrom = moment().subtract(8, 'days').startOf('day').toDate();
                dTo = moment().subtract(1, 'day').endOf('day').toDate();
                break;
              case 'today':
                showCustom = false;
                dFrom = moment().startOf('day').toDate();
                dTo = moment().endOf('day').toDate();
                break;
              default:
                dFrom = moment().startOf('day').toDate();
                dTo = moment().endOf('day').toDate();
                break;
            }
            // console.log({ selectedStartDateInitialState, selectedEndDateInitialState, showCustom });
            return { dateFrom: dFrom, dateTo: dTo, showCustom };
          }
          }
        />
      </AccessComponent>
      <Row className="mt-4">
        <Col sm={10}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Title, Description, Creator Name"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
      {showFilters && (
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label className="bold-text">
                Creator(s)
              </Label>
              <Select
                isMulti
                name="creatorSelect"
                isDisabled={loadingCreators}
                isLoading={loadingCreators}
                isClearable
                value={selectedCreators}
                options={creatorOptions}
                onChange={setSelectedCreators}
                placeholder="Select Creator..."
                style={{ maxHeight: '120px' }}
                styles={colourStyles}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                })}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label className="bold-text">
                Content Type(s)
              </Label>
              <Select
                isMulti
                name="typeSelect"
                isClearable
                value={selectedContentTypes || []}
                options={typeFilterOptions}
                onChange={setSelectedContentTypes}
                placeholder="Select Content Type..."
                style={{ maxHeight: '120px' }}
                styles={colourStyles}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                })}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label className="bold-text">
                Brand Status
              </Label>
              <Row className="pt-2">
                <Col sm={4}>
                  <CheckBoxField
                    name="InReview"
                    label="In Review"
                    // className="inline"
                    value={filtersBrandStatus['In Review']}
                    onChange={event => handleChangeStatusFilters(event, 'In Review')}
                  />
                </Col>
                <Col sm={4}>
                  <CheckBoxField
                    name="PendingEdits"
                    label="Pending Edits"
                    // className="inline ml-4"
                    value={filtersBrandStatus['Pending Edits']}
                    onChange={event => handleChangeStatusFilters(event, 'Pending Edits')}
                  />
                </Col>
                <Col sm={4}>
                  <CheckBoxField
                    name="Approved"
                    label="Approved"
                    // className="inline ml-4"
                    value={filtersBrandStatus.Approved}
                    onChange={event => handleChangeStatusFilters(event, 'Approved')}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
      )}
    </div>
  );
};
Actions.propTypes = {
  creators: PropTypes.arrayOf(PropTypes.any).isRequired,
  loadingCreators: PropTypes.bool.isRequired,
  showTable: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  creators: state.creators.creators,
  loadingCreators: state.creators.loading.creators,
});

export default connect(mapStateToProps)(Actions);
