/* eslint react/prop-types: 0 */
import React from 'react';
// import styled from 'styled-components';
import { connect } from 'react-redux';
import NotificationProvider from 'rc-notification';
// import theme from '../../../../theme';
import { dropNotice, setNoticeProgress } from '../../../../containers/App/store/store';
import { FullWideNotification } from '../../../../shared/components/Notification';

// const TIME_TO_DISAPPEAR = 3;

/*
 * const NoticeWrapper = styled.div.attrs({
  className: 'notification',
})`
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  border-style: none none solid none;
  border-width: 1px;
  padding: 13px ${theme.sizes.paddingLayout};

  &:not(:last-child) {
    margin-bottom: 0;
  }

  &.success {
    background-color: ${theme.colors.lightSuccess};
    border-color: ${theme.colors.success};

    & .progressBar {
      background-color: ${theme.colors.success};
    }
  }

  &.error {
    background-color: ${theme.colors.lightError};
    border-color: ${theme.colors.error};
    & .delete:before, & .delete:after {
      background-color: ${theme.colors.error};
    }
    & .progressBar {
      background-color: ${theme.colors.error};
    }
  }

  & .delete {
    background: transparent;
    right: ${theme.sizes.paddingLayout};
    top: ${theme.sizes.padding};

    &:before, &:after {
      background-color: ${theme.colors.success};
    }

    &:after {
      height: 80%;
      width: 1px;
    }
    &:before {
      width: 80%;
      height: 1px;
    }
  }

  & .progressBar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background: #fff;
    transition: width 1s linear;
  }
`;
*/
let notification = null;
const showNotification = (title, notice, color, duration) => {
  notification.notice({
    content: <FullWideNotification
      message={notice.message}
      color={color}
    />,
    duration,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  });
};


class NoticeElement extends React.Component {
  constructor(props) {
    super(props);
    // const { notice } = props;
    this.timer = null;
    // this.state = {
    //   progress: notice.progress || 0,
    // };
  }
  componentDidMount() {
    // const { rtl } = this.props;
    const { notice } = this.props;
    // const { progress } = this.state;
    let title = 'Success';
    let color = 'success';
    if (notice.type === 'error') {
      title = 'Error';
      color = 'danger';
    }
    if (notice.type === 'warning') {
      title = 'Warning';
      color = 'warning';
    }
    // eslint-disable-next-line
    NotificationProvider.newInstance({ style: { top: 65 } }, n => notification = n);
    setTimeout(() => showNotification(title, notice, color, notice.duration), 700);
  }


  /* componentDidMount() {
    const { drop } = this.props;

    this.timer = setInterval(() => {
      this.setState((state) => {
        const progress = ((state.progress + 100) / TIME_TO_DISAPPEAR);
        notifyProgress({ noticeId: notice.id, progress });
        return { progress };
      });
    }, 1000);

    setTimeout(() => {
      if (this.timer) {
        clearInterval(this.timer);
      }
      drop();
    }, 1000 * (TIME_TO_DISAPPEAR + 1));
  } */
  componentWillUnmount() {
    notification.destroy();
  }

  /*
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  */
  render() {
    return (<div />);
  }
}
/*
 *       <NoticeWrapper className={notice.type}>
        <button className="delete" onClick={drop} type="button" />
        { notice.message }
        <div className="progressBar" style={{ width: `${progress}%` }} />
      </NoticeWrapper>
 */

const NoticeBox = (props) => {
  const { drop, notices, notifyProgress } = props;
  const notice = notices.length ? notices[notices.length - 1] : null;
  if (notice) {
    return (
      <NoticeElement
        key={notice.id}
        notice={notice}
        drop={() => drop(notice.id)}
        notifyProgress={notifyProgress}
      />
    );
  }
  return (
    <div />
  );
};

const mapStateToProps = state => ({
  notices: state.notices,
});


const mapDispatchToProps = dispatch => ({
  drop: noticeId => dispatch(dropNotice(noticeId)),
  notifyProgress: ({
    noticeId, progress,
  }) => dispatch(setNoticeProgress({ noticeId, progress })),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoticeBox);
