import React, { PureComponent } from 'react';
import { FormGroup, Label, Input, FormFeedback, Button } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { axios } from 'ApiClient';
import ReCAPTCHA from 'react-google-recaptcha';
import Alert from '../../../shared/easydev2/shared/components/Alert';
import WVValidator from '../../../shared/helpers/WVValidator';
import config from '../../../shared/config/config';
import Footer from '../LogIn/components/Footer';

const recaptchaRef = React.createRef();

class ForgotPasswordForm extends PureComponent {
  constructor() {
    super();

    this.state = {
      email: '',
      recaptchaToken: '',
      errors: {},
      isLoadingData: false,
      successMessage: false,
    };
  }

  componentDidMount() {
    document.title = 'InfluenceLogic: Reset Password';
  }

  onRecaptchaChange = (value) => {
    this.setState({
      recaptchaToken: value,
    });
  }


  onDataChanged = (event) => {
    const { name, value } = event.target;
    const state = {
      ...this.state,
      [name]: value,
    };

    delete state.errors[name]; // clear errors

    this.setState(state);
  }

  onSubmit = () => {
    if (this.state.isLoadingData) { return false; }
    if (!this.validateErrors()) { return false; }

    this.setState({
      isLoadingData: true,
    });

    axios({
      method: 'post',
      url: '/api/brand/forgot-password',
      data: {
        email: this.state.email,
        recaptchaToken: this.state.recaptchaToken,
      },
    }).then((response) => {
      // console.log("SUCCESS RESPONSE", response);

      if (response.data.success === true) {
        this.setState({
          successMessage: 'Check your email for instructions on how to complete resetting your password.',
          email: '',
          recaptchaToken: '',
          isLoadingData: false,
        });
        recaptchaRef.current.reset();
      } else {
        this.handleSubmitError(response.data.error);
      }
    }).catch((error) => {
      // console.log('CATCH ERROR: ',error);
      this.handleSubmitError(error.message);
    });
    return null;
  }

  validateErrors() {
    const errors = {};

    if (!WVValidator.isEmail(this.state.email)) {
      errors.email = 'Please check your email format!';
    }
    if (WVValidator.isEmpty(this.state.recaptchaToken)) {
      errors.global = 'Please verify that you are not a robot!';
    }
    this.setState({
      ...this.state,
      errors,
    });

    return Object.entries(errors).length === 0;
  }

  handleSubmitError(error) {
    recaptchaRef.current.reset();

    const state = {
      ...this.state,
      isLoadingData: false,
    };

    state.errors.global = error;

    this.setState(state);
  }

  render() {
    return (
      <div>
        <div className="account account--not-photo login-brands-bg">
          <div className="account__wrapper">
            <div className="account__card" style={{ minWidth: '330px' }}>
              <div className="account__head-login">
                <img src="/images/general/logo_dark_small.png" alt="IL Brand Portal" />
              </div>
              <div style={{ textAlign: 'center' }}>
                <h2>Brand Portal</h2>
              </div>
              <h4 style={{ textAlign: 'center' }}>Forgot Password</h4>
              {this.state.successMessage && <Alert color="success"><p>{this.state.successMessage}</p></Alert>}
              <FormGroup>
                <Label for="emailInput">Email</Label>
                <Input
                  name="email"
                  type="email"
                  bsSize="lg"
                  invalid={Object.prototype.hasOwnProperty.call(this.state.errors, 'email')}
                  onChange={this.onDataChanged}
                  value={this.state.email}
                />
                <FormFeedback>{this.state.errors.email}</FormFeedback>
              </FormGroup>
              <FormGroup className="captcha-div">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={config().recaptcha.siteKey}
                  onChange={this.onRecaptchaChange}
                />
              </FormGroup>
              {
                Object.prototype.hasOwnProperty.call(this.state.errors, 'global') &&
                <Alert color="danger">
                  <p>{this.state.errors.global}</p>
                </Alert>
              }
              <Button
                color="primary"
                size="lg"
                block
                onClick={this.onSubmit}
                style={{ marginTop: '15px' }}
              >
                {this.state.isLoadingData && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
                <span>Reset Password</span>
              </Button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default reduxForm({
  form: 'forgot_password_form',
})(ForgotPasswordForm);
