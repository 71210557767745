/* eslint react/prop-types: 0 */
/* eslint no-underscore-dangle: 0 */
import React from 'react';

export default (props) => {
  const { record } = props;
  const name = `Payout amount: ${parseFloat(record.populated.payable.params.payoutAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`;
  return (
    <div>
      {`${name} `}
    </div>
  );
};
