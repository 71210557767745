/* eslint react/prop-types: 0 */
import React from 'react';
import Select from 'react-select';
import ApiClient from 'ApiClient';

const roles = {
  owner: 'Owner',
  auditor: 'Auditor',
};

export default class RoleInList extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.api = new ApiClient();
    const { record } = this.props;
    this.state = {
      selected: { value: record.params.role, label: roles[record.params.role] },
      loading: false,
      id: record.id,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { record } = props;
    if (record.id !== state.id) {
      return {
        selected: { value: record.params.role, label: roles[record.params.role] },
        id: record.id,
      };
    }
    return null;
  }

  handleSelectChange(selected) {
    this.setState({ selected });
    this.setState({ loading: true });
    const { resource, record } = this.props;
    const value = selected ? selected.value : '';
    if (value === '') {
      return false;
    }

    const data = new FormData();
    data.append('role', value);
    this.api.recordAction({
      resourceId: resource.id,
      recordId: record.id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(() => {
      this.setState({ loading: false });
      // eslint-disable-next-line
      if (window.REDUX_STATE.session && window.REDUX_STATE.session['_id'] === record.id) {
        window.location.reload();
      }
    });
    return true;
  }

  render() {
    const { selected, loading } = this.state;
    const rolesMap = Object.keys(roles).map(key => ({
      value: key,
      label: roles[key],
    }));

    // console.log("LSD ROLE:", this.props)
    const { record } = this.props;
    let isEditable = false;
    if (Array.isArray(record.recordActions)) {
      const editAction = record.recordActions.find(action => action.name === 'edit');
      if (editAction !== undefined) {
        isEditable = true;
      }
    }
    // console.log("LSD isEditable", isEditable)
    return (
      <div style={{ minWidth: '100px' }}>
        <Select
          isDisabled={loading || !isEditable}
          value={selected}
          options={rolesMap}
          onChange={this.handleSelectChange}
          menuPlacement="auto"
          menuPortalTarget={document.body}
        />
      </div>
    );
  }
}
