import { unflatten } from 'flat';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';

const DisplayMessage = (props) => {
  const { record, onChange } = props;
  const recortUnflatten = unflatten(record);
  const rowData = recortUnflatten.params;
  const key = 'displayMessage';

  let val = false;

  if (typeof rowData !== 'undefined') {
    if (typeof rowData[key] !== 'undefined' && rowData[key] !== null) {
      val = rowData[key];
    }
  }

  const [value, setValue] = useState(val);

  const handleChange = async (e) => {
    if (e) {
      setValue(e.target.checked);
      onChange(key, e.target.checked);
    }
  };

  return (
    <section>
      <h4 className="il-color-deep-blue font-weight-bold mt-5">Notification Schedule</h4>
      <hr className="mt-3 mb-4" />
      <FormGroup>
        <CheckBoxField
          name={key}
          label="Display Notification"
          value={value}
          onChange={handleChange}
        />
      </FormGroup>
    </section>
  );
};

DisplayMessage.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DisplayMessage;
