import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// import Icon from 'mdi-react/NoteOutlineIcon';
import { IconButton as IB } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const IconButton = (props) => {
  const { agent } = props;
  return (
    <span>
      <Tooltip title="Show Agent Creators">
        <Link to={`/agents/${agent.id}/creators`}>
          <IB size="small">
            <i className="fa fa-users" style={{ fontSize: '0.7em' }} />
          </IB>
        </Link>
      </Tooltip>
    </span>
  );
};

IconButton.propTypes = {
  agent: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default IconButton;
