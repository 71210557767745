import React from 'react';
import StatusInList from './StatusInList';

export default {
  edit: {},
  list: {
    publishedDealstage: props => (<StatusInList {...props} stage="publishedDealstage" />),
    paidDealstage: props => (<StatusInList {...props} stage="paidDealstage" />),
    needRateFromCreator: props => (<StatusInList {...props} stage="needRateFromCreator" />),
    inNegotiations: props => (<StatusInList {...props} stage="inNegotiations" />),
    verbalAgreement: props => (<StatusInList {...props} stage="verbalAgreement" />),
    contractSent: props => (<StatusInList {...props} stage="contractSent" />),
    futurePublishDate: props => (<StatusInList {...props} stage="futurePublishDate" />),
    contentInReview: props => (<StatusInList {...props} stage="contentInReview" />),
    dealLost: props => (<StatusInList {...props} stage="dealLost" />),
    bHPassedonDeal: props => (<StatusInList {...props} stage="bHPassedonDeal" />),
    pricingReview: props => (<StatusInList {...props} stage="pricingReview" />),
    pricingApproved: props => (<StatusInList {...props} stage="pricingApproved" />),

  },
};
