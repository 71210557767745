import React from 'react';
import ApiClient from 'ApiClient';
import { unflatten } from 'flat';
import recordToFormData from '../components/actions/record-to-form-data';

class Record {
  constructor({
    record = {},
    stateName = '',
    resource = '',
    schema = null,
  }, contest) {
    this.api = new ApiClient();
    const defaultProps = this.initRecord();
    this.contest = contest;
    this.params = record.params || defaultProps.record.params || {};
    this.errors = record.errors || defaultProps.record.errors || {};
    this.populated = record.populated || defaultProps.record.populated || {};
    this.stateName = stateName || defaultProps.stateName;
    this.resourceName = resource || defaultProps.resource;
    this.schema = schema || defaultProps.schema;
    this.id = this.params._id || null;
  }
  // eslint-disable-next-line class-methods-use-this
  initRecord() {
    return {
      record: {},
      stateName: 'record',
      resource: '',
      schema: {},
    };
  }
  get(name) {
    return this.params && this.params[name] ? this.params[name] : '';
  }
  getP(populatedItem, param) {
    return this.populated && this.populated[populatedItem] && this.populated[populatedItem].params && this.populated[populatedItem].params[param] ? this.populated[populatedItem].params[param] : '';
  }
  set(name, value, callback) {
    this.params[name] = value;
    this.errors[name] = undefined;
    this.contest.setState({
      [this.stateName]: {
        params: this.params,
        errors: this.errors,
        id: this.id,
        populated: this.populated,
      },
    }, (state) => {
      if (callback) {
        callback(state);
      }
    });
  }
  async fetchRecord(id) {
    const res = await this.api.recordAction({
      resourceId: this.resourceName,
      recordId: id,
      actionName: 'show',
    });
    this.setRecord(unflatten(res.data.record));
  }
  setRecord({
    params,
    errors,
    id,
    populated,
  }) {
    console.log(this);
    this.params = params;
    this.errors = errors;
    this.populated = populated;
    this.id = id;
    this.contest.setState({
      [this.stateName]: {
        params: this.params,
        errors: this.errors,
        id: this.id,
        populated: this.populated,
      },
    });
  }
  async newItemF() {
    const data = this.paramsToForm();
    const res = await this.api.resourceAction({
      resourceId: this.resourceName,
      actionName: 'new',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (!res.data.record.id) {
      throw new Error(res.data.notice.message);
    }
    return res.data.record;
  }
  async updateItemF() {
    const data = this.paramsToForm();
    const res = await this.api.resourceAction({
      resourceId: this.resourceName,
      recordId: this.id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data.record;
  }
  isNew() {
    return !this.id;
  }
  getModel() {
    return {
      errors: { ...this.errors },
      params: { ...this.params },
      populated: { ...this.populated },
      id: this.id,
    };
  }
  async save() {
    let record = null;
    const valid = await this.validate();
    if (!valid) {
      throw new Error('Invalid values');
    }
    if (!this.id) {
      record = await this.newItemF(this.params);
    } else {
      record = await this.updateItemF(this.params);
    }
    this.setRecord(record);
    if (this.haveErrors()) {
      throw new Error('Please check Form.');
    }
    return this;
  }
  haveErrors() {
    let isValid = true;
    Object.keys(this.errors).forEach((key) => {
      if (this.errors && this.errors[key] && this.errors[key].message) {
        isValid = false;
      }
    });
    return !isValid;
  }
  getSchemaParams() {
    const params = {};
    Object.keys(this.schema).forEach((key) => {
      const value = this.params[key];
      params[key] = value;
    });
    return params;
  }
  paramsToForm() {
    const params = this.getSchemaParams();
    return recordToFormData({ params });
  }
  addError(prop, error) {
    this.errors[prop] = { message: error };
    this.contest.setState({
      [this.stateName]: {
        params: this.params,
        errors: this.errors,
        id: this.id,
        populated: this.populated,
      },
    });
  }
  removeError(prop) {
    if (this.errors[prop]) {
      delete this.errors[prop];
    }
    this.contest.setState({
      [this.stateName]: {
        params: this.params,
        errors: this.errors,
        id: this.id,
        populated: this.populated,
      },
    });
  }
  getErrorView(prop) {
    return this.errors[prop] && (<div className="form__form-group-error">{this.errors[prop].message || 'Check this field'}</div>);
  }
  async validate() {
    console.log(this);
    Object.keys(this.schema).forEach((key) => {
      this.validateParam(key);
    });
    return !this.haveErrors();
  }
  async validateParam(key) {
    if (this.schema[key].required && this.schema[key].required === true) {
      this.checkRequired(key, this.schema[key].title);
    }
    if (this.schema[key].validate && typeof this.schema[key].validate === 'function') {
      try {
        this.schema[key].validate.call(this, this.params[key], this.schema[key].title);
        this.removeError(key);
      } catch (e) {
        this.addError(key, e.message);
      }
    }
  }
  checkRequired(prop, title) {
    if (this.params[prop] !== null && this.params[prop] !== undefined) {
      this.removeError(prop);
      return true;
    }
    this.addError(prop, `${title} is required`);
    return false;
  }
}
export default Record;
