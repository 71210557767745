import React, { useEffect, useState } from 'react';
import { Modal, ButtonToolbar, Button, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatNumber, formatIntNumber } from '../helpers/WVFormatter';
import Alert from './Alert';

const StatsModal = ({
  isOpen,
  dispatch,
  close,
  tubularStatistics,
  creatorName,
  loading,
  loadData,
}) => {
  const [youtube, setYoutube] = useState(false);
  const determinePercentageFromArray = function determinePercentageFromArray(data, key, value) {
    if (typeof data !== 'undefined' && data !== undefined && data !== null) {
      for (let i = 0; i < data.length; i += 1) {
        if (data[i][key] === value) {
          return data[i].value;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(loadData());
    }
  }, [isOpen]);

  useEffect(() => {
    setYoutube(tubularStatistics?.accounts?.find(a => a.platform === 'youtube') || false);
  }, [tubularStatistics]);

  const getO = t => (
    <a
      href={t.account_url}
      target="_blank"
      rel="noreferrer"
      key={t.platform}
      style={{ marginRight: '4px' }}
    >
      <img
        src={`/images/mediacontent/${t.platform}.png`.toString()}

        alt={t.platform}
        title={t.title}
        style={{ width: '20px' }}
      />
    </a>);
  return (
    <Modal
      size="lg"
      style={{ maxWidth: '1600px', width: '70%', textAlign: 'center' }}
      isOpen={isOpen}
      toggle={close}
    >
      <div className="modal__header">
        <h2 className="text-modal modal__title">{creatorName}</h2>
        {!loading && tubularStatistics && youtube && (
          <Row style={{ fontSize: '125%' }}>
            <Col><strong>Influencer Score</strong>   {formatIntNumber(Math.ceil(Number(youtube.influencer_score)))}</Col>
            <Col><strong>V30</strong>   {formatIntNumber(Math.ceil(Number(youtube.monthly_v30)))}</Col>
            <Col><strong>ER30</strong>   {formatNumber(Number(youtube.monthly_er30))}</Col>
            <Col><strong>Total Followers</strong>   {formatIntNumber(Number(youtube.followers))}</Col>
          </Row>
        )}
        <hr className="mt-2 mb-2" />
      </div>
      <div className="modal__body ltr-support">
        {!loading && tubularStatistics && youtube ? (
          <div>
            <Row>
              <Col xs={2}><img src={youtube.thumbnail_url} alt="" className="img-thumbnail" /></Col>
              <Col xs={10}>
                <Row>
                  <Col>
                    {tubularStatistics.description}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h4 className="mt-3 bold-text">Demographics</h4>
                    <hr className="mt-2 mb-2" />
                    <Row>
                      <Col><strong>Percent Male</strong>   {`${Number(determinePercentageFromArray(youtube.audience_genders, 'gender', 'male') || 0).toFixed(2)}%`}</Col>
                      <Col><strong>Percent Female</strong>   {`${Number(determinePercentageFromArray(youtube.audience_genders, 'gender', 'female') || 0).toFixed(2)}%`}</Col>
                      <Col><strong>Percent US</strong>   {`${Number(determinePercentageFromArray(youtube.audience_locations, 'country', 'US') || 0).toFixed(2)}%`}</Col>
                      <Col><strong>Percent Over 25 YO</strong>   {`${Number(determinePercentageFromArray(youtube.audience_ages, 'range', 'ages_25_55_plus') || 0).toFixed(2)}%`}</Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h4 className="mt-3 bold-text">Details</h4>
                    <hr className="mt-2 mb-2" />
                    <Row>
                      <Col>
                        <div>
                          <FontAwesomeIcon icon={['fas', 'video']} /> Platforms:{' '}
                          <span className="" >
                            {tubularStatistics?.accounts?.map(t => getO(t))}
                          </span>
                        </div>
                        <div>
                          <FontAwesomeIcon icon={['fas', 'flag']} /> Country: {tubularStatistics?.country || '-'}
                        </div>
                        <div>
                          <FontAwesomeIcon icon={['fas', 'globe-americas']} /> Language: {tubularStatistics?.language || '-'}
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <FontAwesomeIcon icon={['fas', 'book']} /> Themes: {tubularStatistics?.themes?.map(t => t.title).join(', ') || '-'}
                        </div>
                        <div>
                          <FontAwesomeIcon icon={['fas', 'tag']} /> Genre: {tubularStatistics?.genre?.title || '-'}
                        </div>
                        <div>
                          <FontAwesomeIcon icon={['fas', 'suitcase']} /> Industry: {tubularStatistics?.industry?.title || '-'}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        ) : null}
        {loading ? (
          <div className="text-center">
            <div role="status" className="spinner-border-sm spinner-border text-primary">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : null}
        {!loading && !youtube ? (
          <Alert color="info" className="pr-4 pl-1" hideCloseButton>
            <p>{`${creatorName} currently has no statistical data available`}</p>
          </Alert>
        ) : null}
      </div>
      <ButtonToolbar className="modal__footer">
        <Button className="btn-sm" color="primary" onClick={close}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

StatsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  creatorName: PropTypes.string.isRequired,
  tubularStatistics: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  loadData: PropTypes.func.isRequired,
};


export default connect()(StatsModal);
