import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ButtonToolbar,
  Button,
  Spinner,
} from 'reactstrap';
import {
  getBrandUsers,
  closeScorecardAnswersDialog,

} from '../../../../../redux/reducers/brand/CreatorsReducer';
import ScorecardsAnswersForm from '../../../../admin/Scorecard/ScorecardAnswersSheet/components/ScorecardsAnswersForm';
import Table from './Table';
import Actions from './Actions';

const version = (new Date()).toString();

const ProspectContainer = ({
  setTag,
  action,
  resource,
  dispatch,
  history,
  brand,
  showScorecardAnswersDialog,
  loadingScorecardData,
  scorecardData,
}) => {
  const [showTable, setShowTable] = useState(false);
  useEffect(() => {
    // dispatch(getTotalStats());
    dispatch(getBrandUsers());
  }, []);
  const isFTCCompliant = scorecardData !== null && typeof scorecardData.data !== 'undefined' ? scorecardData.data.isFTCCompliant : null;
  const includesPaidPromotion = scorecardData !== null && typeof scorecardData.data !== 'undefined' ? scorecardData.data.includesPaidPromotion : null;

  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Actions
                history={history}
                showTable={() => setShowTable(true)}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              {showTable && <Table
                setTag={setTag}
                action={action}
                resource={resource}
                version={version}
                brand={brand}
              />}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={showScorecardAnswersDialog}
        toggle={() => dispatch(closeScorecardAnswersDialog(''))}
        style={{ minWidth: '750px' }}
      >
        <ModalHeader toggle={() => dispatch(closeScorecardAnswersDialog(''))} tag="h4" className="modal-title-statistics">Scorecard Preview</ModalHeader>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="modal__body">
          {!loadingScorecardData && showScorecardAnswersDialog && scorecardData && (
            <ScorecardsAnswersForm
              questions={scorecardData.data.scorecard}
              answers={scorecardData}
              view="modal"
              isFTCCompliant={isFTCCompliant}
              includesPaidPromotion={includesPaidPromotion}
              type={scorecardData.data.mediaContent.type}
              mediaContentText={scorecardData.data.mediaContent.contentText}
              disableNotes
            />
          )}
          {loadingScorecardData && showScorecardAnswersDialog && !scorecardData && (
            <div className="text-center"><Spinner color="primary" size="lg" /></div>
          )}
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="btn btn-primary btn-sm" onClick={() => dispatch(closeScorecardAnswersDialog(''))}>Close</Button>
        </ButtonToolbar>
      </Modal>
    </>
  );
};


ProspectContainer.propTypes = {
  setTag: PropTypes.func.isRequired,
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  brand: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  scorecardData: PropTypes.objectOf(PropTypes.any),
  loadingScorecardData: PropTypes.bool,
  showScorecardAnswersDialog: PropTypes.bool.isRequired,
};
ProspectContainer.defaultProps = {
  loadingScorecardData: null,
  scorecardData: null,
};

export default connect(state => ({
  creators: state.creators,
  loading: state.creators.loading,
  brand: state.session,
  showScorecardAnswersDialog: state.creators.showScorecardAnswersDialog,
  loadingScorecardData: state.score.loading.fetchScorecardAnswersContent,
  scorecardData: state.score.answers,
}))(ProspectContainer);
