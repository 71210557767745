import React from 'react';

export default (props) => {
  const { record } = props;
  let url = '';
  let actionId = '';
  if (window.location.href.indexOf('reports') >= 0) {
    url = `https://app.impact.com/secure/lightbox/mp/getActionDetail.ihtml?${record.params.campaignId ? `cid=${record.params.campaignId}&` : ''}acid=${record.params.actionId}`;
    ({ actionId } = record.params);
  }
  const result = (url.length > 0
    ? (
      <a href={url} target="_blank" rel="noreferrer">
        {actionId}
      </a>
    )
    : '');
  return result;
};
