/* eslint react/prop-types: 0 */
import React from 'react';
import Select from 'react-select/async';
import ApiClient from 'ApiClient';
import PropertyInFilter from '../../ui/property-in-filter';
import { filterStyles } from '../../../styles/selectStyles';
import theme from '../../../../../theme';

class Filter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.api = new ApiClient();
    this.options = [];
    this.loadOptions = this.loadOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selected) {
    const { onChange, property } = this.props;
    onChange(property.name, selected ? selected.value : '');
  }

  async loadOptions(inputValue) {
    const { property } = this.props;
    const records = await this.api.searchRecords({
      resourceId: property.reference,
      query: inputValue,
    });
    this.options = records.map(r => ({ value: r.id, label: r.title }));
    return this.options;
  }

  render() {
    const { property, filter } = this.props;
    const value = typeof filter[property.name] === 'undefined' ? '' : filter[property.name];
    const selected = (this.options || []).find(o => o.value === value);
    return (
      <PropertyInFilter property={property}>
        <Select
          value={typeof selected === 'undefined' ? '' : selected}
          isClearable
          cacheOptions
          styles={filterStyles(theme)}
          loadOptions={this.loadOptions}
          onChange={this.handleChange}
          defaultOptions
        />
      </PropertyInFilter>
    );
  }
}

export default Filter;
