/* eslint react/prop-types: 0 */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { unflatten } from 'flat';

export default (props) => {
  const { record, property } = props;
  console.log(record);
  const meta = JSON.stringify(unflatten(record.params).meta, undefined, 4);
  console.log('mata', meta);
  const metaFormated = meta.split('\\n').map(line => line.replaceAll('\\', '').replaceAll('","', '", "'));
  return (
    <div>
      <label
        className="label"
        style={{
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            fontSize: '11px',
            color: '#70728F',
          }}
      >
        {property.name}
      </label>
      <br />
      {
        metaFormated.map((line, index) => (
          <p
            style={{ margin: '0' }}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            {line}
          </p>
          ))
      }
      <br />
    </div>
  );
};
