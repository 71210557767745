import { unflatten } from 'flat';
import React from 'react';
import PropTypes from 'prop-types';

const InfluencerInList = ({ record }) => {
  const recordUnflatten = unflatten(record);
  let val;
  if (recordUnflatten.params.influencer) {
    if (recordUnflatten.params.affiliateData && recordUnflatten.params.affiliateData.company) {
      val = `${recordUnflatten.params.influencer.fullName} (${recordUnflatten.params.affiliateData.company})`;
    } else {
      val = recordUnflatten.params.influencer.fullName;
    }
  } else {
    val = '';
  }
  return (
    <span>{val}</span>
  );
};
InfluencerInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default InfluencerInList;

