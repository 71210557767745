import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
// import Icon from 'mdi-react/LinkOffIcon';
// import InfoIcon from 'mdi-react/InformationIcon';
// import Tooltip from '@material-ui/core/Tooltip';
import { unflatten } from 'flat';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Spinner,
  // Alert,
} from 'reactstrap';
import { axios } from 'ApiClient';
import CheckBoxField from '../../../../shared/components/CheckBox';
import Alert from '../../../../shared/easydev2/shared/components/Alert';

const DisConnectModal = (props) => {
  const {
    agent, meta, type, modal, toggle,
  } = props;
  const [showSuccessClearDialog, setShowSuccessClearDialog] = useState(false);
  const [showStripe, setShowStripe] = useState(false);
  const [showBillCom, setShowBillCom] = useState(false);
  const [loading, setLoading] = useState(false);
  const aUnfl = unflatten(agent);
  const clear = async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: 'post',
        url: '/api/portal/finance/post-clear-payment-data',
        data: {
          type,
          id: agent.id,
          isBillCom: showBillCom,
          isStripe: showStripe,
        },
      });
      if (response.data.success !== true) throw new Error(response.data.error);
      toggle(false);
      setShowSuccessClearDialog(true);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };
  const existsPayment = aUnfl?.params?.payment?.stripe?.accountId || aUnfl?.params?.payment?.billCom?.accountId;
  return (
    <span>
      <Modal isOpen={modal} toggle={() => toggle(false)} size="sm" className=" theme-light">
        <ModalHeader toggle={() => toggle(false)} >
          <h4>Disconnect Payment Methods</h4>
        </ModalHeader>
        <ModalBody>
          {loading ? (<div className="text-center"><Spinner color="primary" size="lg" /></div>) : null}
          <Row>
            { existsPayment ? (
              <Col xs="3" className="text-center mt-2" style={{ color: '#70bbfd' }}> <i className="fa fa-question" style={{ fontSize: '5em' }} /></Col>
            ) : null}
            <Col xs={existsPayment ? '9' : '12'}>
              {existsPayment ? (<h4 > Select the payment methods to disconnect</h4>) : null }
              <Row>
                {aUnfl?.params?.payment?.stripe?.accountId && !loading ? (
                  <Col xs="4">
                    <FormGroup className="text-center">
                      <CheckBoxField
                        className=" float-left mt-4"
                        name="stripe"
                        label=" "
                        value={showStripe}
                        onChange={({ target }) => target && (setShowStripe(!showStripe) || console.log(target.checked))}
                      />
                      <button className="MuiButtonBase-root material-table__toolbar-button MuiIconButton-sizeLarge " onClick={() => setShowStripe(!showStripe)} style={{ color: '#32325d' }}>
                        <FontAwesomeIcon icon={['fab', 'stripe']} style={{ fontSize: '5em' }} />
                      </button>
                    </FormGroup>
                  </Col>
                ) : null}
                {aUnfl?.params?.payment?.billCom?.accountId && !loading ? (
                  <Col xs="5">
                    <FormGroup className="text-center">
                      <CheckBoxField
                        className=" float-left mt-4"
                        name="billCom"
                        label=" "
                        value={showBillCom}
                        onChange={({ target }) => target && (setShowBillCom(!showBillCom) || console.log(target.checked))}
                      />
                      <button className="MuiButtonBase-root material-table__toolbar-button MuiIconButton-sizeLarge mt-2" onClick={() => setShowBillCom(!showBillCom)}>
                        <img src="/images/payments/billCom-logo.png" style={{ width: '140px' }} className="img-fluid" alt="Bill.com" />
                      </button>
                    </FormGroup>
                  </Col>
                ) : null}
                {!existsPayment && !loading ? (
                  <Col>
                    <Alert color="warning" className="w-100">
                      <p className="py-2 text-dark">
                        {aUnfl?.params?.first_name } {aUnfl?.params?.last_name } is not connected to any payment methods.
                      </p>
                    </Alert>
                  </Col>) : null}
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="modal__footer">
          {existsPayment ? (
            <Button
              className="btn-sm"
              color="primary"
              onClick={() => clear()}
              disabled={(!showBillCom && !showStripe) || loading}
            >
              Disconnect
            </Button>
          ) : null}
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => toggle(false)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showSuccessClearDialog} toggle={() => setShowSuccessClearDialog(false) || (meta.closeFunction ? meta.closeFunction() : null)} size="sm">
        <ModalHeader toggle={() => setShowSuccessClearDialog(false) || (meta.closeFunction ? meta.closeFunction() : null)}>
          <h4>Success</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="3 text-center">
              <i className="fa fa-info-circle" style={{ color: '#70bbfd', fontSize: '5em' }} />
            </Col>
            <Col><h4>Payment method(s) successfully disconnected.</h4></Col>
          </Row>
        </ModalBody>
        <ModalFooter className="modal__footer">
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => setShowSuccessClearDialog(false) || (meta.closeFunction ? meta.closeFunction() : null)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </span>
  );
};

DisConnectModal.propTypes = {
  agent: PropTypes.objectOf(PropTypes.any).isRequired,
  meta: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired,
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};
export default DisConnectModal;
