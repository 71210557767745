/* eslint react/prop-types: 0 */
import React from 'react';
import styled from 'styled-components';
import theme from '../../../../theme';
import Label from './label';

const Property = styled.div`
  margin: ${theme.sizes.paddingLayout} 0;
  & .icon {
    opacity: 0.25;
  }
`;

/**
 * Wrapps input with label in Filter
 *
 * @component
 * @example
 * const property = {
 *   label: 'My amazing property',
 *   name: 'myAmazingProperty',
 * }
 * return (
 *   <WrapperBox style={{ background: '#303b62' }}>
 *     <PropertyInFilter property={property}>
 *       <input className="input" />
 *     </PropertyInFilter>
 *   </WrapperBox>
 * )
 */
const PropertyInFilter = (props) => {
  const { property, children } = props;
  if (property.availableValues || property.type === 'reference') {
    return (
      <Property>
        <Label>{property.label}</Label>
        {children}
      </Property>
    );
  }

  return (
    <div className="form__form-group">
      <span className="form__form-group-label">{property.label}</span>
      <div className="form__form-group-field">
        {children}
      </div>
    </div>
  );
};

export { Label, Property };

export default PropertyInFilter;
