import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Icon from 'mdi-react/NoteOutlineIcon';
import Tooltip from '@material-ui/core/Tooltip';

const Button = (props) => {
  const { influencer } = props;
  return (
    <span>
      <Tooltip title="Creator Service Agreements">
        <Link to={`/service-agreements/${influencer.id}`}>
          <button className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall">
            <Icon style={{ width: '15px', height: '15px' }} />
          </button>
        </Link>
      </Tooltip>
    </span>
  );
};

Button.propTypes = {
  influencer: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Button;
