import React from 'react';
import PropTypes from 'prop-types';
import {
  useTable, useGlobalFilter, usePagination, useSortBy, useResizeColumns, useRowSelect,
} from 'react-table';
import ReactTableHeader from './ReactTableHeader';
import BodyReactTable from './ReactTableBody';
import ReactTableFooter from './ReactTableFooter';
import ReactTableFilter from './ReactTableFilter';
import ReactTablePagination from './ReactTablePagination';

const ReactTableConstructor = ({
  tableConfig, tableOptions, tableOptionalHook, notFoundProps,
}) => {
  const {
    isEditable,
    isResizable,
    isSortable,
    isFreezeHeader,
    useFlex,
    withPagination,
    withSearchEngine,
    manualPageSize,
    placeholder,
    searchFieldMinWidth,
    tableNotes,
  } = tableConfig;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    state,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    setGlobalFilter,
    withDragAndDrop,
    updateDraggableData,
    updateEditableData,
    dataLength,
    updateSelect,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    tableOptions,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useResizeColumns,
    useRowSelect,
    ...tableOptionalHook,
  );
  const tableRef = React.useRef();
  React.useEffect(() => updateSelect(selectedRowIds, rows), [selectedRowIds]);

  
  React.useEffect(() => {
    // console.log([pageIndex, pageCount], 'CHANGED COUNT');
    if (pageIndex !== 0 && (pageIndex + 1) > pageCount) gotoPage(0);
  }, [tableOptions.data]);
  let className = ['react-table'];
  if (isEditable) className.push('editable-table');
  if (!isEditable && isResizable) className.push('resizable-table');
  if (isFreezeHeader) className.push('position-relative');
  return (
    <div style={{ width: '100%' }}>
      {withSearchEngine && (
        <ReactTableFilter
          rows={rows}
          setGlobalFilter={setGlobalFilter}
          setFilterValue={tableOptions.setFilterValue}
          globalFilter={state.globalFilter}
          placeholder={placeholder}
          dataLength={dataLength}
          gotoPage={gotoPage}
          searchFieldMinWidth={searchFieldMinWidth}
        />
      )}
      {tableNotes}
      <div className={withPagination ? 'table react-table' : 'table react-table table--not-pagination'}>
        <table
          {...getTableProps()}
          className={className.join(' ')}
          ref={tableRef}
        >
          <ReactTableHeader
            headerGroups={headerGroups}
            isSortable={isSortable}
            isResizable={isResizable}
            isFreeze={isFreezeHeader}
            tableRef={tableRef}
          />
          <BodyReactTable
            page={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            updateDraggableData={updateDraggableData}
            updateEditableData={updateEditableData}
            isEditable={isEditable}
            withDragAndDrop={withDragAndDrop}
            isResizable={isResizable}
            useFlex={useFlex}
            notFoundProps={{
              columnLength: headerGroups[0].headers.length,
              title: notFoundProps.title || 'No records found',
              styles: notFoundProps.styles || {},
            }}
          />
          {(pageCount === (pageIndex + 1) || (!withPagination && rows.length !== 0)) && (
            <ReactTableFooter
              footerGroups={footerGroups}
            />
          )}
        </table>
      </div>
      {(withPagination && rows.length > 0) && (
        <ReactTablePagination
          page={page}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageSize={pageSize}
          pageIndex={pageIndex}
          pageCount={pageCount}
          setPageSize={setPageSize}
          manualPageSize={manualPageSize}
          dataLength={rows.length !== dataLength ? rows.length : dataLength}
        />
      )}
    </div>
  );
};

ReactTableConstructor.propTypes = {
  tableConfig: PropTypes.shape({
    isEditable: PropTypes.bool,
    isResizable: PropTypes.bool,
    useFlex: PropTypes.bool,
    isSortable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    withPagination: PropTypes.bool,
    withSearchEngine: PropTypes.bool,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
    placeholder: PropTypes.string,
    searchFieldMinWidth: PropTypes.string,
    tableNotes: PropTypes.func,
  }),
  tableOptions: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    data: PropTypes.arrayOf(PropTypes.shape()),
    setFilterValue: PropTypes.func,
    updateDraggableData: PropTypes.func,
    updateEditableData: PropTypes.func,
    defaultColumn: PropTypes.oneOfType([
      PropTypes.any,
      PropTypes.shape({
        Cell: PropTypes.func,
      }).isRequired,
    ]),
    isEditable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    dataLength: PropTypes.number,
    updateSelect: PropTypes.func,
  }),
  tableOptionalHook: PropTypes.arrayOf(PropTypes.func).isRequired,
  notFoundProps: PropTypes.objectOf(PropTypes.any),
};

ReactTableConstructor.defaultProps = {
  tableConfig: {
    isEditable: false,
    isResizable: false,
    isFreezeHeader: false,
    useFlex: false,
    isSortable: false,
    withDragAndDrop: false,
    withPagination: false,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    searchFieldMinWidth: '20%',
    tableNotes: null,
  },
  tableOptions: [{
    columns: [],
    data: [],
    setFilterValue: () => {},
    updateDraggableData: () => {},
    updateEditableData: () => {},
    handleEvent: () => {},
    defaultColumn: [],
    withDragAndDrop: false,
    dataLength: null,
    disableSortBy: false,
    manualSortBy: false,
    manualGlobalFilter: false,
    manualPagination: false,
  }],
  notFoundProps: {},
};

export default ReactTableConstructor;
