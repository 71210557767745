import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import {
  ButtonDropdown, DropdownToggle, DropdownMenu,
  Button,
} from 'reactstrap';

const useStyles = makeStyles({
  formFields: {
    padding: '5px 20px',
    margin: 0,
  },
});

const buttonColors = {
  primary: 'primary',
  secondary: 'secondary',
};

const CheckboxFilter = ({
  filterProp,
  filterTitle,
  filterAction,
  style,
  classname,
}) => {
  const [dropdownOpen, setOpen] = useState(false);
  const [buttonColor, setButtonColor] = useState(buttonColors.primary);
  const [filterState, setFilterState] = useState(filterProp);
  const classes = useStyles();

  useEffect(() => {
    setFilterState(filterProp);
  }, [filterProp]);

  useEffect(() => {
    if (Object.values(filterState).some(filterValue => filterValue === false)) {
      setButtonColor(buttonColors.secondary);
    }
  }, [filterState]);

  const toggle = () => setOpen(!dropdownOpen);

  const handleChange = (event) => {
    setFilterState({ ...filterState, [event.target.name]: event.target.checked });
  };

  const applyFilters = (event) => {
    event.preventDefault();
    filterAction({ data: filterState });
    setOpen(false);
    if (Object.values(filterState).some(v => !v)) {
      setButtonColor(buttonColors.secondary);
    } else {
      setButtonColor(buttonColors.primary);
    }
  };

  return (
    <ButtonDropdown className={classname || 'm-1 btn-sm'} isOpen={dropdownOpen} toggle={toggle} style={style} disabled={Object.keys(filterState).length === 0}>

      <DropdownToggle className="btn-sm" color={buttonColor} caret>
        {filterTitle}
      </DropdownToggle>

      <DropdownMenu className="w-100">

        <div className={classes.formFields}>
          <FormControl component="fieldset">
            <FormGroup>
              { filterState
                      && Object.keys(filterState).map(key => (
                        <FormControlLabel
                          key={key}
                          control={(
                            <Checkbox
                              checked={filterState[key]}
                              onChange={handleChange}
                              name={key}
                              color="primary"
                            />
                          )}
                          label={key}
                        />
                      ))
              }

            </FormGroup>
          </FormControl>
          <FormControl style={{ display: 'block', textAlign: 'center' }} >
            <Button
              className="btn-sm mb-2"
              color="primary"
              onClick={applyFilters}
            >
              Apply
            </Button>
          </FormControl>
        </div>

      </DropdownMenu>
    </ButtonDropdown>
  );
};

CheckboxFilter.propTypes = {
  filterProp: PropTypes.objectOf(PropTypes.bool).isRequired,
  filterTitle: PropTypes.string.isRequired,
  filterAction: PropTypes.func.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  classname: PropTypes.string,
};

CheckboxFilter.defaultProps = {
  style: {},
  classname: '',
};

export default CheckboxFilter;
