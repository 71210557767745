import { unflatten } from 'flat';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ApiClient from 'ApiClient';
import { getConstant } from '../../../../../../shared/helpers/WVConstants';

const PaymentsStatus = ({ record }) => {
  const key = 'paymentStatus';

  const aValues = getConstant('invoice', 'paymentsStatus', {});

  const recordUnflatten = unflatten(record);
  const rowData = recordUnflatten.params;
  const val = (typeof rowData !== 'undefined' && typeof rowData[key] !== 'undefined') ? rowData[key] : 'unpaid';
  const [value, setValue] = useState(val);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  const options = Object.keys(aValues).map(k => ({ value: k, label: aValues[k] }));

  const handleChange = async (option) => {
    setDisabled(true);
    setError(null);
    setValue(option.value);
    const data = new FormData();
    data.append(key, option.value);
    const api = new ApiClient();
    try {
      await api.recordAction({
        resourceId: 'Invoice',
        recordId: record.id,
        actionName: 'edit',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (e) {
      setError(e.message);
      setDisabled(false);
    }
    setDisabled(false);
    return true;
  };
  return (
    <div style={{ minWidth: '120px', maxWidth: '150px' }}>
      <Select
        value={options.filter(option => option.value === value)}
        options={options}
        onChange={handleChange}
        placeholder="Payment Status"
        isDisabled={disabled}
        menuPlacement="auto"
        menuPortalTarget={document.body}
      />
      {error ? (<span className="text-danger">{error}</span>) : null}
    </div>
  );
};
PaymentsStatus.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default PaymentsStatus;
