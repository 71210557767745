/* eslint react/prop-types: 0 */
import React from 'react';
import { Alert } from 'reactstrap';
import WrapperBox from './wrapper-box';


/**
 * @class
 * Prints error message
 *
 * @component
 * @example
 * return (
 * <ErrorMessageBox title={'Some error'}>
 *   <p>Text below the title</p>
 * </ErrorMessageBox>
 * )
 */
const ErrorMessageBox = (props) => {
  const { children, title } = props;
  return (
    <WrapperBox>
      <WrapperBox border>
        <div className="content has-text-centered">
          <h3>{title}</h3>
          <div>
            {children}
          </div>
        </div>
      </WrapperBox>
    </WrapperBox>
  );
};
const ErrorMessageBoxAlert = (props) => {
  const { children, title } = props;
  return (
    <WrapperBox>
      <WrapperBox border>
        <div className="content has-text-centered">
          <h3>{title}</h3>
          <Alert color="warning" className="mt-3">
            <div className="py-2 text-dark">
              {children}
            </div>
          </Alert>
        </div>
      </WrapperBox>
    </WrapperBox>
  );
};

const Error404 = (props) => {
  const { children } = props;
  return (
    <WrapperBox>
      <WrapperBox border>
        <div className="content has-text-centered">
          <h3>404 - PAGE NOT FOUND</h3>
          <div>
            {children}
          </div>
        </div>
      </WrapperBox>
    </WrapperBox>
  );
};

const NoResourceError = (props) => {
  const { resourceId } = props;
  return (
    <Error404>
      Resource of given id:
      <b>{` ${resourceId} `}</b>
      cannot be found.
    </Error404>
  );
};

const NoActionError = (props) => {
  const { resourceId, actionName } = props;
  return (
    <Error404>
      Resource:
      <b>{` ${resourceId} `}</b>
      does not have an action with name:
      <b>{` ${actionName} `}</b>
    </Error404>
  );
};

const NoRecordError = (props) => {
  const { resourceId, recordId } = props;
  return (
    <Error404>
      Resource:
      <b>{` ${resourceId} `}</b>
      does not have a record with id:
      <b>{` ${recordId} `}</b>
    </Error404>
  );
};

export {
  Error404 as default,
  NoResourceError,
  NoActionError,
  NoRecordError,
  ErrorMessageBox,
  ErrorMessageBoxAlert,
};
