/* eslint react/prop-types: 0 */
import React from 'react';

export default (props) => {
  const { record } = props;
  const link = `/resources/MediaChannel/actions/list?filters.campaigns=${record.id}`;
  return (
    <a href={link} target="_blank" rel="noreferrer noopener">{record.title}</a>
  );
};

