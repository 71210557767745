import UploadOwner from './UploadOwner';
import UploadDatetime from './UploadDatetime';
import CellNumber from '../../../../../../shared/components/table/CellNumber';

export default {
  list: {
    uploadOwner: UploadOwner,
    uploadDatetime: UploadDatetime,
    totalRows: CellNumber,
    totalSuccess: CellNumber,
    totalErrors: CellNumber,
  },
};
