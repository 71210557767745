const moment = require('moment');
const momentTimezone = require('moment-timezone');
const { momentUTCToLocalDate } = require('./Dates');
const WVValidator = require('./WVValidator');

exports.formatString = (value) => {
  let res = value;
  if (!value || (value.toLowerCase() === 'null')) {
    res = '';
  }
  return res;
};

exports.formatCurrency = (amount, currency = 'usd') => {
  if (!WVValidator.isNumber(amount)) { return ''; }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  });

  return formatter.format(amount);
};

exports.formatNumber = (amount, minimumFractionDigits = 2) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits,
  });

  return formatter.format(amount);
};
exports.formatIntNumber = (amount, maximumFractionDigits = 2) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits,
  });

  return formatter.format(amount);
};

exports.formatIntNumberAndAddPercentSign = (amount) => {
  const formatterPercent = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return formatterPercent.format(amount);
};

exports.formatIntNumberWithPercent = (amount, total) => {
  const formatterNumber = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
  });
  const formatterPercent = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
  });
  let percent = 0;
  if (total > 0) {
    percent = amount / total;
  }

  return `${formatterNumber.format(amount)} (${formatterPercent.format(percent)})`;
};

exports.roundToInt = (value) => {
  const decimal = value % 1; // Get the decimal part of the percentage
  if (decimal >= 0.5) {
    return Math.ceil(value); // Round up
  }
  return Math.floor(value); // Round down
};

exports.formatDateForCreatorsPaymentsTables = (date, format = 'M/D/YYYY h:mm A') => {
  if (date) {
    const utc = moment.utc(new Date(date));
    const local = moment(utc).local();
    return local.format(format);
  }
  return '-';
};

exports.fillUrl = value => (!value || /^https?:/.test(value) ? value : `https://${value}`);
const formatToEST = momentO => (momentO.tz ? momentO.tz('America/New_York') : momentO.utcOffset('-04:00'));
exports.formatToEST = formatToEST;
exports.getServerDate = date => momentUTCToLocalDate(moment(date)).format('MM/DD/YY');
exports.getServerDateTime = (datetime, format) => (datetime ? moment(datetime).format(format || 'M/D/YY h:mma') : '');
exports.formatToMT = (date, format) =>
  (momentTimezone.tz(moment(date).format('YYYY-MM-DD HH:mm:ss'), 'America/Denver').isDST()
    ? moment(date).utcOffset('-06:00').format(format || 'M/D/YY h:mma')
    : moment(date).utcOffset('-07:00').format(format || 'M/D/YY h:mma'));
exports.getFormatedDate = date => moment(date).format('M/DD/YYYY');
exports.getFormattedDateNoZeroes = date => moment(date).format('M/D/YYYY');
exports.formatDate = date => (date ? formatToEST(moment(date))?.format('M/D/YY') : '');
exports.capitalize = n => `${n.charAt(0).toUpperCase()}${n.slice(1)}`;
exports.formatCheckedOption = value => (value ? 'Yes' : 'No');
exports.s = k => (k !== 1 ? 's' : '');
