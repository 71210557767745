import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonToolbar,
  Modal,
} from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from 'mdi-react/InformationIcon';
import Tooltip from '@material-ui/core/Tooltip';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';


class ImpactClickShowAdditionalInfoButton extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    record: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  render() {
    const {
      modal,
    } = this.state;

    // const { params: { impactAction } } = unflatten(this.props.record);

    let impactClick = {};
    if (this.props.record.params) {
      impactClick = this.props.record.params;
    }

    return (
      <div style={{ display: 'inline' }}>
        <Tooltip title="Show Additional Information">
          <IconButton
            size="small"
            onClick={this.toggle}
            className="material-table__toolbar-button"
          >
            <InfoIcon size="15" />
          </IconButton>
        </Tooltip>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          modalClassName="ltr-support"
          className="modal-dialog"
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
          </div>
          <div className="modal__body">
            <Row>
              <Col md={5}>
                <span className="bold-text">Click ID</span>
              </Col>
              <Col md={7}>
                <div style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>
                  <span>{impactClick.clickId || 'n/a'}</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <span className="bold-text">Sub ID1</span>
              </Col>
              <Col md={7}>
                <span>{impactClick.subId1 || 'n/a'}</span>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <span className="bold-text">Sub ID2</span>
              </Col>
              <Col md={7}>
                <span>{impactClick.subId2 || 'n/a'}</span>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <span className="bold-text">Sub ID3</span>
              </Col>
              <Col md={7}>
                <span>{impactClick.subId3 || 'n/a'}</span>
              </Col>
            </Row>
          </div>
          <ButtonToolbar className="modal__footer">
            <Button className="modal_ok btn-sm" color="primary" onClick={this.toggle}>
              <span>Close</span>
            </Button>
          </ButtonToolbar>
        </Modal>
      </div>
    );
  }
}

export default ImpactClickShowAdditionalInfoButton;
