/* eslint-disable max-len */
import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { getContentApprovalsFilter } from '../../../../../shared/helpers/Filter';

class SidebarContent extends Component {
  static propTypes = {
    // changeToDark: PropTypes.func.isRequired,
    // changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    prospectsLink: PropTypes.string.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    // const { changeToLight, changeToDark } = this.props;
    const startDate = moment().startOf('month').toISOString();
    const endDate = moment().endOf('day').toISOString();
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          {/* <SidebarLink title="Home" icon="home" route="/" onClick={this.hideSidebar} /> */}
          <SidebarLink title="Overview" icon="map" route="/" />
          <SidebarLink title="Creators" icon="users" route="/creators/list" />
          <SidebarLink title="Prospects" icon="eye" route={this.props.prospectsLink} />
          <SidebarCategory title="Content" icon="file-empty">
            <SidebarLink title="Published" route="/content" />
            <SidebarLink
              title="Approvals"
              route={`/content-approvals?${getContentApprovalsFilter('7_days')}`}
            />
          </SidebarCategory>
          <SidebarCategory title="Youtube Comments" icon="bubble">
            <SidebarLink
              title="Comments"
              route={`/comments?page=1&range=current_month&filters.publishedAt~~from=${startDate}&filters.publishedAt~~to=${endDate}&publishedDateType=%3D&filters.sentiment=positive&filters.sentiment=negative`}
            />
            <SidebarLink title="Analysis" route="/youtube-comments-analysis" />
          </SidebarCategory>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  prospectsLink: state.creators.prospectLink,
});

export default connect(mapStateToProps)(SidebarContent);
