import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

const ReceivablesDashboard = (props) => {
  const { record } = props;
  const link = `/finance/account-receivable-report/${record.id}`;
  return (
    <Link to={link}>
      <Button
        color="primary"
        outline
        size="sm"
        style={{ margin: '0' }}
      >
        Accounts Receivable Dashboard
      </Button>
    </Link>
  );
};
ReceivablesDashboard.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default ReceivablesDashboard;
