/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import { unflatten } from 'flat';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
// import _ from 'lodash';
import StatusHistoryModal from './StatusHistoryModal';


const Connected = (props) => {
  const record = unflatten(props.record);
  const [modal, setModal] = useState(false);
  const isCompliance = record.params.hsContactObject?.ftc_compliance_status === 'Compliant';
  // let prospect = record.params?.prospects[0] || null;
  const targetAdvertiser = window.REDUX_STATE.session.advertiser;
  const prospect = record.params?.prospects?.find(pr => pr?.approvalStatus?.brand?.targetAdvertiserId === targetAdvertiser);
  return (
    <div
      style={{ fontSize: '25px' }}
      className={`pl-4 ${isCompliance ? 'text-warning' : ''}`}
    >
      {prospect?._id ? (
        <React.Fragment>
          <IconButton
            onClick={() => setModal(true)}
            className={`${isCompliance ? 'text-warning' : ''}`}
          >
            <i className="fa fa-shield-alt" />
          </IconButton>
          <StatusHistoryModal
            modal={modal}
            setModalVisible={() => setModal(false)}
            record={{ params: prospect, props }}
            brandUsers={props.brandUsers}
          />
        </React.Fragment>) : (
          <div style={{ marginLeft: '12px' }}><i className="fa fa-shield-alt" /></div>
        )}
    </div>
  );
};

const mapStateToProps = state => ({
  brandUsers: state.creators.brandUsers,
});

export default connect(mapStateToProps)(Connected);
