const initializeDB = async (dbName, storeName) => new Promise(async (resolve, reject) => {
  try {
    console.log('OPEN CONNECTION');
    const request = indexedDB.open(dbName, 1);
    request.onerror = (event) => {
      reject(event);
    };
    request.onupgradeneeded = (event) => {
      console.log('OPEN CONNECTION and STORE NAME');
      const db = event.target.result;
      db.createObjectStore(storeName, { keyPath: 'id' });
      // objectStore.createIndex("name", "name", { unique: false });
    };
    request.onsuccess = (event) => {
      const db = event.target.result;
      resolve(db);
    };
  } catch (error) {
    reject(error);
  }
});

// Add a Document as an async function
const addDocument = async (db, storeName, document) => new Promise(async (resolve, reject) => {
  try {
    const transaction = db.transaction(storeName, 'readwrite');
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.put(document);
    request.onsuccess = (event) => {
      resolve(event);
    };
    request.onerror = (event) => {
      reject(event);
    };
  } catch (error) {
    reject(error);
  }
});

// Update a Document as an async function
const updateDocument = async (db, storeName, document) => new Promise(async (resolve, reject) => {
  try {
    const transaction = db.transaction(storeName, 'readwrite');
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.put(document);
    request.onsuccess = (event) => {
      resolve(event);
    };
    request.onerror = (event) => {
      reject(event);
    };
  } catch (error) {
    reject(error);
  }
});

// Remove a Document as an async function
const removeDocument = async (db, storeName, documentId) => new Promise(async (resolve, reject) => {
  try {
    const transaction = db.transaction(storeName, 'readwrite');
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.delete(documentId);
    request.onsuccess = (event) => {
      resolve(event);
    };
    request.onerror = (event) => {
      reject(event);
    };
  } catch (event) {
    reject(event);
  }
});
const getAllDocuments = async (db, storeName) => new Promise(async (resolve, reject) => {
  try {
    const transaction = db.transaction(storeName, 'readonly');
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.getAll();
    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
    request.onerror = (event) => {
      reject(event);
    };
  } catch (error) {
    reject(error);
  }
});
const addDocuments = async (db, storeName, documents) => new Promise(async (resolve, reject) => {
  try {
    const transaction = db.transaction(storeName, 'readwrite');
    const objectStore = transaction.objectStore(storeName);
    for (let i = 0; i < documents.length; i += 1) {
      console.log(documents[i], 'save');
      const request = objectStore.add(documents[i]);
      request.onerror = (event) => {
        reject(event);
      };
    }
    transaction.oncomplete = () => {
      resolve('Documents pasted successfully');
    };
  } catch (error) {
    reject(error);
  }
});

// Clear All Documents as an async function
const clearAllDocuments = async (db, storeName) => new Promise(async (resolve, reject) => {
  try {
    const transaction = db.transaction(storeName, 'readwrite');
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.clear();
    request.onsuccess = (event) => {
      resolve(event);
    };
    request.onerror = (event) => {
      reject(event);
    };
  } catch (event) {
    reject(event);
  }
});
const getDocumentById = (db, storeName, documentId) => new Promise(async (resolve, reject) => {
  try {
    const transaction = db.transaction(storeName, 'readonly');
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.get(documentId);
    request.onsuccess = (event) => {
      const document = event.target.result;
      if (document) {
        resolve(document);
      } else {
        resolve(null);
      }
    };
    request.onerror = (event) => {
      reject(event);
    };
  } catch (event) {
    reject(event);
  }
});

const getRecordsByArrayIds = async (db, storeName, idArray) => new Promise(async (resolve, reject) => {
  try {
    const promises = idArray.map(id => getDocumentById(db, storeName, id));

    resolve((await Promise.all(promises)).filter(a => a));
  } catch (error) {
    reject(error);
  }
});

/* exports.initializeDB = initializeDB;
exports.addDocument = addDocument;
exports.updateDocument = updateDocument;
exports.removeDocument = removeDocument;
exports.getAllDocuments = getAllDocuments; */
export {
  initializeDB,
  addDocument,
  updateDocument,
  removeDocument,
  getAllDocuments,
};
export default async (dbName) => {
  if (!dbName) throw new Error('DataBase Name is required');
  const storeName = 'records';
  let db = window.dbRecords ? (window.dbRecords[dbName] || null) : null;
  console.log(db, 'DB');
  if (!db) {
    console.log('storeNamesFromConnectedStart');
    db = await initializeDB(dbName, 'records');
    window.dbRecords = { [dbName]: db };
  }
  console.log(db, 'db1');
  return {
    addDocuments: async documents => addDocuments(db, storeName, documents),
    getAllDocuments: async () => getAllDocuments(db, storeName),
    clearAllDocuments: async () => clearAllDocuments(db, storeName),
    getDocumentById: async id => getDocumentById(db, storeName, id),
    updateDocument: async doc => updateDocument(db, storeName, doc),
    getAllDocumentsByIds: async ids => getRecordsByArrayIds(db, storeName, ids),
  };
};

