/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react';
import { FormGroup } from 'reactstrap';
import SelectAsync from 'react-select/async';
import { components } from 'react-select';
import { axios } from 'ApiClient';
import ApiClient from 'ApiClient';
import { unflatten } from 'flat';

const loadContactsOptions = async (param, value) => {
  const response = await axios.get('/api/portal/hubil/get-contact-search', {
    params: {
      param,
      value,
    },
  });
  return response.data.records.map(record => ({
    value: record.params.hs_object_id,
    label: record.params.email,
    record: record.params,
  }));
};

const option = (props) => {
  const { record, label } = props.data;
  console.log(record);
  return (
    <components.Option {...props} >
      <div>
        <div>{record?.firstname} {record?.lastname}</div>
        <div style={{ color: '#888', fontSize: '13px' }}>
          {label}
        </div>
      </div>
    </components.Option>
  );
};

export default (props) => {
  const { record } = props;
  const [params, setParams] = useState(unflatten(record.params));
  const [selectedContact, setContact] = useState({});
  const [disabled, setDisabled] = useState(false);

  const handleChangeInfluencer = async (selectedItem) => {
    setDisabled(true);
    const data = new FormData();
    data.append('hsContactVid', selectedItem.value);
    const api = new ApiClient();
    api.recordAction({
      resourceId: 'Influencer',
      recordId: record.id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(() => {
      setContact(selectedItem);
      setDisabled(false);
    });
    return true;
  };

  useEffect(() => {
    setParams(unflatten(record.params));
  }, [record]);

  useEffect(() => {
    if (params.hsContactVid) {
      setContact({
        value: params.hs_object_id,
        label: params.hsEmail,
        record: params,
      });
    } else setContact({});
  }, [params]);

  return (
    <div
      className="d-flex"
      style={{
        minWidth: '190px',
        maxWidth: '190px',
        marginBottom: 0,
      }}
    >
      <div className="pr-0">
        <FormGroup style={{ minWidth: '160px', maxWidth: '160px', marginBottom: 0 }}>
          <SelectAsync
            cacheOptions
            value={selectedContact}
            defaultOptions={Object.keys(selectedContact).length > 0 ? [selectedContact] : []}
            loadOptions={async value => loadContactsOptions('email', value)}
            onChange={handleChangeInfluencer}
            placeholder="Please Select Contact"
            isDisabled={!!disabled}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            components={{ Option: option }}
          />
        </FormGroup>
      </div>
      <div className="pl-2" style={{ paddingTop: '8px' }}>
        {selectedContact?.value ? (
          <a
            href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${selectedContact.value}/`}
            target="_blank"
            title="Open Hubspot Contact"
            rel="noreferrer noopener"
          >
            <i className="fab fa-hubspot" style={{ fontSize: '1.5em' }} />
          </a>
        ) : null}
      </div>
    </div>
  );
};
