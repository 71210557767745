
import { useSelector } from 'react-redux';

const useAccessHook = ({
  showWhenTeam, hideWhenRole, showWhenRole, or, and, hideWhenTeam,
}) => {
  const session = useSelector(state => state.session);
  // const session = false;
  if (!session) return [false, [], []];
  const { teams } = session;
  const { role } = session;

  const checkTeam = () => (!(showWhenTeam?.length > 0) || (showWhenTeam?.length > 0 && showWhenTeam.some(r => teams?.includes(r))))
    && (!(hideWhenTeam?.length > 0) || (hideWhenTeam?.length > 0 && !hideWhenTeam.some(r => teams?.includes(r))));
  const checkRole = () => (!(showWhenRole?.length > 0) || (showWhenRole?.length > 0 && showWhenRole.includes(role)))
    && (!(hideWhenRole?.length > 0) || (hideWhenRole?.length > 0 && !hideWhenRole.includes(role)));
  let allowAccess = false;
  if (and || (!and && !or)) {
    if (checkTeam() && checkRole()) {
      allowAccess = true;
    } else allowAccess = false;
  }
  if (or) {
    if (checkTeam() || checkRole()) {
      allowAccess = true;
    } else allowAccess = false;
  }
  return [allowAccess, teams, role];
};

export default useAccessHook;
