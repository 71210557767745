import PropTypes from 'prop-types';
import React, { useState } from 'react';
import MoreVertIcon from 'mdi-react/MoreVertIcon';
import IconButton from '@material-ui/core/IconButton';
import { Menu, MenuItem } from '@material-ui/core';

const TableCellContextMenu = ({ options }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        // disableScrollLock
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            color: '#646777',
          },
        }}
      >
        {options.map((option) => {
          if (option.type === 'divider') {
            return (
              <div
                key={option.label}
                tabIndex="-1"
                className="dropdown-divider"
              />);
          }
          return (
            <MenuItem
              key={option.label}
              onClick={() => { option.handler(); handleClose(); }}
              style={{ fontSize: '14px' }}
              disabled={option.disabled}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
TableCellContextMenu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

export default TableCellContextMenu;
