import Record from '../components/components/Record';

export default class Invoice extends Record {
  async updateItemF() {
    const params = this.getSchemaParams();
    const res = await this.api.client.post('/api/portal/influencer/post-edit-invoice', {
      ...params,
      _id: this.id,
    });
    if (res.data.success === true) {
      return this.getModel();
    }
    throw new Error('Undable to update Invoice record');
  }
  getTotalPaid() {
    let payoutAmount = 0;
    if (!this.params.payments) {
      return 0;
    }
    this.params.payments.forEach((payout) => {
      payoutAmount += Number(payout.amount);
      return true;
    });
    return payoutAmount;
  }
}
