/* eslint-disable filenames/match-exported */
import ApiClient from 'ApiClient';
import recordToFormData from '../components/components/actions/record-to-form-data';
// import { unflatten } from 'flat';

export const updateParams = (data, paramsToUpdate) => {
  let res;
  if (data instanceof Array) {
    res = [...data];
  } else {
    res = data ? { ...data } : {};
  }
  const setValue = (v, d) => ((v && typeof v === 'object' && !(v instanceof Array)) ? updateParams(d, v) : v);
  Object.entries(paramsToUpdate).forEach(([key, value]) => {
    if (key === '$arr') {
      if (!(res instanceof Array)) res = [];
      value.forEach((a) => {
        res[a[0]] = setValue(a[1], data?.[a[0]]);
      });
    } else {
      res[key] = setValue(value, data?.[key]);
    }
  });
  return res;
};

const filtersToParams = (filters, query) => {
  const search = new URLSearchParams(query);
  Object.keys(filters).forEach((key) => {
    const val = filters[key];
    if (val) {
      search.append(`filters.${key}`, val);
    }
  });
  return search;
};

const fetchResourceData = async (resourceId, filters, query = '') => {
  const api = new ApiClient();
  const res = await api.resourceAction({
    actionName: 'list',
    resourceId,
    params: filtersToParams(filters, query),
  });
  return res.data;
};

export const fetchDictionary = async (key) => {
  const data = await fetchResourceData('Dictionary', { key });
  const { records } = data;
  return {
    success: true,
    records: records.reduce((a, v) => ({ ...a, [v.params.key]: v.params.value }), {}),
  };
};

export const updateResourceData = async (resourceId, recordId, dataToUpdate) => {
  const data = new FormData();
  Object.keys(dataToUpdate).forEach((key) => {
    data.append(key, dataToUpdate[key]);
  });
  const api = new ApiClient();
  const res = await api.recordAction({
    actionName: 'edit',
    resourceId,
    recordId,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res;
};

export const fetchRecord = async (resourceId, recordId) => {
  if (!recordId) {
    return {};
  }
  const api = new ApiClient();
  const res = await api.recordAction({
    actionName: 'show',
    resourceId,
    recordId,
  });
  return res;
};

export const saveRecord = async (resourceId, data, recordId) => {
  if (!data?.params) {
    return { data: { notice: { message: 'No data to save' } } };
  }
  const id = recordId || data.params?._id;
  const formData = recordToFormData(data);
  const api = new ApiClient();
  const params = {
    resourceId,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  let res;
  if (id) {
    params['actionName'] = 'edit';
    params['recordId'] = id;
    res = await api.recordAction(params);
  } else {
    params['actionName'] = 'new';
    res = await api.resourceAction(params);
  }
  return res;
};

export async function fetchStreamAsObject(fetch, data) {
  const response = await fetch;
  console.log(response, 'response');
  const reader = response.body.getReader();
  const decoder = new TextDecoder('utf-8');
  let partial = '';
  const result = {};

  while (true) {
    console.log('1');
    const { done, value } = await reader.read();
    if (done) {
      break;
    }
    partial += decoder.decode(value, { stream: true });

    let boundary = partial.indexOf('\n');
    while (boundary !== -1) {
      const chunk = partial.slice(0, boundary);
      partial = partial.slice(boundary + 1);
      console.log(boundary, 'boundary');
      // if (!chunk) continue;
      try {
        const json = JSON.parse(chunk);
        for (const key in json) {
          if (json.hasOwnProperty(key)) {
            if (Array.isArray(json[key])) {
              result[key] = json[key] || [];
            } else {
              result[key] = json[key];
            }
          }
        }
      } catch (e) {
        console.error('Error parsing JSON:', e);
      }

      boundary = partial.indexOf('\n');
      console.log(boundary, 'boundary');
    }
  }
  // console.log(result, 'result', unflatten(result));
  // const res = unflatten(result);
  const res = {};
  console.log(result, 'res');
  Object.keys(result).forEach((key) => {
    const keyToSplit = key.includes('.');
    if (keyToSplit) {
      const keyToShow = key.split('.')[0];
      if (!res[keyToShow]) res[keyToShow] = [];
      res[keyToShow].push(result[key]);
    } else {
      res[key] = result[key];
    }
    /* if(!Array.isArray(res[key]) || res[key].length === 0) {
      res[key] = result[key]
    }; */
  });
  return (res);
}


export default fetchResourceData;
