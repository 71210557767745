/* eslint react/prop-types: 0 */
import React, { useState, useImperativeHandle } from 'react';
import { unflatten } from 'flat';
import {
  Modal,
  ModalHeader,
  ListGroup,
  ListGroupItem,
  ModalFooter,
  Button,
} from 'reactstrap';
// import { IconButton } from '@material-ui/core';
// import Tooltip from '@material-ui/core/Tooltip';
// import { checkIfImageExists } from '../../../../../shared/helpers/WVUtilities';

export default React.forwardRef((props, ref) => {
  const record = unflatten(props.record);
  const [showModal, setShowModal] = useState(false);

  const searchTerms = [...props.searchTerms];
  const youtube = record.params.tubularDetails?.accounts?.find(t => t.platform === 'youtube');
  const instagram = record.params.tubularDetails?.accounts?.find(t => t.platform === 'instagram');
  const facebook = record.params.tubularDetails?.accounts?.find(t => t.platform === 'facebook');
  const twitter = record.params.tubularDetails?.accounts?.find(t => t.platform === 'twitter');
  if (youtube) searchTerms.push('YouTube Channel');
  if (instagram) searchTerms.push('Instagram Channel');
  if (facebook) searchTerms.push('Facebook');
  if (twitter) searchTerms.push('Twitter');

  useImperativeHandle(ref, () => ({
    openNewTabs: () => {
      const wTabs = { ...(window?.customTabs || {}) };
      let isBlocked = false;
      searchTerms.forEach((term) => {
        if (!wTabs[term] || wTabs[term].closed) {
          const tryOpenWindow = window.open('', '_blank');
          if (!tryOpenWindow) {
            isBlocked = true;
            return false;
          }
          tryOpenWindow.blur();
          wTabs[term] = tryOpenWindow;
        }
        return true;
      });
      if (isBlocked) {
        // console.log(wTabs);
        Object.values(wTabs).forEach(t => t && t.close());
        setShowModal(true);
      } else {
        // console.log(wTabs);
        searchTerms.forEach((term) => {
          if (['YouTube Channel', 'Instagram Channel', 'Facebook', 'Twitter'].includes(term)) {
            // wTabs[term].location.href = term === 'YouTube Channel' ? `${youtube.url}/videos` : instagram.url;
            switch (term) {
              case 'YouTube Channel':
                wTabs[term].location.href = `${youtube.url}/videos`;
                break;
              case 'Instagram Channel':
                wTabs[term].location.href = instagram.url;
                break;
              case 'Facebook':
                wTabs[term].location.href = facebook.url;
                break;
              case 'Twitter':
                wTabs[term].location.href = twitter.url;
                break;
              default:
                break;
            }
          } else {
            // console.log(term);
            const query = new URLSearchParams();
            const title = (record.params.firstname || record.params.lastname ? `${record.params.firstname} ${record.params.lastname}` : (record.params.title || ''));
            query.set('q', `${title} ${term}`);
            wTabs[term].location.href = `https://www.google.com/search?${query.toString()}`;
          }
        });
        window.customTabs = wTabs;
      }
    },
  }));
  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(false)}
      >
        <ModalHeader toggle={() => setShowModal(false)} tag="h4" className="pl-0">Safety Check</ModalHeader>
        <div>
          <div className="pt-3">Disable your Pop-Up Blocker for this site if you wish to automatically open search results in new tabs.</div>
          <ListGroup className="mt-3">
            {
              searchTerms.map((term) => {
                const query = new URLSearchParams();
                const title = (record.params.firstname || record.params.lastname ? `${record.params.firstname} ${record.params.lastname}` : (record.params.title || ''));
                query.set('q', `${title} ${term}`);
                let url = `https://www.google.com/search?${query.toString()}`;
                if (['YouTube Channel', 'Instagram Channel', 'Facebook', 'Twitter'].includes(term)) {
                  switch (term) {
                    case 'YouTube Channel':
                      url = `${youtube.url}/videos`;
                      break;
                    case 'Instagram Channel':
                      ({ url } = instagram);
                      break;
                    case 'Facebook':
                      ({ url } = facebook);
                      break;
                    case 'Twitter':
                      ({ url } = twitter);
                      break;
                    default:
                      break;
                  }

                  // url = term === 'YouTube Channel' ? `${youtube.url}/videos` : instagram.url;
                }
                return (<ListGroupItem key={term} >{term} <a href={url} target="_blank" rel="noreferrer"><i className="fa fa-external-link-alt float-right" /></a></ListGroupItem>);
              })
            }
          </ListGroup>
        </div>
        <ModalFooter className="modal__footer">
          <Button
            className="btn-sm modal_cancel"
            color="secondary"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
});
