/* eslint react/prop-types: 0 */
import React from 'react';
import {
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import debounce from 'lodash.debounce';
import { axios } from 'ApiClient';

export default class TypeInEdit extends React.Component {
  constructor(props) {
    super(props);
    const { record } = this.props;
    this.handleChange = this.handleChange.bind(this);
    this.onChangeDebounced = debounce(this.onChangeDebounced, 1000);
    this.state = {
      value: record.params.type,
      error: '',
    };
  }

  onChangeDebounced = (value) => {
    const { onChange, enableSaveButton } = this.props;
    const { record: { params: { _id } } } = this.props;
    if (value) {
      axios.post(
        '/api/portal/emailTemplates/email-templates-with-type-count',
        {
          type: value,
          currentEmailTemplateId: _id,
        },
      ).then((response) => {
        const { count } = response.data;
        // eslint-disable-next-line eqeqeq
        if (count <= 0) {
          this.setState({ error: '' });
          onChange('type', value);
          enableSaveButton(true);
        } else {
          enableSaveButton(false);
          this.setState({ error: 'This Type is already in use' });
        }
      }).catch((error) => {
        console.log(error);
        enableSaveButton(false);
      });
    }
  };

  handleChange(event) {
    const { value } = event.target;
    const { enableSaveButton } = this.props;
    enableSaveButton(false);
    this.setState({ value });
    this.onChangeDebounced(value);
  }

  render() {
    const {
      value,
      error,
    } = this.state;
    const helpBlockClassName = error ? 'help-block color-danger' : 'help-block';

    return (
      <FormGroup>
        <Label for="type">Type</Label>
        <Input
          id="type"
          name="type"
          value={value}
          onChange={this.handleChange}
          style={{ width: '100%' }}
        />
        <span className={helpBlockClassName}>
          {
            error || 'Note that the developers should be notified on its changes.'
          }
        </span>
      </FormGroup>
    );
  }
}

