/* eslint-disable react/prop-types */
import React from 'react';
import { momentServerUTCToDate } from '../../../../../../shared/helpers/Dates';
// import InfluencerCompany from './InfluencerCompany';
import InfluencerCompanyStatus from './InfluencerCompanyStatus';
import InfluencerCompanyTuneId from './InfluencerCompanyTuneId';
import PasswordInEdit from './PasswordInEdit';
import ContactInList from './ContactInList';
import PaymentInEdit from './PaymentInEdit';
import ActionsInList from './ActionsInList';
import NameInList from './CreatorInList';
// import EmailInList from './EmailInList';
import InfluencerStatus from './InfluencerStatus';

export default {
  edit: {
    password: PasswordInEdit,
    payment: PaymentInEdit,
  },
  list: {
    fullName: NameInList,
    // email: EmailInList,
    status: InfluencerStatus,
    // 'affiliateDataObject.company': InfluencerCompany,
    'affiliateDataObject.tune_id': InfluencerCompanyTuneId,
    hsContactVid: ContactInList,
    'affiliateDataObject.status': InfluencerCompanyStatus,
    date_of_first_conversion: ({ record }) => (<span className="">{record.params.date_of_first_conversion ? momentServerUTCToDate(record.params.date_of_first_conversion).format('MM/DD/YY') : ''}</span>),
    date_of_last_conversion: ({ record }) => (record.params.date_of_last_conversion ? momentServerUTCToDate(record.params.date_of_last_conversion).format('MM/DD/YY') : ''),
  },
  actions: ActionsInList,
};
