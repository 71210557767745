import React, { useState } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import PropTypes from 'prop-types';

const VideoTitle = ({ record }) => {
  const [openPopover, setOpenPopover] = useState(false);
  const [popoverTargetId] = useState(`popoverTargetId_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10)}`);
  const videoLink = `https://www.youtube.com/watch?v=${record.row.original.params?.videoId}`;
  return (
    <div>
      {(record.row.original.params?.videoTitle && record.row.original.params?.videoTitle.length > 80) ?
        <div className="font-weight-bold">
          <Popover
            id="videoTitlePopover"
            placement="top"
            target={popoverTargetId}
            open={openPopover}
            trigger="click"
            toggle={() => { setOpenPopover(false); }}
          >
            <PopoverBody>
              {record.row.original.params.videoTitle}
            </PopoverBody>
          </Popover>
          <a
            href={videoLink}
            target="_blank"
            id={popoverTargetId}
            onClick={() => { setOpenPopover(true); }}
            onKeyDown={() => { setOpenPopover(true); }}
            role="button"
            tabIndex={0}
            rel="noreferrer"
          >
            {`${record.row.original.params.videoTitle.toString().substring(0, 80)}...`}
          </a>
        </div> :
        <a className="font-weight-bold" href={videoLink} rel="noreferrer" target="_blank">{record.row.original.params.videoTitle}</a>
      }

      <div>
        <span>Likes ({record.row.original.params.likeCount || 0})</span> / <span>Replies ({record.row.original.params.totalReplyCount || 0})</span>
      </div>
    </div>
  );
};

VideoTitle.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default VideoTitle;
