/* eslint react/prop-types: 0 */

export default {
  show: {
  //  status: CampaignStatusInShow,
  },
  list: {

  },
};
