class ViewHelpers {
  constructor({ options } = {}) {
    this.options = ViewHelpers.getPaths(options) || { rootPath: '/admin' };
  }

  static getPaths(options) {
    return options || window?.REDUX_STATE?.paths;
  }

  urlBuilder(paths = [], search = '') {
    const separator = '/';
    const replace = new RegExp(`${separator}{1,}`, 'g');
    let { rootPath } = this.options;

    if (!rootPath.startsWith(separator)) {
      rootPath = `${separator}${rootPath}`;
    }

    const parts = [rootPath, ...paths];
    return `${parts.join(separator).replace(replace, separator)}${search}`;
  }

  loginUrl() {
    return this.options.loginPath;
  }

  logoutUrl() {
    return this.options.logoutPath;
  }

  dashboardUrl() {
    return this.options.rootPath;
  }

  pageUrl(pageName) {
    return this.urlBuilder(['pages', pageName]);
  }

  editUrl(resourceId, recordId, search) {
    return this.recordActionUrl({ resourceId, recordId, actionName: 'edit', search });
  }

  showUrl(resourceId, recordId, search) {
    return this.recordActionUrl({ resourceId, recordId, actionName: 'show', search });
  }

  deleteUrl(resourceId, recordId, search) {
    return this.recordActionUrl({ resourceId, recordId, actionName: 'delete', search });
  }

  newUrl(resourceId, search) {
    return this.resourceActionUrl({ resourceId, actionName: 'new', search });
  }

  listUrl(resourceId, search) {
    return this.resourceActionUrl({ resourceId, actionName: 'list', search });
  }

  bulkDeleteUrl(resourceId, recordIds, search) {
    return this.bulkActionUrl({ resourceId, recordIds, actionName: 'bulkDelete', search });
  }

  resourceActionUrl({ resourceId, actionName, search }) {
    return this.urlBuilder(['resources', resourceId, 'actions', actionName], search);
  }

  resourceUrl({ resourceId, search }) {
    return this.urlBuilder(['resources', resourceId], search);
  }

  recordActionUrl({ resourceId, recordId, actionName, search }) {
    return this.urlBuilder(['resources', resourceId, 'records', recordId, actionName], search);
  }

  bulkActionUrl({ resourceId, recordIds, actionName, search }) {
    const url = this.urlBuilder(['resources', resourceId, 'bulk', actionName]);

    if (recordIds && recordIds.length) {
      const query = new URLSearchParams(search);
      query.set('recordIds', recordIds.join(','));
      return `${url}?${query.toString()}`;
    }

    return `${url}${search || ''}`;
  }

}

export default ViewHelpers;
