import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFlexLayout } from 'react-table';
import _ from 'lodash';
import ReactTableConstructor from './components/ReactTableConstructor';
import ReactTableCell from './components/ReactTableCell';
import ReactTableCellEditable from './components/ReactTableEditableCell';
import CheckBoxField from '../../components/CheckBox';


const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    if (resolvedRef.current) resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);
  // console.log(rest);
  return (
    <div style={{ position: 'relative' }}>
      <CheckBoxField
        label=""
        name={Math.random().toString(36).slice(2)}
        value={!rest.disabled && rest.checked}
        onChange={e => !rest.disabled && e.target && rest.onChange(e)}
        disabled={rest.disabled}
        className={rest.top ? ' pb-2' : ''}
      />
    </div>
  );
});
IndeterminateCheckbox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  indeterminate: PropTypes.any.isRequired,
};
const ReactTableBase = React.memo(({
  tableConfig,
  columns,
  data,
  updateDraggableData,
  updateEditableData,
  updateSelect,
  notFoundProps,
  disableSortRemove,
}) => {
  const {
    isEditable,
    isResizable,
    isSortable,
    isSelectable,
    withDragAndDrop,
    withPagination,
    withSearchEngine,
    manualPageSize,
    sortBy,
    direction,
    useFlex,
  } = tableConfig;
  const [filterValue, setFilterValue] = useState(null);
  const tableOptions = {
    columns,
    data,
    updateDraggableData,
    updateEditableData,
    setFilterValue,
    defaultColumn: {},
    isEditable,
    withDragAndDrop: withDragAndDrop || false,
    dataLength: data.length,
    autoResetPage: false,
    disableSortBy: !isSortable,
    manualSortBy: !isSortable,
    manualGlobalFilter: !withSearchEngine,
    manualPagination: !withPagination,
    initialState: {
      pageIndex: 0,
      pageSize: manualPageSize ? manualPageSize[0] : 10,
      globalFilter: withSearchEngine && filterValue ? filterValue : undefined,
      ...sortBy && { sortBy: [{ id: sortBy, desc: direction === 'desc' }] },
    },
    updateSelect,
    disableSortRemove,
  };

  let tableOptionalHook = [];
  if (isResizable || useFlex) tableOptionalHook = [useFlexLayout];
  if (isSelectable) {
    tableOptionalHook.push((hooks) => {
      hooks.visibleColumns.push(columnsAll => [
        // Let's make a column for selection
        {
          minWidth: 60,
          width: 60,
          maxWidth: 60,
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          // eslint-disable-next-line react/prop-types
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} top />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          // eslint-disable-next-line react/prop-types
          Cell: ({ row }) => (
            <div>
              {/* eslint-disable-next-line react/prop-types */}
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} disabled={row?.original?.checkboxDisable || false} />
            </div>
          ),
        },
        ...columnsAll,
      ]);
    });
  }
  if (withSearchEngine) {
    tableOptions.defaultColumn = {
      Cell: ReactTableCell,
    };
  }
  if (isEditable) {
    tableOptions.defaultColumn = {
      Cell: ReactTableCellEditable,
    };
  }

  return (
    <ReactTableConstructor
      key={isResizable || isEditable ? 'modified' : 'common'}
      tableConfig={tableConfig}
      tableOptions={tableOptions}
      tableOptionalHook={tableOptionalHook}
      notFoundProps={notFoundProps}
    />
  );
}, (a, a1) => _.isEqual(a.data, a1.data) && _.isEqual(a.columns, a1.columns));

ReactTableBase.propTypes = {
  tableConfig: PropTypes.shape({
    isEditable: PropTypes.bool,
    isResizable: PropTypes.bool,
    isSortable: PropTypes.bool,
    isSelectable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    withPagination: PropTypes.bool,
    withSearchEngine: PropTypes.bool,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
    sortBy: PropTypes.string,
    direction: PropTypes.string,
    handleEvent: PropTypes.func,
    useFlex: PropTypes.bool,
  }),
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
  })),
  data: PropTypes.arrayOf(PropTypes.shape()),
  updateDraggableData: PropTypes.func,
  updateEditableData: PropTypes.func,
  updateSelect: PropTypes.func,
  notFoundProps: PropTypes.objectOf(PropTypes.any),
  disableSortRemove: PropTypes.bool,
};

ReactTableBase.defaultProps = {
  tableConfig: {
    isEditable: false,
    isResizable: false,
    isSortable: false,
    isSelectable: false,
    withDragAndDrop: false,
    withPagination: false,
    withSearchEngine: false,
    sortBy: null,
    direction: null,
    manualPageSize: [],
    useFlex: true,
  },
  columns: [
    { Header: '#', accessor: 'id' },
    { Header: 'Header Example Title one', accessor: 'first' },
    { Header: 'Header Example Title two', accessor: 'last' },
  ],
  data: [
    { id: 1, first: 'Cell Example Data one', last: 'Cell Example Data two' },
    { id: 2, first: 'Cell Example Data three', last: 'Cell Example Data four' },
  ],
  updateDraggableData: () => {},
  updateEditableData: () => {},
  updateSelect: () => {},
  notFoundProps: {},
  disableSortRemove: false,
};

export default ReactTableBase;
