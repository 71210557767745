/* eslint react/prop-types: 0 */
import React from 'react';
import Select from 'react-select';
import mapValue from './map-value';
import PropertyInFilter from '../../ui/property-in-filter';
import { filterStyles } from '../../../styles/selectStyles';
import theme from '../../../../../theme';

class Filter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selected) {
    const { onChange, property } = this.props;
    const value = selected ? selected.value : '';
    onChange(property.name, value);
  }

  render() {
    const { property, filter = {} } = this.props;
    const value = typeof filter[property.name] === 'undefined' ? '' : filter[property.name];
    const options = [
      { value: true, label: mapValue(true) },
      { value: false, label: mapValue(false) },
    ];
    const selected = options.find(o => o.value === value);
    return (
      <PropertyInFilter property={property}>
        <Select
          value={typeof selected === 'undefined' ? '' : selected}
          isClearable
          options={options}
          styles={filterStyles(theme)}
          onChange={this.handleChange}
        />
      </PropertyInFilter>
    );
  }
}

export default Filter;
