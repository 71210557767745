import { unflatten } from 'flat';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TableCellContextMenu from './TableCellContextMenu';
import {
  setShowBrandStatusHistoryModal,
  setShowSafetyCheck,
  setCurrentMediaContent,
  showScorecardAnswersDialog,
} from '../../../../../redux/reducers/brand/CreatorsReducer';
import {
  getScorecardAnswersData,
} from '../../../../../redux/reducers/admin/ScoringReducer';

const ContextMenuInList = ({
  record,
  dispatch,
}) => {
  const recordUnflatten = unflatten(record);
  const {
    scorecards,
  } = recordUnflatten.params;

  const handleClickViewScorecard = (id) => {
    if (id) {
      dispatch(showScorecardAnswersDialog(id));
      dispatch(getScorecardAnswersData(id));
    }
  };

  const clickStatusHistory = () => {
    dispatch(setCurrentMediaContent(recordUnflatten));
    dispatch(setShowBrandStatusHistoryModal(true));
  };

  const clickSafetyCheck = () => {
    dispatch(setCurrentMediaContent(recordUnflatten));
    dispatch(setShowSafetyCheck(true));
  };

  const options = [];
  if (scorecards?.length > 0) {
    for (let i = 0; i < scorecards.length; i += 1) {
      options.push({
        label: `View Scorecard ${i + 1}`,
        handler: () => handleClickViewScorecard(scorecards[i]._id),
      });
    }
    options.push({ type: 'divider', label: 'divider1' });
  }
  options.push({
    label: 'Safety Check',
    handler: clickSafetyCheck,
  });
  options.push({
    label: 'Status History',
    handler: clickStatusHistory,
  });

  return (
    <div className="text-right">
      <TableCellContextMenu options={options} />
    </div>
  );
};
ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ContextMenuInList);
