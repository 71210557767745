import { unflatten } from 'flat';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ApiClient from 'ApiClient';

const EndDate = ({ record }) => {
  const key = 'endDate';
  const recordUnflatten = unflatten(record);
  const rowData = recordUnflatten.params;
  let val = null;
  if (typeof rowData !== 'undefined') {
    if (typeof rowData[key] !== 'undefined' && rowData[key] !== null) {
      val = moment(rowData[key]).toDate();
    }
  }

  const [value, setValue] = useState(val);

  const handleChange = async (newValue) => {
    setValue(newValue);
    const data = new FormData();
    data.append(key, newValue || '');
    const api = new ApiClient();
    api.recordAction({
      resourceId: 'PortalNotification',
      recordId: record.id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return true;
  };

  return (
    <DatePicker
      onChange={handleChange}
      selected={value}
      dateFormat="M/d/yyyy h:mmaa"
      placeholderText="End Date"
      className="form-control"
      showTimeInput
    />
  );
};
EndDate.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default EndDate;
