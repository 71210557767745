import moment from 'moment';
import PayablesDashboard from './PayablesDashboard';
import PayablesDashboardId from './PayablesDashboardId';
import { getServerDate } from '../../../../../../shared/helpers/WVFormatter';
// import StartDate from './StartDate';
// import EndDate from './EndDate';


export default {
  edit: {},
  list: {
    payables: PayablesDashboard,
    name: PayablesDashboardId,
    startDate: ({ record }) => (record.params.startDate ? getServerDate(moment(record.params.startDate)) : '-'),
    endDate: ({ record }) => (record.params.endDate ? getServerDate(moment(record.params.endDate)) : '-'),
  },
};
