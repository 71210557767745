import { unflatten } from 'flat';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import SpyLogInButton from '../../../../../../shared/components/SpyLogInButton';

const ActionsInList = ({ record }) => {
  const influencer = unflatten(record);
  return (
    <>
      <div style={{ minWidth: '100px' }} >
        <SpyLogInButton
          userId={influencer.id}
          url="/api/portal/influencers/spy-login"
        />
        <Link to={`/influencer/profile/${influencer.id}`}>
          <Tooltip title="Creator Profile">
            <button
              className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall "
            >
              <i className="fa fa-info-circle" />
            </button>
          </Tooltip>
        </Link>
      </div>
    </>
  );
};
ActionsInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  // meta: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default ActionsInList;
