import React from 'react';
import PlayIcon from 'mdi-react/PlayIcon';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import { Modal } from 'reactstrap';

export default class ExecuteJobButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modal: false,
      status: '',
      url: '',
      message: '',
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  executeJob = () => {
    // eslint-disable-next-line react/prop-types
    const { identifier, url } = this.props;
    this.setState({ loading: true });
    axios.post(
      url,
      {
        identifier,
      },
    ).catch((error) => {
      console.log(error);
      this.setState({ loading: false });
    }).then((response) => {
      if (response.data.success === true) {
        this.setState({
          loading: false,
          modal: true,
          status: 'Job execution succeed.',
          url: `/resources/Log/actions/list?filters.jobIdentifier=${identifier}&filters.level=info`,
          message: 'You can find its results in the corresponding Slack channel or check the latest ',
        });
      } else if (response.data.jobIdentifier) {
        this.setState({
          loading: false,
          modal: true,
          status: 'Job execution failed.',
          url: `/resources/Log/actions/list?filters.jobIdentifier=${identifier}&filters.level=error`,
          message: 'Check the latest ',
        });
      } else if (response.data.error) {
        this.setState({
          loading: false,
          modal: true,
          status: 'Job execution failed.',
          url: '/resources/Log/actions/list?filters.level=error',
          message: 'Check the latest ',
        });
      }
    });
  };

  render() {
    const {
      loading,
      modal,
      status,
      url,
      message,
    } = this.state;
    return loading ? (
      <div
        className="spinner-border"
        style={
          {
            width: '12px',
            height: '12px',
            marginLeft: '3px',
            marginRight: '6px',
            color: '#646777',
          }
        }
      />
    ) : (
      <div style={{ display: 'inline' }}>
        <Tooltip title="Execute Job Immediately">
          <button
            className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall"
            onClick={this.executeJob}
          >
            <PlayIcon
              style={{ width: '15px', height: '15px' }}
            />
          </button>
        </Tooltip>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          modalClassName="ltr-support"
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
          </div>
          <div className="modal__body">
            <p>
              {status}
              <br />
              {message}<a href={url} target="_blank" rel="noopener noreferrer">Logs</a>
            </p>
          </div>
        </Modal>
      </div>
    );
  }
}

