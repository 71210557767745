import { useDispatch } from 'react-redux';
import { addNotice } from '../../containers/App/store/store';

const useNoticeHook = () => {
  const dispatch = useDispatch();
  const showSuccessNotice = message => dispatch(addNotice({
    message,
    type: 'success',
  }));
  const showErrorNotice = message => dispatch(addNotice({
    message,
    type: 'error',
    duration: 10,
  }));
  const showWarnongNotice = message => dispatch(addNotice({
    message,
    type: 'warning',
    duration: 10,
  }));

  return { showSuccess: showSuccessNotice, showError: showErrorNotice, showWarning: showWarnongNotice };
};

export default useNoticeHook;
