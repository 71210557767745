import React from 'react';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import CopyLeft from './CopyLeft';
import { fillUrl } from '../../../helpers/WVFormatter';

export default ({ property, record }) => {
  const value = record?.params?.[property.name];
  return value ? (
    <>
      <CopyLeft
        property={property}
        record={record}
      />
      <a href={fillUrl(value)} target="_blank" className="ml-1" rel="noopener noreferrer">
        <OpenInNewIcon size={17} />
      </a>
    </>
  ) : '';
};
