/* eslint react/prop-types: 0 */
import React from 'react';
import { Badge } from 'reactstrap';

const mapStatus = {
  1: 'Enabled',
  2: 'Disabled',
};
export default ({ record }) => {
  const status = mapStatus[record.params.status];
  let colorClass = 'primary';
  if (status === 'Enabled') {
    colorClass = 'success';
  } else if (status === 'Disabled') {
    colorClass = 'danger';
  }
  return (
    <Badge color={colorClass}>{status}</Badge>
  );
};
