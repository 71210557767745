
exports.activateHideNavigationMenuOnClick = () => {
  const links = document.querySelectorAll('.sidebar__link');
  const buttons = document.querySelectorAll('.topbar__button--mobile');

  links.forEach(li => li.type !== 'button' && li.addEventListener('click', () => {
    buttons.forEach((b) => {
      b.click();
    });
  }));
};
