/* eslint-disable no-param-reassign */
import { unflatten } from 'flat';
import axios from 'axios';
import ApiClient from 'ApiClient';
import { createSlice } from '@reduxjs/toolkit';
import { addNotice } from '../../../containers/App/store/store';

/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const accountPayableReducer = createSlice({
  name: 'accountPayable',
  initialState: {
    payables: [],
    payablesAgent: [],
    deals: [],
    loading: {
      payables: false,
      fillPayables: false,
      accountPayable: false,
      editingFields: [],
      stripePayouts: false,
      createNew: false,
      payablesAgent: false,
    },
    logs: {
      payables: {
        request: [],
        added: [],
        removed: [],
        errors: [],
      },
      stripePayouts: {
        selectedIds: [],
        logs: [],
      },
    },
    stripe: {
      currentBalance: 0,
      availableBalance: 0,
    },
    stats: {
      total: 0,
      noConnected: 0,
      noPaid: 0,
      totalRevenue: 0,
      totalPayout: 0,
      totalUniques: 0,
    },
    errors: [],
    error: null,
    lastFetched: new Date(),
    unassignedDealFetched: false,
    accountPayable: null,
    showUpdateAmountModal: false,
    totalBillAmount: 0,
    totalInvoiceAmount: 0,
  },
  reducers: {
    billInvoiceAmount(state, action) {
      const { totalBillAmount, totalInvoiceAmount } = action.payload;
      state.totalBillAmount = totalBillAmount;
      state.totalInvoiceAmount = totalInvoiceAmount;
    },
    sendStripeStart(state, action) {
      const { selectedIds } = action;
      state.loading = {
        ...state.loading,
        stripePayouts: true,
      };
      state.logs = {
        ...state.logs,
        stripePayouts: {
          selectedIds,
          logs: [],
        },
      };
    },
    sendStripeSuccess(state, action) {
      const { logs } = action.payload;
      state.loading = {
        ...state.loading,
        stripePayouts: false,
      };
      state.logs = {
        ...state.logs,
        stripePayouts: {
          ...state.logs.stripePayouts,
          logs,
        },
      };
    },
    sendStripeError(state) {
      state.loading = {
        ...state.loading,
        stripePayouts: false,
      };
    },

    getAccountPayableRecordStart(state) {
      state.loading = {
        ...state.loading,
        accountPayable: true,
      };
    },
    setStats(state, action) {
      console.log(action.payload);
      const stats = action.payload;
      state.stats = stats;
    },
    getAccountPayableRecordSuccess(state, action) {
      const { record } = action.payload;
      state.loading = {
        ...state.loading,
        accountPayable: false,
        editingFields: [],
      };
      state.error = null;
      state.accountPayable = record;
    },
    getAccountPayableRecordInvlidValidation(state, action) {
      const { record } = action.payload;
      state.loading = {
        ...state.loading,
        accountPayable: false,
        editingFields: [],
      };
      state.error = null;
      state.accountPayable = {
        ...state.accountPayable,
        errors: record.errors,
      };
    },
    getAccountPayableRecordError(state) {
      state.loading = {
        ...state.loading,
        accountPayable: false,
      };
    },
    saveAccountPayableRecordStart(state) {
      state.loading = {
        ...state.loading,
        accountPayable: true,
      };
      state.error = null;
      state.accountPayable = null;
    },
    saveAccountPayableRecordSuccess(state) {
      state.loading = {
        ...state.loading,
        accountPayable: false,
        editingFields: [],
      };
      state.error = null;
    },
    saveAccountPayableRecordError(state, action) {
      const { record } = action.payload;
      state.loading = {
        ...state.loading,
        accountPayable: false,
      };
      state.accountPayable = record;
    },
    getAccountPayableRecordClear(state) {
      state.loading = {
        ...state.loading,
        accountPayable: false,
      };
      state.error = null;
      state.accountPayable = null;
    },
    setEditingFieldRecord(state, action) {
      const { edit } = action.payload;
      state.loading = {
        ...state.loading,
        accountPayable: false,
        editingFields: edit,
      };
    },
    getAccountPayableAgentStart(state) {
      state.unassignedDealFetched = false;
      state.loading = {
        ...state.loading,
        payablesAgent: true,
      };
    },
    getAccountPayableAgentSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        payablesAgent: false,
      };
      state.error = null;
      // state.lastFetched = new Date();
      const newPayables = [];
      data.forEach((payable) => {
        const newPayable = { ...payable };
        newPayable.deal = newPayable.populated.deal && newPayable.populated.deal.params;
        if (payable.params.status === 'complete') {
          newPayable.checkboxDisable = true;
        }
        newPayables.push(newPayable);
      });
      state.payablesAgent = newPayables;
    },
    getAccountPayableAgentError(state) {
      state.loading = {
        ...state.loading,
        payablesAgent: false,
      };
    },

    getAccountPayableStart(state) {
      state.unassignedDealFetched = false;
      state.loading = {
        ...state.loading,
        payables: true,
      };
    },
    calculateAccountPayableStart(state) {
      state.loading = {
        ...state.loading,
        calculate: true,
      };
    },
    getAccountPayableSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        payables: false,
      };
      state.error = null;
      // state.lastFetched = new Date();
      const newPayables = [];
      data.forEach((payable) => {
        const newPayable = { ...payable };
        newPayable.deal = newPayable.populated.deal && newPayable.populated.deal.params;
        if (payable.params.status === 'complete') {
          newPayable.checkboxDisable = true;
        }
        if (!payable.deal && payable.unassignedDeal) {
          newPayable.highlightRowError = true;
        }
        newPayable.meta = {
          selectableFunc: record => !(['complete', 'failed'].includes(record.params.status)),
        };
        newPayables.push(newPayable);
      });
      state.payables = newPayables;
      state.lastFetched = new Date();
    },
    fillAccountsPayablesSuccess(state, action) {
      const { unassignedDeals } = action.payload;
      state.unassignedDealFetched = true;
      const newPayables = [];
      let countChanged = 0;
      state.payables.forEach((payable) => {
        const newPayable = { ...payable };
        if (!newPayable.deal) {
          newPayable.unassignedDeal = unassignedDeals.find(item =>
            !item.ilDealData.offer
            && item.contact && item.contact.hs_object_id
            && newPayable.populated.influencer
            && Number(item.contact.hs_object_id) === Number(newPayable.populated.influencer.params.hsContactVid));
          if (newPayable.unassignedDeal && !payable.unassignedDeal) {
            countChanged += 1;
          }
        }
        if (!newPayable.deal && newPayable.unassignedDeal) {
          newPayable.highlightRowError = true;
        }
        newPayables.push(newPayable);
      });
      if (countChanged > 0) {
        state.payables = newPayables;
        state.lastFetched = new Date();
      }
    },
    updatePayableRecord(state, action) {
      const { id, record } = action.payload;
      console.log(record);
      const newRecord = [];
      state.payables.forEach((payable) => {
        if (payable.id === id) {
          record.deal = payable.deal;
          if (record.params.status === 'complete') {
            record.checkboxDisable = true;
          }
          newRecord.push(unflatten(record));
        } else {
          newRecord.push(payable);
        }
      });
      state.payables = newRecord;
    },
    updatePayableAgentRecord(state, action) {
      const { id, record } = action.payload;
      const newRecord = [];
      console.log(record);
      state.payablesAgent.forEach((payable) => {
        if (payable.id === id) {
          record.deal = payable.deal;
          if (record.params.status === 'complete') {
            record.checkboxDisable = true;
          }
          newRecord.push(unflatten(record));
        } else {
          newRecord.push(payable);
        }
      });
      state.lastFetched = new Date();
      state.payablesAgent = newRecord;
    },
    getAccountPayableError(state) {
      state.loading = {
        ...state.loading,
        payables: false,
      };
    },
    calculateAccountPayableError(state) {
      state.loading = {
        ...state.loading,
        calculate: false,
      };
    },
    calculateAccountPayableSuccess(state) {
      state.loading = {
        ...state.loading,
        calculate: false,
      };
    },
    getFillAccountPayablesStart(state) {
      state.loading = {
        ...state.loading,
        payables: true,
        fillPayables: true,
      };
      state.logs = {
        ...state.logs,
        payables: {
          request: [],
          added: [],
          removed: [],
          errors: [],
        },
      };
      state.error = null;
    },
    getFillAccountPayablesSuccess(state, action) {
      const { payablesLog } = action.payload;
      state.loading = {
        ...state.loading,
        payables: true,
        fillPayables: false,
      };
      state.logs = {
        ...state.logs,
        payables: payablesLog,
      };
      state.error = null;
      // state.payables = items;
    },
    getFillAccountPayablesError(state, acton) {
      state.loading = {
        ...state.loading,
        payables: false,
        fillPayables: false,
      };
      state.error = acton.payload;
    },
    showAmountReportModal(state) {
      state.showUpdateAmountModal = true;
    },
    hideAmountReportModal(state) {
      state.showUpdateAmountModal = false;
    },
    setStripeStats(state, action) {
      const data = action.payload;
      state.stripe = data;
    },
  },
});

const getAccountPayableApi = async (id) => {
  const api = new ApiClient();
  return api.recordAction({
    resourceId: 'AccountsPayable',
    recordId: id,
    actionName: 'show',
  });
};
const fetchStripeBalance = async () => {
  const resp = await axios.get('/api/portal/stripe/balance');
  const { balance } = resp.data;
  return ({
    currentBalance: balance.available[0].amount + balance.pending[0].amount,
    availableBalance: balance.available[0].amount,
  });
};

const getPayablesApi = async (id) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/finance/get-payouts?reportId=${id}`);
};
const getPayablesAgentApi = async (id) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/finance/get-payouts-agent?reportId=${id}`);
};

const getDealsForReportApi = async (id) => {
  const api = new ApiClient();
  return api.client.get(`/api/portal/finance/get-deals-for-payment?reportId=${id}`);
};

const fillPayablesApi = async (id, isForce) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/finance/fill-payout', { reportId: id, isForce });
};

const fillUnpaidPayablesApi = async (id) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/finance/fill-unpaid-payout', { reportId: id });
};

const calculatePayablesApi = async (id, status) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/finance/post-calculate-payouts', { reportId: id, status });
};

const getStatsApi = async (id) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/finance/post-payables-stats', { reportId: id });
};

const stripePayoutsApi = async (selectedIds, billComChartOfAccount = []) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/stripe/transfer-funds', {
    payableIds: selectedIds,
    billComChartOfAccount,
  });
  /*
  axios({
      method: 'post',
      url: '/api/portal/stripe/transfer-funds',
      data: {
        payableIds: this.state.selectedIds,
      },
    }).then((response) => {
      if (response.data.success) {
        dispatch(addNotice({
          message: 'Payouts successfully scheduled',
          type: 'success',
        }));
      } else {
        dispatch(addNotice({
          message: response.data.error,
          type: 'error',
        }));
      }
      this.setState({ loading: { sendPayouts: false } });
    }).catch((error) => {
      console.log('CATCH ERROR: ', error);
      dispatch(addNotice({
        message: error.message,
        type: 'error',
      }));
      this.setState({ loading: { sendPayouts: false } });
    });
    */
};

const stripePayoutsAgentApi = async (selectedIds, billComChartOfAccount = []) => {
  const api = new ApiClient();
  return api.client.post('/api/portal/stripe/transfer-funds-agent', {
    payableIds: selectedIds,
    billComChartOfAccount,
  });
};


export const {
  billInvoiceAmount,
  // fill table
  getFillAccountPayablesStart,
  getFillAccountPayablesError,
  getFillAccountPayablesSuccess,
  // get payables
  getAccountPayableStart,
  getAccountPayableSuccess,
  getAccountPayableError,
  // get account payable record
  getAccountPayableRecordStart,
  getAccountPayableRecordSuccess,
  getAccountPayableRecordInvlidValidation,
  getAccountPayableRecordError,
  getAccountPayableRecordClear,
  // create new
  saveAccountPayableRecordStart,
  saveAccountPayableRecordSuccess,
  saveAccountPayableRecordError,
  setEditingFieldRecord,
  clearEditingFieldRecord,
  // stripe
  sendStripeStart,
  sendStripeSuccess,
  sendStripeError,
  // update table
  updatePayableAgentRecord,
  updatePayableRecord,
  fillAccountsPayablesSuccess,
  fillAccountPayableStart,
  fillAccountPayableError,
  calculateAccountPayableStart,
  calculateAccountPayableError,
  calculateAccountPayableSuccess,
  showAmountReportModal,
  hideAmountReportModal,
  getAccountPayableAgentStart,
  getAccountPayableAgentSuccess,
  getAccountPayableAgentError,
  setStripeStats,
  setStats,
} = accountPayableReducer.actions;

export const getAccountPayableRecord = id => async (dispatch) => {
  try {
    if (!id) {
      dispatch(getAccountPayableRecordClear());
    } else {
      dispatch(getAccountPayableRecordStart());
      const response = await getAccountPayableApi(id);
      if (response.data && response.data.record) {
        dispatch(getAccountPayableRecordSuccess({ record: response.data.record }));
      } else {
        throw new Error('There was an error fetching records, Check out console to see more information.');
      }
    }
  } catch (err) {
    dispatch(getAccountPayableRecordError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};
const getErrorsAsArray = obj => Object.values(obj).map(item => item.message);

export const editAccountPayableRecord = (id, values, callback) => async (dispatch) => {
  try {
    dispatch(setEditingFieldRecord({ edit: Object.keys(values) }));
    const api = new ApiClient();
    const data = new FormData();
    Object.keys(values).forEach((key) => {
      data.append(key, values[key]);
    });
    const editRecord = await api.recordAction({
      resourceId: 'AccountsPayable',
      recordId: id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (editRecord.data && editRecord.data.record && Object.keys(editRecord.data.record.errors).length !== 0) {
      dispatch(getAccountPayableRecordInvlidValidation({ record: editRecord.data.record }));
      throw new Error((getErrorsAsArray(editRecord.data.record.errors)).join('\n'));
    }
    const response = await getAccountPayableApi(id);
    if (response.data && response.data.record) {
      dispatch(getAccountPayableRecordSuccess({ record: response.data.record }));
    }
    if (callback) {
      callback(response.data.record);
    }
  } catch (err) {
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};
export const getAccountPayables = id => async (dispatch) => {
  try {
    dispatch(getAccountPayableStart());
    const response = await getPayablesApi(id);
    if (response.data) {
      dispatch(getAccountPayableSuccess({ data: response.data.records }));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
    const deals = await getDealsForReportApi(id);
    dispatch(fillAccountsPayablesSuccess({ unassignedDeals: deals.data.unassignedDeals }));
    dispatch(billInvoiceAmount({ totalBillAmount: deals.data.totalBillAmount, totalInvoiceAmount: deals.data.totalInvoiceAmount }));
    // dis
  } catch (err) {
    dispatch(getAccountPayableError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};
export const getAccountPayablesAgent = id => async (dispatch) => {
  try {
    dispatch(getAccountPayableAgentStart());
    const response = await getPayablesAgentApi(id);
    if (response.data) {
      dispatch(getAccountPayableAgentSuccess({ data: response.data.records }));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
    // dis
  } catch (err) {
    dispatch(getAccountPayableError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const calculatePayables = (id, status) => async (dispatch) => {
  try {
    dispatch(calculateAccountPayableStart());
    const res = await calculatePayablesApi(id, status);
    if (res.data.res) {
      dispatch(calculateAccountPayableSuccess());
      dispatch(getAccountPayables(id));
      dispatch(getAccountPayablesAgent(id));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
    // dis
  } catch (err) {
    dispatch(calculateAccountPayableError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const editPayablesBulk = (reportId, ids, values, callback) => async (dispatch) => {
  try {
    const api = new ApiClient();
    const response = await api.client.post('/api/portal/payables/bulk-edit', { reportId, ids, values });

    if (response.data && response.data.success) {
      if (callback) {
        callback(response.data);
      }
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const fillAccountPayables = (id, isForce) => async (dispatch) => {
  try {
    // console.log(id, isForce);
    dispatch(getFillAccountPayablesStart());
    const response = await fillPayablesApi(id, isForce);
    if (response.data.success !== true) {
      throw new Error(response.data.error || 'Unable to fetch report');
    }
    await calculatePayablesApi(id, 'pending');
    dispatch(getFillAccountPayablesSuccess({ payablesLog: response.data.payablesLog }));
    dispatch(getAccountPayables(id));
  } catch (err) {
    dispatch(getFillAccountPayablesError(err));
  }
};
export const fillUnpaidAccountPayables = id => async (dispatch) => {
  try {
    // console.log(id, isForce);
    dispatch(getFillAccountPayablesStart());
    const response = await fillUnpaidPayablesApi(id);
    if (response.data.success !== true) {
      throw new Error(response.data.error || 'Unable to fetch report');
    }
    await calculatePayablesApi(id, 'pending');
    dispatch(getFillAccountPayablesSuccess({ payablesLog: response.data.payablesLog }));
    dispatch(getAccountPayables(id));
    dispatch(getAccountPayablesAgent(id));
  } catch (err) {
    dispatch(getFillAccountPayablesError(err));
  }
};


export const sendAgentPayouts = (id, selectedIds, billComChartOfAccount, callback) => async (dispatch) => {
  try {
    dispatch(sendStripeStart());
    dispatch(editAccountPayableRecord(id, { status: 'startedTransaction' }));

    const response = await stripePayoutsAgentApi(selectedIds, billComChartOfAccount);
    if (response.data && response.data.success) {
      dispatch(sendStripeSuccess({ logs: response.data.logs }));
    } else {
      throw new Error(response.data.error);
    }
    if (!callback) dispatch(getAccountPayablesAgent(id));
  } catch (err) {
    dispatch(sendStripeError(err));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};
export const sendStripePayouts = (id, selectedIds, billComChartOfAccount, callback) => async (dispatch) => {
  try {
    dispatch(sendStripeStart());
    dispatch(editAccountPayableRecord(id, { status: 'startedTransaction' }));

    const response = await stripePayoutsApi(selectedIds, billComChartOfAccount);
    if (response.data && response.data.success) {
      dispatch(sendStripeSuccess({ logs: response.data.logs }));
    } else {
      throw new Error(response.data.error);
    }
    if (callback) callback();
    // dispatch(getAccountPayables(id));
  } catch (err) {
    dispatch(sendStripeError(err));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const addAccountPayableRecord = data => async (dispatch) => {
  try {
    dispatch(saveAccountPayableRecordStart());
    const api = new ApiClient();
    const response = await api.resourceAction({
      resourceId: 'AccountsPayable',
      actionName: 'new',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.data && response.data.record && response.data.redirectUrl && Object.keys(response.data.record.errors).length === 0) {
      dispatch(getAccountPayableRecord(response.data.record.id));
      dispatch(fillAccountPayables(response.data.record.id));
      dispatch(saveAccountPayableRecordError({ record: response.data.record }));
    } else {
      dispatch(getAccountPayableRecordInvlidValidation({ record: response.data.record }));
      throw new Error((getErrorsAsArray(response.data.record.errors)).join('\n'));
    }
  } catch (err) {
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};
export const fetchStripe = () => async (dispatch) => {
  try {
    const results = await fetchStripeBalance();
    dispatch(setStripeStats(results));
  } catch (err) {
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};
export const getBillStatsPayables = id => async (dispatch) => {
  try {
    const api = new ApiClient();
    const deals = await api.client.get(`/api/portal/finance/get-bill-stats?reportId=${id}`);
    dispatch(billInvoiceAmount({ totalBillAmount: deals.data.totalBillAmount, totalInvoiceAmount: deals.data.totalInvoiceAmount }));
    // dis
  } catch (err) {
    console.error(err);
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const getPayablesStats = id => async (dispatch) => {
  try {
    dispatch(getBillStatsPayables(id));
    const results = await getStatsApi(id);
    dispatch(setStats(results.data.stats));
  } catch (err) {
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};


export default accountPayableReducer.reducer;
