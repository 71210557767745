import React, { useState, useEffect, useMemo } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import PropTypes from 'prop-types';
import Select from 'react-select';
import RefreshIcon from 'mdi-react/RefreshIcon';
import { Row, Col, Container, Card, CardBody, CardTitle, Spinner, FormGroup } from 'reactstrap';
import { camelCase } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import Filter from './TimePeriodFilter';

import ChartComponentCommentsPercentage from './charts/ChartComponentCommentsPercentage';
import ChartComponentCommentsWithSentinement from './charts/ChartComponentCommentsWithSentinement';
import ChartComponentCommentsTotal from './charts/ChartComponentCommentsTotal';
import ChartComponentCreatorsPositive from './charts/ChartComponentCreatorsPositive';
import ChartComponentCreatorsNegative from './charts/ChartComponentCreatorsNegative';
import withNotice from '../../App/store/with-notice';
import ChartComponentVideosPositive from './charts/ChartComponentVideosPositive';
import ChartComponentVideosNegative from './charts/ChartComponentVideosNegative';
import CheckBoxField from '../../../shared/components/CheckBox';

const dataOptions = [
  { value: 'byDay', label: 'By Day' },
  { value: 'week', label: 'By Week' },
  { value: 'month', label: 'By Month' },
  { value: 'total', label: 'Over Time' },
];

const Item = ({ label, value, description }) => (
  <div
    style={{
      padding: '15px 15px',
      border: '1px solid #cccccc',
      borderRadius: '5px',
      textAlign: 'center',
      height: '100%',
    }}
  >
    <span style={{ textAlign: 'center', color: 'black' }} >
      <span
        id={camelCase(label)}
        style={{
          fontSize: '30px',
          fontWeight: '700',
          marginBottom: '12px',
          width: '100%',
          textAlign: 'center',
        }}
      >
        {value}
      </span>
      <br style={{ lineHeight: '50%' }} />
      <span style={{ width: '100%', fontSize: '16px' }}>{label}</span>
      <Tooltip title={description}>
        <IconButton size="small" style={{ marginTop: '-3px' }}>
          <i className="fa fa-info-circle" style={{ fontSize: '0.8em' }} />
        </IconButton>
      </Tooltip>
    </span>
  </div>);

Item.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
};

const SummaryReport = ({ addNotice }) => {
  const [loading, setLoading] = useState(false);
  const [showLabels, setShowLabels] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState((moment().subtract(1, 'day').endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).format('YYYY-MM-DD'));
  // const [selectedDate, setSelectedDate] = useState({ startDate, endDate });

  const [identity] = useState(Math.random().toString(36).substring(2));
  const [periodsData, setPeriodsData] = useState({
    comments: [],
    influencersNegative: [],
    influencersPositive: [],
    mediaContentPositive: [],
    mediaContentNegative: [],
  });
  const [selectedOption, setSelectedOption] = useState(dataOptions[0]);


  const setPeriodDataForTypes = (comments, influencersNegative, influencersPositive, mediaContentPositive, mediaContentNegative) => {
    const array = {
      comments,
      influencersNegative,
      influencersPositive,
      mediaContentPositive,
      mediaContentNegative,
    };
    setPeriodsData(array);
  };
  const fetchReport = async () => {
    setLoading(true);
    try {
      const resp = await axios.post('/api/brand/dashboard/post-report-youtube-comments-analysis', {
        startDate,
        endDate,
        identity,
      });
      setLoading(false);
      if (resp.data.success) {
        const {
          comments, influencersNegative, influencersPositive, mediaContentPositive, mediaContentNegative,
        } = resp.data;
        setPeriodDataForTypes(comments, influencersNegative, influencersPositive, mediaContentPositive, mediaContentNegative);
      } else {
        throw new Error(resp.data.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (startDate && endDate && !loading) {
      fetchReport();
    }
  }, [startDate, endDate]);

  const commentsStats = useMemo(() => {
    const sumAll = ({
      count, negativeCount, positiveCount, label,
    }, item) =>
    // console.log(item);
      ({
        count: count + item?.count || 0,
        negativeCount: negativeCount + item?.negativeCount || 0,
        // neutralCount: neutralCount + item?.neutralCount || 0,
        positiveCount: positiveCount + item?.positiveCount || 0,
        positivePercentage: (count + item?.count || 0) ? ((positiveCount + item?.positiveCount || 0) / (count + item?.count || 0)) : 0,
        negativePercentage: (count + item?.count || 0) ? ((negativeCount + item?.negativeCount || 0) / (count + item?.count || 0)) : 0,
        label: label || item?.label,
      });
    const total = selectedOption.value;
    const startMoment = moment(startDate).startOf('day');
    const endMoment = moment() > moment(endDate).endOf('day') ? moment(endDate).endOf('day') : moment().endOf('day');
    const chartData = {};
    const chartDataMonth = {};
    const chartDataWeek = {};
    const totalData = {};
    const days = endMoment.diff(startMoment, 'day');
    const months = endMoment.diff(startMoment, 'month');
    const weeks = endMoment.diff(startMoment, 'weeks') + 1;

    for (let i = 0; i <= days; i += 1) {
      const momentDate = startMoment.clone().add(i, 'day');
      const key = momentDate.format('YYYY-MM-DD');
      chartData[key] = {
        label: momentDate.format('M/D/YY'), count: 0, negativeCount: 0, positiveCount: 0, positivePercentage: 0, negativePercentage: 0,
      };
      totalData[key] = {
        label: momentDate.format('M/D/YY'), count: 0, negativeCount: 0, positiveCount: 0, positivePercentage: 0, negativePercentage: 0,
      };
    }
    for (let i = 0; i <= months; i += 1) {
      const momentDate = startMoment.clone().add(i, 'month');
      const key = momentDate.format('YYYY-MM');
      chartDataMonth[key] = {
        label: momentDate.format('M/YY'), count: 0, negativeCount: 0, positiveCount: 0, positivePercentage: 0, negativePercentage: 0,
      };
    }
    for (let i = 0; i <= weeks; i += 1) {
      const momentDate = startMoment.clone().add(i, 'week').startOf('week');
      const key = momentDate.format('YYYY-w');
      // console.log(key, moment(key, 'YYYY-w').startOf('week'), endMoment);
      if (momentDate > endMoment) break;
      chartDataWeek[key] = {
        label: `${moment(key, 'YYYY-w').startOf('week').format('M/D/YY')} - ${moment(key, 'YYYY-w').endOf('week').format('M/D/YY')}`,
        count: 0,
        negativeCount: 0,
        // neutralCount: 0,
        positiveCount: 0,
        positivePercentage: 0,
        negativePercentage: 0,
      };
    }


    periodsData.comments.forEach(({
      label, count, negativeCount, positiveCount,
    }) => {
      const week = moment(label).format('YYYY-w');
      const byMonthKey = label.substring(0, 7);
      chartData[label] = sumAll({
        count, negativeCount, positiveCount,
      }, chartData[label]);
      chartDataMonth[byMonthKey] = sumAll({
        count, negativeCount, positiveCount, label: moment(label).format('M/YY'),
      }, chartDataMonth[byMonthKey]);
      chartDataWeek[week] = sumAll({
        count, negativeCount, positiveCount,
      }, chartDataWeek[week]);
    });

    if (total === 'total') {
      let beforeValue = {
        count: 0, negativeCount: 0, positiveCount: 0,
      };
      Object.keys(chartData).forEach((day) => {
        const valueForDays = chartData[day];
        totalData[day] = sumAll(beforeValue, valueForDays);
        beforeValue = { ...totalData[day] };
      });
    }
    console.log(Object.values(chartData));
    if (total === 'month') return Object.values(chartDataMonth);
    if (total === 'week') return Object.values(chartDataWeek);
    if (total === 'total') return Object.values(totalData);
    return Object.values(chartData);
  }, [selectedOption, periodsData.comments]);
  return (
    <Container className="dashboard">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Analysis </h3>
              </CardTitle>
              <hr />
              <Row className="mt-3" >
                <Col>
                  <Filter
                    setValue={(param, value) => {
                      console.log(value);
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
                      }
                    }}
                    isLoading={loading}
                  />
                </Col>
                <Col sm="auto mt-1">
                  <Tooltip title="Refresh Data">
                    <IconButton size="small" style={{ marginTop: '-3px' }} disabled={loading} onClick={fetchReport}>
                      <RefreshIcon size={30} />
                    </IconButton>
                  </Tooltip>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div
                    className="form-group mt-3"
                    style={{ width: '250px' }}
                  >
                    <Select
                      placeholder="Select Property to Chart..."
                      value={selectedOption}
                      options={dataOptions}
                      onChange={setSelectedOption}
                    />
                  </div>
                </Col>
                <Col />
              </Row>
              <Row className="mt-3">
                <Col md={8}>
                  <FormGroup>
                    <CheckBoxField
                      name="showLabels"
                      label="Show Labels on Chart"
                      value={showLabels}
                      onChange={e => setShowLabels(e.target?.checked || false)}
                      disabled={loading}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  {loading && <Spinner color="primary" size="lg" style={{ position: 'absolute', top: '40%', left: '50%' }} />}
                  <h4 className="font-weight-bold my-3">Percent Positive & Negative Comments</h4>
                  <ChartComponentCommentsPercentage
                    periodsData={commentsStats || []}
                    showLabels={showLabels}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  {loading && <Spinner color="primary" size="lg" style={{ position: 'absolute', top: '40%', left: '50%' }} />}
                  <h4 className="font-weight-bold my-3">Positive and Negative Comments</h4>
                  <ChartComponentCommentsWithSentinement
                    periodsData={commentsStats}
                    showLabels={showLabels}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  {loading && <Spinner color="primary" size="lg" style={{ position: 'absolute', top: '40%', left: '50%' }} />}
                  <h4 className="font-weight-bold my-3">Total Comments</h4>
                  <ChartComponentCommentsTotal
                    periodsData={commentsStats}
                    showLabels={showLabels}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={6} md={12}>
                  {loading && <Spinner color="primary" size="lg" style={{ position: 'absolute', top: '40%', left: '50%' }} />}
                  <h4 className="font-weight-bold my-3">Top 10 Creators with Highest Percentage of Positive Comments</h4>
                  <ChartComponentCreatorsPositive
                    periodsData={periodsData.influencersPositive}
                  />
                </Col>
                <Col lg={6} md={12}>
                  {loading && <Spinner color="primary" size="lg" style={{ position: 'absolute', top: '40%', left: '50%' }} />}
                  <h4 className="font-weight-bold my-3">Top 10 Creators with Highest Percentage of Negative Comments</h4>
                  <ChartComponentCreatorsNegative
                    periodsData={periodsData.influencersNegative}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={6} md={12}>
                  {loading && <Spinner color="primary" size="lg" style={{ position: 'absolute', top: '40%', left: '50%' }} />}
                  <h4 className="font-weight-bold my-3">Top 10 Videos with the Highest Percentage of Positive Comments</h4>
                  <ChartComponentVideosPositive
                    periodsData={periodsData.mediaContentPositive}
                  />
                </Col>
                <Col lg={6} md={12}>
                  {loading && <Spinner color="primary" size="lg" style={{ position: 'absolute', top: '40%', left: '50%' }} />}
                  <h4 className="font-weight-bold my-3">Top 10 Videos with the Highest Percentage of Negative Comments</h4>
                  <ChartComponentVideosNegative
                    periodsData={periodsData.mediaContentNegative}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};
export default withNotice(SummaryReport);
