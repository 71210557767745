/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Col, Row, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import MinusIcon from 'mdi-react/MinusIcon';
import PropTypes from 'prop-types';

const IntervalDatePickerField = React.memo(({
  onChange,
  minDate,
  maxDate,
  startDate,
  endDate,
  size,
  titleFrom,
  titleTo,
  placeholderFrom,
  placeholderTo,
  verticalFields,
  disabled,
  datePickerCustomClass,
}) => {
  const [stateStartDate, setStateStartDate] = useState(startDate);
  const [stateEndDate, setStateEndDate] = useState(endDate);

  useEffect(() => {
    setStateStartDate(startDate);
  }, [startDate]);
  useEffect(() => {
    setStateEndDate(endDate);
  }, [endDate]);

  const getStyles = () => {
    if (verticalFields) {
      return 12;
    }
    if (size !== 'sm' && size !== 'lg') {
      return 5;
    }
    return 6;
  };

  const handleChange = ({ startDateChanged, endDateChanged }) => {
    startDateChanged = startDateChanged || startDateChanged === null ? startDateChanged : stateStartDate;
    endDateChanged = endDateChanged || endDateChanged === null ? endDateChanged : stateEndDate;

    setStateStartDate(startDateChanged);
    setStateEndDate(endDateChanged);
    onChange({ start: startDateChanged, end: endDateChanged });
  };

  const handleChangeStart = startDateChanged => handleChange({ startDateChanged });

  const handleChangeEnd = endDateChanged => handleChange({ endDateChanged });

  return (
    <Row
      className="date-picker date-picker--interval"
    >
      <Col
        sm={getStyles()}
        style={{ marginBottom: verticalFields ? '10px' : 0 }}
      >
        {titleFrom && (<Label>{titleFrom}</Label>)}
        <DatePicker
          selected={startDate}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={maxDate}
          onChange={handleChangeStart}
          dateFormat="MMM d, yyyy"
          placeholderText={placeholderFrom}
          dropDownMode="select"
          className={`${datePickerCustomClass || 'datepicker-in-prospects'} form-control ${size ? `form-control-${size}` : ''} ${size === 'lg' ? 'font-size-custom' : ''}`}
          isClearable
          disabled={disabled}
        />
        {startDate === null ? (
          <svg
            className="mdi-icon"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
            style={{
              position: 'absolute',
              top: `${datePickerCustomClass ? '8px' : '13px'}`,
              right: '22px',
              fill: '#999',
              pointerEvents: 'none',
            }}
          >
            <path
              d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
            />
          </svg>) : null}
      </Col>
      {size !== 'sm' && size !== 'lg' ? (
        <Col
          sm="2"
          className="d-flex justify-content-center"
        >
          <MinusIcon className="date-picker__svg" />
        </Col>) : null
      }
      <Col
        sm={getStyles()}
      >
        {titleTo && (<Label>{titleTo}</Label>)}
        <DatePicker
          selected={endDate}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={maxDate}
          onChange={handleChangeEnd}
          dateFormat="MMM d, yyyy"
          placeholderText={placeholderTo}
          dropDownMode="select"
          className={`${datePickerCustomClass || 'datepicker-in-prospects'} form-control ${size ? `form-control-${size}` : ''} ${size === 'lg' ? 'font-size-custom' : ''}`}
          isClearable
          disabled={disabled}
        />
        {endDate === null ? (
          <svg
            className="mdi-icon"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
            style={{
              position: 'absolute',
              top: `${datePickerCustomClass ? '8px' : '13px'}`,
              right: '22px',
              fill: '#999',
              pointerEvents: 'none',
            }}
          >
            <path
              d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
            />
          </svg>) : null}
      </Col>
    </Row>
  );
}, (a, a1) =>
  a.startDate === a1.startDate &&
  a.endDate === a1.endDate &&
  a.disabled === a1.disabled);

IntervalDatePickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  minDate: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  maxDate: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  startDate: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  endDate: PropTypes.object,
  size: PropTypes.string,
  titleFrom: PropTypes.string,
  titleTo: PropTypes.string,
  placeholderFrom: PropTypes.string,
  placeholderTo: PropTypes.string,
  verticalFields: PropTypes.bool,
  disabled: PropTypes.bool,
  datePickerCustomClass: PropTypes.string,
};

IntervalDatePickerField.defaultProps = {
  minDate: null,
  maxDate: null,
  startDate: null,
  endDate: null,
  size: null,
  titleFrom: null,
  titleTo: null,
  placeholderFrom: 'From',
  placeholderTo: 'To',
  verticalFields: false,
  disabled: false,
  datePickerCustomClass: null,
};

export default IntervalDatePickerField;
