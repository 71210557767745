import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  Button,
} from 'reactstrap';
import Select from 'react-select';
import { getConstant } from '../../../../../shared/helpers/WVConstants';
import withNotice from '../../../../App/store/with-notice';

const UpdateStatusModal = ({
  isOpen,
  closeFunc,
  setVersion,
  selectedIds,

}) => {
  const aValues = getConstant('prospect', 'brandStatuses', {});
  const [value, setValue] = useState(null);
  const [disabled, setDisable] = useState(false);
  const [error, setError] = useState(null);
  const options = Object.keys(aValues).map(k => ({ value: k, label: aValues[k] }));
  // console.log(aValues);
  const handleChange = async (option) => {
    setValue(option.value);
  };
  const handleSubmit = async () => {
    setDisable(true);
    setError(null);
    try {
      const res = await axios.post('/api/brand/prospects/post-update-status-bulk', {
        prospectIds: selectedIds,
        status: value,
      });
      setDisable(false);
      if (!res.data.success && res.data.error) {
        throw new Error('Unable to save Statuses');
      }
      closeFunc();
      setVersion((new Date()).toString());
    } catch (e) {
      setError(e.message);
      setDisable(false);
    }
    setDisable(false);
    return true;
  };
  // console.log(isOpen);
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => !disabled && closeFunc()}
    >
      <ModalHeader toggle={() => !disabled && closeFunc()} tag="h4" className="modal-title-statistics">Update Status</ModalHeader>
      <div className="px-3">
        <p className="py-3">Select status to update {selectedIds.length || 0} record{selectedIds.length > 1 ? 's' : ''}</p>
        <Select
          value={options.filter(option => option.value === value)}
          options={options}
          onChange={handleChange}
          placeholder="Brand Status"
          isDisabled={disabled}
          menuPlacement="auto"
        />
        {error ? (<span className="text-danger">{error}</span>) : null}
      </div>
      <ModalFooter className="modal__footer">
        <Button
          className="btn-sm"
          disabled={disabled}
          color="secondary"
          onClick={() => !disabled && closeFunc()}
        >
          Close
        </Button>
        <Button
          className="btn-sm"
          color="primary"
          onClick={() => handleSubmit()}
          active={!disabled}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};


UpdateStatusModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(UpdateStatusModal);
