import React from 'react';
import PropTypes from 'prop-types';
// import Icon from 'mdi-react/NoteOutlineIcon';
import Tooltip from '@material-ui/core/Tooltip';

const HubspotLinkIcon = (props) => {
  const { contact } = props;
  return (
    <span>
      {contact.params.hsContactVid && (
        <Tooltip title="Show HubSpot Contact">
          <a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${contact.params.hsContactVid}/`} target="_blank" rel="noreferrer noopener">
            <button className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall">
              <i className="fab fa-hubspot" style={{ width: '15px', height: '15px' }} />
              {/* <Icon style={{ width: '15px', height: '15px' }} /> */}
            </button>
          </a>
        </Tooltip>
      )}
    </span>
  );
};

HubspotLinkIcon.propTypes = {
  contact: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default HubspotLinkIcon;
