/* eslint react/prop-types: 0 */
import React from 'react';
import { Badge } from 'reactstrap';

export default (props) => {
  const { record } = props;
  let color = 'secondary';
  switch (record.params.status) {
    case 'done':
      color = 'success';
      break;
    case 'inprocess':
      color = 'warning';
      break;
    case 'new':
      color = 'secondary';
      break;
    default:
      color = 'secondary';
  }
  return (
    <Badge color={color}>{record.params.status}</Badge>
  );
};
