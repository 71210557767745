import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from '../topbar/TopbarProfile';
// import TopbarNotification from './TopbarNotification';
import TopbarNav from './tobar_nav/TopbarNav';

class TopbarWithNavigation extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    session: PropTypes.objectOf(PropTypes.any).isRequired,
    paths: PropTypes.objectOf(PropTypes.any).isRequired,
    creatorsLink: PropTypes.string.isRequired,
  };

  render() {
    const {
      changeMobileSidebarVisibility,
      session,
      paths,
      creatorsLink,
    } = this.props;

    return (
      <div className="topbar topbar--navigation">
        <div className="topbar__wrapper">
          <div className="topbar__left" style={{ width: '200px' }}>
            <TopbarSidebarButton changeMobileSidebarVisibility={changeMobileSidebarVisibility} />
            <Link className="topbar__logo" to={creatorsLink} />
          </div>
          <TopbarNav />
          <div className="topbar__right">
            <TopbarProfile
              session={session}
              paths={paths}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  creatorsLink: state.creators.creatorLink,
});

export default connect(mapStateToProps)(TopbarWithNavigation);
