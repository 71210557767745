/* eslint react/prop-types: 0 */
import React from 'react';
import { unflatten } from 'flat';

export default (props) => {
  const { record } = props;
  const params = unflatten(record.params);
  const status = params.dealTerms?.cpa_status;
  if (status) {
    let color = 'grey';
    if (status === 'Active') color = 'green';
    if (status === 'Expired') color = 'red';
    if (status === 'Pending') color = 'darkorange';
    if (status === 'Paid') color = 'blue';
    return (<span style={{ color }}>{status}</span>);
  }
  return '';
};
