// import { unflatten } from 'flat';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// import { connect } from 'react-redux';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  FormGroup,
  Label,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Select from 'react-select';
import { NumericFormat as NumberFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import CheckboxFilter from '../../../../../shared/components/CheckboxFilter';
import CheckboxManyFilter from '../../../../../shared/components/CheckboxManyFilter';
import TopButtonsPeriod from '../../../../admin/Reports/TimePeriodFilterExtended';
import { parseUrl } from '../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../shared/components/CheckBox';
import Stat from '../../../../../shared/components/stat';
import AccessComponent from '../../../../../shared/components/auth/AccessComponent';
import Filter from '../../../../../shared/components/filter/TimePeriodFilter';

/* import {
  updateCreatorFilterItemUpdate,
  updateTopMenuCreatorLink,
} from '../../../../../redux/reducers/brand/CreatorsReducer'; */

const initialChannelsFilters = {
  YouTube: true,
  'YouTube Description Text': true,
  Podcast: true,
  'Podcast Script Text': true,
  'Instagram Story': true,
  'Instagram Post': true,
  'Instagram TV Video': true,
  TikTok: true,
  'Facebook Post': true,
  'Facebook Watch Video': true,
  'Twitter Post': true,
  LinkTree: true,
  Website: true,
  Email: true,
  Text: true,
};
const specialCriteriaFilters = {
  'Oscar Worthy': true,
  'Not Oscar Worthy': true,
};
const getFilterValueInitial = (filterType) => {
  let initialFilterType = '<=';
  const type = new URLSearchParams(window.location.search).get(filterType);
  if (type) {
    initialFilterType = type;
  }
  return initialFilterType;
};

const Actions = React.memo(({
  history,
  showTable,
}) => {
  const getSingleAvgScoreInitial = () => {
    let singleAvgScore = '';
    const filters = parseUrl();
    const type = new URLSearchParams(window.location.search).get('averageScoreType');
    if (type && type !== 'Between') {
      if (type === '>=' || type === '=') {
        if (filters?.['averageScore~~min']) {
          singleAvgScore = filters?.['averageScore~~min'];
        }
      }
      if (type === '>') {
        if (filters?.['averageScore~~min']) {
          singleAvgScore = Number(filters['averageScore~~min']) - 1;
        }
      }
      if (type === '<=') {
        singleAvgScore = filters?.['averageScore~~max'];
      }
      if (type === '<') {
        singleAvgScore = Number(filters['averageScore~~max']) + 1;
      }
    }
    return parseInt(singleAvgScore, 10);
  };
  const session = useSelector(state => state.session);
  const selectedInitialRange = session.role === 'auditor' ? '7_days' : 'current_month';
  const selectedStartDateInitialState = session.role === 'auditor' ? moment().subtract(8, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss') : moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
  const selectedEndDateInitialState = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
  const averageScoreFilterType = getFilterValueInitial('averageScoreType');
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({ type: initialChannelsFilters, special: specialCriteriaFilters });
  const [dateSearch, setDateSearch] = useState(null);
  const [averageScoreTypeValue, setAverageScoreTypeValue] = useState({ value: averageScoreFilterType, label: averageScoreFilterType });
  const [singleAvgScore, setSingleAvgScore] = useState(getSingleAvgScoreInitial());
  const [enableAverageScoreFilter, setEnableAverageScoreFilter] = useState(false);
  const [minAvgScore, setMinAvgScore] = useState(filters?.['averageScore~~min'] && averageScoreFilterType === 'Between' ? parseInt(filters['averageScore~~min'], 10) : '');
  const [maxAvgScore, setMaxAvgScore] = useState(filters?.['averageScore~~max'] && averageScoreFilterType === 'Between' ? parseInt(filters['averageScore~~max'], 10) : '');
  const [startDate, setStartDate] = useState(selectedStartDateInitialState);
  const [endDate, setEndDate] = useState(selectedEndDateInitialState);
  const [selectedButton, setSelectedButton] = useState(selectedInitialRange);
  const [url, setUrlU] = useState('');
  const [loadingTotalsUrl, setLoadingTotalsUrl] = useState(null);
  const [dateUrl, setDateUrl] = useState(null);

  const averageScoreLabels = [
    { value: '=', label: '=' },
    { value: '>', label: '>' },
    { value: '>=', label: '>=' },
    { value: '<', label: '<' },
    { value: '<=', label: '<=' },
    { value: 'Between', label: 'Between' },
  ];
  /* useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      dispatch(updateCreatorFilterItemUpdate('search', search));
    }, 1000));
  }, [search]); */

  const getUrlSearch = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', '1');
    if (search) {
      searchParams.set('filters.fullSearchName', search);
    } else {
      searchParams.delete('filters.fullSearchName');
    }
    if (filters && filters.type && Object.keys(filters.type).length > 0) {
      searchParams.delete('filters.type');
      Object.keys(filters.type).forEach((key) => {
        if (!filters.type[key]) return false;
        searchParams.append('filters.type', key);
        return true;
      });
    }
    if (filters && filters.special && Object.keys(filters.special).length > 0) {
      searchParams.delete('filters.special');
      Object.keys(filters.special).forEach((key) => {
        if (!filters.special[key]) return false;
        searchParams.append('filters.special', key);
        return true;
      });
    }
    return searchParams;
  };

  const setUrl = (/* setFirstPage = false */) => {
    const urlSearch = getUrlSearch();
    urlSearch.delete('filters.createdAt~~from');
    urlSearch.delete('filters.createdAt~~to');
    urlSearch.delete('range');
    if (startDate !== '') {
      urlSearch.append('filters.createdAt~~from', startDate);
    }
    if (endDate !== '') {
      urlSearch.append('filters.createdAt~~to', endDate);
    }
    urlSearch.append('range', selectedButton);
    urlSearch.delete('filters.averageScore~~min');
    urlSearch.delete('filters.averageScore~~max');
    urlSearch.delete('averageScoreType');
    if (minAvgScore && maxAvgScore && averageScoreTypeValue.value === 'Between') {
      urlSearch.append('filters.averageScore~~min', minAvgScore);
      urlSearch.append('filters.averageScore~~max', maxAvgScore);
    }
    if (singleAvgScore) {
      if (averageScoreTypeValue.value === '>') {
        urlSearch.append('filters.averageScore~~min', Number(singleAvgScore) + 1);
      }
      if (averageScoreTypeValue.value === '>=') {
        urlSearch.append('filters.averageScore~~min', singleAvgScore);
      }
      if (averageScoreTypeValue.value === '<') {
        urlSearch.append('filters.averageScore~~max', Number(singleAvgScore) - 1);
      }
      if (averageScoreTypeValue.value === '<=') {
        urlSearch.append('filters.averageScore~~max', singleAvgScore);
      }
      if (averageScoreTypeValue.value === '=') {
        urlSearch.append('filters.averageScore~~min', singleAvgScore);
        urlSearch.append('filters.averageScore~~max', singleAvgScore);
      }
    }
    if (averageScoreTypeValue.value && ((minAvgScore && maxAvgScore) || singleAvgScore)) {
      setEnableAverageScoreFilter(true);
      urlSearch.append('averageScoreType', averageScoreTypeValue.value);
    }
    urlSearch.delete('filters.urlStatus');
    urlSearch.append('filters.urlStatus', 'Approved');
    urlSearch.append('filters.urlStatus', 'Not Set');
    urlSearch.delete('filters.brandStatus');
    urlSearch.append('filters.brandStatus', 'Approved');
    urlSearch.delete('filters.status');
    urlSearch.append('filters.status', 'Brand Approved');
    setUrlU(urlSearch.toString());
    setLoadingTotalsUrl(`/api/brand/content/get-video-totals?${urlSearch.toString()}`);
  };
  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      setUrl();
    }, 1000));
  }, [search]);
  useEffect(() => {
    if (dateUrl) {
      clearTimeout(dateUrl);
    }
    setDateUrl(setTimeout(() => {
      setTimeout(() => showTable(true));
      history.push(`${history.location.pathname}?${url}`);
    }, 500));
  }, [url]);
  useEffect(() => {
    setUrl(true);
  }, [filters, singleAvgScore, minAvgScore, maxAvgScore, startDate, endDate, averageScoreTypeValue]);
  const handleChangeAverageScoreCheckBox = ({ target }) => {
    setEnableAverageScoreFilter(!enableAverageScoreFilter);
    if (!target.checked) {
      setSingleAvgScore('');
      setMinAvgScore('');
      setMaxAvgScore('');
    }
  };
  const customStyles = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? '#e9ecef' : 'white',
      marginTop: '0px',
      // height: '31px',
      // minHeight: '31px',
    }),
  };

  return (
    <div>
      <Row>
        <Col className="ml-3">
          <AccessComponent showWhenRole={['owner']}>
            <TopButtonsPeriod
              selectedButtonInitial={selectedButton}
              startDateInitial={selectedStartDateInitialState}
              endDateInitial={selectedEndDateInitialState}
              setValue={(param, value, range) => {
                if (param === 'startDate') {
                  setStartDate(moment(value).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
                }
                if (param === 'endDate') {
                  setEndDate(moment(value).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
                }
                setSelectedButton(range);
              }}
            />
          </AccessComponent>
          <AccessComponent showWhenRole={['auditor']}>
            <Filter
              startDateInitial={selectedStartDateInitialState}
              endDateInitial={selectedEndDateInitialState}
              selectedButtonInitial={selectedButton}
              setValue={(param, value, range) => {
                if (param === 'startDate') {
                  setStartDate(moment(value).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
                }
                if (param === 'endDate') {
                  setEndDate(moment(value).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
                }
                setSelectedButton(range);
              }}
              options={[
                { value: '30_days', label: '30 Days', border: '5px 0 0 5px' },
                { value: '7_days', label: 'Last 7 Days' },
                {
                  value: 'today', label: 'Today', border: '0 5px 5px 0', lastElement: true,
                },
              ]}
              selectDateRangeFunction={(range) => {
                let dFrom;
                let dTo;
                let showCustom = false;
                switch (range) {
                  case '30_days':
                    showCustom = false;
                    dFrom = moment().subtract(31, 'days').startOf('day').toDate();
                    dTo = moment().subtract(1, 'day').endOf('day').toDate();
                    break;
                  case '7_days':
                    showCustom = false;
                    dFrom = moment().subtract(8, 'days').startOf('day').toDate();
                    dTo = moment().subtract(1, 'day').endOf('day').toDate();
                    break;
                  case 'today':
                    showCustom = false;
                    dFrom = moment().startOf('day').toDate();
                    dTo = moment().endOf('day').toDate();
                    break;
                  default:
                    dFrom = moment().startOf('day').toDate();
                    dTo = moment().endOf('day').toDate();
                    break;
                }
                // console.log({ selectedStartDateInitialState, selectedEndDateInitialState, showCustom });
                return { dateFrom: dFrom, dateTo: dTo, showCustom };
              }
              }
            />
          </AccessComponent>
        </Col>
      </Row>
      <Stat
        labels={{
          count: 'No. Videos',
          views: 'No. Views',
        }}
        url={loadingTotalsUrl}
      />
      <Row>
        <Col sm="auto">
          <div className="float-left my-1" style={{ width: '3px' }} />
          <CheckboxManyFilter
            filterProp={filters.type}
            filterTitle="Type"
            filterAction={({ data }) => setFilters(f => ({ ...f, type: data }))}
          />
          <CheckboxFilter
            filterProp={filters.special}
            filterTitle="Oscar Worthy"
            filterAction={({ data }) => setFilters(f => ({ ...f, special: data }))}
          />
        </Col>
        <Col sm="auto">
          <FormGroup
            style={{ paddingTop: '11px' }}
          >
            <Label>Average Score:</Label>
          </FormGroup>
        </Col>
        <Col sm="auto">
          <FormGroup
            style={{ paddingTop: '11px' }}
          >
            <CheckBoxField
              name="averageScoreCheckBox"
              value={enableAverageScoreFilter}
              onChange={e => e.target && handleChangeAverageScoreCheckBox(e)}
            />
          </FormGroup>
        </Col>
        <Col style={{ paddingLeft: '0px', minWidth: '120px' }} sm="auto">
          <Select
            value={averageScoreTypeValue}
            onChange={setAverageScoreTypeValue}
            options={averageScoreLabels}
            isDisabled={!enableAverageScoreFilter}
            styles={customStyles}
          />
        </Col>
        <Col sm="auto">
          {averageScoreTypeValue.value === 'Between' ? (
            <>
              <NumberFormat
                key="averageMin"
                disabled={!enableAverageScoreFilter}
                thousandSeparator
                prefix=""
                name="what"
                value={minAvgScore}
                className="form-control-sm form-control mt-1"
                placeholder="Min Avg Score"
                inputMode="numeric"
                allowEmptyFormatting={false}
                fixedDecimalScale
                decimalScale={0}
                onValueChange={values => setMinAvgScore(values.floatValue)}
                allowNegative={false}
                onBlur={() => {
                  if (
                    minAvgScore
                    && maxAvgScore
                    && (minAvgScore > maxAvgScore)
                  ) {
                    setMaxAvgScore(minAvgScore + 1);
                  }
                }}
              />
              <NumberFormat
                key="scoreMax"
                disabled={!enableAverageScoreFilter}
                thousandSeparator
                prefix=""
                name="what"
                value={maxAvgScore}
                className="form-control-sm form-control mt-1"
                style={{ marginTop: '16px' }}
                placeholder="Max Avg Score"
                inputMode="numeric"
                allowEmptyFormatting={false}
                fixedDecimalScale
                decimalScale={0}
                onValueChange={values => setMaxAvgScore(values.floatValue)}
                allowNegative={false}
                onBlur={() => {
                  if (
                    minAvgScore
                    && maxAvgScore
                    && (minAvgScore > maxAvgScore)
                  ) {
                    setMinAvgScore(maxAvgScore - 1);
                  }
                }}
              />
            </>
          ) : (
            <NumberFormat
              key="singleAvgScore"
              disabled={!enableAverageScoreFilter}
              thousandSeparator
              prefix=""
              name="what"
              value={singleAvgScore}
              className="form-control-sm form-control mt-1"
              placeholder="Average Score"
              inputMode="numeric"
              allowEmptyFormatting={false}
              fixedDecimalScale
              decimalScale={0}
              onValueChange={values => setSingleAvgScore(values.floatValue)}
              allowNegative={false}
            />
          )}
        </Col>
        <Col>
          <UncontrolledButtonDropdown className="mt-2 float-right">
            <DropdownToggle caret className="mr-2 time-period-filter-buttons-extended active btn-sm" color="primary">
              Actions
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => window.open(`/resources/content/download-csv?${getUrlSearch().toString()}`, '_blank').focus()}>Download CSV</DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <InputGroup>
            <Input
              className="form-control-sm form-control ml-3"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Creator Name, Email, Title"
              value={search}
              onChange={p => setSearch(p.target.value)}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </Row>
    </div>
  );
}); // &&
  // Object.keys(a.matchFilters).length === Object.keys(a1.matchFilters).length);

Actions.propTypes = {
  showTable: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};
export default Actions;
