/* eslint react/prop-types: 0 */
import React from 'react';
import { Badge } from 'reactstrap';

export default ({ record }) => {
  // let colorClass = 'primary';
  let colorClass = 'danger';
  let text = 'NOT REPLIED';
  if (record.params.replyStatus === 'ourself' || record.params.replyStatus === 'user' || record.params.replyStatus === 'adminUser' || record.params.replyStatus === 'sequencer') {
    colorClass = 'success';
    text = 'REPLIED';
  }
  return (
    <Badge
      color={colorClass}
      style={{
        padding: '6px 10px 6px 10px',
        marginTop: '4px',
        borderRadius: '15px',
      }}
    >
      {text}
    </Badge>
  );
};
