// import { unflatten } from 'flat';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from 'reactstrap';
import CheckboxFilter from '../../../../../shared/components/CheckboxFilter';
import {
  updateCreatorFilterItemUpdate,
  updateTopMenuCreatorLink,
} from '../../../../../redux/reducers/brand/CreatorsReducer';

const Table = React.memo(({
  filters,
  dispatch,
  history,
}) => {
  const [search, setSearch] = useState('');
  const [dateSearch, setDateSearch] = useState(null);
  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      dispatch(updateCreatorFilterItemUpdate('search', search));
    }, 1000));
  }, [search]);

  const getUrlSearch = () => {
    const searchParams = new URLSearchParams(window.location.search);
    if (search) {
      searchParams.set('filters.fullSearchName', search);
    } else {
      searchParams.delete('filters.fullSearchName');
    }
    if (filters && filters.compliantFilter && Object.keys(filters.compliantFilter).length > 0) {
      searchParams.delete('filters.compliant');
      Object.keys(filters.compliantFilter).forEach((key) => {
        if (!filters.compliantFilter[key]) return false;
        searchParams.append('filters.compliant', key);
        return true;
      });
    }
    if (filters && filters.publishedFilter && Object.keys(filters.publishedFilter).length > 0) {
      searchParams.delete('filters.published');
      Object.keys(filters.publishedFilter).forEach((key) => {
        if (!filters.publishedFilter[key]) return false;
        searchParams.append('filters.published', key);
        return true;
      });
    }
    if (filters && filters.creatorType && Object.keys(filters.creatorType).length > 0) {
      searchParams.delete('filters.creatorType');
      Object.keys(filters.creatorType).forEach((key) => {
        if (!filters.creatorType[key]) return false;
        searchParams.append('filters.creatorType', key);
        return true;
      });
    }
    return searchParams;
  };

  const setUrl = (setFirstPage = false) => {
    const urlSearch = getUrlSearch();
    if (setFirstPage) {
      urlSearch.set('page', '1');
    }
    const strSearch = urlSearch.toString();
    dispatch(updateTopMenuCreatorLink(`/creators/list?${strSearch}`));
    history.push(`${history.location.pathname}?${strSearch}`);
  };

  useEffect(() => {
    setUrl(true);
  }, [filters]);

  return (
    <div>
      <Row>
        <Col md={6}>
          <div className="float-left my-1" style={{ width: '3px' }} />
          <CheckboxFilter
            filterProp={filters.compliantFilter}
            filterTitle="Compliant"
            filterAction={({ data }) => dispatch(updateCreatorFilterItemUpdate('compliantFilter', data))}
          />
          <CheckboxFilter
            filterProp={filters.publishedFilter}
            filterTitle="Published"
            filterAction={({ data }) => dispatch(updateCreatorFilterItemUpdate('publishedFilter', data))}
          />
          <CheckboxFilter
            filterProp={filters.creatorType}
            filterTitle="Creator Type"
            filterAction={({ data }) => dispatch(updateCreatorFilterItemUpdate('creatorType', data))}
          />
        </Col>
        <Col>
          <UncontrolledButtonDropdown className="mt-2 float-right">
            <DropdownToggle caret className="mr-2" color="primary">
              Actions
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => window.open(`/resources/influencers/download-csv?${getUrlSearch().toString()}`, '_blank').focus()}>Download CSV</DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <InputGroup>
            <Input
              className="form-control-sm form-control ml-3"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search names, emails, channel urls or usernames"
              value={search}
              onChange={p => setSearch(p.target.value)}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </Row>
    </div>
  );
}); // &&
  // Object.keys(a.matchFilters).length === Object.keys(a1.matchFilters).length);

Table.propTypes = {
  dispatch: PropTypes.func.isRequired,
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};
export default connect(state => ({
  filters: state.creators.filters.creators,
}))(Table);
