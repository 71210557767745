import React, { useState, forwardRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import CalendarIcon from 'mdi-react/CalendarIcon';
import { getLast7Days, getLast7DaysIncludingToday, getLastNDays } from '../../helpers/Dates';

const TimePeriodFilter = ({
  setValue,
  isLoading,
  selectedButtonInitial,
  startDateInitial,
  endDateInitial,
  options,
  title,
  selectDateRangeFunction,
  minDate,
  includeToday,
}) => {
  const [showCustom, setShowCustom] = useState(selectedButtonInitial === 'custom');
  const [selectedButton, setSelectedButton] = useState(selectedButtonInitial || 'year_to_date');
  const [startDate, setStartDate] = useState(startDateInitial ? moment(startDateInitial).toDate() : moment().startOf('month').toDate());
  const [endDate, setEndDate] = useState(endDateInitial ? moment(endDateInitial).toDate() : moment().endOf('month').toDate());
  const selectDateRange = (range) => {
    let dateFrom;
    let dateTo;
    let showCustomDatefields = false;
    if (selectDateRangeFunction) {
      ({ dateFrom, dateTo, showCustom: showCustomDatefields } = selectDateRangeFunction(range));
      setShowCustom(showCustomDatefields);
    } else {
      switch (range) {
        case 'today':
          setShowCustom(false);
          dateFrom = moment().startOf('day').toDate();
          dateTo = moment().endOf('day').toDate();
          break;
        case 'yesterday':
          setShowCustom(false);
          dateFrom = moment().subtract(1, 'day').startOf('day').toDate();
          dateTo = moment().subtract(1, 'day').endOf('day').toDate();
          break;
        case 'current_month':
          setShowCustom(false);
          dateFrom = moment().startOf('month').toDate();
          dateTo = moment().endOf('month').toDate();
          break;
        case 'last_month':
          setShowCustom(false);
          dateFrom = moment().subtract(1, 'month').startOf('month').toDate();
          dateTo = moment().subtract(1, 'month').endOf('month').toDate();
          break;
        case '6_month':
          setShowCustom(false);
          dateFrom = moment().subtract(5, 'month').startOf('month').toDate();
          dateTo = moment().endOf('day').toDate();
          break;
        case 'last_7_days':
          setShowCustom(false);
          [dateFrom, dateTo] = includeToday ? getLast7DaysIncludingToday() : getLast7Days();
          break;
        case 'last_30_days':
          setShowCustom(false);
          [dateFrom, dateTo] = getLastNDays(30, includeToday);
          break;
        case 'year_to_date':
          setShowCustom(false);
          dateFrom = moment().startOf('Year').toDate();
          dateTo = moment().endOf('month').toDate();
          break;
        case 'all_time':
          setShowCustom(false);
          dateFrom = moment('2019-01-01').startOf('day').toDate();
          dateTo = moment().endOf('day').toDate();
          break;
        case 'custom':
          setShowCustom(true);
          dateFrom = moment().startOf('Year').toDate();
          dateTo = moment().endOf('day').toDate();
          break;
        default:
          dateFrom = moment().startOf('month').toDate();
          dateTo = moment().endOf('month').toDate();
          break;
      }
    }
    setStartDate(dateFrom);
    setEndDate(dateTo);
    setValue('startDate', dateFrom, range);
    setValue('endDate', dateTo, range);
    setSelectedButton(range);
  };
  const getText = () => {
    let dateText;
    if (
      moment(startDate).format('M') === moment(endDate).format('M') &&
      moment(startDate).format('D') === moment(endDate).format('D') &&
      moment(startDate).format('YYYY') === moment(endDate).format('YYYY')) {
      dateText = moment(startDate).format('MMM D, YYYY');
    } else if (selectedButton === 'current_month' || selectedButton === 'year_to_date') {
      dateText = `${moment(startDate).format('MMM D, YYYY')}  —  ${moment(endDate).endOf('day').format('MMM D, YYYY')}`;
    } else {
      dateText = `${moment(startDate).format('MMM D, YYYY')}  —  ${moment(endDate).format('MMM D, YYYY')}`;
    }
    return dateText;
  };
  const buttonClass = 'btn-sm mr-0 time-period-filter-buttons-extended';
  // eslint-disable-next-line react/prop-types
  const DatePickerCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <div className="form-group mb-2">
      <InputGroup size="md">
        <input
          type="text"
          className="form-control form-control-sm"
          value={value}
          onClick={onClick}
          onChange={onChange}
          style={{
            border: '1px solid #ced4da',
            borderRadius: '5px',
          }}
          ref={ref}
        />
        <InputGroupAddon
          addonType="append"
          style={{
            padding: '3px 0 3px 3px',
          }}
        >
          <CalendarIcon
            className="date-picker-icon"
            onClick={onClick}
          />
        </InputGroupAddon>
      </InputGroup>
    </div>
  ));
  return (
    <div>
      {title && (
        <Row style={{ minWidth: '300px' }}>
          <Col lg="auto">
            <h5 style={{ color: 'black', marginBottom: '8px' }} className="bold-text">{title}</h5>
          </Col>
        </Row>
      )}
      <Row style={{ minWidth: '300px' }}>
        <Col lg="auto">
          {options.map((item) => {
            const style = { borderRadius: item.border || '0' };
            if (item.lastElement) {
              style.borderColor = '#4A69F2';
            } else {
              style.borderRight = '0';
            }
            return (
              <Button
                key={item.value}
                onClick={() => selectDateRange(item.value)}
                className={selectedButton === item.value ? `active ${buttonClass}` : buttonClass}
                style={style}
                size="sm"
                disabled={isLoading}
              >
                {item.label}
              </Button>
            );
          })}
        </Col>
        <Col>
          {showCustom ? (
            <Row>
              <Col xs="auto" className="">
                <div style={{ maxWidth: '150px' }}>
                  <DatePicker
                    id="startDateDatePicker"
                    selected={startDate}
                    onChange={date => setStartDate(date) || setValue('startDate', date)}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    minDate={minDate}
                    placeholderText="Start Date"
                    dropDownMode="select"
                    customInput={<DatePickerCustomInput />}
                    disabled={isLoading}
                  />
                </div>
              </Col>
              <Col xs="auto" className="">
                <div style={{ maxWidth: '150px' }}>
                  <DatePicker
                    id="endDateDatePicker"
                    selected={endDate}
                    onChange={date => setEndDate(date) || setValue('endDate', date)}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    minDate={minDate}
                    placeholderText="End Date"
                    dropDownMode="select"
                    customInput={<DatePickerCustomInput />}
                    disabled={isLoading}
                  />
                </div>
              </Col>
              <Col />
            </Row>) : null
          }
        </Col>
      </Row>
      <Row style={{ minWidth: '300px' }}>
        <Col lg="auto">
          <h5 style={{ color: 'black', marginBottom: '8px' }} className="bold-text">{getText()}</h5>
        </Col>
      </Row>
    </div>
  );
};
TimePeriodFilter.propTypes = {
  setValue: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  selectedButtonInitial: PropTypes.string,
  startDateInitial: PropTypes.string,
  endDateInitial: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string,
  selectDateRangeFunction: PropTypes.func,
  minDate: PropTypes.objectOf(PropTypes.any),
  includeToday: PropTypes.bool,
};
TimePeriodFilter.defaultProps = {
  isLoading: false,
  selectedButtonInitial: 'current_month',
  startDateInitial: null,
  endDateInitial: null,
  options: [
    { value: 'current_month', label: 'Current Month', border: '5px 0 0 5px' },
    { value: 'last_month', label: 'Last Month' },
    { value: 'today', label: 'Today' },
    { value: 'last_7_days', label: 'Last 7 days' },
    { value: 'year_to_date', label: 'Year to Date' },
    {
      value: 'custom', label: 'Custom', border: '0 5px 5px 0', lastElement: true,
    },
  ],
  selectDateRangeFunction: null,
  title: '',
  minDate: moment().subtract(25, 'months').toDate(),
  includeToday: false,
};
export default TimePeriodFilter;
