/* eslint react/prop-types: 0 */
/* eslint no-underscore-dangle: 0 */
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CopyIcon from 'mdi-react/ContentCopyIcon';
import Tooltip from '@material-ui/core/Tooltip';

export default class InfluencerInList extends React.Component {
  constructor(props) {
    super(props);
    this.textRef = React.createRef();
    this.handleCopy = this.handleCopy.bind(this);
  }
  handleCopy = () => {
    const { record } = this.props;
    const textField = document.createElement('textarea');
    textField.innerText = record.populated.influencer.params?.email;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };
  render() {
    const { record } = this.props;
    let name = record.populated.influencer ? record.populated.influencer.params?.publisherName : null;
    if (record.populated.influencer && record.populated.influencer.params?.first_name) {
      name = `${record.populated.influencer.params?.first_name} ${record.populated.influencer.params?.last_name}`;
    }
    return name ? (
      <div>
        {`${name} `}
        <Tooltip title={`Copy ${record.populated.influencer.params?.email} to Clipboard`}>
          <IconButton
            size="small"
            className="material-table__toolbar-button"
            onClick={this.handleCopy}
          >
            <CopyIcon />
          </IconButton>
        </Tooltip>
      </div>
    ) : 'Unable to find Influencer';
  }
}
