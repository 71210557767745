import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  Modal,
  ModalHeader,
  Col,
} from 'reactstrap';
import ProgressBar from '../../../../../shared/helpers/ProgressBarWithObject';
import withNotice from '../../../../App/store/with-notice';

const UpdateAllModal = ({
  isOpen,
  closeFunc,
  setVersion,
  selectedIds,
  addNotice,
}) => {
  const [identity] = useState(Math.random().toString(36).substring(2));
  const [loading, selLoading] = useState(false);
  useEffect(async () => {
    selLoading(true);
    if (!isOpen) return false;
    try {
      const res = await axios.post('/api/brand/prospects/post-fetch-data', {
        prospectsIds: selectedIds,
        identity,
      });
      closeFunc();
      if (!res.data.success && res.data.error) {
        throw new Error('Unable to refresh table');
      }
      setVersion((new Date()).toString());
    } catch (e) {
      addNotice({
        message: e.message,
        type: 'error',
        duration: 10,
      });
      // console.log(e);
    }
    selLoading(false);
    return true;
  }, [isOpen]);
  // console.log(isOpen);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => !loading && closeFunc()}
    >
      <ModalHeader toggle={() => !loading && closeFunc()} tag="h4" className="modal-title-statistics">Refresh Statistics</ModalHeader>
      <div>
        <Col className="py-3">Updating {selectedIds?.length === 0 ? 'all' : selectedIds?.length} record{selectedIds.length === 1 ? '' : 's'}</Col>
        <ProgressBar
          afterFunction={() => true}
          identity={identity}
          topic="prospects"
          isShow
        />
      </div>
    </Modal>
  );
};


UpdateAllModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(UpdateAllModal);
