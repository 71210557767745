/* eslint react/prop-types: 0 */
import React from 'react';
import ContextMenuInList from './ContextMenuInList';
import formater from '../../../../../../shared/helpers/WVFormatter';

export default {
  show: {
  //  status: CampaignStatusInShow,
  },
  list: {
    amount: ({ record }) => formater.formatCurrency(Number(record.params.amount || 0)),
    amountDue: ({ record }) => formater.formatCurrency(Number(record.params.amountDue || 0)),
    createdAt: ({ record }) => formater.getServerDateTime(record.params.createdAt),
    'dealObject.hs_closedate': ({ record }) => formater.getServerDateTime(record.params['dealObject.hs_closedate']),
    invoiceNumber: ({ record }) => console.log(record) || (<span>{record.params.invoiceNumber}{' '}{!record.params['dealObject.hs_object_id'] && (<span className="form__form-group-error">Missing deal for invoice no. {record.params.invoiceNumber}.</span>)}</span>),
  },
  actions: ContextMenuInList,
};
