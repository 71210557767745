/* eslint react/prop-types: 0 */
import React from 'react';
import {
  FormGroup,
  Label,
  Row,
  Col,
} from 'reactstrap';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import NotificationProvider from 'rc-notification';
import { FullWideNotification } from '../../../../../../shared/components/Notification';

let notification = null;
// eslint-disable-next-line no-return-assign
NotificationProvider.newInstance({ style: {} }, n => notification = n);

export default class TextInEdit extends React.Component {
  constructor(props) {
    super(props);
    const { record: { params: { text } } } = this.props;
    this.handleDataChange = this.handleDataChange.bind(this);
    this.state = {
      text,
    };
  }

  handleDataChange(event) {
    const { onChange, enableSaveButton } = this.props;
    const { target: { value } } = event;
    enableSaveButton(false);
    this.setState({ text: value });
    onChange('text', value);
    enableSaveButton(true);
  }

  copyToken = (e) => {
    let textInput = false;
    try {
      textInput = e.nativeEvent.target.closest('.form__form-group-field').querySelector('input');
    } catch (err) {
      textInput = false;
    }

    e.preventDefault();

    if (textInput) {
      textInput.select();
      document.execCommand('copy');

      notification.notice({
        content: <FullWideNotification color="success" title="" message="Copied..." />,
        duration: 1,
        closable: false,
        style: { top: 0, left: 0 },
        className: 'full',
      });
    }
  };

  render() {
    const {
      text,
    } = this.state;

    const tokens = [
      '{{first_name}}',
      '{{last_name}}',
      '{{full_name}}',
    ];

    return (
      <Row>
        <Col md={8}>
          <FormGroup style={{ width: '100%' }}>
            <Label>Email Body</Label>
            <textarea
              style={{ width: '100%', padding: '5px' }}
              name="text"
              value={text}
              onChange={this.handleDataChange}
              rows="15"
            />
          </FormGroup>
        </Col>
        <Col
          md={4}
          style={{ paddingTop: '28px' }}
        >
          <Label>You can use this tokens:</Label>
          <ul
            style={{ padding: '0' }}
          >
            {tokens.map(value => (
              <FormGroup
                key={`div_${value}`}
                className="form__form-group-field"
                style={{ marginTop: '5px' }}
              >
                <input
                  style={{ width: '100%', paddingLeft: '5px' }}
                  readOnly
                  value={value}
                />
                <button
                  className="btn btn-primary btn-sm"
                  type="button"
                  title="Copy"
                  style={{
                    padding: '5px 5px 5px 10px',
                    height: '38px',
                    margin: '0px',
                    borderRadius: '0px 5px 5px 0px',
                  }}
                  onClick={e => this.copyToken(e)}
                ><ContentCopyIcon size={16} />
                </button>
              </FormGroup>
              ))}
          </ul>
        </Col>
      </Row>
    );
  }
}

