import styled, { css } from 'styled-components';
import theme from '../../../../theme';


const styles = css`
&&& {
    font-size: ${theme.fonts.medium};
    border-radius: 0;
    border-color: ${theme.colors.primary};
    background: ${theme.colors.bck};
    height: 34px;
    padding:  ${theme.sizes.padding};
    color: ${theme.colors.primary};
    & i, & svg {
      margin-right: 5px;
    }
    &:hover {
      border-color: ${theme.colors.primaryHover};
    }

    &.is-white {
      background-color: ${theme.colors.bck};
      border-color: transparent;
      color: ${theme.colors.defaultText};
    }

    &.is-primary {
      background-color: ${theme.colors.primary};
      color: #ffffff;
      &:hover {
        background-color: ${theme.colors.primaryHover};
      }
    }

    &.is-text {
      background-color: transparent;
      color: ${theme.colors.primary};
      border: transparent;
    }

    &.in-dropdown {
      color: ${theme.colors.defaultText};
      font-size: ${theme.fonts.base};
      width: 100%;
      text-align: flex-start;
      justify-content: flex-start;
      height: 40px;
      padding-left: 40px;
      border: none;

      &:hover {
        border: 4px ${theme.colors.primary};
        padding-left: 36px;
        border-style: none solid;
      }
    }
  }
`;

const StyledButton = styled('button').attrs({
  className: 'btn btn-primary btn-sm',
})``;

export {
  StyledButton as default,
  styles,
};
