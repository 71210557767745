/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';


/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const modalReducer = createSlice({
  name: 'modals',
  initialState: {
    modals: {},
  },
  reducers: {
    showModal(state, action) {
      const { id, data } = action.payload;
      state.modals[id] = { isShow: true, data };
      // state.modals[id].isShow = true;
      // state.modals[id].data = data;
    },
    hideModal(state, action) {
      const { id } = action.payload;
      state.modals[id].isShow = false; // = { isShow: false, data: {} };
    },
    clearModal(state, action) {
      const { id } = action.payload;
      state.modals[id] = null;
    },
  },
});

export const {
  showModal,
  hideModal,
  clearModal,
} = modalReducer.actions;


export const showModalById = (id, data) => async (dispatch) => {
  try {
    dispatch(showModal({ id, data }));
    // dis
  } catch (err) {
    console.log(err);
  }
};
export const hideModalById = id => async (dispatch) => {
  try {
    dispatch(hideModal({ id }));
    setTimeout(() => dispatch(clearModal({ id })), 500);
    // dis
  } catch (err) {
    console.log(err);
  }
};
export default modalReducer.reducer;
