import { unflatten } from 'flat';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal } from 'reactstrap';

const strip = (htmlString) => {
  const tmp = document.createElement('div');
  tmp.innerHTML = htmlString;

  return tmp.textContent || tmp.innerText;
};

const DescriptionInList = ({ record }) => {
  const recortUnflatten = unflatten(record);
  const { description } = recortUnflatten.params;
  const [showModal, setShowModal] = useState(false);
  const clearDescription = strip(description);
  let result = '';
  if (clearDescription.length > 50) {
    result = clearDescription.slice(0, 50);
    result += '...';
  } else {
    result = clearDescription;
  }

  const toggleDialog = (e) => {
    e.preventDefault();
    setShowModal(!showModal);
  };
  return (
    <>
      <span>
        {result}
        &nbsp;&nbsp;&nbsp;
        {result && clearDescription.length > 50 &&
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a href="#" onClick={() => { setShowModal(true); }}><i className="fas fa-external-link-alt" /></a>
        }
      </span>
      <Modal
        isOpen={showModal}
        toggle={toggleDialog}
      >
        <div className="modal__header">
          <h4 className="text-modal  modal__title">Description</h4>
        </div>
        <div className="modal__body">{clearDescription}</div>
        <ButtonToolbar className="modal__footer">
          <Button className="btn btn-primary btn-sm" onClick={toggleDialog}>Close</Button>
        </ButtonToolbar>
      </Modal>
    </>
  );
};
DescriptionInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default DescriptionInList;

