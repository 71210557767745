/* eslint react/prop-types: 0 */
/* eslint-disable no-prototype-builtins */
import React from 'react';
import {
  Popover,
  PopoverHeader,
  PopoverBody,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  FormFeedback,
} from 'reactstrap';
import EyeIcon from 'mdi-react/EyeIcon';
import WVValidator from '../../../../../../shared/helpers/WVValidator';

export default class PasswordInEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: this.props.password,
      newPassword: '',
      confirmNewPassword: '',
      showNewPassword: false,
      showConfirmNewPassword: false,
      errors: {},
      isLoadingData: false,
      popoverOpen: false,
    };
  }

  onNewPasswordChanged = (event) => {
    const { value } = event.target;

    this.setState(
      prevState => ({ ...prevState, newPassword: value }),
      () => {
        const { onChange, enableSaveButton } = this.props;
        const { confirmNewPassword } = this.state;

        if (!this.validatePassword(value, confirmNewPassword)) {
          enableSaveButton(false);
          return false;
        }

        this.setState(prevState => ({ ...prevState, isLoadingData: true }));
        onChange('password', value);
        this.setState(prevState => ({ ...prevState, isLoadingData: false }));
        enableSaveButton(true);

        return true;
      },
    );
  };

  onConfirmNewPasswordChanged = (event) => {
    const { value } = event.target;

    this.setState(
      prevState => ({ ...prevState, confirmNewPassword: value }),
      () => {
        const { onChange, enableSaveButton } = this.props;
        const { newPassword } = this.state;

        if (!this.validatePassword(newPassword, value)) {
          enableSaveButton(false);
          return false;
        }

        this.setState(prevState => ({ ...prevState, isLoadingData: true }));
        onChange('password', newPassword);
        this.setState(prevState => ({ ...prevState, isLoadingData: false }));
        enableSaveButton(true);

        return true;
      },
    );
  };

  validatePassword(newPassword, confirmNewPassword) {
    const errors = {};
    const { onChange, enableSaveButton } = this.props;
    const { currentPassword } = this.state;

    if (WVValidator.isEmpty(newPassword) && WVValidator.isEmpty(confirmNewPassword)) {
      this.setState({
        ...this.state,
        errors,
      });
      onChange('password', currentPassword);
      enableSaveButton(true);
      return true;
    }

    if (WVValidator.isEmpty(newPassword)) {
      errors.newPassword = 'Please provide your new password!';
    } else if (!WVValidator.isValidPassword(newPassword)) {
      errors.newPassword = 'Please follow the password requirements.';
    }
    if (WVValidator.isEmpty(confirmNewPassword)) {
      errors.confirmNewPassword = 'Please confirm your new password!';
    } else if (newPassword !== confirmNewPassword) {
      errors.confirmNewPassword = 'Passwords do not match!';
    }

    this.setState({
      ...this.state,
      errors,
    });

    if (Object.entries(errors).length === 0) {
      return true;
    }
    onChange('password', currentPassword);
    enableSaveButton(true);
    return false;
  }

  showPassword = (property) => {
    this.setState(prevState => ({
      ...prevState,
      [`${property}`]: !this.state[`${property}`],
    }));
  };

  showNewPassword = () => {
    this.showPassword('showNewPassword');
  };

  showConfirmNewPassword = () => {
    this.showPassword('showConfirmNewPassword');
  };

  togglePopover = () => {
    const { popoverOpen } = this.state;
    this.setState(prevState => ({ ...prevState, popoverOpen: !popoverOpen }));
  };

  render() {
    const {
      popoverOpen,
      errors,
      showNewPassword,
      showConfirmNewPassword,
    } = this.state;

    return (
      <section>
        <h4 className="il-color-deep-blue font-weight-bold mt-5">Change Password</h4>
        <hr className="mt-3 mb-4" />
        <FormGroup>
          <Label for="newPasswordInput">New Password</Label>
          <InputGroup size="md">
            <Input
              id="newPasswordInput"
              name="newPassword"
              type={showNewPassword ? 'text' : 'password'}
              bsSize="md"
              placeholder="Enter new password"
              invalid={errors.hasOwnProperty('newPassword')}
              onChange={this.onNewPasswordChanged}
            />
            <Popover
              id="popover-new-password"
              placement="right"
              isOpen={popoverOpen}
              target="newPasswordInput"
              trigger="focus"
              toggle={this.togglePopover}
            >
              <PopoverHeader>Password requirements:</PopoverHeader>
              <PopoverBody>
                <p>
                  at least 1 Upper Case letter<br />
                  at least 1 Lower Case letter<br />
                  at least 1 number<br />
                  at least 1 special character ($.,)(!%^*#)<br />
                  at least 8 characters in length<br />
                  New Password and Confirm Password should match
                </p>
              </PopoverBody>
            </Popover>
            <InputGroupAddon addonType="append">
              <button
                style={{
                  maxHeight: '38px',
                  border: '1px solid #ced4da',
                  borderRadius: '0 0.25rem 0.25rem 0',
                }}
                type="button"
                className={`form__form-group-button${showNewPassword ? ' active' : ''}`}
                onClick={this.showNewPassword}
              ><EyeIcon />
              </button>
            </InputGroupAddon>
            <FormFeedback>{errors.newPassword}</FormFeedback>
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label for="passwordInput">Confirm New Password</Label>
          <InputGroup size="md">
            <Input
              name="confirmNewPassword"
              type={showConfirmNewPassword ? 'text' : 'password'}
              bsSize="md"
              placeholder="Re-enter new password"
              invalid={errors.hasOwnProperty('confirmNewPassword')}
              onChange={this.onConfirmNewPasswordChanged}
            />
            <InputGroupAddon addonType="append">
              <button
                style={{
                  maxHeight: '38px',
                  border: '1px solid #ced4da',
                  borderRadius: '0 0.25rem 0.25rem 0',
                }}
                type="button"
                className={`form__form-group-button${showConfirmNewPassword ? ' active' : ''}`}
                onClick={this.showConfirmNewPassword}
              ><EyeIcon />
              </button>
            </InputGroupAddon>
            <FormFeedback>{errors.confirmNewPassword}</FormFeedback>
          </InputGroup>
        </FormGroup>
      </section>
    );
  }
}

