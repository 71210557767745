import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LogInBrand from '../../brand/LogIn/index';
import LogInSecretBrand from '../../brand/LogInSecret/index';
import ForgotPassword from '../../brand/ForgotPassword/index';
import ResetPassword from '../../brand/ResetPassword/index';
import MainWrapper from '../MainWrapper';
import WrappedRoutesBrand from './WrappedRoutesBrand';

const RouterBrands = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route path="/login" component={LogInBrand} />
        <Route path="/forgot" component={ForgotPassword} />
        <Route path="/reset/:token/:firstLogin" component={ResetPassword} />
        <Route path="/login-secret/:token" component={LogInSecretBrand} />
        <Route path="/" component={WrappedRoutesBrand} />
      </Switch>
    </main>
  </MainWrapper>
);

export default RouterBrands;
