/* eslint react/prop-types: 0 */
import React from 'react';

import PropertyInShow from '../../ui/property-in-show';
import ReferenceValue from './reference-value';

export default class Show extends React.PureComponent {
  render() {
    const { property, record } = this.props;

    return (
      <PropertyInShow property={property}>
        <ReferenceValue
          property={property}
          record={record}
        />
      </PropertyInShow>
    );
  }
}
