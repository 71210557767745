/* eslint react/prop-types: 0 */
import React from 'react';
import styled from 'styled-components';
import theme from '../../../../theme';
import Label from './label';

const Property = styled.div`
  margin-bottom: ${theme.sizes.paddingLayout};
`;

/**
 * Wrapps input with label in Show
 *
 * @component
 * @example
 * const property = {
  *   label: 'My amazing property',
  *   name: 'myAmazingProperty',
  * }
  * return (
  *   <WrapperBox border>
  *     <PropertyInShow property={property}>
  *       And here goes a property value.
  *     </PropertyInShow>
  *   </WrapperBox>
  * )
 */
const PropertyInShow = (props) => {
  const { property, children } = props;
  return (
    <Property>
      <Label>{property.label}</Label>
      {children}
    </Property>
  );
};

PropertyInShow.defaultProps = {
  children: null,
};

export default PropertyInShow;
