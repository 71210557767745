import { unflatten } from 'flat';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  setShowUrlStatusHistoryDialog,
} from '../../../../../../redux/reducers/admin/ScoringReducer';
import TableCellContextMenu from './TableCellContextMenu';
import { showModalById } from '../../../../../../redux/reducers/TableReducer';
import { modalId } from '../../../MediaContent/components/custom/modals/RefreshYoutubeDialog';
import { modalId as modalIdComments } from './modals/RefreshCommentsDialog';

const ContextMenuInList = ({ record, dispatch }) => {
  const recordUnflatten = unflatten(record);
  const {
    _id,
    hubilDeal,
    mediaUrl,
    creatorId,
  } = recordUnflatten.params;
  const hsLink = typeof hubilDeal !== 'undefined' && typeof hubilDeal[0] !== 'undefined' ? `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${hubilDeal[0].hs_object_id}` : null;
  const editLink = `/scorecard/media-content/edit/${_id}`;

  const handleClickViewDeal = () => {
    if (hsLink) {
      window.open(hsLink, '_blank').focus();
    }
  };

  const handleClickViewVideoContent = () => {
    if (mediaUrl) {
      window.open((mediaUrl.includes('https://') || mediaUrl.includes('http://')) ? mediaUrl : `https://${mediaUrl}`, '_blank').focus();
    }
  };

  const handleClickViewCreatorProfile = () => {
    if (creatorId) {
      window.open(`/influencer/profile/${creatorId}`, '_blank').focus();
    }
  };

  const handleClickEditContent = () => {
    if (editLink) {
      window.open(editLink, '_blank').focus();
    }
  };

  const clickStatusHistory = () => {
    dispatch(setShowUrlStatusHistoryDialog(true, recordUnflatten));
  };

  const options = [
    { label: 'View URL Status History', handler: clickStatusHistory },
    { type: 'divider', label: 'divider1' },
    { label: 'View Video Content', handler: handleClickViewVideoContent, disabled: !mediaUrl },
    { label: 'View Creator Profile', handler: handleClickViewCreatorProfile, disabled: !creatorId },
    { label: 'View Media Content', handler: handleClickEditContent, disabled: !_id },
    { type: 'divider', label: 'divider2' },
    { label: 'View HubSpot Deal', handler: handleClickViewDeal, disabled: !hsLink },
    { type: 'divider', label: 'divider3' },
    { label: 'Refresh YouTube Data', handler: () => dispatch(showModalById(modalId, { record })), disabled: !hsLink },
    { label: 'Refresh YouTube Comments', handler: () => dispatch(showModalById(modalIdComments, { record })), disabled: !hsLink },
  ];

  return (
    <div className="text-right">
      <TableCellContextMenu options={options} />
    </div>
  );
};
ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ContextMenuInList);
