/* eslint prefer-destructuring: 0 */
/* eslint react/prop-types: 0 */

import React from 'react';
// import { Badge } from 'reactstrap';

// const mapStatus = {
//   1: 'Enabled',
//   2: 'Disabled',
// };
export default ({ record }) => {
  const { title } = record.params;
  let category = '';
  if (record.params.request) {
    category = record.params.request.category;
  }
  if (record.params.category) {
    category = record.params.category;
  }

  return <span><strong>{category}</strong><br />{title}</span>;
  // const status = mapStatus[record.params.replyStatus];
  // let colorClass = 'primary';
  // if (status === 'Enabled') {
  //   colorClass = 'success';
  // } else {
  //   colorClass = 'danger';
  // }
  // return (
  //   <Badge color={colorClass}>{status}f</Badge>
  // );
};
