/* eslint react/prop-types: 0 */
import React from 'react';
import { Row, Col, CustomInput } from 'reactstrap';
import { unflatten } from 'flat';

export default class PermissionsInShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions: this.getPermissions(),
    };
  }

  getPermissions() {
    const { record: { params } } = this.props;
    const paramsObject = unflatten(params);
    const { permissions } = paramsObject;
    if (permissions && Array.isArray(permissions)) {
      return permissions;
    }
    return [];
  }

  isChecked(value) {
    const { permissions } = this.state;
    if (permissions && Array.isArray(permissions)) {
      return permissions.includes(value);
    }
    return false;
  }

  renderItem(item) {
    const { value, label } = item;
    const id = `${value}Switch`;
    return (
      <Row key={value} >
        <Col xs="2" className="d-flex justify-content-center">
          <CustomInput
            id={id}
            type="switch"
            name={value}
            disabled
            checked={this.isChecked(value)}
          />
        </Col>
        <Col xs="10" className="mr-auto">
          <h4 className="py-2">{label}</h4>
        </Col>
      </Row>
    );
  }

  renderInputs() {
    const { property: { availableValues } } = this.props;
    return availableValues.map(item => this.renderItem(item));
  }

  render() {
    const {
      property: {
        label,
      },
    } = this.props;

    return (
      <section style={{ marginBottom: '16px' }}>
        <h4 className="il-color-deep-blue font-weight-bold mt-5 mb-4">{label}</h4>
        <Row>
          <Col xs="2" className="text-left">
            <h4 className="font-weight-bold">Allow access?</h4>
          </Col>
          <Col xs="10" className="mr-auto">
            <h4 className="font-weight-bold">Module Name</h4>
          </Col>
        </Row>
        <hr className="my-2" />
        {this.renderInputs()}
      </section>
    );
  }
}
