import React, { useState, useEffect } from 'react';
import { axios } from 'ApiClient';
import moment from 'moment';
import Select from 'react-select';
import PropTypes from 'prop-types';
import RefreshIcon from 'mdi-react/RefreshIcon';
import { useAsyncDebounce } from 'react-table';
import { Row, Col, Container, Card, CardBody, CardTitle, FormGroup, Spinner } from 'reactstrap';
import { camelCase } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import Filter from './TimePeriodFilter';
import { formatIntNumber } from '../../../shared/helpers/WVFormatter';
import CheckBoxField from '../../../shared/components/CheckBox';

// import Breadcrumbs from '../../../shared/components/BreadCrumbs';
import withNotice from '../../App/store/with-notice';


// import GenerationInfo from '../GenerationInfoV2';
import ChartComponent from './ChartComponent';
import TableComponent from './TableComponent';

const dataOptions = [
  { value: 'byDay', label: 'By Day' },
  { value: 'week', label: 'By Week' },
  { value: 'month', label: 'By Month' },
  { value: 'total', label: 'Over Time' },
];

const Item = ({ label, value, description }) => (
  <div
    style={{
      padding: '15px 15px',
      border: '1px solid #cccccc',
      borderRadius: '5px',
      textAlign: 'center',
      height: '100%',
    }}
  >
    <span style={{ textAlign: 'center', color: 'black' }} >
      <span
        id={camelCase(label)}
        style={{
          fontSize: '30px',
          fontWeight: '700',
          marginBottom: '12px',
          width: '100%',
          textAlign: 'center',
        }}
      >
        {value}
      </span>
      <br style={{ lineHeight: '50%' }} />
      <span style={{ width: '100%', fontSize: '16px' }}>{label}</span>
      <Tooltip title={description}>
        <IconButton size="small" style={{ marginTop: '-3px' }}>
          <i className="fa fa-info-circle" style={{ fontSize: '0.8em' }} />
        </IconButton>
      </Tooltip>
    </span>
  </div>);

Item.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
};

const SummaryReport = ({ addNotice }) => {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState((moment().subtract(1, 'day').endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).format('YYYY-MM-DD'));
  const [selectedDate, setSelectedDate] = useState({ startDate, endDate });

  const [identity] = useState(Math.random().toString(36).substring(2));
  const [fetched, setFetched] = useState(false);
  const [show0, setShow0] = useState(false);
  const [showLabels, setShowLabels] = useState(false);
  const [chartDataConversions, setChartDataConversions] = useState([]);
  // const [chartDataConversionsTotal, setChartDataConversionsTotal] = useState([]);
  const [chartDataContacts, setChartDataContacts] = useState([]);
  const [chartDataCreators, setChartDataCreators] = useState([]);
  const [chartDataCreatorsArray, setChartDataCreatorsArray] = useState([]);
  const [chartDataContracts, setChartDataContracts] = useState([]);
  const [chartDataContent, setChartDataContent] = useState([]);
  const [chartDataContentYoutube, setChartDataContentYoutube] = useState([]);

  const [selectedOption, setSelectedOption] = useState(dataOptions[0]);
  const [totalCreators, setTotalCreators] = useState(0);
  const [reports, setReports] = useState({
    contacts: 0, contracts: 0, content: 0, conversions: 0, creators: 0, youtubeContent: 0, conversionsTotal: 0,
  });


  const fetchAmountReport = useAsyncDebounce(async () => {
    setLoading(true);
    setSelectedDate({ startDate, endDate });
    try {
      const resp = await axios.post('/api/brand/dashboard/post-report5c-by-date', {
        startDate,
        endDate,
        identity,
        show0,
      });
      if (resp.data.success) {
        setFetched(true);
        setLoading(false);
        console.log(resp.data);
        const total = {
          contacts: 0, contracts: 0, content: 0, conversions: 0, creators: 0, conversionsTotal: 0, youtubeContent: 0
        };
        const _chartDataConversions = {};

        const conversions  = show0 ? resp.data.conversionsTotal : resp.data.conversions;
        conversions.forEach((data) => {
          if (!_chartDataConversions[data.brand]) _chartDataConversions[data.brand] = { option: { _id: data.brand || 'na', label: data.brand || 'Conversions' }, data: {} };
          _chartDataConversions[data.brand].data[data.date] = data.value;
          total.conversions += data.value;
        });
        /* const _chartDataConversionsTotal = {};
        resp.data.conversionsTotal.forEach((data) => {
          if (!_chartDataConversionsTotal[data.brand]) _chartDataConversionsTotal[data.brand] = { option: { _id: data.brand || 'na', label: data.brand || 'Conversions' }, data: {} };
          _chartDataConversionsTotal[data.brand].data[data.date] = data.value;
          total.conversionsTotal += data.value;
        }); */

        const _setChartDataContacts = {};
        resp.data.contacts.forEach((data) => {
          if (!_setChartDataContacts[data.brand]) _setChartDataContacts[data.brand] = { option: { _id: data.brand || 'na', label: data.brand || 'Contacts' }, data: {} };
          _setChartDataContacts[data.brand].data[data.date] = data.value;
          total.contacts += data.value;
        });
        const _setChartDataCreators = {};
        const _setChartDataCreatorsArray = {};
        const uniqueCreators = {};
        resp.data.creators.forEach((data) => {
          if (!_setChartDataCreators[data.brand]) _setChartDataCreators[data.brand] = { option: { _id: data.brand || 'na', label: data.brand || 'Creators' }, data: {} };
          _setChartDataCreators[data.brand].data[data.date] = data.value;
          _setChartDataCreatorsArray[data.date] = data.creators;
          data.creators.forEach((creator) => { uniqueCreators[creator] = true; });
        });
        const _setChartDataContracts = {};
        resp.data.contracts.forEach((data) => {
          if (!_setChartDataContracts[data.brand]) _setChartDataContracts[data.brand] = { option: { _id: data.brand || 'na', label: data.brand || 'Contracts' }, data: {} };
          _setChartDataContracts[data.brand].data[data.date] = data.value;
          total.contracts += data.value;
        });
        const _setChartDataContent = {};
        resp.data.content.forEach((data) => {
          if (!_setChartDataContent[data.brand]) _setChartDataContent[data.brand] = { option: { _id: data.brand || 'na', label: data.brand || 'Content' }, data: {} };
          _setChartDataContent[data.brand].data[data.date] = data.value;
          total.content += data.value;
        });

        const _setChartDataContentYoutube = {};
        resp.data.youtubeContent.forEach((data) => {
          if (!_setChartDataContentYoutube[data.brand]) _setChartDataContentYoutube[data.brand] = { option: { _id: data.brand || 'na', label: data.brand || 'Youtube Content' }, data: {} };
          _setChartDataContentYoutube[data.brand].data[data.date] = data.value;
          total.youtubeContent += data.value;
        });

        setChartDataContacts(Object.values(_setChartDataContacts));
        setChartDataConversions(Object.values(_chartDataConversions));
        // setChartDataConversionsTotal(Object.values(_chartDataConversionsTotal));
        setChartDataCreators(Object.values(_setChartDataCreators));
        setChartDataCreatorsArray(_setChartDataCreatorsArray);
        setChartDataContracts(Object.values(_setChartDataContracts));
        setChartDataContent(Object.values(_setChartDataContent));
        setChartDataContentYoutube(Object.values(_setChartDataContentYoutube));
        setTotalCreators(Object.keys(uniqueCreators).length);
        total.creators = Object.keys(uniqueCreators)?.length || 0;
        setReports(total);
      } else {
        setFetched(false);
        setLoading(false);
        throw new Error(resp.data.error || resp.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
    return true;
  }, 500);
  useEffect(() => {
    if (startDate && endDate) {
      setFetched(true);
      fetchAmountReport();
    }
  }, []);
  return (
    <Container className="dashboard">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h3>Overview </h3>
              </CardTitle>
              <hr />
              <Row className="mt-3" >
                <Col>
                  <Filter
                    setValue={(param, value) => {
                      console.log(value);
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('day').format('YYYY-MM-DD'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('day').format('YYYY-MM-DD'));
                      }
                    }}
                    isLoading={loading}
                    endDateInitial={endDate}
                    startDateInitial={startDate}
                  />
                </Col>
                <Col sm="auto mt-1">
                  <Tooltip title="Refresh Button">
                    <IconButton size="small" style={{ marginTop: '-3px' }} disabled={loading} onClick={fetchAmountReport}>
                      <RefreshIcon size={30} />
                    </IconButton>
                  </Tooltip>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={8}>
                  <FormGroup>
                    <CheckBoxField
                      name="previewTypeInfluencers"
                      label="Include conversions that produce no payout ($0)?"
                      value={show0}
                      onChange={e => setShow0(e.target?.checked || false)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Item
                    label="Contacts"
                    description="The number of leads approved by brand safety and sent to team members for outreach"
                    value={loading ? (<Spinner color="primary" size="sm" />) : formatIntNumber(reports.contacts)}
                  />
                </Col>
                <Col>
                  <Item
                    label="Contracts"
                    description="Deals that have closed with actual signed contracts"
                    // label={`/ ${reportsTotal.contracts} Total`}
                    value={loading ? (<Spinner color="primary" size="sm" />) : formatIntNumber(reports.contracts)}
                  />
                </Col>
                <Col>
                  <Item
                    label="Content"
                    description="The number of content pieces that were approved for publication"
                    // label={`/ ${reportsTotal.content} Total`}
                    value={loading ? (<Spinner color="primary" size="sm" />) : formatIntNumber(reports.content)}
                  />
                </Col>
                <Col>
                  <Item
                    label="Conversions"
                    description="Tracked purchases, sales, signups, etc"
                    // label={`/ ${reportsTotal.conversions} Total`}
                    value={loading ? (<Spinner color="primary" size="sm" />) : formatIntNumber(reports.conversions)}
                  />
                </Col>
                <Col>
                  <Item
                    label="Active Creators"
                    description="Total number of creators who have produced at least one conversion in the selected time frame"
                    // label={`/ ${reportsTotal.creators} Total`}
                    value={loading ? (<Spinner color="primary" size="sm" />) : formatIntNumber(reports.creators)}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div
                    className="form-group mt-3"
                    style={{ width: '250px' }}
                  >
                    {fetched ? (
                      <Select
                        placeholder="Select Property to Chart..."
                        value={selectedOption}
                        options={dataOptions}
                        onChange={setSelectedOption}
                      />) : null
                    }
                  </div>
                </Col>
                <Col />
              </Row>
              {fetched ? (
                <Row className="mt-3">
                  <Col md={8}>
                    <FormGroup>
                      <CheckBoxField
                        name="showLabels"
                        label="Show Labels on Chart"
                        value={showLabels}
                        onChange={e => setShowLabels(e.target?.checked || false)}
                      />
                    </FormGroup>
                  </Col>
                </Row>) : null}
              <Row className="mt-3">
                <Col sm={12}>
                  {fetched ? (
                    <ChartComponent
                      selectedAdmins={chartDataContacts}
                      startDate={selectedDate.startDate}
                      endDate={selectedDate.endDate}
                      label="Contacts"
                      isLoading={loading}
                      total={selectedOption?.value}
                      showLabels={showLabels}
                    />) : null
                  }
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={12}>
                  {fetched ? (
                    <ChartComponent
                      selectedAdmins={chartDataContracts}
                      startDate={selectedDate.startDate}
                      endDate={selectedDate.endDate}
                      label="Contracts"
                      isLoading={loading}
                      total={selectedOption?.value}
                      showLabels={showLabels}
                    />) : null
                    }
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={12}>
                  {fetched ? (
                    <ChartComponent
                      selectedAdmins={chartDataContent}
                      startDate={selectedDate.startDate}
                      endDate={selectedDate.endDate}
                      label="Content"
                      isLoading={loading}
                      total={selectedOption?.value}
                      showLabels={showLabels}
                    />) : null
                  }
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={12}>
                  {fetched ? (
                    <ChartComponent
                      selectedAdmins={chartDataConversions}
                      startDate={selectedDate.startDate}
                      endDate={selectedDate.endDate}
                      label="Conversions"
                      isLoading={loading}
                      total={selectedOption?.value}
                      showLabels={showLabels}
                    />) : null
                  }
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={12}>
                  {fetched ? (
                    <ChartComponent
                      selectedAdmins={chartDataCreators}
                      startDate={selectedDate.startDate}
                      endDate={selectedDate.endDate}
                      label="Creators"
                      isLoading={loading}
                      total={selectedOption?.value}
                      totalValue={totalCreators}
                      itemsByArray={chartDataCreatorsArray}
                      showLabels={showLabels}
                    />) : null
                  }
                </Col>
              </Row>
              <Row>
                <Col>
                  {fetched && !loading ? (
                    <TableComponent
                      selectedAdmins={{
                        chartDataContacts, chartDataContracts, chartDataContent, chartDataContentYoutube, chartDataConversions, chartDataCreators,
                      }}
                      startDate={selectedDate.startDate}
                      endDate={selectedDate.endDate}
                      total={selectedOption?.value}
                      itemsByArray={{ chartDataCreators: chartDataCreatorsArray }}
                    />) : null}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
SummaryReport.propTypes = {
  addNotice: PropTypes.func.isRequired,
};
export default withNotice(SummaryReport);
