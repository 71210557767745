/* eslint react/prop-types: 0 */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../../../../theme';
import WrapperBox from './wrapper-box';
import Label from './label';

const OverlayLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  transition: border-width 0.2s;
  &:hover {
    transition: border-width 0.2s;
    border-bottom: 5px solid ${theme.colors.primary};
  }
`;

const Level = styled.div.attrs({
  className: 'level',
})`
  color: ${theme.colors.primary};
  margin-top: 8px;

  & .value {
    font-size: 34px;
  }

  & .icon {
    font-size: 34px;
  }
`;

/**
 * Simple Widget, which can be used in the dashboard
 *
 * @name ValueBlock
 * @component
 * @example
 * return (
 *   <WrapperBox><Columns>
 *     <Column><ValueBlock  icon="fa fa-bomb" value="5">
 *       Utils
 *     </ValueBlock></Column>
 *     <Column><ValueBlock  icon="fa fa-star" value="12" href="/api/resourceName">
 *       Are
 *     </ValueBlock></Column>
 *     <Column><ValueBlock  icon="fa fa-cog" value="5" color="red">
 *       Awesome
 *     </ValueBlock></Column>
 *   </Columns></WrapperBox>
 * )
 */
const ValueBlock = (props) => {
  const {
    icon, value, children, color, href, label,
  } = props;

  return (
    <WrapperBox border style={{ position: 'relative' }}>
      {href ? <OverlayLink to={href} /> : ''}
      { label ? <Label>{label}</Label> : '' }
      <Level color={color}>
        <div className="value">
          {value}
        </div>
        <div className="icon">
          <i className={icon} />
        </div>
      </Level>
      {children}
    </WrapperBox>
  );
};

export default ValueBlock;
