import { unflatten } from 'flat';
import React, { useState } from 'react';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const StartDate = ({ record, onChange }) => {
  const recordUnflatten = unflatten(record);
  const rowData = recordUnflatten.params;

  const fetchValue = (property) => {
    if (typeof rowData !== 'undefined') {
      if (typeof rowData[property] !== 'undefined' && rowData[property] !== null) {
        return moment(rowData[property]).toDate();
      }
    }

    return '';
  };

  const [startDate, setStartDate] = useState(fetchValue('startDate'));
  const [endDate, setEndDate] = useState(fetchValue('endDate'));

  const handleChange = (property, newValue) => {
    onChange(property, newValue ? newValue.toString() : '');
  };

  const handleChangeStartDate = (newValue) => {
    setStartDate(newValue);
    handleChange('startDate', newValue);
  };

  const handleChangeEndDate = (newValue) => {
    setEndDate(newValue);
    handleChange('endDate', newValue);
  };

  return (
    <Row>
      <Col md={6}>
        <FormGroup>
          <Label for="startDate">Start Date</Label><br />
          <DatePicker
            id="startDate"
            onChange={handleChangeStartDate}
            selected={startDate}
            dateFormat="M/d/yyyy h:mmaa"
            className="form-control"
            wrapperClassName="w-100"
            showTimeInput
          />
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label for="endDate">End Date</Label><br />
          <DatePicker
            id="endDate"
            onChange={handleChangeEndDate}
            selected={endDate}
            dateFormat="M/d/yyyy h:mmaa"
            className="form-control"
            wrapperClassName="w-100"
            showTimeInput
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

StartDate.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StartDate;
