/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import { Modal, ButtonToolbar, Button, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { currentTimezoneAggr } from '../../../../../shared/helpers/Dates';
import {
  setShowBrandStatusHistoryModal,
  setCurrentMediaContent,
} from '../../../../../redux/reducers/brand/CreatorsReducer';

const BrandStatusHistoryModal = ({
  isOpen,
  record,
  dispatch,
}) => (
  <Modal
    isOpen={isOpen}
    toggle={() => {
      dispatch(setShowBrandStatusHistoryModal(false));
      dispatch(setCurrentMediaContent({}));
    }}
  >
    <ModalHeader
      toggle={() => {
        dispatch(setShowBrandStatusHistoryModal(false));
        dispatch(setCurrentMediaContent({}));
      }}
      tag="h3"
      className="modal-title-statistics"
    >
      Status Change History
    </ModalHeader>
    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
      {record?.params?.history?.brandStatus?.length > 0 ? (
        <div className="mt-1 mb-4">
          {record.params.history.brandStatus.map((item) => {
            const previousName = item.prevStatus || null;
            const currentName = item.newStatus || null;
            const userName = item.adminUserName ? item.adminUserName : (item.brandUserName ? item.brandUserName : 'AUTOMATION');
            return (
              <div key={moment(item.dateModified).toString() + moment.now().toString()}>
                <div><strong>{moment(item.dateModified).format('M/D/YYYY h:mm A')} {currentTimezoneAggr()}</strong></div>
                <div className="pl-3">
                  {userName} - <b>{previousName}</b> changed to <b>{currentName}</b>
                </div>
              </div>);
          })}
        </div>
      ) : null}
    </div>
    <ButtonToolbar className="modal__footer">
      <Button
        className="modal_cancel btn-sm"
        color="secondary"
        onClick={() => {
          dispatch(setShowBrandStatusHistoryModal(false));
          dispatch(setCurrentMediaContent({}));
        }}
      >
        Close
      </Button>
    </ButtonToolbar>
  </Modal>
);

BrandStatusHistoryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  record: state.creators.currentMediaContent,
  isOpen: state.creators.showBrandStatusHistoryModal,
});

export default connect(mapStateToProps)(BrandStatusHistoryModal);
