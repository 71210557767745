// import { unflatten } from 'flat';
import React, { useState, useEffect, forwardRef } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import CalendarIcon from 'mdi-react/CalendarIcon';
import {
  Button,
  Row,
  Col,
  Card,
  CardBody, InputGroup, InputGroupAddon,
} from 'reactstrap';
import {
  loadCreatorStatistics,
  loadTubularStats,
} from '../../../../redux/reducers/brand/CreatorsReducer';
import { formatCurrency } from '../../../../shared/helpers/WVFormatter';
import StatsModal from '../../../../shared/components/StatsModal';
import { getLast7Days } from '../../../../shared/helpers/Dates';

const ContentTable = React.memo(({
  creatorStatistics,
  loading,
  dispatch,
  match,
  currentCreator,
  loadingTubularStats,
  tubularStatistics,
}) => {
  const disableButtons = loading.creators || loading.statistics;
  const [defaultPeriod, setDefaultPeriod] = useState('current_month');
  const [dates, setDates] = useState({
    from: moment().startOf('month').unix(),
    to: moment().unix(),
  });
  const [openModal, setOpenModal] = useState(false);

  const minDate = moment().subtract(365, 'day').startOf('day').toDate();
  const maxDate = moment().set({
    hour: 23, minute: 59, second: 59, millisecond: 999,
  }).toDate();

  const setPeriod = (period) => {
    let dateFrom = false;
    let dateTo = false;
    switch (period) {
      case 'current_month':
      default:
        dateFrom = moment().startOf('month').set({
          hour: 0, minute: 0, second: 0, millisecond: 0,
        }).unix();
        dateTo = moment().unix();
        break;
      case 'today':
        dateFrom = moment().startOf('day').set({
          hour: 0, minute: 0, second: 0, millisecond: 0,
        }).unix();
        dateTo = moment().unix();
        break;
      case 'yesterday':
        dateFrom = moment().subtract(1, 'day').startOf('day').set({
          hour: 0, minute: 0, second: 0, millisecond: 0,
        })
          .unix();
        dateTo = moment().subtract(1, 'day').endOf('day').set({
          hour: 23, minute: 59, second: 59, millisecond: 999,
        })
          .unix();
        break;
      case 'last_7_days':
        [dateFrom, dateTo] = getLast7Days();
        dateFrom = moment(dateFrom).unix();
        dateTo = moment(dateTo).unix();
        break;
      case 'custom':
        dateFrom = moment(minDate).unix();
        dateTo = moment(maxDate).unix();
        break;
    }
    setDefaultPeriod(period);
    setDates({
      from: dateFrom,
      to: dateTo,
    });
  };

  const fetchStatistics = (forceStart) => {
    if ((creatorStatistics === false || forceStart) && !loading.statistics) {
      const dateFrom = moment.unix(dates.from).format('YYYY-MM-DD');
      const dateTo = moment.unix(dates.to).format('YYYY-MM-DD');
      dispatch(loadCreatorStatistics(match.params.creatorId, dateFrom, dateTo));
    }
  };

  useEffect(() => {
    if (defaultPeriod !== 'custom') {
      fetchStatistics(true);
    }
    if (defaultPeriod === 'custom' && dates.from && dates.to) {
      fetchStatistics(true);
    }
  }, [dates]);

  const buttonClass = 'btn-sm mr-0 time-period-filter-buttons-extended';
  // eslint-disable-next-line react/prop-types
  const DatePickerCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <div className="form-group mb-2">
      <InputGroup size="md">
        <input
          type="text"
          className="form-control form-control-sm"
          value={value}
          onClick={onClick}
          onChange={onChange}
          style={{
            border: '1px solid #ced4da',
            borderRadius: '5px',
          }}
          ref={ref}
        />
        <InputGroupAddon
          addonType="append"
          style={{
            padding: '3px 0 3px 3px',
          }}
        >
          <CalendarIcon
            className="date-picker-icon"
            onClick={onClick}
          />
        </InputGroupAddon>
      </InputGroup>
    </div>
  ));

  return (
    <div>
      <h2 className="my-3">{ !disableButtons ? `${currentCreator.first_name} ${currentCreator.last_name}` : ''} Performance
        <Button className="float-right" color="primary" onClick={() => setOpenModal(true)}>
          <FontAwesomeIcon icon={['fas', 'chart-line']} /> Statistics
        </Button>
      </h2>
      <Card>
        <CardBody>
          <Row style={{ minWidth: '300px' }}>
            <Col lg="auto">
              <Button
                onClick={() => setPeriod('current_month')}
                className={defaultPeriod === 'current_month' ? `active ${buttonClass}` : buttonClass}
                style={{ borderRadius: '5px 0 0 5px', borderRight: '0' }}
                size="sm"
                disabled={disableButtons}
              >
                Month To Date
              </Button>
              <Button
                onClick={() => setPeriod('today')}
                className={defaultPeriod === 'today' ? `active ${buttonClass}` : buttonClass}
                style={{ borderRadius: '0', borderRight: '0' }}
                size="sm"
                disabled={disableButtons}
              >
                Today
              </Button>
              <Button
                onClick={() => setPeriod('yesterday')}
                className={defaultPeriod === 'yesterday' ? `active ${buttonClass}` : buttonClass}
                style={{ borderRadius: '0', borderRight: '0' }}
                size="sm"
                disabled={disableButtons}
              >
                Yesterday
              </Button>
              <Button
                onClick={() => setPeriod('last_7_days')}
                className={defaultPeriod === 'last_7_days' ? `active ${buttonClass}` : buttonClass}
                style={{ borderRadius: '0', borderRight: '0' }}
                size="sm"
                disabled={disableButtons}
              >
                Last 7 days
              </Button>
              <Button
                onClick={() => setPeriod('custom')}
                className={defaultPeriod === 'custom' ? `active ${buttonClass}` : buttonClass}
                style={{ borderRadius: '0 5px 5px 0', borderColor: '#4A69F2' }}
                size="sm"
                disabled={disableButtons}
              >
                Custom
              </Button>
            </Col>
            <Col>
              {defaultPeriod === 'custom' ? (
                <Row>
                  <Col xs="auto" className="">
                    <div style={{ maxWidth: '150px' }}>
                      <DatePicker
                        id="startDateDatePicker"
                        selected={!dates.from ? null : moment.unix(dates.from).toDate()}
                        onChange={(date) => {
                          setDates({
                            ...dates,
                            from: moment(date)
                              .set({
                                hour: 0, minute: 0, second: 0, millisecond: 0,
                              })
                              .unix(),
                          });
                        }}
                        dateFormat="MM/dd/yyyy"
                        maxDate={maxDate}
                        minDate={minDate}
                        placeholderText="Start Date"
                        dropDownMode="select"
                        customInput={<DatePickerCustomInput />}
                        disabled={disableButtons}
                      />
                    </div>
                  </Col>
                  <Col xs="auto" className="">
                    <div style={{ maxWidth: '150px' }}>
                      <DatePicker
                        id="endDateDatePicker"
                        selected={!dates.to ? null : moment.unix(dates.to).toDate()}
                        onChange={(date) => {
                          setDates({
                            ...dates,
                            to: moment(date)
                              .set({
                                hour: 23, minute: 59, second: 59, millisecond: 999,
                              })
                              .unix(),
                          });
                        }}
                        dateFormat="MM/dd/yyyy"
                        maxDate={maxDate}
                        minDate={minDate}
                        placeholderText="End Date"
                        dropDownMode="select"
                        customInput={<DatePickerCustomInput />}
                        disabled={disableButtons}
                      />
                    </div>
                  </Col>
                  <Col />
                </Row>) : null
              }
            </Col>
          </Row>
          <div>
            <Row>
              <Col md="4" className="modal-title-statistics text-center">
                Clicks
              </Col>
              <Col md="4" className="modal-title-statistics text-center">
                Conversions
              </Col>
              <Col md="4" className="modal-title-statistics text-center">
                Payout
              </Col>
            </Row>
            <Row>
              <Col md="4" className="text-center ">
                {creatorStatistics && creatorStatistics.clicks !== false && !loading.statistics ? (
                  <h1 className="statistics-value text-center">{creatorStatistics.clicks}</h1>
                ) : (
                  <div role="status" className="spinner-border-sm spinner-border text-primary">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </Col>
              <Col md="4" className="text-center ">
                {creatorStatistics && creatorStatistics.conversions !== false && !loading.statistics ? (
                  <h1 className="statistics-value text-center">{creatorStatistics.conversions}</h1>
                ) : (
                  <div role="status" className="spinner-border-sm spinner-border text-primary">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </Col>
              <Col md="4" className="text-center ">
                {creatorStatistics && creatorStatistics.payout !== false && !loading.statistics ? (
                  <h1 className="statistics-value text-center">{formatCurrency(creatorStatistics.payout)}</h1>
                ) : (
                  <div role="status" className="spinner-border-sm spinner-border text-primary">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
      <StatsModal
        isOpen={openModal}
        close={() => setOpenModal(false)}
        loadData={loadTubularStats}
        creatorName={`${currentCreator.first_name ? `${currentCreator.first_name} ` : ''}${currentCreator.last_name ? `${currentCreator.last_name}` : ''}`}
        loading={loadingTubularStats}
        tubularStatistics={tubularStatistics}
      />
    </div>);
});
ContentTable.propTypes = {
  creatorStatistics: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  loading: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  currentCreator: PropTypes.objectOf(PropTypes.any).isRequired,
  loadingTubularStats: PropTypes.bool.isRequired,
  tubularStatistics: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  loading: state.creators.loading,
  creators: state.creators.creators,
  creatorsTableVersion: state.creators.creatorsTableVersion,
  showStatisticsDialog: state.creators.showStatisticsDialog,
  showStatisticsCreatorId: state.creators.showStatisticsCreatorId,
  creatorStatistics: state.creators.creatorStatistics,
  filters: state.creators.filters,
  currentView: state.creators.view.creators,
  loadingTubularStats: state.creators.loading.tubular,
  currentCreator: state.creators.currentCreator,
  tubularStatistics: state.creators.tubularStatistics,
});
export default connect(mapStateToProps)(reduxForm({
  form: 'creatorslist_form',
})(ContentTable));
