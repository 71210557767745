import React from 'react';

export default (props) => {
  const { record } = props;
  console.log(record);
  const url = `${window.location.href.substring(0, window.location.href.indexOf('VanityUrl'))}Offer/records/${record.params.searchOfferId}/show`;
  const name = record.params.offerName || '';
  const result = ((typeof record.params.searchOfferId !== 'undefined')
    ? (
      <a href={url} target="_blank" rel="noreferrer">
        {name}
      </a>
    )
    : name);
  return result;
};
