/* eslint react/prop-types: 0 */
import React from 'react';

import PropertyInShow from '../../ui/property-in-show';

export default class Show extends React.PureComponent {
  render() {
    const { property, record } = this.props;

    const value = record.params[property.name];

    const className = property.availableValues ? 'tag' : '';

    return (
      <PropertyInShow property={property}>
        {typeof value !== 'undefined' ? (
          <span className={className}>{value}</span>
        ) : ''}
      </PropertyInShow>
    );
  }
}
