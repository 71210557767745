import React from 'react';
import PropTypes from 'prop-types';

const TitleInList = ({ record }) => (
  <span>
    {record.thumbnail && (
      <img style={{ float: 'left', maxWidth: 100, marginRight: 10 }} src={record.thumbnail} alt="" />
    )}
    {!record.thumbnail && (
      <img style={{ float: 'left', maxWidth: 100, marginRight: 10 }} src="/images/brands/thumbnail-default.png" alt="" />
    )}
    {record.title}
    {record.mediaUrl && record.title &&
    // eslint-disable-next-line react/jsx-no-target-blank
    <a target="_blank" href={record.mediaUrl}>&nbsp;<i className="fas fa-external-link-alt" /></a>
    }
  </span>
);
TitleInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default TitleInList;

