import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import { TextField } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import BinaryAnswerOptions from './BinaryAnswerOptions';

const useStyles = makeStyles(() => ({
  gridItems: {
    margin: 'auto',
  },
}));

const QuestionItem = ({
  item,
  disable,
  answersScore,
  handleOnChangeValue,
  handleChangeBinaryValue,
}) => {
  // console.log(answersScore);
  const classes = useStyles();
  let questionText = '';
  if (item.questionType === 'Binary') {
    questionText = `${item.question} (Yes or No)`;
  } else {
    questionText = `${item.question} (${item.minVal} ${item.maxVal === item.scale ? 'or' : 'to'} ${item.maxVal})`;
  }

  return (
    <Row>
      <Col sm={9} className={classes.gridItems}>
        <Typography gutterBottom variant="body2" align="right">{questionText}</Typography>
      </Col>
      <Col sm={1} className={classes.gridItems} style={{ maxWidth: 40 }}>
        {item.helpText && (
          <Tooltip title={item.helpText}>
            <IconButton
              size="small"
            >
              <InformationOutlineIcon size="17" />
            </IconButton>
          </Tooltip>
        )}
      </Col>
      <Col sm={2} style={{ padding: 8 }}>
        {item.questionType !== 'Binary' && (
          <TextField
            name={item._id}
            disabled={disable}
            type="number"
            InputProps={{
              inputProps: {
                max: item.maxVal,
                min: item.minVal,
                step: item.scale,
              },
            }}
            variant="outlined"
            size="small"
            onChange={(event) => { handleOnChangeValue(event); }}
            value={typeof answersScore !== 'undefined' && typeof answersScore[item._id] !== 'undefined' && answersScore[item._id] !== null ? Number(answersScore[item._id]).toString() : 0}
          />
        )}
        {item.questionType === 'Binary' && (
          <BinaryAnswerOptions disable={disable} handleChangeBinaryAnswer={handleChangeBinaryValue} answer={answersScore[item._id]} questionId={item._id} />
        )}
      </Col>
    </Row>
  );
};
QuestionItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  answersScore: PropTypes.objectOf(PropTypes.any).isRequired,
  handleOnChangeValue: PropTypes.func.isRequired,
  handleChangeBinaryValue: PropTypes.func.isRequired,
  disable: PropTypes.bool,
};

QuestionItem.defaultProps = {
  disable: false,
};
export default QuestionItem;

