import { unflatten } from 'flat';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ButtonToolbar, Button, Spinner, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { fetchScorecards } from '../../../../../../redux/reducers/admin/ScoringReducer';
import ScorecardsAnswersForm from '../../../ScorecardAnswersSheet/components/ScorecardsAnswersForm';


class ActionsInList extends React.Component {
  constructor(props) {
    super(props);
    const { record } = this.props;
    const recordUnflatten = unflatten(record);
    const { _id } = recordUnflatten.params;
    const editLink = `/resources/Scorecard/records/${_id}/edit`;
    this.state = {
      id: _id,
      editLink,
      showScorecard: false,
    };

    this.toggleDialog = this.toggleDialog.bind(this);
    // this.fetchScorecards = this.fetchScorecards.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { id } = props.record;
    if (id !== state.id) {
      const editLink = `/resources/Scorecard/records/${id}/edit`;
      return {
        id,
        editLink,
      };
    }
    return null;
  }

  toggleDialog(e) {
    e.preventDefault();
    this.setState({ showScorecard: !this.state.showScorecard }, () => {
      if (this.state.showScorecard) {
        this.fetchScorecardData();
      }
    });
  }

  fetchScorecardData() {
    const { dispatch } = this.props;
    dispatch(fetchScorecards(this.state.id)).then(() => {});
  }

  render() {
    const {
      editLink,
      showScorecard,
    } = this.state;
    const {
      loading,
      scorecardData,
    } = this.props;
    return (
      <>
        <span>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a title="Preview Scorecard" href="#" onClick={this.toggleDialog}><i className="fas fa-eye" /></a>&nbsp;&nbsp;&nbsp;&nbsp;
          <a title="Edit" href={editLink}><i className="fas fa-edit" /></a>&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <Modal
          isOpen={showScorecard}
          toggle={this.toggleDialog}
          style={{ minWidth: '750px' }}
        >
          <ModalHeader toggle={this.toggleDialog} tag="h4">Scorecard Preview</ModalHeader>
          {/* eslint-disable-next-line react/no-danger */}
          <div className="modal__body">
            {!loading && showScorecard && scorecardData && (
              <ScorecardsAnswersForm questions={scorecardData.params} view="modal" isFTCCompliant={null} includesPaidPromotion={null} isOscar={null} type={scorecardData.params.type} />
            )}
            {loading && showScorecard && !scorecardData && (
              <div className="text-center"><Spinner color="primary" size="lg" /></div>
            )}
          </div>
          <ButtonToolbar className="modal__footer">
            <Button className="btn btn-primary btn-sm" onClick={this.toggleDialog}>Close</Button>
          </ButtonToolbar>
        </Modal>
      </>
    );
  }
}
ActionsInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  scorecardData: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool,
};

ActionsInList.defaultProps = {
  loading: null,
  scorecardData: null,
};

const mapStateToProps = state => ({
  loading: state.score.loading.fetchScorecardContent,
  scorecardData: state.score.scorecardItem,
});

export default connect(mapStateToProps)(ActionsInList);
