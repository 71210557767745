import useDidMountEffect from './useDidMountEffect';
import useModalHook from './useModalHook';
import useShowModalHook from './useShowModalHook';
import useTableFilterHook from './useTableFilterHook';
import usePrefillHook from './usePrefillHook';
import useReloadTableHook from './useReloadTableHook';
import useTableHook from './useTableHook';
import useSelectedRecordsTableHook from './useSelectedRecordsTableHook';
import useSelectedIdsTableHook from './useSelectedIdsTableHook';
import useAccessHook from './useAccessHook';
import useNoticeHook from './useNoticeHook';
import useErrorHook from './useErrorHook';

const getFromUrlSelectfunction = ({
  filters, filterName, property, initialState,
}) => {
  let items = (Array.isArray(filters[filterName]) ? filters[filterName] : [filters[filterName]]);
  items = filters[filterName] ? items : [];
  const tags = items?.map((tag) => {
    const prop = property.options.find(a => a.value === tag);
    if (!prop) return null;
    return { ...prop, value: prop.value, label: prop.label };
  }).filter(a => a) || [];
  return { tags: (tags.length > 0 ? tags : initialState.tags) };
};
const getToUrlSelectfunction = ({
  search, item, filterName,
}) => {
  const key = `filters.${filterName}`;
  search.delete(key);
  item?.tags?.forEach((obj) => {
    const { value } = obj || {};
    search.append(key, value);
  });
  return search;
};


const getFromUrlTagsfunction = ({
  filters, filterName, property, initialState,
}) => {
  let items = (Array.isArray(filters[filterName]) ? filters[filterName] : [filters[filterName]]);
  items = filters[filterName] ? items : [];
  const tags = items?.map((tag) => {
    const prop = property.options.find(a => String(a.id) === String(tag));
    if (!prop) return null;
    return { ...prop, name: prop.name, id: prop.id };
  }).filter(a => a) || [];

  return { tags: (tags.length > 0 ? tags : initialState.tags) };
};
const getToUrlTagsfunction = ({
  search, item, filterName,
}) => {
  const key = `filters.${filterName}`;
  search.delete(key);
  item?.tags?.forEach((obj) => {
    const { id } = obj || {};
    search.append(key, id);
  });
  return search;
};

const getFromUrlStringfunction = ({ filters, filterName }) => filters[filterName] || '';
const getToUrlStringfunction = ({ search, item, filterName }) => {
  if (item) {
    search.set(`filters.${filterName}`, item);
  } else {
    search.delete(`filters.${filterName}`);
  }
  return search;
};

const getFromUrlCheckboxFunction = ({ initialState: IS, filters, filterName }) => {
  console.log({ initialState: IS, filters, filterName });
  const init = { ...(IS || {}) };
  if (filters[filterName]) {
    Object.keys(IS).forEach((key) => {
      init[key] = filters[filterName].includes(key);
    });
  }
  return init;
};
const getToUrlCheckboxFunction = ({ search, item, filterName }) => {
  search.delete(`filters.${filterName}`);
  Object.keys(item).forEach((key) => {
    if (!item[key]) return false;
    search.append(`filters.${filterName}`, key);
    return true;
  });
  return search;
};


const Hooks =  {
  useErrorHook,
  useDidMountEffect,
  useModalHook,
  useShowModalHook,
  useTableFilterHook,
  usePrefillHook,
  useTableHook,
  useSelectedIdsTableHook,
  useTableFilterHookTags: (id, options, property) => {
    // eslint-disable-next-line no-param-reassign
    if (property && options) property.options = options;
    const [state, setState, pr] = useTableFilterHook(id, property || {
      initialState: { tags: [] },
      func: ({ filters, property: p }) => {
        let items = (Array.isArray(filters?.contentReleaseDateMonth) ? filters?.contentReleaseDateMonth : [filters?.contentReleaseDateMonth]);
        items = filters?.contentReleaseDateMonth ? items : [];
        const tags = items?.map((tag) => {
          const prop = p.options.find(a => a?.value === tag);
          if (!prop) return null;
          return { value: prop?.value, label: prop.label };
        }).filter(a => a) || [];
        return { tags };
      },
      funcToUrl: ({
        search, item, filterName,
      }) => {
        const key = `filters.${filterName}`;
        search.delete(key);
        item?.tags?.forEach((obj) => {
          const { value } = obj || {};
          search.append(key, value);
        });
        return search;
      },
      options,
      filterName: id,
    });
    return [state?.tags || [], tags => setState({ tags: tags || [] }), pr?.options || []];
  },
  useSelectedRecordsTableHook,
  useTableFilterHookString: (id, property) => {
    const [state, setState] = useTableFilterHook(id, property || {
      initialState: '',
      func: getFromUrlStringfunction,
      filterName: id,
      funcToUrl: getToUrlStringfunction,
    });
    return [state || '', string => setState(string || '')];
  },
  useTableFilterHookCheckbox: (id, initialState, property) => {
    // eslint-disable-next-line no-param-reassign
    if (property && initialState) property.initialState = initialState;
    const [state, setState] = useTableFilterHook(id, property || {
      initialState,
      func: getFromUrlCheckboxFunction, /* ({ initialState: IS, filters, filterName }) => {
        console.log({ initialState: IS, filters, filterName });
        const init = { ...(IS || {}) };
        if (filters[filterName]) {
          Object.keys(IS).forEach((key) => {
            init[key] = filters[filterName].includes(key);
          });
        }
        return init;
      }, */
      filterName: id,
      funcToUrl: getToUrlCheckboxFunction, /* ({ search, item, filterName }) => {
        search.delete(`filters.${filterName}`);
        Object.keys(item).forEach((key) => {
          if (!item[key]) return false;
          search.append(`filters.${filterName}`, key);
          return true;
        });
        return search;
      }, */
    });
    return [state || {}, (event, name) => {
      if (event && event.target) {
        const { checked } = event.target;
        setState({ [name]: checked });
      }
    }];
  },
  useReloadTableHook,

  getFromUrlSelectfunction,
  getToUrlSelectfunction,
  getFromUrlTagsfunction,
  getToUrlTagsfunction,
  getFromUrlCheckboxFunction,
  getToUrlCheckboxFunction,
  useAccessHook,

  useNoticeHook,
};
export default Hooks;
