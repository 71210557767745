import InfluencerInList from './InfluencerInList';
import AmountInList from './AmountInList';
import PayoutInList from './PayoutInList';
import ItemsInList from './ItemsInList';
import CategoryInList from './CategoryInList';
import QuantityInList from './QuantityInList';

export default {
  show: {
  },
  list: {
    influencer: InfluencerInList,
    amount: AmountInList,
    payout: PayoutInList,
    items: ItemsInList,
    category: CategoryInList,
    quantity: QuantityInList,
  },
  edit: {
  },
};
