import React, { useState } from 'react';
import { unflatten } from 'flat';
import PropTypes from 'prop-types';
import { Modal, Button, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import ApiClient from 'ApiClient';
import withNotice from '../../../../../App/store/with-notice';
import TableCellContextMenu from '../../../../Influencer/Research/Prospects/components/custom/TableCellContextMenu';

const ContextMenuInList = ({
  record,
  meta,
  addNotice,
}) => {
  const user = unflatten(record);
  const [loading, setLoading] = useState(false);
  const [showRemoveUser, setShowRemoveUser] = useState(false);

  const handleDeleteDialogSubmit = async () => {
    setLoading(true);
    const api = new ApiClient();
    const response = await api.recordAction({
      resourceId: 'AdminUser', actionName: 'delete', recordId: user.id,
    });
    meta.closeFunction();
    if (response.status === 200) {
      addNotice({
        message: 'The selected user has been deleted',
        type: 'success',
      });
      meta.closeFunction();
    } else {
      console.log('Response of deleting user', response);
      addNotice({
        message: 'Couldn\'t delete the selected user',
        type: 'error',
      });
    }
    setShowRemoveUser(!showRemoveUser);
  };

  const goToPage = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  const contextMenuOptions = [
    {
      label: 'Details',
      handler: () => goToPage(`/resources/AdminUser/records/${user.id}/show`),
    },
    {
      label: 'Edit',
      handler: () => goToPage(`/resources/AdminUser/records/${user.id}/edit`),
    },
    {
      label: 'Delete',
      handler: () => setShowRemoveUser(!showRemoveUser),
    },
  ];

  return (
    <>
      <TableCellContextMenu options={contextMenuOptions} />

      <Modal isOpen={showRemoveUser} toggle={() => setShowRemoveUser(!showRemoveUser)} className="delete-modal" size="sm">
        <ModalHeader toggle={() => setShowRemoveUser(!showRemoveUser)} >
          Delete User
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete the selected User?<br />

        </ModalBody>
        <ModalFooter>
          <Button
            disabled={loading}
            className="btn-sm"
            color="danger"
            onClick={handleDeleteDialogSubmit}
          >
            Delete
          </Button>{' '}
          <Button
            disabled={loading}
            className="btn-sm"
            color="secondary"
            onClick={() => setShowRemoveUser(!showRemoveUser)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  meta: PropTypes.objectOf(PropTypes.any).isRequired,
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(ContextMenuInList);
