/* eslint react/prop-types: 0 */
import React from 'react';
import { unflatten } from 'flat';
import { uid } from 'react-uid';

export default (props) => {
  const { record } = props;
  const { items } = unflatten(record.populated);
  if (items) {
    return (
      <div>
        {items.map(item => (
          <p key={uid(item.id)}>{item.params.category}</p>
        ))}
      </div>
    );
  }
  return '';
};
