import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'mdi-react/PencilIcon';
import Tooltip from '@material-ui/core/Tooltip';
import AgentForm from './AgentForm';

class EditButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agentDialogVisible: false,
    };
    this.showEditForm = this.showEditForm.bind(this);
    this.hideEditForm = this.hideEditForm.bind(this);
  }

  showEditForm() {
    this.setState({ agentDialogVisible: true });
  }

  hideEditForm() {
    this.setState({ agentDialogVisible: false });
  }

  render() {
    const { agent } = this.props;
    const { agentDialogVisible } = this.state;
    return (
      <Tooltip title="Edit" className="editToolTip">
        <button
          className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall"
          onClick={this.showEditForm}
        >
          <Icon
            style={{ width: '15px', height: '15px' }}
          />
          <AgentForm
            modal={agentDialogVisible}
            modalTitle="Edit Agent"
            closeDialog={this.hideEditForm}
            row={agent}
          />
        </button>
      </Tooltip>
    );
  }
}

EditButton.propTypes = {
  agent: PropTypes.PropTypes.objectOf(PropTypes.any).isRequired,
};

export default EditButton;
