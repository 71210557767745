import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ButtonToolbar, Button, ModalHeader } from 'reactstrap';


const TranscriptTextModal = ({ record, showTranscriptText, setShowTranscriptText }) => (
  <Modal
    isOpen={showTranscriptText}
    toggle={() => { setShowTranscriptText(false); }}
  >
    <ModalHeader
      toggle={() => { setShowTranscriptText(false); }}
      tag="h4"
      className="modal-title-statistics"
    >
      Transcript
    </ModalHeader>
    {/* eslint-disable-next-line react/no-danger */}
    <div className="modal__body" dangerouslySetInnerHTML={{ __html: record.params.transcriptionText !== 'null' ? record.params.transcriptionText : '' }} />
    <ButtonToolbar className="modal__footer">
      <Button className="btn btn-sm" color="secondary" onClick={() => { setShowTranscriptText(false); }}>Close</Button>
    </ButtonToolbar>
  </Modal>
);

TranscriptTextModal.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  showTranscriptText: PropTypes.bool.isRequired,
  setShowTranscriptText: PropTypes.func.isRequired,
};
export default TranscriptTextModal;
