import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import { unflatten } from 'flat';
import WVValidator from '../../../../../../shared/helpers/WVValidator';

class PaymentInEdit extends React.Component {
  onPaymentProviderChanged = (option) => {
    console.log(option);
    const { onChange } = this.props;
    onChange('payment.stripe.isDefault', option.value === 'stripe');
    onChange('payment.billCom.isDefault', option.value !== 'stripe');
  }

  paymentOptions() {
    const { record } = this.props;
    const influecer = unflatten(record.params);
    let hasStripe = false;
    let hasBillCom = false;

    if (influecer && influecer.payment) {
      if (influecer.payment.stripe) {
        hasStripe = !WVValidator.isEmpty(influecer.payment.stripe.accountId);
      }
      if (influecer.payment.billCom) {
        hasBillCom = !WVValidator.isEmpty(influecer.payment.billCom.accountId);
      }
    }

    return [
      { value: 'stripe', label: `Stripe${!hasStripe ? ' (not connected)' : ''}`, isDisabled: !hasStripe },
      { value: 'billCom', label: `Bill.com${!hasBillCom ? ' (not connected)' : ''}`, isDisabled: !hasBillCom },
    ];
  }

  defaultOption() {
    const { record } = this.props;
    const influecer = unflatten(record.params);
    let isStripeDefault = false;
    let isBillComDefault = false;

    if (influecer && influecer.payment) {
      if (influecer.payment.stripe) {
        isStripeDefault = influecer.payment.stripe.isDefault;
      }
      if (influecer.payment.billCom) {
        isBillComDefault = influecer.payment.billCom.isDefault;
      }
    }

    const options = this.paymentOptions();

    if (isStripeDefault) {
      return options[0];
    }
    if (isBillComDefault) {
      return options[1];
    }

    return null;
  }

  render() {
    return (
      <section>
        <h4 className="il-color-deep-blue font-weight-bold mt-5">Payments</h4>
        <hr className="mt-3 mb-4" />
        <FormGroup>
          <Label>Default Payment Provider</Label>
          <Select
            defaultValue={this.defaultOption()}
            options={this.paymentOptions()}
            onChange={this.onPaymentProviderChanged}
            placeholder="Select Default Payment Provider"
            className="mb-3"
          />
        </FormGroup>
      </section>
    );
  }
}

PaymentInEdit.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PaymentInEdit;
