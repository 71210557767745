import React, { useEffect, useState } from 'react';
import { unflatten } from 'flat';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Modal, ButtonToolbar, Button, Badge, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { axios } from 'ApiClient';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import formater from '../../../../../../shared/helpers/WVFormatter';
import NewBillPayout from './NewBillPayout';
import { getConstant } from '../../../../../../shared/helpers/WVConstants';
import TableCellContextMenu from '../../../../Influencer/Research/Prospects/components/custom/TableCellContextMenu';
import { showModalById } from '../../../../../../redux/reducers/TableReducer';
import { modalId } from '../../modals/RefreshBillModal';
import { addNotice } from '../../../../../App/store/store';


const columnTransform = {
  payer: {
    title: 'Payer',
    valueFunc: record => (record.adminUserName || ''),
    sort: record => record.adminUserName,
  },
  startDate: {
    title: 'Payment Date (ET)',
    valueFunc: record => formater.formatToEST(moment(record.date || '')).format('MM/DD/YY'),
    sort: record => formater.formatToEST(moment(record.date || '')),
  },
  amount: {
    title: 'Amount',
    valueFunc: record => formater.formatCurrency(Number(record.amount || 0)),
    sort: record => Number(record.amount || 0),
  },
  method: {
    title: 'Method',
    valueFunc: (record) => {
      const paymentMethod = getConstant('bill', 'paymentMethod', {});
      return (record.method && paymentMethod[record.method] ? paymentMethod[record.method] : '-');
    },
    sort: record => record.method,
  },
  status: {
    title: 'Status',
    valueFunc: record => (record.transactionStatus === 'success' ? (<Badge color="primary">Success</Badge>) : (<Badge color="danger">Failure</Badge>)),
    sort: record => record.transactionStatus,
  },
  transactionId: {
    title: 'Transaction Id',
    valueFunc: record => (record.transactionId || ''),
    sort: record => record.transactionId,
  },
  notes: {
    title: 'Notes',
    valueFunc: record => (record.notes || ''),
    sort: record => record.notes,
  },
};

const ContextMenuInList = ({
  record,
  meta,
}) => {
  const bill = unflatten(record);
  const [hsLink, setHsLink] = useState({});
  const [showHistory, setShowHistory] = useState(false);
  const [showPayout, setShowPayout] = useState(false);
  const [showRemoveInvoice, setShowRemoveInvoice] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const {
      dealObject,
    } = bill.params;
    setHsLink(typeof dealObject !== 'undefined' ? `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${dealObject.hs_object_id}` : null);
  }, [bill]);

  const handleDeleteDialogSubmit = () => {
    axios({
      method: 'post',
      url: '/api/portal/finance/post-delete-bill',
      data: {
        id: record.id,
      },
    }).then(() => {
      if (meta && meta.closeFunction) {
        meta.closeFunction();
      }
    }).catch((error) => {
      console.error(error);
    });
    setShowRemoveInvoice(!showRemoveInvoice);
  };

  const goToPage = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  const contextMenuOptions = [
    {
      label: 'Send Payment',
      handler: () => setShowPayout(true),
      disabled: !bill.populated?.deal?.params?.authorizedPayment?.summaryValidation,
    },
    { type: 'divider', label: 'divider1' },
    {
      label: 'View Payment History',
      handler: () => setShowHistory(!showHistory),
    },
  ];

  if (hsLink) {
    contextMenuOptions.push({
      label: 'View HubSpot Deal',
      handler: () => goToPage(hsLink),
    });
  }

  contextMenuOptions.push({ type: 'divider', label: 'divider2' });

  contextMenuOptions.push({
    label: 'Update Payment Authorizations',
    handler: async () => {
      try {
        await axios.post('/api/portal/finance/post-deal-validation-prefill', { ids: [bill.params?.deal] });
        dispatch(addNotice({
          message: 'Authorization has been updated',
          type: 'success',
        }));
        if (meta && meta.closeFunction) {
          meta.closeFunction();
        }
      } catch (e) {
        console.log(e);
      }
    },
  });

  contextMenuOptions.push({
    label: 'Edit Bill',
    handler: () => goToPage(`/finance/Bill/${bill.id}`),
  });

  if (bill.params.payments.length === 0) {
    contextMenuOptions.push({
      label: 'Update Bill',
      handler: () => dispatch(showModalById(modalId, { record })),
    });
  }
  if (bill.params.payments.length === 0 && !bill.params.dealObject) {
    contextMenuOptions.push({
      label: 'Remove Bill',
      handler: () => setShowRemoveInvoice(!showRemoveInvoice),
    });
  }

  return (
    <>
      <TableCellContextMenu options={contextMenuOptions} />

      <Modal
        isOpen={showHistory}
        toggle={() => setShowHistory(!showHistory)}
        size="lg"
        style={{ maxWidth: '1200px' }}
      >
        <div className="modal__header">
          <h4 className="text-modal  modal__title">Payment History</h4>
        </div>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="modal__body ltr-support theme-light">
          {showHistory ? (
            <MatTableForRedux
              dataArray={bill.params.payments}
              selectable={false}
              columns={columnTransform}
              version={(new Date()).toString()}
              rowsPerPage={5}
            />
          ) : null}
        </div>
        <ButtonToolbar className="modal__footer">
          <Button color="danger" onClick={() => setShowHistory(!showHistory)}>Close</Button>
        </ButtonToolbar>
      </Modal>

      <Modal isOpen={showRemoveInvoice} toggle={() => setShowRemoveInvoice(!showRemoveInvoice)} className="delete-modal" size="sm">
        <ModalHeader toggle={() => setShowRemoveInvoice(!showRemoveInvoice)} >
          Remove Bill
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete the selected Bill?<br />

        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-sm"
            color="danger"
            onClick={handleDeleteDialogSubmit}
          >
            Delete
          </Button>{' '}
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => setShowRemoveInvoice(!showRemoveInvoice)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <NewBillPayout
        modal={showPayout}
        bill={record}
        modalTitle="New Payment"
        closeDialog={(isUpdate) => {
          setShowPayout(false);
          if (meta && meta.closeFunction && isUpdate) {
            meta.closeFunction();
          }
        }}
      />
    </>
  );
};

ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  meta: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ContextMenuInList;
