import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ListGroup,
  ListGroupItem,
  ModalFooter,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  setShowSafetyCheck,
  setCurrentMediaContent,
} from '../../../../../redux/reducers/brand/CreatorsReducer';

const TabsOrModal = React.memo(({
  record, searchTerms, openTabs, dispatch,
}) => {
  const [showModal, setShowModal] = useState(false);

  const newSearchTerms = [...searchTerms];
  const openNewTabs = () => {
    dispatch(setShowSafetyCheck(false));
    const wTabs = { ...(window?.customTabs || {}) };
    let isBlocked = false;
    if (newSearchTerms) {
      newSearchTerms.forEach((term) => {
        if (!wTabs[term] || wTabs[term].closed) {
          const tryOpenWindow = window.open('', '_blank');
          if (!tryOpenWindow) {
            isBlocked = true;
            return false;
          }
          tryOpenWindow.blur();
          wTabs[term] = tryOpenWindow;
        }
        return true;
      });
    }
    if (isBlocked) {
      // console.log(wTabs);
      if (Object.values(wTabs)) Object.values(wTabs).forEach(t => t && t.close());
      setShowModal(true);
    } else {
      // console.log(wTabs);
      if (newSearchTerms) {
        newSearchTerms.forEach((term) => {
          // console.log(term);
          const query = new URLSearchParams();
          const title = (record?.params.creatorFullName ? record.params.creatorFullName : (record?.params.title || ''));
          query.set('q', `${title} ${term}`);
          wTabs[term].location.href = `https://www.google.com/search?${query.toString()}`;
        });
      }
      window.customTabs = wTabs;
    }
  };

  useEffect(() => {
    if (openTabs) {
      openNewTabs();
    }
  }, [openTabs]);

  useEffect(() => {
    if (!showModal) {
      dispatch(setShowSafetyCheck(false));
      dispatch(setCurrentMediaContent({}));
    }
  }, [showModal]);

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(false)}
      >
        <ModalHeader toggle={() => setShowModal(false)} tag="h4" className="pl-0">Safety Check</ModalHeader>
        <div>
          <div className="pt-3">Disable your Pop-Up Blocker for this site if you wish to automatically open search results in new tabs.</div>
          <ListGroup className="mt-3">
            {
              newSearchTerms.map((term) => {
                const query = new URLSearchParams();
                const title = record?.params?.creatorFullName ? record.params.creatorFullName : '';
                query.set('q', `${title} ${term}`);
                const url = `https://www.google.com/search?${query.toString()}`;
                return (<ListGroupItem key={term} >{term} <a href={url} target="_blank" rel="noreferrer"><i className="fa fa-external-link-alt float-right" /></a></ListGroupItem>);
              })
            }
          </ListGroup>
        </div>
        <ModalFooter className="modal__footer">
          <Button
            className="btn-sm modal_cancel"
            color="secondary"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}, (a, a1) => _.isEqual(a.record, a1.record) && _.isEqual(a.openTabs, a1.openTabs) && _.isEqual(a.searchTerms, a1.searchTerms));

TabsOrModal.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  searchTerms: PropTypes.arrayOf(PropTypes.any).isRequired,
  openTabs: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

TabsOrModal.defaultProps = {
  record: null,
};

const mapStateToProps = state => ({
  searchTerms: state.creators.brandSafetySearchTerms,
  openTabs: state.creators.showSafetyCheck,
  record: state.creators.currentMediaContent,
});

export default connect(mapStateToProps)(TabsOrModal);
