import React, { /* useRef, useMemo, */ useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  useTable, useGlobalFilter, usePagination, useSortBy, useResizeColumns, useRowSelect, useAsyncDebounce,
} from 'react-table';
import ReactTableHeader from '../table/components/ReactTableHeader';
import BodyReactTable from '../table/components/ReactTableBody';
import ReactTableFooter from '../table/components/ReactTableFooter';
import ReactTableFilter from '../table/components/ReactTableFilter';
import ReactTablePagination from '../table/components/ReactTablePagination';


const AdminBroServerSide = ({
  tableConfig, tableOptions, tableOptionalHook, onFetchData, isLoading,
  pageIndex: pageIndexDefault, pageSize: pageSizeDefault, updateRecordFunction, updateSelect,
  enabledStyles, enableWarning, onColumnResize, notFoundProps, useDebounce,
}) => {
  const {
    isEditable,
    isResizable,
    isSortable,
    useFlex,
    withPagination,
    withSearchEngine,
    manualPageSize,
    placeholder,
  } = tableConfig;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    state,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    setGlobalFilter,
    withDragAndDrop,
    updateDraggableData,
    updateEditableData,
    dataLength,
    setSortBy,
    state: {
      pageIndex, pageSize, sortBy, filters, selectedRowIds, columnResizing,
    },
  } = useTable(
    tableOptions,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useResizeColumns,
    useRowSelect,
    ...tableOptionalHook,
  );

  console.log(sortBy, 'sortBy on end')
  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 100);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (updateSelect) updateSelect(selectedRowIds);
  }, [selectedRowIds]);

  useEffect(() => {
    if (
      columnResizing
      && Object.keys(columnResizing.columnWidths).length !== 0
      && columnResizing.isResizingColumn === null
    ) onColumnResize(columnResizing.columnWidths);
  }, [columnResizing]);

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (pageSize !== tableOptions.initialState.pageSize && tableOptions.initialState.pageSize >= 0) {
      console.log('change page size');
      // eslint-disable-next-line react/prop-types
      setPageSize(tableOptions.initialState.pageSize);
    }
    // eslint-disable-next-line react/prop-types
  }, [tableOptions.initialState.pageSize]);

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (pageIndex !== tableOptions.initialState.pageIndex && tableOptions.initialState.pageIndex >= 0) {
      console.log('go to page');
      // eslint-disable-next-line react/prop-types
      gotoPage(tableOptions.initialState.pageIndex);
    }
  // eslint-disable-next-line react/prop-types
  }, [tableOptions.initialState.pageIndex]);

  useEffect(() => {
    // console.log([sortBy[0].id, tableOptions.initialState.sortBy[0].id], 'COMPARE SORT BY')
    // eslint-disable-next-line react/prop-types
    if (tableOptions.initialState.allowChange === true && tableOptions.initialState?.sortBy[0]?.id && sortBy[0]?.id !== tableOptions.initialState.sortBy[0]?.id) {
      console.log('change page sortBy');
      // eslint-disable-next-line react/prop-types
      setSortBy(tableOptions.initialState.sortBy);
    }
    // eslint-disable-next-line react/prop-types
  }, [tableOptions.initialState.sortBy]);

  useEffect(() => {
    // Every change will call our debounced function
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (useDebounce) {
      onFetchDataDebounced({
        pageIndex, pageSize, sortBy, filters,
      });
    } else {
      onFetchData({
        pageIndex, pageSize, sortBy, filters,
      });
    }
    // Only the last call after the 100ms debounce is over will be fired!
  }, [onFetchDataDebounced, pageIndex, pageSize, sortBy, filters]);
  return (
    <div style={{ opacity: isLoading ? '0.6' : '1' }} className="mt-3">
      {withSearchEngine && null && (
        <ReactTableFilter
          rows={rows}
          setGlobalFilter={setGlobalFilter}
          setFilterValue={tableOptions.setFilterValue}
          globalFilter={state.globalFilter}
          placeholder={placeholder}
          dataLength={dataLength}
        />
      )}
      <div className="table-wrapper">
        <div className={withPagination ? 'table react-table' : 'table react-table table--not-pagination'}>
          <table
            {...getTableProps()}
            className={isEditable ? 'react-table editable-table' : `react-table${isResizable ? ' resizable-table' : ''}`}
          >
            <ReactTableHeader
              headerGroups={headerGroups}
              isSortable={isSortable}
              isResizable={isResizable}
            />
            <BodyReactTable
              page={page}
              getTableBodyProps={getTableBodyProps}
              prepareRow={prepareRow}
              updateDraggableData={updateDraggableData}
              updateEditableData={updateEditableData}
              isEditable={isEditable}
              withDragAndDrop={withDragAndDrop}
              updateRecordFunction={updateRecordFunction}
              enabledStyles={enabledStyles}
              enableWarning={enableWarning}
              isResizable={isResizable}
              isLoading={isLoading}
              useFlex={useFlex}
              notFoundProps={{
                columnLength: headerGroups[0].headers.length,
                title: notFoundProps.title || 'No records found',
                styles: notFoundProps.styles || {},
              }}
            />
            {(pageCount === (pageIndex + 1) || (!withPagination && rows.length !== 0)) && (
              <ReactTableFooter
                footerGroups={footerGroups}
              />
            )}
          </table>
        </div>
      </div>
      {(rows.length > 0 && withPagination) && (
        <ReactTablePagination
          page={page}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageSize={pageSizeDefault}
          pageIndex={pageIndexDefault}
          pageCount={pageCount}
          setPageSize={setPageSize}
          manualPageSize={manualPageSize}
          dataLength={dataLength}
        />
      )}
    </div>
  );
};

AdminBroServerSide.propTypes = {
  tableConfig: PropTypes.shape({
    isEditable: PropTypes.bool,
    isResizable: PropTypes.bool,
    isSortable: PropTypes.bool,
    useFlex: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    withPagination: PropTypes.bool,
    withSearchEngine: PropTypes.bool,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
    placeholder: PropTypes.string,
  }),
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  onFetchData: PropTypes.func,
  tableOptions: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    data: PropTypes.arrayOf(PropTypes.shape()),
    setFilterValue: PropTypes.func,
    updateDraggableData: PropTypes.func,
    updateEditableData: PropTypes.func,
    defaultColumn: PropTypes.oneOfType([
      PropTypes.any,
      PropTypes.shape({
        Cell: PropTypes.func,
      }).isRequired,
    ]),
    isEditable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    dataLength: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
  tableOptionalHook: PropTypes.arrayOf(PropTypes.func).isRequired,
  updateRecordFunction: PropTypes.func,
  updateSelect: PropTypes.func,
  onColumnResize: PropTypes.func,
  enabledStyles: PropTypes.bool,
  enableWarning: PropTypes.bool,
  useDebounce: PropTypes.bool,
  notFoundProps: PropTypes.objectOf(PropTypes.any),
};

AdminBroServerSide.defaultProps = {
  tableConfig: {
    isEditable: false,
    isResizable: false,
    isSortable: false,
    useFlex: false,
    withDragAndDrop: false,
    withPagination: false,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  },
  pageIndex: 0,
  pageSize: 10,
  onFetchData: () => {},
  tableOptions: [{
    columns: [],
    data: [],
    setFilterValue: () => {},
    updateDraggableData: () => {},
    updateEditableData: () => {},
    defaultColumn: [],
    withDragAndDrop: false,
    dataLength: null,
    disableSortBy: false,
    manualSortBy: false,
    manualGlobalFilter: false,
    manualPagination: false,
  }],
  isLoading: false,
  updateRecordFunction: () => {},
  updateSelect: () => {},
  onColumnResize: () => {},
  enabledStyles: true,
  enableWarning: true,
  notFoundProps: {},
  useDebounce: true,
};

export default AdminBroServerSide;
