/* eslint react/prop-types: 0 */
import React from 'react';
import { Button } from 'reactstrap';

export default (props) => {
  const { record } = props;
  const link = `/resources/MediaChannel/actions/list?filters.campaigns=${record.id}`;
  return (
    <Button
      tag="a"
      href={link}
      color="primary"
      outline
      size="sm"
      target="_blank"
      rel="noreferrer noopener"
      style={{ margin: '0' }}
    >
      Show Podcasts
    </Button>
  );
};
