import PayableInList from './PayableInList';

export default {
  show: {
  //  status: CampaignStatusInShow,
  //  status: CampaignStatusInList,
  },
  list: {
    payable: PayableInList,
  },
};
