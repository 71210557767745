import { unflatten } from 'flat';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import mapMediaTypeIcons from '../../../MediaContent/components/custom/mapMediaTypeIcons';

const mapType = mapMediaTypeIcons();

const TypeInList = ({ record }) => {
  const recortUnflatten = unflatten(record);
  const { type, mediaUrl, contentText } = recortUnflatten.params.mediaContent;
  const [showContentText, setShowContentText] = useState(false);

  const toggleDialog = (e) => {
    e.preventDefault();
    setShowContentText(!showContentText);
  };

  const style = type.length < 20 ? { whiteSpace: 'nowrap' } : {};
  const isTextType = type === 'YouTube Description Text' || type === 'Podcast Script Text' || type === 'Twitter Post' || type === 'LinkTree' || type === 'Text';

  return (
    <>
      <span style={style}>
        <img style={{ width: '30px' }} src={`/images/mediacontent/${mapType[type]}.png`} alt={type} title={type} />
        &nbsp;
        {type}
        &nbsp;&nbsp;&nbsp;
        {isTextType ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a title="Show Content Text" href="#" onClick={toggleDialog}>
            <FontAwesomeIcon icon={faClipboard} />
          </a>
          )
          : mediaUrl &&
          // eslint-disable-next-line react/jsx-no-target-blank
          <a target="_blank" href={mediaUrl}>
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
        }
      </span>
      {isTextType && (
        <Modal
          isOpen={showContentText}
          toggle={toggleDialog}
        >
          <div className="modal__header">
            <h4 className="text-modal  modal__title">{type}</h4>
          </div>
          {/* eslint-disable-next-line react/no-danger */}
          <div className="modal__body">{contentText}</div>
          <ButtonToolbar className="modal__footer">
            <Button className="btn btn-primary btn-sm" onClick={toggleDialog}>Close</Button>
          </ButtonToolbar>
        </Modal>
      )}
    </>
  );
};

TypeInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default TypeInList;

