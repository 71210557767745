import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import Box from './Box';
import withNotice from '../../../containers/App/store/with-notice';

const Stat = ({ addNotice, labels, url }) => {
  const [totals, setTotals] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    if (url) {
      setLoading(true);
      try {
        const resp = await axios.get(url);
        if (resp?.data?.success) {
          setTotals(resp.data);
          setLoading(false);
        } else {
          throw new Error(resp?.data?.error);
        }
      } catch (e) {
        console.error(e);
        addNotice({
          message: e.message,
          type: 'error',
        });
      }
    }
  };

  useEffect(fetchData, [url]);

  return (
    <Row style={{ maxWidth: `${Object.keys(labels).length * 279}px`, margin: '0 auto' }}>
      {Object.keys(labels).map(key => (
        <Col key={key} className="pl-4 pr-4 pb-4">
          <Box label={labels[key]} value={totals[key]} loading={loading} />
        </Col>
      ))}
    </Row>
  );
};

Stat.propTypes = {
  addNotice: PropTypes.func.isRequired,
  labels: PropTypes.objectOf(PropTypes.any).isRequired,
  url: PropTypes.string,
};

Stat.defaultProps = {
  url: null,
};

export default withNotice(Stat);
