import React from 'react';
import { FormGroup, Label, Modal, Spinner } from 'reactstrap';
import ApiClient from 'ApiClient';
import { axios } from 'ApiClient';
import SelectAsync from 'react-select/async';
import Select from 'react-select';
import PropTypes from 'prop-types';
import CurrencyField from '../../../../../../shared/components/form/CurrencyField';
import StyledButton from '../../../../../../shared/components/components/ui/styled-button';
// import recordToFormData from '../../../../../../shared/components/components/actions/record-to-form-data';
import withNotice from '../../../../../App/store/with-notice';
import formater from '../../../../../../shared/helpers/WVFormatter';
import Invoice from '../../../../../../shared/recordCollection/Invoice';

class BillForm extends React.Component {
  static async loadInfluencerOptions(inputValue) {
    const api = new ApiClient();
    const records = await api.searchRecords({
      resourceId: 'Influencer',
      query: inputValue,
    });
    const res = [];
    records.forEach((record) => {
      if (record.params.email !== null) {
        res.push({
          value: record.id,
          label: record.params.email,
          params: record.params,
          record,
        });
      }
    });
    return res;
  }

  constructor(props) {
    super(props);
    this.api = new ApiClient();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeInfluencer = this.handleChangeInfluencer.bind(this);
    this.fetchData = this.fetchData.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    this.handleElementChange = this.handleElementChange.bind(this);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
    this.closeFormDialog = this.closeFormDialog.bind(this);
    this.model = new Invoice({
      record: props.invoice || {},
      stateName: 'invoice',
      resource: 'Invoice',
      schema: {
        amount: {
          title: 'Amount',
          required: true,
          // eslint-disable-next-line object-shorthand,func-names
          validate: function (value) {
            console.log(this);
            const amount = Number(value);
            if (amount < this.getTotalPaid()) {
              console.error('error');
              throw new Error(`Min value: ${formater.formatCurrency(Number(this.getTotalPaid()))}`);
            }
            return true;
          },
        },
        influencer: {
          title: 'Creator',
          required: true,
        },
        deal: {
          title: 'Deal',
          required: true,
        },
      },
    }, this);
    this.state = {
      dealList: [],
      selectedInfluencer: null,
      loading: {
        form: false,
      },
      invoice: this.model.getModel(),
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  getDeals(influencerId) {
    axios({
      method: 'get',
      url: `/api/portal/influencer/get-deals-by-influencer?id=${influencerId}&object=invoice`,
    }).then((response) => {
      let deals = [];
      if (response.data.success === true) {
        if (response.data.deals !== null) {
          deals = response.data.deals.map(deal => ({ value: deal._id, label: deal.dealname }));
        }
      } else {
        this.props.addNotice({
          message: 'An error occurred ',
          type: 'warning',
        });
      }
      this.setState({
        dealList: deals,
      });
    });
  }
  fetchData() {
    if (this.state.invoice.params._id && this.state.invoice.params.influencerObject) {
      this.getDeals(this.state.invoice.params.influencerObject._id);
    }
    return null;
  }

  handleChangeInfluencer(selected) {
    if (selected) {
      this.getDeals(selected.record.params._id);
      this.setState({ selectedInfluencer: selected });
      this.model.set('influencer', selected.record.params._id !== null ? selected.record.params._id : '');
      this.model.set('deal', null);
    }
  }

  handleChangeAmount(e, value) {
    this.model.set('amount', value);
    this.model.validateParam('amount');
  }
  handleElementChange(event) {
    this.handleChange(`${event.target.name}`, event.target.value);
  }
  async handleSubmit(event) {
    event.preventDefault();
    console.log(this.model);
    await this.model.validate();
    if (this.model.haveErrors()) {
      return true;
    }
    this.setState({
      loading: {
        form: true,
      },
    });
    try {
      await this.model.save();
    } catch (e) {
      console.log(e);
      this.props.addNotice({
        message: e.message,
        type: 'error',
      });
      this.setState({
        loading: {
          form: false,
        },
      });
      return false;
    }
    this.closeFormDialog({ isUpdate: true });
    this.props.addNotice({
      message: 'New Bill has been added successfully',
      type: 'success',
    });
    return false;
  }
  closeFormDialog({ isUpdate = false }) {
    this.setState({
      dealList: [],
      selectedInfluencer: null,
    });
    const { closeDialog } = this.props;
    closeDialog(isUpdate);
  }
  render() {
    const { modalTitle, modal } = this.props;
    const {
      dealList,
      selectedInfluencer,
    } = this.state;
    let selectedInfluencerOption = {};
    if (selectedInfluencer) {
      selectedInfluencerOption = { ...selectedInfluencer };
    }
    const dealListItems = [...dealList];
    if (!this.model.isNew() && this.model.get('dealObject')) {
      dealListItems.push({ value: this.model.get('dealObject')._id, label: this.model.get('dealObject').dealname });
    }
    const selectedDealOption = this.model.get('deal') ? dealListItems.find(deal => deal.value.toString() === this.model.get('deal').toString()) : {};
    return (
      <Modal
        isOpen={modal}
        toggle={this.closeFormDialog}
        modalClassName="ltr-support"
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn btn-sm" type="button" onClick={this.closeFormDialog} />
          <h4 className="text-modal  modal__title">{modalTitle}</h4>
        </div>
        <div className="modal__body finance-form">
          {this.state.loading.form &&
            (
              <div className="text-center"><Spinner color="primary" size="lg" /></div>
            )
          }
          {!this.state.loading.form &&
          (
            <form onSubmit={this.handleSubmit}>
              <FormGroup>
                <Label>Creator</Label>
                {!this.model.isNew() ? (
                  <span> {this.model.get('influencerObject').first_name} {this.model.get('influencerObject').last_name}</span>
                ) : (
                  <SelectAsync
                    cacheOptions
                    value={selectedInfluencerOption}
                    defaultOptions
                    loadOptions={BillForm.loadInfluencerOptions}
                    onChange={this.handleChangeInfluencer}
                    placeholder=""
                  />
                  )}
                {this.model.getErrorView('influencer')}
              </FormGroup>
              <hr />
              <FormGroup>
                <Label>Deal</Label>
                <Select
                  value={selectedDealOption}
                  options={dealListItems}
                  isDisabled={!dealListItems || dealListItems.length === 0}
                  onChange={e => this.model.set('deal', e.value)}
                  placeholder="Select Deal"
                />
                {this.model.getErrorView('deal')}
                <span className="form__form-group-description">Note: We show just Unique deal. If you can not find deal for selected influencer Bill already exist</span>
              </FormGroup>
              <FormGroup>
                <Label>Amount</Label>
                <CurrencyField
                  placeholder="Amount"
                  disabled={false}
                  defaultValue={this.model.get('amount') || 0}
                  onBlur={this.handleChangeAmount}
                />
                {this.model.getErrorView('amount')}
                {this.model.get('payments') && this.model.get('payments').length > 0 && (<span className="form__form-group-description">Min Amount: ${this.model.getTotalPaid()}</span>)}
              </FormGroup>
              <StyledButton
                type="submit"
                className="is-primary"
              >
                <i className="icomoon-save" />
                <span className="btn-text">Save</span>
              </StyledButton>
              &nbsp;&nbsp;
              <StyledButton
                type="button"
                className="btn-danger"
                onClick={this.closeFormDialog}
              >
                <i className="icomoon-save" />
                <span className="btn-text">Cancel</span>
              </StyledButton>
            </form>
          )
          }
        </div>
      </Modal>
    );
  }
}

BillForm.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  modal: PropTypes.bool.isRequired,
  invoice: PropTypes.objectOf(PropTypes.any),
  closeDialog: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
  // influencer: PropTypes.objectOf(PropTypes.any).isRequired,
};

BillForm.defaultProps = {
  invoice: {},
};


export default withNotice(BillForm);
