import TypeInEdit from './TypeInEdit';
import EmailAddressLabelInEdit from './EmailAddressLabelInEdit';
import EmailAddressInEdit from './EmailAddressInEdit';
import BccEmailAddressesInEdit from './BccEmailAddressesInEdit';
import BodyInEdit from './BodyInEdit';
import UseWrappingInEdit from './UseWrappingInEdit';

export default {
  edit: {
    type: TypeInEdit,
    emailAddressLabel: EmailAddressLabelInEdit,
    emailAddress: EmailAddressInEdit,
    bccEmailAddresses: BccEmailAddressesInEdit,
    body: BodyInEdit,
    useWrapping: UseWrappingInEdit,
  },
};
