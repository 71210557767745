/* eslint no-restricted-syntax: 0 */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import { Row, Col, FormGroup, Input, Button, Label } from 'reactstrap';
import CheckboxFilter from '../../CheckboxFilter';
// import { PropertyPlace } from 'admin-bro/lib/backend/decorators/property-json.interface';

// import StyledButton from '../ui/styled-button';
// import PropertyType from '../property-type';
// import Panel from '../../../components/Panel';


class Filter extends React.Component {
  static propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    // resource: PropTypes.objectOf(PropTypes.any).isRequired,
    columns: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    additionalActions: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      props: PropTypes.objectOf(PropTypes.any),
    })),
    showSearch: PropTypes.bool,
  };

  static defaultProps = {
    additionalActions: null,
    showSearch: true,
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.resetFilter = this.resetFilter.bind(this);

    this.selectColumn = this.selectColumn.bind(this);
    const filters = this.parseQuery();
    const keys = Object.keys(filters);
    const rightKeys = props.columns.filter(c => keys.includes(c.name));
    const key = rightKeys.length > 0 ? rightKeys[0].name : null;
    this.state = {
      filter: filters,
      searchValueSelected: key ? filters[key] : '',
      searchColumnSelected: key || (props.columns[0] ? props.columns[0].name : null),
    };
    // console.log('started FILTER', props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { match } = this.props;
    if (nextProps.match.params.resourceId !== match.params.resourceId) {
      this.setState({ filter: {} });
    }
  }

  selectColumn(e) {
    const { value } = e.target;
    this.setState(state => ({
      ...state,
      filter: {
        [value]: state.searchValueSelected,
      },
      searchColumnSelected: value,
    }));
  }
  changeValue(e) {
    const { value } = e.target;
    this.setState(state => ({
      ...state,
      filter: {
        [state.searchColumnSelected]: value,
      },
      searchValueSelected: value,
    }));
  }
  parseQuery() {
    const { location } = this.props;
    const filter = {};
    const query = new URLSearchParams(location.search);
    /* eslint no-unused-vars: 0 */
    for (const entry of query.entries()) {
      const [key, value] = entry;
      if (key.match('filters.')) {
        filter[key.replace('filters.', '')] = value;
      }
    }
    return filter;
  }

  handleSubmit(event) {
    event.preventDefault();
    const { filter } = this.state;
    const { history, columns } = this.props;
    const search = new URLSearchParams(window.location.search);
    Object.values(columns).forEach(({ name }) => {
      // console.log(value);
      if (filter[name] && filter[name] !== '') {
        search.set(`filters.${name}`, filter[name]);
      } else {
        search.delete(`filters.${name}`);
      }
    });
    search.set('page', '1');
    history.push(`${history.location.pathname}?${search.toString()}`);
    return false;
  }

  resetFilter(event) {
    const { history, additionalActions } = this.props;
    event.preventDefault();
    const filteredSearch = new URLSearchParams();
    const search = new URLSearchParams(window.location.search);

    for (const key of search.keys()) {
      if (!key.match('filters.')) {
        filteredSearch.set(key, search.get(key));
      }
    }
    const query = filteredSearch.toString() === '' ? `?${filteredSearch.toString()}` : '';
    history.push(history.location.pathname + query);
    this.setState({ filter: {}, searchValueSelected: '' });
    additionalActions.forEach(e => e.reset && e.reset());
  }

  render() {
    // console.log('RENDER FILTER', this.props);
    const { columns, additionalActions, showSearch } = this.props;
    // console.log(additionalActions);
    const { filter } = this.state;
    return (
      <Col>
        <form onSubmit={this.handleSubmit} className="mt-2" >
          <Row>
            {additionalActions && additionalActions.length > 0 && additionalActions.filter(item => !item.row || item.row === 1).map((item, i, array) => {
              if (item.type && item.type === 'checkboxFilter') {
                return (
                  <Col xs="auto" key={uid(item.title)}>
                    <CheckboxFilter
                      filterProp={item.prop}
                      filterTitle={item.title}
                      filterAction={item.action}
                      style={{ paddingTop: 0 }}
                      classname="btn-sm pl-0"
                    />
                  </Col>
                );
              }
              if (item.type && item.type === 'button') {
                return (
                  <Col xs="auto" key={uid(item.title)}>
                    <Button size="sm" color="primary" onClick={item.action}>
                      {item.title}
                    </Button>
                  </Col>
                );
              }
              if (item.type && item.type === 'custom') {
                return (
                  <Col xs={showSearch || (i + 1) !== array.length ? 'auto' : ''} key={uid(item.title)}>
                    {item.element}
                  </Col>
                );
              }
              return false;
            })
            }
            {showSearch ? (<Col />) : null}
            {showSearch ? (
              <Col xs="auto">
                <FormGroup>
                  <Input placeholder="Search" type="search" onChange={this.changeValue} value={this.state.searchValueSelected} style={{ minWidth: '150px' }} bsSize="sm" />
                </FormGroup>
              </Col>
              ) : null}
            {showSearch ? (
              <Col xs="auto">
                <Input
                  type="select"
                  name="select"
                  id="exampleSelect"
                  bsSize="sm"
                  onChange={this.selectColumn}
                  defaultValue={this.state.searchColumnSelected}
                >
                  {columns.map(property => (<option value={property.name} key={property.name}>{property.label}</option>))}
                </Input>
              </Col>
            ) : null}
            {showSearch ? (
              <Col xs="auto">
                <Button size="sm" onClick={this.handleSubmit} color="primary">Search</Button>
              </Col>
            ) : null}
            {showSearch ? (
              <Col xs="auto">
                <Button size="sm" onClick={this.resetFilter} color="danger">Reset</Button>
              </Col>
            ) : null}
          </Row>
          <Row>
            {additionalActions && additionalActions.length > 0 && additionalActions.filter(item => item.row && item.row === 2).map((item) => {
                if (item.type && item.type === 'checkboxFilter') {
                  return (
                    <Col xs="auto" key={uid(item.title)}>
                      <CheckboxFilter
                        filterProp={item.prop}
                        filterTitle={item.title}
                        filterAction={item.action}
                        style={{ paddingTop: 0 }}
                        classname="btn-sm"
                      />
                    </Col>
                  );
                }
                if (item.type && item.type === 'button') {
                  return (
                    <Col xs="auto" key={uid(item.title)}>
                      <Button size="sm" color="primary" onClick={item.action}>
                        {item.title}
                      </Button>
                    </Col>
                  );
                }
                if (item.type && item.type === 'custom') {
                  return (
                    // eslint-disable-next-line jsx-a11y/label-has-for
                    <Col xs="auto" key={uid(item.title)}>
                      {item.element}
                    </Col>
                  );
                }
                return false;
              })
              }
          </Row>
        </form>
      </Col>
    );
  }
}

export default withRouter(Filter);
