import React from 'react';

export default (props) => {
  const { record } = props;
  const url = `${window.location.href.substring(0, window.location.href.indexOf('ImpactActionItem'))}ImpactAction/records/${record.params.impactAction}/show`;
  const result = ((typeof record.populated.impactAction !== 'undefined' && record.populated.impactAction !== null)
    ? (
      <a href={url} target="_blank" rel="noreferrer">
        {record.populated.impactAction.params.actionId}
      </a>
    )
    : '');
  return result;
};
