/* eslint react/prop-types: 0 */
import React from 'react';
import Select from 'react-select';
import { axios } from 'ApiClient';
import ApiClient from 'ApiClient';


export default class SlackHandleInEdit extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.api = new ApiClient();
    this.state = {
      users: [],
      selected: {},
      loading: true,
    };
    console.log('here we are');
    this.loadUsers();
  }

  loadUsers() {
    axios.get('/api/portal/adminUsers/get-slack-users').then((response) => {
      const { success, users } = response.data;
      const { record } = this.props;
      if (success) {
        let selected = {};
        users.forEach((item) => {
          if (record.params.slackId === item.value) {
            selected = item;
          }
        });
        this.setState(prevState => ({
          ...prevState,
          loading: false,
          users,
          selected,
        }));
      } else {
        this.setState(prevState => ({
          ...prevState,
          loading: false,
          users: [],
        }));
      }
    }).catch((error) => {
      console.log(error);
      this.setState(prevState => ({
        ...prevState,
        loading: false,
        users: [],
      }));
    });
  }

  handleSelectChange(selected) {
    this.setState({ selected });
    const { resource, record, onChange } = this.props;
    let action = 'edit';
    if (selected === null) {
      selected = this.state.selected;
      action = 'delete';
    }
    const value = selected ? selected.value : '';
    console.log('selected', selected);

    if (typeof record.id !== 'undefined') {
      this.setState({ loading: true });
      const data = new FormData();
      data.append('slackId', value);
      data.append('slackUserId', selected.id);
      data.append('action', action);
      this.api.recordAction({
        resourceId: resource.id,
        recordId: record.id,
        actionName: 'edit',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        this.setState({ loading: false });
        onChange('slackId', value);
        // eslint-disable-next-line
        if (window.REDUX_STATE.session && window.REDUX_STATE.session['_id'] === record.id) {
          window.location.reload();
        }
      });
    } else {
      onChange('slackId', value);
    }
    return true;
  }

  render() {
    const { selected, loading, users } = this.state;
    return (
      <div className="form-group" style={{ minWidth: '100px' }}>
        Slack User
        <Select
          isDisabled={loading}
          value={selected}
          options={users}
          onChange={this.handleSelectChange}
          isClearable
        />
      </div>
    );
  }
}
