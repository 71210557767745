/* eslint react/prop-types: 0 */
/* eslint no-undef: 0 */
/* eslint no-underscore-dangle: 0 */

import React from 'react';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import CalendarIcon from 'mdi-react/CalendarIcon';
import PropertyInEdit from '../../ui/property-in-edit';
// import StyledInput from '../../ui/styled-input';


export default class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.datepickerRef = React.createRef();
  }

  componentDidMount() {
    this.setupDatePicker();
  }

  shouldComponentUpdate(nextProps) {
    const { record, property } = this.props;
    const nextRecord = nextProps.record;
    const value = (record.params && record.params[property.name]) || '';
    const nextValue = (nextRecord.params && nextRecord.params[property.name]) || '';

    if (nextValue !== value) {
      if (nextValue) {
        this.datepickerRef.current._flatpickr.jumpToDate(nextValue);
      } else {
        this.datepickerRef.current._flatpickr.input.value = '';
      }
    }

    const prevError = record.errors && record.errors[property.name];
    const newError = nextRecord.errors && nextRecord.errors[property.name];

    return prevError !== newError;
  }

  setupDatePicker() {
    const { record, property } = this.props;
    const defaultDate = (record.params && record.params[property.name]) || null;
    let options = {
      format: 'Y-m-d',
      enableTime: false,
      time_24hr: false,
    };
    if (property.type === 'datetime') {
      options = {
        format: 'Y-m-d H:i',
        enableTime: true,
        time_24hr: true,
      };
    }

    if (!property.isDisabled) {
      const inst = flatpickr(this.datepickerRef.current, {
        defaultDate,
        ...options,
      });
      inst.config.onChange.push((dates, text) => {
        this.handleChange(text);
      });
    }
  }

  handleChange(value) {
    const { onChange, property } = this.props;
    onChange(property.name, value);
  }

  render() {
    const { property, record } = this.props;
    const error = record.errors && record.errors[property.name];
    return (
      <PropertyInEdit property={property} error={error}>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText><CalendarIcon size="15" /></InputGroupText>
          </InputGroupAddon>
          <input
            className="input pickadate form-control"
            id={property.name}
            ref={this.datepickerRef}
            name={property.name}
            disabled={property.isDisabled}
          />
        </InputGroup>
      </PropertyInEdit>
    );
  }
}
/*
 * <div className="control has-icons-right">
          <StyledInput
            type="text"
            className="input pickadate"
            id={property.name}
            ref={this.datepickerRef}
            name={property.name}
            disabled={property.isDisabled}
          />
          <span className="icon is-small is-right">
            <i className="icomoon-calendar" />
          </span>
        </div>
 */
