/* eslint prefer-destructuring: 0 */
/* eslint react/prop-types: 0 */

import React from 'react';

export default ({ record }) => {
  const { leadID, customerName } = record.params;

  return <span><strong>{leadID}</strong><br />{customerName}</span>;
};
