/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import { unflatten } from 'flat';
import _ from 'lodash';
import { checkImagePromise, parseUrl } from '../../../../../shared/helpers/WVUtilities';
import HighLight from '../../../../../shared/components/HighLight';

export default React.memo((props) => {
  const filters = parseUrl();
  const search = filters.fullSearchName || '';
  const record = unflatten(props.record);
  const [image, setImage] = useState('/images/user/defaultAvatar.png');
  const recordY = record.params.tubularDetails?.accounts.find(a => a.platform === 'youtube');
  const recordI = record.params.tubularDetails?.accounts.find(a => a.platform === 'instagram');
  const recordF = record.params.tubularDetails?.accounts.find(a => a.platform === 'facebook');
  const recordT = record.params.tubularDetails?.accounts.find(a => a.platform === 'twitter');
  const title = record.params.title || '';
  const name = `${record.params?.firstname || ''} ${record.params?.lastname || ''}`.trim() !== '' ? `${record.params?.firstname || ''} ${record.params?.lastname || ''}`.trim() : '';
  useEffect(async () => {
    setImage('/images/user/defaultAvatar.png');
    if (recordY && await checkImagePromise(recordY.thumbnailUrl)) {
      // console.log('youtube Image');
      setImage(recordY.thumbnailUrl);
    } else if (recordI && await checkImagePromise(recordI.thumbnailUrl)) {
      // console.log('Instagram Image');
      setImage(recordI.thumbnailUrl);
    } else if (recordF && await checkImagePromise(recordF.thumbnailUrl)) {
      // console.log('Facebook Image');
      setImage(recordF.thumbnailUrl);
    } else if (recordT && await checkImagePromise(recordT.thumbnailUrl)) {
      // console.log('Twitter Image');
      setImage(recordT.thumbnailUrl);
    }
  }, [record.id]);

  return (
    <div
      className="d-flex"
      style={{ width: '300px' }}
    >
      <img
        src={image}
        alt={title}
        style={{ width: '50px', height: 'auto' }}
        className="align-self-center"
      />
      <div
        className="align-self-center flex-column"
        style={{ marginLeft: '8px' }}
      >
        <div
          className=""
        >
          <span
            className="bold-text"
          >
            <HighLight search={search}>{title}</HighLight>
          </span>
          {record.params.isInternal && (
            <img
              src="/images/general/logo_short_128.png"
              alt="internalLogo"
              // title={prospectOwnerName}
              style={{ width: '18px', marginLeft: '7px' }}
            />
          )}
        </div>
        <div
          className=""
        >
          <span
            className=""
          >
            <HighLight search={search}>{name}</HighLight>
          </span>
        </div>
        <div className="" >
          {record.params.tubularDetails.accounts.map(t => (
            <a
              href={t.url}
              target="_blank"
              rel="noreferrer"
              key={t.platform}
              style={{ marginRight: '4px' }}
            >
              <img
                src={`/images/mediacontent/${t.platform}.png`}
                alt={t.platform}
                title={t.title}
                style={{ width: '20px' }}
              />
            </a>))}
          {record.params.listenNotesDetails?.url && (
            <a
              href={record.params.listenNotesDetails.url}
              target="_blank"
              rel="noreferrer"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/podcast_128.png"
                alt="podcast"
                title={record.params.listenNotesDetails.title}
                style={{ width: '20px' }}
              />
            </a>
          )}
        </div>
      </div>
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));
