import ImpactActionInList from './ImpactActionInList';
import DeltaAmountInList from './DeltaAmountInList';
import DeltaPayoutInList from './DeltaPayoutInList';
import ItemsInList from './ItemsInList';
import InfluencerInList from './InfluencerInList';
import ImpactActionIdInList from './ImpactActionIdInList';

export default {
  show: {
  },
  list: {
    impactAction: ImpactActionInList,
    deltaAmount: DeltaAmountInList,
    deltaPayout: DeltaPayoutInList,
    items: ItemsInList,
    creatorName: InfluencerInList,
    'impactAction.actionId': ImpactActionIdInList,
  },
  edit: {
  },
};
