import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LogInForm from './components/LogInForm';
import Footer from './components/Footer';
import { initializeSession } from '../../App/store/store';
import { setCookie, getCookie } from '../../../shared/helpers/WVUtilities';

class LogIn extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    // eslint-disable-next-line
    history: PropTypes.object.isRequired,
  };
  constructor() {
    super();
    this.setConnection = this.setConnection.bind(this);
  }
  setConnection(user) {
    // console.log('setConnectionResp', user);
    const { dispatch } = this.props;
    dispatch(initializeSession(user));
    const redirectUrl = getCookie('redirectUrlB');
    dispatch(initializeSession(user));
    if (redirectUrl) {
      setCookie('redirectUrlB', '');
      window.location.replace(redirectUrl);
    } else {
      window.location.replace('/');
    }
    // history.push('/admin');
  }
  render() {
    const { history } = this.props;
    return (
      <div>
        <div className="account account--not-photo login-brands-bg">
          <div className="account__wrapper">
            <div className="account__card">
              <div className="account__head-login">
                <img src="/images/general/logo_dark_small.png" alt="IL Brand Portal" />
              </div>
              <div style={{ textAlign: 'center' }}>
                <h2>Brand Portal</h2>
              </div>
              <LogInForm setConnection={this.setConnection} history={history} />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default connect(() => ({}))(LogIn);

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
