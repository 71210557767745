/* eslint react/prop-types: 0 */
import React from 'react';
import {
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { unflatten } from 'flat';
import WVValidator from '../../../../../../shared/helpers/WVValidator';

export default class BccEmailAddressesInEdit extends React.Component {
  constructor(props) {
    super(props);
    const { record: { params } } = this.props;
    const { bccEmailAddresses } = unflatten(params);
    let value = '';
    if (bccEmailAddresses && Array.isArray(bccEmailAddresses)) {
      value = bccEmailAddresses.join(',');
    }
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      value,
    };
  }

  handleChange(event) {
    const { value } = event.target;
    const { onChange, enableSaveButton } = this.props;
    enableSaveButton(false);
    this.setState({ value });
    if (value) {
      let valid = true;
      const array = value.split(',');
      array.forEach((email) => {
        // eslint-disable-next-line no-useless-escape
        if (!WVValidator.isEmail(email)) {
          valid = false;
        }
      });
      if (valid) {
        this.setState({ error: '' });
        onChange('bccEmailAddresses', array);
        enableSaveButton(true);
      } else {
        enableSaveButton(false);
        this.setState({ error: 'Check the emails for validity!' });
      }
    } else {
      this.setState({ error: '' });
      onChange('bccEmailAddresses', []);
      enableSaveButton(true);
    }
  }

  render() {
    const {
      value,
      error,
    } = this.state;
    const helpBlockClassName = error ? 'help-block color-danger' : 'help-block';
    const displayHelpBlock = error ? 'block' : 'none';

    return (
      <FormGroup>
        <Label for="bccEmailAddresses">BCC Emails (comma separated)</Label>
        <Input
          id="bccEmailAddresses"
          name="bccEmailAddresses"
          value={value}
          onChange={this.handleChange}
          style={{ width: '100%' }}
        />
        <span
          className={helpBlockClassName}
          style={{ display: displayHelpBlock }}
        >
          {error}
        </span>
      </FormGroup>
    );
  }
}

