function config() {
  return {
    recaptcha: {
      siteKey: '6LdpwdsUAAAAAPqtBN0vH2ECFek4funKvOnkm6kh',
      secretKey: '6LdpwdsUAAAAALZoPCstoFt9hxcSR3ox0NRg9pD2',
    },
  };
}

export default config;
