import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import {
  form,
  resources,
  branding,
  paths,
  session,
  dashboard,
  notices,
  pages,
  versions,
  theme,
  rtl,
  sidebar,
  customizer,
  locale,
  assets,
  table,
  modals,
} from './store';
// import { reducer as reduxFormReducer } from 'redux-form';
// import sidebarReducer from '../../../redux/reducers/sidebarReducer';
// import themeReducer from '../../../redux/reducers/themeReducer';
// import customizerReducer from '../../../redux/reducers/customizerReducer';
// import rtlReducer from '../../../redux/reducers/rtlReducer';
import creatorsReducer from '../../../redux/reducers/brand/CreatorsReducer';
import scoringReducer from '../../../redux/reducers/admin/ScoringReducer';
import dashboardReducer from '../../../redux/reducers/brand/DashboardReducer';


const reducer = combineReducers({
  form,
  resources,
  branding,
  paths,
  session,
  dashboard,
  notices,
  pages,
  versions,
  theme,
  rtl,
  sidebar,
  customizer,
  locale,
  assets,
  table,
  modals,
  // cryptoTable: cryptoTableReducer,
  // newOrder: newOrderTableReducer,
  // customizer: customizerReducer,
  creators: creatorsReducer,
  score: scoringReducer,
  brandDashboard: dashboardReducer,

});

// const middleware = [thunk];
const brandStore = (initialState = {}) => configureStore({
  reducer,
  middleware: () => [thunk],
  preloadedState: initialState,
  devTools: window.process.env.NODE_ENV === 'local', // Enables redux devtools. Allows to time-travel redux store.
});

export default brandStore;
