/* eslint react/prop-types: 0 */
import React from 'react';
import {
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

export default class EmailAddressLabelInEdit extends React.Component {
  constructor(props) {
    super(props);
    const { record } = this.props;
    // console.log(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      value: record.params.emailAddressLabel || 'InfluenceLogic Support',
    };
  }

  handleChange(event) {
    const { value } = event.target;
    const { onChange, enableSaveButton } = this.props;
    enableSaveButton(false);
    this.setState({ value });
    onChange('emailAddressLabel', value);
    enableSaveButton(true);
  }

  render() {
    const {
      value,
    } = this.state;

    return (
      <FormGroup>
        <Label for="emailAddressLabel">From Email Label Name</Label>
        <Input
          id="emailAddressLabel"
          name="emailAddressLabel"
          value={value}
          onChange={this.handleChange}
          style={{ width: '100%' }}
        />
      </FormGroup>
    );
  }
}

