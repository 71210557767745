/* eslint react/prop-types: 0 */
import React from 'react';

import ReferenceValue from './reference-value';

export default class List extends React.PureComponent {
  render() {
    const { property, record } = this.props;
    return (
      <ReferenceValue
        property={property}
        record={record}
      />
    );
  }
}
