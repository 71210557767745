/* eslint-disable filenames/match-exported */
import { connect } from 'react-redux';
import { addNotice } from './store';

const mapDispatchToProps = dispatch => ({
  addNotice: notice => dispatch(addNotice(notice)),
});

/**
 * HighOriderComponent which allows you to post notice messages from your components
 *
 * It gives you the additional prop: `addNotice(noticeMessage)` taking {@link NoticeMessage}.
 *
 * ```javascript
 * import { withNotice } from 'admin-bro'
 *
 * const MY_MESSAGE = {
 *   message: 'I am toast message',
 *   type: 'success',
 * }
 * const MyCustomComponent = ({ addNotice }) => {
 *   return (
 *     <a onClick={() => addNotice(MY_MESSAGE)}>Click Me</a>
 *   )
 * }
 * export default withNotice(MyCustomComponent)
 * ```
 *
 * @component
 */
const WithNotice = Component => connect(null, mapDispatchToProps)(Component);

export default WithNotice;
