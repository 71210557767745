import EnabledStatusInList from './EnabledStatusInList';
import StartDatetimeInList from './StartDatetimeInList';
import FrequencyInList from './FrequencyInList';
import LastRunDatetimeInList from './LastRunDatetimeInList';
import SlackChannel from './SlackChannel';

export default {
  list: {
    startDatetime: StartDatetimeInList,
    enabled: EnabledStatusInList,
    frequency: FrequencyInList,
    lastRunDatetime: LastRunDatetimeInList,
    slackChannel: SlackChannel,
  },
};
