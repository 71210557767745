import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const PayablesDashboardId = (props) => {
  const { record } = props;
  const link = `/finance/account-payable-report-2/${record.id}/creators`;
  return (
    <Link to={link}>
      {record.params.name || 'Show Payable Report'}
    </Link>
  );
};
PayablesDashboardId.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default PayablesDashboardId;
