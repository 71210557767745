import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { getScorecardAnswersData } from '../../../../../redux/reducers/admin/ScoringReducer';
import { showScorecardAnswersDialog } from '../../../../../redux/reducers/brand/CreatorsReducer';

class ButtonInList extends React.Component {
  constructor(props) {
    super(props);
    this.clickViewScorecard = this.clickViewScorecard.bind(this);
  }

  clickViewScorecard(e) {
    const i = e.target.value || 0;
    const { dispatch, record } = this.props;
    if (record && typeof record.scorecards !== 'undefined' && record.scorecards !== null && record.scorecards.length > 0 && record.scorecards[i]) {
      console.log(record.scorecards);
      dispatch(showScorecardAnswersDialog(record.scorecards[i]._id));
      dispatch(getScorecardAnswersData(record.scorecards[i]._id));
    }
  }

  render() {
    const { record } = this.props;
    return (
      <>
        {record.scorecards.length > 1 ? (
          <UncontrolledButtonDropdown className="m-0">
            <DropdownToggle caret className="brand-table-button" color="primary" style={{ minWidth: '170px' }}>
              View Scorecards
            </DropdownToggle>
            <DropdownMenu>
              {record.scorecards.map((scoreCard, i) => (
                <DropdownItem
                  key={scoreCard._id}
                  value={i}
                  onClick={this.clickViewScorecard}
                >
                  Scorecard {i + 1}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        ) : (
          <Button
            color="primary"
            value="0"
            className="mt-2 btn-sm brand-table-button"
            style={{ minWidth: '170px' }}
            onClick={this.clickViewScorecard}
            // disabled={!record.answers || record.answers.length === 0}
          >
            View Scorecard
          </Button>
        )}
      </>
    );
  }
}

ButtonInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loading: state.score.loading.fetchScorecardAnswersContent,
});

export default connect(mapStateToProps)(ButtonInList);
