import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
} from 'reactstrap';
import withNotice from '../../App/store/with-notice';
import { NoResourceError } from '../../../shared/components/components/ui/error404';
import Table from './components/Table';
import Actions from './components/Actions';

const YouTubeVideoComment = ({
  resources,
}) => {
  useEffect(async () => {
    console.log('First render is done');
  }, []);

  const downloadCsv = () => {
    const search = new URLSearchParams(window.location.search);
    const url = `/api/brand/dashboard/get-you-tube-comments-csv?${search.toString()}`;
    const win = window.open(url, '_blank');
    win.focus();
  };

  const resource = resources.find(r => r.id === 'YoutubeComment');
  if (!resource) {
    return (<NoResourceError resourceId="YoutubeComment" />);
  }
  const action = null;

  return (
    <Container className="dashboard">
      <Row>
        <Col md="3">
          <h2 style={{ marginBottom: '25px' }}>Comments</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody style={{ padding: '2rem 2rem 0 2rem' }}>
              <Actions downloadCsv={downloadCsv} />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody style={{ padding: '2rem 2rem 0 2rem' }}>
              <Row>
                <Table
                  action={action}
                  resource={resource}
                />
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
YouTubeVideoComment.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  
  resources: state.resources,
});

export default connect(mapStateToProps)(withNotice(YouTubeVideoComment));
