/* eslint react/prop-types: 0 */
import React from 'react';
import { flatten, unflatten } from 'flat';
import { Row, Col, Button } from 'reactstrap';

import PropertyInEdit from '../../ui/property-in-edit';
import convertParamsToArrayItems from './convert-params-to-array-items';
import StyledSection from '../../ui/styled-section';
import { StyledButton } from '../../ui';
import updateParamsArray from './update-params-array';

const normalizeParams = params => (
  flatten(unflatten(params, { overwrite: true }))
);

export default class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.addNew = this.addNew.bind(this);
  }

  addNew(event) {
    const { property, record, onChange } = this.props;
    const items = convertParamsToArrayItems(property, record);
    const newRecord = { ...record };
    newRecord.params = normalizeParams({
      ...newRecord.params, // oterwise yarn types is not working
      [property.name]: [
        ...items,
        property.subProperties.length ? {} : '',
      ],
    });
    onChange(newRecord);
    event.preventDefault();
    return false;
  }

  removeItem(i, event) {
    const { property, record, onChange } = this.props;
    const items = convertParamsToArrayItems(property, record);
    const newItems = [...items];
    newItems.splice(i, 1);
    const newRecord = { ...record };

    newRecord.params = updateParamsArray(newRecord.params, property.name, newItems);

    onChange(newRecord);
    event.preventDefault();
    return false;
  }

  renderItem(item, i) {
    const { ItemComponent, property } = this.props;
    return (
      <Row key={i}>
        <Col xs={10}>
          <ItemComponent
            {...this.props}
            property={{
              ...property,
              name: `${property.name}.${i}`,
              label: `[${i + 1}]`,
              isArray: false,
            }}
          />
        </Col>
        <Col xs={2}>
          <Button
            style={{ marginTop: 29, float: 'right' }}
            onClick={(event) => this.removeItem(i, event)}
            color="danger"
            size="sm"
          >
            Remove
          </Button>
        </Col>
      </Row>
    );
  }

  renderInput() {
    const { property, record } = this.props;
    const items = convertParamsToArrayItems(property, record);
    return (
      <StyledSection style={{ marginTop: 20 }}>
        {items.map((item, i) => this.renderItem(item, i))}
        <p>
          <StyledButton onClick={this.addNew}>
            Add new item
          </StyledButton>
        </p>
      </StyledSection>
    );
  }

  render() {
    const { property, record } = this.props;
    const error = record.errors && record.errors[property.name];
    return (
      <PropertyInEdit property={property} error={error}>
        {this.renderInput()}
      </PropertyInEdit>
    );
  }
}
/*
 * <StyledButton
            style={{ marginTop: 29 }}
            onClick={(event): false => this.removeItem(i, event)}
          >
            Remove
          </StyledButton>
 */
