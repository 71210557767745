/* eslint react/prop-types: 0 */
import React, { useMemo, useState } from 'react';
// import moment from 'moment';
import { Row, Col, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip as TooltipOnLine,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';

import getCurrentChartData from './CalculateForTables';
import { formatIntNumber } from '../../../shared/helpers/WVFormatter';


const colorOptions = [
  '#5fac46',
];

const chartMargins = {
  top: 5,
  right: 15,
  left: -30,
  bottom: 20,
};
let indexesForX = {};

const CustomizedTooltip = (props) => {
  // const prefix = props.selected !== 'conversionsCount' ? '$' : '';
  const getText = (name, value) => `${name}: ${formatIntNumber(value)}`;
  if (props.active && props.payload && props.payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: 'hsla(0, 0%, 100%, .8)',
          border: '1px solid #f5f5f5',
          lineHeight: '24px',
          margin: '0',
          padding: '10px',
        }}
      >
        <p className="bold-text">{props.label}</p>
        <ul
          style={{
            listStyle: 'none',
            padding: '0',
            margin: '0',
          }}
        >
          {props.payload?.map((item, index) => (
            <li
              key={item.name}
              style={{
                color: colorOptions[index],
              }}
            >
              {getText(item.name, item.value)}
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return null;
};
const CustomizedXAxisTick = ({ x, y, payload }) => {
  const values = [16, 26, 40];
  // console.log(payload);
  const children = payload.value?.includes('-')
    ? payload.value.split(' ').map(((string, index) => (
      <text x={0} y={0} dy={values[index]} textAnchor="middle" fill="#666">
        {string}
      </text>
    ))) : (
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
        {payload.value}
      </text>
    );
  return (
    <g transform={`translate(${x},${y})`}>
      {children}
    </g>
  );
};

const CustomizedYAxisTick = ({
  x, y, payload,
}) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} textAnchor="end" fill="#666">
      {formatIntNumber(payload.value)}
    </text>
  </g>
);

const CustomizedLabel = ({
  x, y, stroke, value, ...payload
}) => {
  console.log(payload.indexes, 'indexes');
  if (!payload.indexes[payload.index]) return null;
  return (
    <text x={x} y={y} dy={-6} dx={6} fill={stroke} fontSize={13} textAnchor="middle" fontWeight="bold">
      {value}
    </text>
  );
};
const ChartComponent = ({
  selectedAdmins,
  startDate,
  endDate,
  label,
  isLoading,
  total,
  totalValue,
  itemsByArray,
  showLabels,
}) => {
  // console.log(showLabels, selectedAdmins);
  // const [indexForX, setIndexes] = useState({});
  const [top7, setTop7] = useState([]);
  const [totalAll, setTotalAll] = useState([]);
  const calculateurrentChartData = () => {
    // const [keysInValue, total] = selectedOption.value.split('-');
    const top7ByValue = {};
    selectedAdmins.forEach(({ data, option }) => {
      Object.keys(data).forEach((key) => {
        const periodsDataItem = data[key];
        if (!top7ByValue[option._id]) top7ByValue[option._id] = { value: 0, data, option };
        top7ByValue[option._id].value += periodsDataItem;
      });
    });
    setTotalAll(Object.values(top7ByValue)[0]?.value);
    // console.log(top7ByValue);
    const values = Object.values(top7ByValue).sort((a, b) => b.value - a.value)
      .slice(0, 7)
      .map(({ option, data }) => ({ option, data }));
    setTop7(values);
  };
  const getCalculatedChartData = () => {
    console.log(indexesForX, `INDEXFORX${label}`);
    indexesForX = {};
    calculateurrentChartData();
    const chartData = getCurrentChartData({
      startDate,
      endDate,
      selectedAdmins,
      itemsByArray,
      total,
    });
    // indexesForX
    return chartData;
    // return Object.values(chartData);
  };
  // const [currentChartData] = useState(getCurrentChartData());

  const currentChartData = useMemo(getCalculatedChartData, [selectedAdmins, total, itemsByArray, startDate, endDate]);
  const indexesForXAdd = useMemo(() => {
    const idex = {};
    currentChartData.forEach((data, index) => {
      console.log([currentChartData[index + 1]?.na, data.na, currentChartData[index - 1]?.na], index);
      if (currentChartData[index - 1] && currentChartData[index + 1] && currentChartData[index + 1]?.na < data.na && currentChartData[index - 1]?.na < data.na) {
        idex[index] = true;
      }
    });
    return idex;
  }, [currentChartData]);

  // console.log(currentChartData);
  /* const handleMouseEnter = (dataKey) => {
    const localOpacity = { ...opacity };
    // eslint-disable-next-line no-return-assign
    Object.keys(localOpacity).forEach(companyName => localOpacity[companyName] = companyName === dataKey ? 1 : 0.2);
    setOpacity(localOpacity);
  };

  const handleMouseLeave = () => {
    const localOpacity = { ...opacity };
    // eslint-disable-next-line no-return-assign
    Object.keys(localOpacity).forEach(companyName => localOpacity[companyName] = 1);
    setOpacity(localOpacity);
  }; */
  // console.log(opacity);
  console.log(indexesForXAdd, label);
  return (
    <Row>
      <Col>
        <>
          <h4 className="font-weight-bold my-3">{label} ({formatIntNumber(totalValue || (totalAll || 0))})</h4>
          {currentChartData.length ? (
            <>
              <Row>
                <Col md={12}>
                  {isLoading && <Spinner color="primary" size="lg" style={{ position: 'absolute', top: '40%', left: '50%' }} />}
                  <ResponsiveContainer
                    width="100%"
                    height={400}
                    debounce={1}
                  >

                    <LineChart
                      width="100%"
                      height="100%"
                      data={currentChartData}
                      margin={chartMargins}
                      key={(new Date()).toString()}
                    >
                      {top7.map(({ option }, index) => (
                        <Line
                          key={`line${option._id}${showLabels}`}
                          type="monotone"
                          dataKey={option._id}
                          name={`${option.label}`}
                          stroke={colorOptions[index]}
                          // strokeOpacity={opacity[option._id] || 1}
                          strokeWidth={2}
                          label={showLabels ? <CustomizedLabel length={currentChartData.length || 0} indexes={{ ...indexesForX, ...indexesForXAdd }} /> : null}
                          dot={showLabels}
                        />
                    ))}
                      <XAxis
                        dataKey="date"
                        padding={{ right: 30 }}
                        tick={(payload) => { indexesForX[payload.payload.index] = true; return <CustomizedXAxisTick x={payload.x} y={payload.y} payload={payload.payload} />; }}
                        interval="preserveStartEnd"
                      />
                      <YAxis
                        padding={{ top: 10 }}
                        tick={<CustomizedYAxisTick />}
                        domain={[0, 'dataMax']}
                        interval="preserveStartEnd"
                        allowDecimals={false}
                        // tickFormatter={value => (selectedOption?.value !== 'conversionsCount' ? formatCurrency(value) : value)}
                      />
                      <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                      <TooltipOnLine content={<CustomizedTooltip />} />
                    </LineChart>
                  </ResponsiveContainer>
                </Col>
              </Row>
            </>
          ) : (
            <span className="mt-2">No data to chart</span>
          )}
        </>
      </Col>
    </Row>
  );
};

ChartComponent.propTypes = {
  selectedAdmins: PropTypes.arrayOf(PropTypes.any),
  total: PropTypes.string,
  totalValue: PropTypes.number,
  showLabels: PropTypes.bool.isRequired,
};

ChartComponent.defaultProps = {
  selectedAdmins: [],
  total: '',
  totalValue: 0,
};

export default ChartComponent;
