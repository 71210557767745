import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  // UncontrolledButtonDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  Row,
  Col,
  Button, Label, FormGroup,
} from 'reactstrap';
import { useAsyncDebounce } from 'react-table';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import { axios } from 'ApiClient';
import DatePicker from 'react-datepicker';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import moment from 'moment';
import { getCookie, setCookie } from '../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../shared/components/CheckBox';
import IntervalDatePickerField
  from '../../../admin/Influencer/Research/Prospects/components/custom/IntervalDatePickerField';
import TimePeriodFilter from './custom/actions/TimePeriodFilter';
import { setUrl } from './custom/actions/setUrl';

const dateRangeFilterLabels = [
  { value: '=', label: '=' },
  { value: '>', label: '>' },
  { value: '>=', label: '>=' },
  { value: '<', label: '<' },
  { value: '<=', label: '<=' },
  { value: 'Between', label: 'Between' },
];

const SentimentStatus = {
  positive: 'Positive',
  // neutral: 'Neutral',
  negative: 'Negative',
  ignore: 'Ignore',
};


const loadCreatorsOptions = async (value) => {
  let response = {};
  if (value === 'getByIds') {
    const initialSearch = new URLSearchParams(window.location.search);
    if (initialSearch.has('filters.creator') && initialSearch.getAll('filters.creator').length > 0) {
      response = await axios.get('/api/brand/dashboard/get-creators-by-ids', {
        params: { ids: initialSearch.getAll('filters.creator') },
      });
      if (!response.data.success) {
        return {};
      }
    }
  } else {
    response = await axios.get('/api/brand/dashboard/get-creator-search', {
      params: {
        value,
        defaultSearch: 'a',
      },
    });
  }
  return response.data.records.map(record => ({
    value: record.params?._id || record._id,
    label: record.params ? `${record.params.first_name} ${record.params.last_name}` : `${record.first_name} ${record.last_name}`,
    record,
  }));
};

const Actions = () => {
  let selectedStartDateInitialState = moment().startOf('month').toISOString();
  let selectedEndDateInitialState = moment().endOf('day').toISOString();
  let selectedInitialRange = 'current_month';

  const initialSearch = new URLSearchParams(window.location.search);
  if (initialSearch.has('filters.publishedAt~~from') && initialSearch.getAll('filters.publishedAt~~from').length > 0) {
    initialSearch.getAll('filters.publishedAt~~from').forEach((from) => {
      selectedStartDateInitialState = moment(from).toISOString();
    });
  }
  if (initialSearch.has('filters.publishedAt~~to') && initialSearch.getAll('filters.publishedAt~~to').length > 0) {
    initialSearch.getAll('filters.publishedAt~~to').forEach((to) => {
      selectedEndDateInitialState = moment(to).toISOString();
    });
  }
  if (initialSearch.has('range') && initialSearch.getAll('range').length > 0) {
    initialSearch.getAll('range').forEach((range) => {
      selectedInitialRange = range;
    });
  }
  const [search, setSearch] = useState('');
  const [dateSearch, setDateSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(getCookie('youtubeCommentsShowFilters') === 'true');
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [enablePublishDateFilter, setEnablePublishDateFilter] = useState(true);
  const [enablePublishAtDateFilter, setEnablePublishAtDateFilter] = useState(true);


  const [dateFrom, setDateFrom] = useState(moment(selectedStartDateInitialState).startOf('day').toISOString());
  const [dateTo, setDateTo] = useState(moment(selectedEndDateInitialState).startOf('day').toISOString());
  const [selectedButton, setSelectedButton] = useState(selectedInitialRange);

  const [publishDateFrom, setPublishDateFrom] = useState(new Date(moment(selectedStartDateInitialState).toISOString()));
  const [publishDateTo, setPublishDateTo] = useState(new Date(moment(selectedEndDateInitialState).toISOString()));

  const [publishDateFilterType, setPublishDateFilterType] = useState(dateRangeFilterLabels[0]);
  const [publishAtDateFilterType, setPublishAtDateFilterType] = useState(dateRangeFilterLabels[0]);
  const [publishVideoDateFrom, setPublishVideoDateFrom] = useState(new Date(selectedStartDateInitialState));
  const [publishVideoDateTo, setPublishVideoDateTo] = useState(new Date(selectedEndDateInitialState));
  const [singlePublishVideoDate, setsinglePublishVideoDate] = useState(null);
  const [singlePublishDate, setSinglePublishDate] = useState(null);
  const [sentiment, setSentiment] = useState({
    positive: true, negative: true, ignore: false,
  });

  const history = useHistory();
  const firstUpdate = useRef(true);

  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl({
      history,
      search,
      selectedButton,
      publishDateFrom,
      publishDateTo,
      publishDateFilterType,
      selectedCreators,
      publishVideoDateTo,
      publishVideoDateFrom,
      singlePublishVideoDate,
      sentiment,
      enablePublishDateFilter,
      publishAtDateFilterType,
      singlePublishDate,
      enablePublishAtDateFilter,
    });
  }, 800);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setUrlDebounced();
  }, [
    search,
    dateFrom, dateTo,
    selectedCreators,
    publishDateFrom, publishDateTo, publishDateFilterType,
    publishVideoDateFrom, publishVideoDateTo, singlePublishVideoDate, sentiment, enablePublishDateFilter, singlePublishDate, publishAtDateFilterType, enablePublishAtDateFilter,
  ]);

  // useEffect(() => {
  //   if (selectedButton === 'custom') {
  //     setEnablePublishDateFilter(true);
  //     setPublishDateFilterType(dateRangeFilterLabels[5]);
  //   }
  // }, [selectedButton]);

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };
  const customStyles = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? '#e9ecef' : 'white',
    }),
  };

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      // onSearchChange(search);
      console.log(search);
    }, 1000));
  }, [search]);

  useEffect(() => {
    setCookie('youtubeCommentsShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  useEffect(() => {
    if (initialSearch.has('filters.creator') && initialSearch.getAll('filters.creator').length > 0) {
      loadCreatorsOptions('getByIds').then((response) => {
        setSelectedCreators(response);
      });
    }
  }, []);

  const resetFilters = () => {
    setSearch('');
    setSelectedCreators([]);

    setEnablePublishDateFilter(false);
    setPublishDateFilterType(dateRangeFilterLabels[0]);
    setPublishAtDateFilterType(dateRangeFilterLabels[0]);
    setPublishDateFrom(null);
    setPublishDateTo(null);
  };
  console.log(sentiment);
  return (
    <div className="mb-3">
      <h5 className="font-weight-bold text-black mb-2" style={{ color: 'black' }}>Comment Date</h5>
      <TimePeriodFilter
        startDateInitial={dateFrom}
        endDateInitial={dateTo}
        selectedButtonInitial={selectedButton}
        setValue={(param, value, range) => {
          if (param === 'startDate') {
            setDateFrom(moment(value).startOf('day').toISOString());
            setPublishDateFrom(new Date(moment(value).startOf('day').toISOString()));
          }
          if (param === 'endDate') {
            setDateTo(moment(value).endOf('day').toISOString());
            setPublishDateTo(new Date(moment(value).endOf('day').toISOString()));
          }
          setSelectedButton(range);
        }}
      />
      <Row className="mt-4">
        <Col sm={6}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Comments, Video Title, Author Name"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        {/* <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
          </UncontrolledButtonDropdown>
            </Col> */}
      </Row>
      {showFilters && (
        <>
          <Row className="mt-3">
            <Col sm="4">
              <Row >
                <Col sm="auto">
                  <Label className="bold-text" style={{ marginTop: '8px' }}>Creators:</Label>
                </Col>
                <Col>
                  <FormGroup style={{ minWidth: '300px' }} >
                    <SelectAsync
                      cacheOptions
                      defaultOptions
                      isClearable
                      isMulti
                      loadOptions={async value => loadCreatorsOptions(value)}
                      onChange={setSelectedCreators}
                      placeholder="Select Creator..."
                      value={selectedCreators}
                      styles={colourStyles}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col sm="4">
              <Row >
                <Col sm="auto">
                  <Label className="bold-text" style={{ marginTop: '8px' }}>Publish Date:</Label>
                </Col>
                <Col sm={1}>
                  <FormGroup
                    style={{ paddingTop: '8px', paddingLeft: '8px' }}
                  >
                    <CheckBoxField
                      name="videoPublishDate"
                      value={enablePublishDateFilter}
                      onChange={() => { setEnablePublishDateFilter(!enablePublishDateFilter); }}
                    />
                  </FormGroup>
                </Col>
                <Col sm={2} style={{ paddingLeft: '20px', paddingRight: 0, minWidth: '120px' }}>
                  <Select
                    value={publishDateFilterType}
                    onChange={(option) => {
                      // if ((publishDateFilterType.value !== 'Between' && option.value === 'Between') || (publishDateFilterType.value === 'Between' && option.value !== 'Between')) {
                      //   setPublishDateFrom(null);
                      //   setPublishDateTo(null);
                      //   setSinglePublishDate(null);
                      // }
                      setPublishDateFilterType(option);
                    }}
                    options={dateRangeFilterLabels}
                    isDisabled={!enablePublishDateFilter}
                    styles={customStyles}
                  />
                </Col>
                <Col>
                  {publishDateFilterType.value === 'Between' ? (
                    <div style={{ marginRight: '-30px' }}>
                      <IntervalDatePickerField
                        onChange={({ start, end }) => {
                          setPublishVideoDateFrom(start);
                          end?.setHours(23, 59, 59);
                          setPublishVideoDateTo(end);
                        }}
                        startDate={publishVideoDateFrom}
                        endDate={publishVideoDateTo}
                        maxDate={new Date()}
                        placeholderFrom="Start Date"
                        placeholderTo="End Date"
                        verticalFields
                        disabled={!enablePublishDateFilter}
                        datePickerCustomClass="datepicker-in-profile"
                        size="sm"
                      />
                    </div>
                  ) : (
                    <div className="date-picker datepicker-in-profile">
                      <DatePicker
                        selected={singlePublishVideoDate}
                        onChange={(date) => {
                          if (publishDateFilterType.value === '<=' || publishDateFilterType.value === '>') {
                            date?.setHours(23, 59, 59);
                          }
                          if (publishAtDateFilterType.value === '<' || publishAtDateFilterType.value === '>=') {
                            date?.setHours(0, 0, 0);
                          }
                          setsinglePublishVideoDate(date);
                        }}
                        dateFormat="MMM d, yyyy"
                        maxDate={new Date()}
                        placeholderText="Select Date"
                        dropDownMode="select"
                        isClearable
                        wrapperClassName="date-picker--interval w-100"
                        className="form-control form-control-sm datepicker-in-profile w-100 mt-1"
                        disabled={!enablePublishDateFilter}
                      />
                      {singlePublishVideoDate === null && (
                        <svg
                          className="mdi-icon"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          style={{
                            position: 'absolute',
                            top: '8px',
                            right: '22px',
                            fill: '#999',
                            pointerEvents: 'none',
                          }}
                        >
                          <path
                            d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                          />
                        </svg>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            <Col sm="4">
              <Row >
                <Col sm="auto">
                  <Label className="bold-text" style={{ marginTop: '8px' }}>Comment Date:</Label>
                </Col>
                <Col sm={1}>
                  <FormGroup
                    style={{ paddingTop: '8px', paddingLeft: '8px' }}
                  >
                    <CheckBoxField
                      name="publishDate"
                      value={enablePublishAtDateFilter}
                      onChange={() => { setEnablePublishAtDateFilter(!enablePublishAtDateFilter); }}
                    />
                  </FormGroup>
                </Col>
                <Col sm={2} style={{ paddingLeft: '20px', paddingRight: 0, minWidth: '120px' }}>
                  <Select
                    value={publishAtDateFilterType}
                    onChange={(option) => {
                      // if ((publishDateFilterType.value !== 'Between' && option.value === 'Between') || (publishDateFilterType.value === 'Between' && option.value !== 'Between')) {
                      //   setPublishDateFrom(null);
                      //   setPublishDateTo(null);
                      //   setSinglePublishDate(null);
                      // }
                      setPublishAtDateFilterType(option);
                    }}
                    options={dateRangeFilterLabels}
                    isDisabled={!enablePublishAtDateFilter}
                    styles={customStyles}
                  />
                </Col>
                <Col>
                  {publishAtDateFilterType.value === 'Between' ? (
                    <div style={{ marginRight: '-30px' }}>
                      <IntervalDatePickerField
                        onChange={({ start, end }) => {
                          setPublishDateFrom(start);
                          end?.setHours(23, 59, 59);
                          setPublishDateTo(end);
                        }}
                        startDate={publishDateFrom}
                        endDate={publishDateTo}
                        maxDate={new Date()}
                        placeholderFrom="Start Date"
                        placeholderTo="End Date"
                        verticalFields
                        disabled={!enablePublishAtDateFilter}
                        datePickerCustomClass="datepicker-in-profile"
                        size="sm"
                      />
                    </div>
                  ) : (
                    <div className="date-picker datepicker-in-profile">
                      <DatePicker
                        selected={singlePublishDate}
                        onChange={(date) => {
                          if (publishAtDateFilterType.value === '<=' || publishAtDateFilterType.value === '>') {
                            date?.setHours(23, 59, 59);
                          }
                          if (publishAtDateFilterType.value === '<' || publishAtDateFilterType.value === '<=') {
                            date?.setHours(0, 0, 0);
                          }
                          setSinglePublishDate(date);
                        }}
                        dateFormat="MMM d, yyyy"
                        maxDate={new Date()}
                        placeholderText="Select Date"
                        dropDownMode="select"
                        isClearable
                        wrapperClassName="date-picker--interval w-100"
                        className="form-control form-control-sm datepicker-in-profile w-100 mt-1"
                        disabled={!enablePublishAtDateFilter}
                      />
                      {singlePublishDate === null && (
                        <svg
                          className="mdi-icon"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          style={{
                            position: 'absolute',
                            top: '8px',
                            right: '22px',
                            fill: '#999',
                            pointerEvents: 'none',
                          }}
                        >
                          <path
                            d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                          />
                        </svg>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm="auto">
              <Label className="bold-text">Sentiment:</Label>
            </Col>
            <Col sm="auto" className="px-2">
              <CheckBoxField
                name="positive"
                label={SentimentStatus.positive}
                value={sentiment.positive}
                onChange={e => e.target && setSentiment(s => ({ ...s, positive: e.target.checked }))}
              />
            </Col>
            <Col sm="auto" className="px-2">
              <CheckBoxField
                name="negative"
                label={SentimentStatus.negative}
                value={sentiment.negative}
                onChange={e => e.target && setSentiment(s => ({ ...s, negative: e.target.checked }))}
              />
            </Col>
            <Col sm="auto" className="px-2">
              <CheckBoxField
                name="ignore"
                label={SentimentStatus.ignore}
                value={sentiment.ignore}
                onChange={e => e.target && setSentiment(s => ({ ...s, ignore: e.target.checked }))}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

Actions.defaultProps = {
  total: 0,
};

const mapStateToProps = state => ({
  total: state.table.total,
  selectedRecordsIds: state.table.selectedRecordsIds,
});

export default connect(mapStateToProps)(Actions);
