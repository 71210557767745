import DealnameInList from './DealnameInList';
import InfluencerInList from './InfluencerInList';
// import ContactInList from './ContactInList';
// import OfferInList from './OfferInList';
// import EditButtonInList from './EditButtonInList';
// import StartDateInList from './StartDateInList';
// import AmountInList from './AmountInList';
import StatusInList from './StatusInList';
import FirstConversionDateInList from './FirstConversionDateInList';
import LastConversionDateInList from './LastConversionDateInList';
import CellDateUtcToLocal from '../../../../../../shared/components/table/CellDateUtcToLocal';

export default {
  list: {
    'dealTerms.cpa_status': StatusInList,
    brandApprovedDate: CellDateUtcToLocal,
    'dealTerms.content_release_date': CellDateUtcToLocal,
    'dealTerms.date_of_first_conversion': FirstConversionDateInList,
    'dealTerms.date_of_last_conversion': LastConversionDateInList,
    dealname: DealnameInList,
    'influencerObject.fullName': InfluencerInList,
    // 'contactObject.fullName': ContactInList,
    // 'offer.name': OfferInList,
    // edit: EditButtonInList,
    // amount: AmountInList,
    // 'dealTerms.start_date': StartDateInList,
  },
};
