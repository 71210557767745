// import { unflatten } from 'flat';
import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment-timezone';
import MatTableList from '../../../../../shared/tables/materialTable/MatTableList';
import UserPropertyType from '../../components/custom';
import CreatorInList from './CreatorInList';
import BrandStatus from './BrandStatus';
import { formatNumber, formatIntNumber, formatDate } from '../../../../../shared/helpers/WVFormatter';
import TableCellContextMenu from './TableCellContextMenu';
import SafetyCheck from './SafetyCheck';
import TabsOrModal from './TabsOrModal';
import StatusHistoryModal from './StatusHistoryModal';


const Table = React.memo(({
  action,
  resource,
  setTag,
  version,
  setSelectedIds,
  prospectContainerRefresh,
  searchTerms,
  setCurrentProspect,
  setShowNotesModal,
  setPortal,
  setDisabledNotes,
}) => (
  <MatTableList
    action={action}
    resource={resource}
    setTag={setTag}
    version={version}
    UserPropertyType={UserPropertyType}
    showFilters={false}
    showTitle={false}
    sortBy="createdAt"
    direction="desc"
    useFlex
    // date={new Date()}
    v={2}
    noActions
    isResizable={false}
    useCheckboxes
    checkboxHandler={e => setSelectedIds(e)}
    columnsSettings={{
      fullSearchName: {
        Header: () => 'Name',
        accessor: () => '',
        Cell: p => useMemo(() => (<CreatorInList record={p.row.original} />), [p.row.original.id]),
        width: 300,
      },
      'approvalStatus.influencelogic.status': {
        id: 'approvalStatus.influencelogic.status',
        accessor: () => '',
        Cell: p => useMemo(() => p.row.original.params['approvalStatus.influencelogic.status'] || '', [p.row.original.id]),
        width: 50,
      },
      'lastHistory.dateModified': {
        Header: () => 'Last Modified Date',
        Cell: p => useMemo(() => formatDate(p.row.original.params['lastHistory.dateModified']), [p.row.original.id]),
        width: 100,
      },
      'approvalStatus.brand.status': {
        id: 'approvalStatus.brand.status',
        accessor: () => '',
        Header: () => 'Brand Status',
        Cell: p => (<BrandStatus
          record={p.row.original}
          setShowNotesModal={setShowNotesModal}
          setCurrentProspect={setCurrentProspect}
          setPortal={setPortal}
          setDisabledNotes={setDisabledNotes}
          updateRecordFunction={p.updateRecordFunction}
        />),
        width: 140,
      },
      safetyCheck: {
        Header: () => '',
        Cell: p => (<SafetyCheck
          record={p.row.original}
          searchTerms={searchTerms}
          setPortal={setPortal}
          setShowNotesModal={setShowNotesModal}
          setCurrentProspect={setCurrentProspect}
          setDisabledNotes={setDisabledNotes}
        />),
        disableSortBy: true,
        width: 80,
      },
      isInternal: {
        Header: () => 'Prospect Type',
        Cell: p => useMemo(() => (p.row.original.params.isInternal ? 'InfluenceLogic' : 'Standard'), [p.row.original.params.isInternal]),
        width: 100,
      },
      'youtubeStats.influencer_score': {
        Header: () => 'Score',
        Cell: p => useMemo(() => formatIntNumber(Math.ceil(Number(p.row.original.params['youtubeStats.influencer_score'] || 0))), [p.row.original.id]),
        width: 60,
      },
      'youtubeStats.monthly_v30': {
        Cell: p => useMemo(() => formatIntNumber(p.row.original.params['youtubeStats.monthly_v30'] || 0), [p.row.original.id]),
        width: 50,
      },
      'youtubeStats.monthly_er30': {
        Cell: p => useMemo(() => formatNumber(p.row.original.params['youtubeStats.monthly_er30'] || 0), [p.row.original.id]),
        width: 60,
      },
      'youtubeStats.malePercentage': {
        Cell: p => useMemo(() => `${Number(p.row.original.params['youtubeStats.malePercentage'] || 0).toFixed(2)}%`, [p.row.original.id]),
        width: 60,
      },
      'youtubeStats.femalePercentage': {
        Cell: p => useMemo(() => `${Number(p.row.original.params['youtubeStats.femalePercentage'] || 0).toFixed(2)}%`, [p.row.original.id]),
        width: 60,
      },
      'youtubeStats.percentUS': {
        Cell: p => useMemo(() => `${Number(p.row.original.params['youtubeStats.percentUS'] || 0).toFixed(2)}%`, [p.row.original.id]),
        width: 60,
      },
      'youtubeStats.percent25_55_plus': {
        Cell: p => useMemo(() => `${Number(p.row.original.params['youtubeStats.percent25_55_plus'] || 0).toFixed(2)}%`, [p.row.original.id]),
        width: 70,
      },
      actionProspect: {
        Header: '',
        accessor: () => null,
        id: 'influencer_actions',
        maxWidth: 30,
        Cell: (p) => {
          // console.log(p.updateRecordFunction);
          const ref = useRef();
          const [modalHistory, showModalHistory] = useState(false);
          const contextMenuOptions = [
            {
              label: 'Safety Check',
              handler: () => ref.current.openNewTabs(),
            },
            {
              label: 'Status History',
              handler: () => showModalHistory(true),
            },
            { label: 'Refresh Statistics', handler: () => prospectContainerRefresh(p.row.original.id) },
          ];
          return (
            <div style={{ paddingTop: '8px' }}>
              <TableCellContextMenu options={contextMenuOptions} />
              <TabsOrModal record={p.row.original} ref={ref} searchTerms={searchTerms} />
              <StatusHistoryModal record={p.row.original} modal={modalHistory} setModalVisible={showModalHistory} />
            </div>
          );
        },
        disableSortBy: true,
        className: 'align-middle',
      },
    }}
    enabledStyles={false}
  />
), (a, a1) => a.version === a1.version && a.searchTerms?.length === a1.searchTerms?.length);

Table.propTypes = {
  setTag: PropTypes.func.isRequired,
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  version: PropTypes.string.isRequired,
  setSelectedIds: PropTypes.func.isRequired,
  setCurrentProspect: PropTypes.func.isRequired,
  setShowNotesModal: PropTypes.func.isRequired,
  setPortal: PropTypes.func.isRequired,
  setDisabledNotes: PropTypes.func.isRequired,
  prospectContainerRefresh: PropTypes.func.isRequired,
  searchTerms: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default Table;
