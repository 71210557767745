/* eslint react/prop-types: 0 */
import React, { useMemo, useState, useEffect } from 'react';
import { unflatten } from 'flat';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { checkImagePromise } from '../../../../../shared/helpers/WVUtilities';


export default React.memo((props) => {
  const { populated, params: record } = unflatten(props.record);
  const { advertiser } = props.brand;
  const rightProspect = populated.prospects?.find(p => p?.params?.approvalStatus?.brand.targetAdvertiserId === advertiser);
  let prospect = rightProspect?.params;
  if (!prospect) prospect = record.prospects && record.prospects.length > 0 && populated.prospects ? populated.prospects[0].params : null;
  const [image, setImage] = useState('/images/user/defaultAvatar.png');
  const recordY = prospect?.tubularDetails?.accounts.find(a => a.platform === 'youtube');
  const recordI = prospect?.tubularDetails?.accounts.find(a => a.platform === 'instagram');
  const recordF = prospect?.tubularDetails?.accounts.find(a => a.platform === 'facebook');
  const recordT = prospect?.tubularDetails?.accounts.find(a => a.platform === 'twitter');
  const title = prospect?.title || '';
  useEffect(async () => {
    setImage('/images/user/defaultAvatar.png');
    if (recordY && await checkImagePromise(recordY.thumbnailUrl)) {
      console.log('youtube Image');
      setImage(recordY.thumbnailUrl);
    } else if (recordI && await checkImagePromise(recordI.thumbnailUrl)) {
      console.log('Instagram Image');
      setImage(recordI.thumbnailUrl);
    } else if (recordF && await checkImagePromise(recordF.thumbnailUrl)) {
      console.log('Facebook Image');
      setImage(recordF.thumbnailUrl);
    } else if (recordT && await checkImagePromise(recordT.thumbnailUrl)) {
      console.log('Twitter Image');
      setImage(recordT.thumbnailUrl);
    }
  }, [record._id]);


  const name = `${record.first_name || ''} ${record.last_name || ''}`;
  const { hsContactObject } = record;
  const mediaChannels = useMemo(() => {
    const returnObj = [];
    const m = hsContactObject?.mediaChannels || {};
    const getoObj = t => (
      <a
        href={t.url}
        target="_blank"
        rel="noreferrer"
        key={t.platform}
        style={{ marginRight: '4px' }}
      >
        <img
          src={`/images/mediacontent/${t.platform}.png`}
          alt={t.platform}
          title={t.title}
          style={{ width: '20px' }}
        />
      </a>);
    // eslint-disable-next-line no-useless-escape
    const addhttp = url => `${!/^(?:f|ht)tps?:\/\//.test(url) ? 'https://' : ''}${url}`;

    if (m.youtube_channel_url) {
      const url = addhttp(m.youtube_channel_url);
      returnObj.push(getoObj({ platform: 'youtube', url, title: 'youtube' }));
    }
    if (m.facebook_channel_url) {
      const url = addhttp(m.facebook_channel_url);
      returnObj.push(getoObj({ platform: 'facebook', url, title: 'facebook' }));
    }
    if (m.instagram_channel) {
      const url = addhttp(m.instagram_channel);
      returnObj.push(getoObj({ platform: 'instagram', url, title: 'instagram' }));
    }
    return returnObj;
  }, [record]);

  return (
    <div
      className="d-flex"
    >
      <Link
        to={`/creator/${record._id}`}
        rel="noreferrer"
      >
        <img
          src={image}
          alt={name}
          style={{ width: '50px', height: 'auto' }}
          className="align-self-center"
        />
      </Link>
      <div
        className="align-self-center flex-column"
        style={{ marginLeft: '8px' }}
      >
        <div
          className=""
        >
          <span
            className="bold-text"
          >
            {title}
          </span>
          {record.isInternal && (
            <img
              src="/images/general/logo_short_128.png"
              alt="internalLogo"
              // title={prospectOwnerName}
              style={{ width: '18px', marginLeft: '7px' }}
            />
          )}
        </div>
        <div
          className=""
        >
          <Link
            className={title ? '' : 'bold-text'}
            to={`/creator/${record._id}`}
            rel="noreferrer"
          >
            {name}
          </Link>
        </div>
        {prospect ? (
          <div className="" >
            {prospect.tubularDetails.accounts.map(t => (
              <a
                href={t.url}
                target="_blank"
                rel="noreferrer"
                key={t.platform}
                style={{ marginRight: '4px' }}
              >
                <img
                  src={`/images/mediacontent/${t.platform}.png`}
                  alt={t.platform}
                  title={t.title}
                  style={{ width: '20px' }}
                />
              </a>))}
            {prospect.listenNotesDetails?.url && (
              <a
                href={prospect.listenNotesDetails.url}
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: '4px' }}
              >
                <img
                  src="/images/mediacontent/podcast_128.png"
                  alt="podcast"
                  title={prospect.listenNotesDetails.title}
                  style={{ width: '20px' }}
                />
              </a>
            )}
          </div>) : (<div className="" >{mediaChannels}</div>)}
      </div>
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));
