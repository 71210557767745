/* eslint react/prop-types: 0 */
/* eslint react/no-array-index-key: 0 */
/* eslint no-underscore-dangle: 0 */
import React from 'react';
import { unflatten } from 'flat';

export default (props) => {
  const { record } = props;
  const recordNew = unflatten(record);
  const keyWords = recordNew.params.campaigns;
  const items = recordNew.populated.campaigns;
  const links = keyWords.map((keyWord) => {
    const item = items.find(e => e.params._id === keyWord);
    const link = `/resources/Campaign/records/${keyWord}/show`;
    return (
      <div key={keyWord} >
        <a href={link} target="_blank" rel="noreferrer noopener">{item.params.keyword}</a>
      </div>
    );
  });
  return (
    <div style={{ minWidth: '150px' }}>
      {links}
    </div>
  );
};
