import { unflatten } from 'flat';
import React from 'react';
import PropTypes from 'prop-types';

const TitleInListApprovals = ({ record }) => {
  const recortUnflatten = unflatten(record);
  const { title, mediaUrl } = recortUnflatten.params;

  return (<a target="_blank" rel="noreferrer" href={mediaUrl}>{title}</a>);
};
TitleInListApprovals.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default TitleInListApprovals;

