import React from 'react';

export default (props) => {
  const { record } = props;
  const url = `${window.location.href.substring(0, window.location.href.indexOf('resources'))}influencer/profile/${record.params.influencer}`;
  let influencer = record.params.sharedId || 'N/A';
  if (typeof record.populated.influencer !== 'undefined') {
    const name = `${record.populated.influencer.params.first_name} ${record.populated.influencer.params.last_name}`;
    if (!name.includes('null')) influencer = name;
  }
  const result = ((typeof record.populated.influencer !== 'undefined')
    ? (
      <a href={url} target="_blank" rel="noreferrer">
        {influencer}
      </a>
    )
    : influencer);
  return result;
};
