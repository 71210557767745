/* eslint react/prop-types: 0 */
/* eslint no-unused-vars: 0 */
import { unflatten } from 'flat';
// import moment from 'moment';
// import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ButtonToolbar, Button } from 'reactstrap';
import AlertCircleIcon from 'mdi-react/AlertCircleIcon';

import ThumbTackLeadCard from '../ThumbTackLeadCard';
// import MatTableForRedux from '../../../../../../shared/tables/materialTable/components/MatTableForRedux';
// import formater from '../../../../../../shared/helpers/WVFormatter';
// import { getConstant } from '../../../../../../shared/helpers/WVConstants';


class ThumbTackLeadActionsInList extends React.Component {
  constructor(props) {
    super(props);
    const { record } = this.props;
    const recordUnflatten = unflatten(record);
    const {
      dealObject,
      payments,
    } = recordUnflatten.params;

    this.state = {
      showPopup: false,
    };

    this.togglePopup = this.togglePopup.bind(this);
  }

  togglePopup(e) {
    e.preventDefault();
    this.setState({ showPopup: !this.state.showPopup });
  }

  render() {
    const { record } = this.props;
    // const recordUnflatten = unflatten(record);

    const {
      showPopup,
    } = this.state;
    return (
      <div>
        <div style={{ minWidth: '100px' }} >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a title="Details" href="#" className="text-dark" onClick={() => this.setState({ showPopup: true })} ><AlertCircleIcon /></a>
        </div>
        <Modal
          isOpen={showPopup}
          toggle={this.togglePopup}
        >
          <div className="modal__header">
            <h4 className="text-modal  modal__title">Details</h4>
          </div>
          {/* eslint-disable-next-line react/no-danger */}
          <div className="modal__body ltr-support">
            <ThumbTackLeadCard
              record={record}
            />
          </div>
          <ButtonToolbar className="modal__footer">
            <Button color="danger" onClick={this.togglePopup}>Close</Button>
          </ButtonToolbar>
        </Modal>
      </div>
    );
  }
}


export default ThumbTackLeadActionsInList;
