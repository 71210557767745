exports.setUrl = ({
  history,
  removeBeforeSet,
  search,
  dateFrom,
  dateTo,
  selectedButton,
  filtersBrandStatus,
  selectedCreators,
  selectedContentTypes,
}) => {
  let searchUrl = new URLSearchParams(window.location.search);
  const oldUrl = `${history.location.pathname}?${searchUrl.toString()}`;

  if (removeBeforeSet) {
    searchUrl = new URLSearchParams();
  }

  if (search) {
    searchUrl.set('filters.fullSearchNameApprovals', search);
  } else {
    searchUrl.delete('filters.fullSearchNameApprovals');
  }

  searchUrl.delete('filters.createdAt~~from');
  searchUrl.delete('filters.createdAt~~to');
  searchUrl.delete('range');
  if (dateFrom !== '') {
    searchUrl.append('filters.createdAt~~from', dateFrom);
  }
  if (dateTo !== '') {
    searchUrl.append('filters.createdAt~~to', dateTo);
  }
  searchUrl.append('range', selectedButton);

  const filterCreators = searchUrl.getAll('filters.creator');
  let deleteFilterCreators = false;

  if (!selectedCreators || selectedCreators.length === 0) {
    searchUrl.delete('filters.creator');
  }
  if (selectedCreators && filterCreators) {
    filterCreators.forEach((creator) => {
      if (selectedCreators.filter(item => item.value === creator).length === 0) {
        deleteFilterCreators = true;
      }
    });
    if (deleteFilterCreators) {
      searchUrl.delete('filters.creator');
    }
    if (selectedCreators) {
      selectedCreators.forEach((creator) => {
        if (creator.value !== '' && (!filterCreators.includes(creator.value) || deleteFilterCreators)) {
          searchUrl.append('filters.creator', creator.value);
        }
      });
    }
  }

  const filtersContentTypes = searchUrl.getAll('filters.type');
  let deleteFilterContentTypes = false;

  if (!selectedContentTypes || selectedContentTypes.length === 0) {
    searchUrl.delete('filters.type');
  }
  if (selectedContentTypes) {
    filtersContentTypes.forEach((type) => {
      if (selectedContentTypes.filter(item => item.value === type).length === 0) {
        deleteFilterContentTypes = true;
      }
    });
    if (deleteFilterContentTypes) {
      searchUrl.delete('filters.type');
    }
    selectedContentTypes.forEach((selectedType) => {
      if (selectedType.value !== '' && (!filtersContentTypes.includes(selectedType.value) || deleteFilterContentTypes)) {
        searchUrl.append('filters.type', selectedType.value);
      }
    });
  }

  searchUrl.delete('filters.brandStatus');
  if (Object.keys(filtersBrandStatus) && (filtersBrandStatus['In Review'] || filtersBrandStatus['Pending Edits'] || filtersBrandStatus.Approved)) {
    Object.keys(filtersBrandStatus).forEach((status) => {
      if (filtersBrandStatus[status]) {
        searchUrl.append('filters.brandStatus', status);
      }
    });
  } else {
    searchUrl.append('filters.brandStatus', 'In Review');
    searchUrl.append('filters.brandStatus', 'Pending Edits');
    searchUrl.append('filters.brandStatus', 'Approved');
  }

  // searchUrl.delete('filters.status');
  // searchUrl.append('filters.status', 'Awaiting Brand Approval');

  let newUrl = `${history.location.pathname}?${searchUrl.toString()}`;
  if (oldUrl !== newUrl) {
    searchUrl.set('page', '1');
    newUrl = `${history.location.pathname}?${searchUrl.toString()}`;
    history.push(newUrl);
  }
};
