// import { unflatten } from 'flat';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { axios } from 'ApiClient';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
// import ApiClient from 'ApiClient';
import { getConstant } from '../../../../../shared/helpers/WVConstants';

const BrandStatus = React.memo(({
  record,
  setCurrentProspect,
  setShowNotesModal,
  setPortal,
  setDisabledNotes,
  updateRecordFunction,
}) => {
  const key = 'approvalStatus.brand.status';
  const notesKey = 'approvalStatus.brand.notes';

  const aValues = getConstant('prospect', 'brandStatuses', {});
  // console.log(aValues);
  // const recordUnflatten = unflatten(record);
  const rowData = record.params;
  const val = (typeof rowData !== 'undefined' && typeof rowData[key] !== 'undefined') ? rowData[key] : '';
  // console.log(val);
  const [value, setValue] = useState(val);
  const notesVal = (typeof rowData !== 'undefined' && typeof rowData[notesKey] !== 'undefined') ? rowData[notesKey] : '';
  const [diabled, setDisable] = useState(false);
  const [error, setError] = useState(null);
  const options = Object.keys(aValues).map(k => ({ value: k, label: aValues[k] }));

  useEffect(() => {
    setValue(val);
  }, [record.id, val]);

  const handleChange = async (option) => {
    setDisable(true);
    setError(null);
    setValue(option.value);
    try {
      const res = await axios.post('/api/brand/prospects/update-status', {
        prospectId: record.id,
        status: option.value,
      });
      if (!res.data.success && res.data.error) {
        throw new Error('Unable to update status');
      }
      console.log(res.data.prospectPopulated);
      if (res.data.prospectPopulated) updateRecordFunction(res.data.prospectPopulated);
    } catch (e) {
      setError(e.message);
      setDisable(false);
    }
    if (option.value === 'Rejected') {
      setCurrentProspect(record);
      setShowNotesModal(true);
    }
    setDisable(false);
    return true;
  };
  return (
    <div style={{ minWidth: '160px', maxWidth: '160px' }}>
      <div style={{ minWidth: '130px', maxWidth: '150px', float: 'left' }} >
        <Select
          value={options.filter(option => option.value === value)}
          options={options}
          className="brandStatusSelector"
          onChange={handleChange}
          placeholder="Brand Status"
          isDisabled={diabled}
          menuPlacement="auto"
          menuPortalTarget={document.body}
        />
        {error ? (<span className="text-danger">{error}</span>) : null}
      </div>
      <div style={{ marginTop: '6px', marginRight: '6px', float: 'right' }}>
        <Tooltip title="Brand Notes">
          <IconButton
            size="small"
            onClick={() => { setCurrentProspect(record); setPortal('brand'); setDisabledNotes(false); setShowNotesModal(true); }}
          >
            <FontAwesomeIcon icon={[notesVal !== '' ? 'fas' : 'far', 'file-alt']} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

BrandStatus.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  setCurrentProspect: PropTypes.func.isRequired,
  setShowNotesModal: PropTypes.func.isRequired,
  setPortal: PropTypes.func.isRequired,
  setDisabledNotes: PropTypes.func.isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
};
export default BrandStatus;
