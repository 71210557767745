import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import ReplyIcon from 'mdi-react/ReplyIcon';
import LaunchIcon from 'mdi-react/LaunchIcon';
// import InfoIcon from 'mdi-react/LaunchIcon';
// import AlertCircleIcon from 'mdi-react/AlertCircleIcon';
import IconButton from '@material-ui/core/IconButton';
import { unflatten } from 'flat';


const ThumbTackLeadButtonsInList = (props) => {
  const { record } = props;
  const recordUnflattened = unflatten(record);

  const messagesLink = `/thumbtack/messages/?lead=${recordUnflattened.id}`;
  let thumbTackLink = '';
  if (recordUnflattened.params.request) {
    thumbTackLink = `https://www.thumbtack.com/request/${recordUnflattened.params.request.requestID}`;
  }
  return (
    <div>
      <Link to={messagesLink}>
        <IconButton
          size="small"
          className="material-table__toolbar-button"
        >
          <ReplyIcon />
        </IconButton>
      </Link>
      <Link to={{ pathname: thumbTackLink }} target="_blank">
        <IconButton
          size="small"
          className="material-table__toolbar-button"
        >
          <LaunchIcon />
        </IconButton>
      </Link>
    </div>
  );
};
ThumbTackLeadButtonsInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ThumbTackLeadButtonsInList;
