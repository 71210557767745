const getName = (record) => {
  if (!record) return '';
  const firstName = record?.firstName?.trim() || record?.first_name?.trim() || '';
  const lastName = record?.lastName?.trim() || record?.last_name?.trim() || '';
  if (firstName || lastName) return `${firstName} ${lastName}`;
  return record?.email || '';
};

const getTitle = (record) => {
  const name = record?.name || '';
  if (name) return name;
  return getName(record);
};

exports.getDeleteMessage = (record) => {
  const title = getTitle(record);
  return title ? `Are you sure you wish to delete ${title}?` : 'Do you want to delete this item?';
};

exports.getName = getName;
