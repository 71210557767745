import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const EventDatesInList = (props) => {
  const { record } = props;
  const date = moment(Number(record.params.occurredAt));
  // console.log(date, 'DATE');
  // console.log(record.params.occurredAt, Number(record.params.occurredAt));
  return (
    <div>{date.format('LLLL')}</div>
  );
};
EventDatesInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default EventDatesInList;
