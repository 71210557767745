import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

const Box = ({ label, value, loading }) => (
  <Row
    style={{
      padding: '15px',
      border: '1px solid #cccccc',
      borderRadius: '5px',
    }}
  >
    <Col>
      <div style={{ textAlign: 'center', color: 'black' }} >
        <span
          style={{
            fontSize: '20px',
            fontWeight: '700',
            marginBottom: '12px',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {loading ? (<div className="spinner-border text-light spinner-border-sm" />) : value}
        </span>
        <br style={{ lineHeight: '50%' }} />
        <span style={{ width: '100%', fontSize: '16px' }}>{label}</span>
      </div>
    </Col>
  </Row>
);

Box.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  loading: PropTypes.bool.isRequired,
};

Box.defaultProps = {
  value: '',
};

export default Box;
