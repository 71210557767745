import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import { axios } from 'ApiClient';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import {
  setCurrentMediaContent,
  setShowBrandStatusNotesModal,
} from '../../../../../redux/reducers/brand/CreatorsReducer';

const BrandStatus = React.memo(({
  record, setVersion, dispatch,
}) => {
  const options = [
    { label: 'In Review', value: 'In Review' },
    { label: 'Pending Edits', value: 'Pending Edits' },
    { label: 'Approved', value: 'Approved' },
  ];
  const [selectedOption, setSelectedOption] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const notesVal = (record && record.params.brandNotes) ? record.params.brandNotes : '';

  useEffect(() => {
    const _value = record?.params.brandStatus || '';
    const selectedValue = _value || null;
    setSelectedOption(options.find(option => option.value === selectedValue));
  }, [record.id, record.params.brandStatus]);

  const handleChange = async (selectedItem) => {
    const { value } = selectedItem;
    setSelectedOption(selectedItem);
    setDisabled(true);
    // console.log(record);
    try {
      axios({
        method: 'post',
        url: '/api/brand/creators/update-brand-status',
        data: {
          id: record.id,
          status: value,
          date: new Date(),
        },
      }).then((response) => {
        if (response.data.success) {
          setDisabled(false);
          console.log(value);
          if (value === 'Pending Edits') {
            dispatch(setCurrentMediaContent(record));
            dispatch(setShowBrandStatusNotesModal(true));
          }
          if (setVersion) setVersion(new Date().toString());
        } else {
          console.log(response.data);
        }
      });
    } catch (e) {
      console.log(e.message);
      setDisabled(false);
    }
    return true;
  };

  return (
    <div style={{ width: '180px' }}>
      <div style={{ width: '150px', float: 'left' }} >
        <Select
          value={selectedOption}
          options={options}
          onChange={handleChange}
          placeholder=""
          isDisabled={disabled}
          menuPortalTarget={document.body}
          isOptionDisabled={option => option.isdisabled}
        />
      </div>
      <div style={{ marginTop: '6px', marginRight: '6px', float: 'right' }}>
        <Tooltip title="Brand Notes">
          <IconButton
            size="small"
            onClick={() => {
              dispatch(setCurrentMediaContent(record));
              dispatch(setShowBrandStatusNotesModal(true));
            }}
          >
            <FontAwesomeIcon icon={[notesVal !== '' ? 'fas' : 'far', 'file-alt']} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

BrandStatus.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
};

BrandStatus.defaultProps = {
  setVersion: null,
};

export default connect()(BrandStatus);
