/* eslint react/prop-types: 0 */
import React from 'react';
import { uid } from 'react-uid';
import IconButton from '@material-ui/core/IconButton';
import PodcastIcon from 'mdi-react/PodcastIcon';
import YoutubeIcon from 'mdi-react/YoutubeIcon';
import InstagramIcon from 'mdi-react/InstagramIcon';
import WebIcon from 'mdi-react/WebIcon';
import TwitterIcon from 'mdi-react/TwitterIcon';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglekIcon from 'mdi-react/GoogleIcon';
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';
import Tooltip from '@material-ui/core/Tooltip';
import { unflatten } from 'flat';

const generateIcons = (channel, link, title, icon) => (
  <span key={uid(channel)}>
    <a href={link} target="_blank" rel="noreferrer noopener">
      <Tooltip title={title}>
        <IconButton
          size="small"
          className="material-table__toolbar-button"
        >
          {icon}
        </IconButton>
      </Tooltip>
    </a>
  </span>
);

export default (props) => {
  const { record } = props;
  const recordNew = unflatten(record);
  const {
    instagramChannels,
    youTubeChannels,
    podcastChannels,
    websiteChannels,
    twitterChannels,
    facebookChannels,
    googleChannels,
  } = recordNew.params;
  const values = podcastChannels.map((podcast) => {
    const link = `https://podcasts.apple.com/us/podcast/id${podcast.itunesId}`;
    const linkAll = `https://podcasts.apple.com/us/podcast/id${podcast.itunesId}#see-all/listeners-also-subscribed`;
    return (
      <span key={uid(podcast)}>
        <a href={link} target="_blank" rel="noreferrer noopener">
          <Tooltip title="Open Podcast">
            <IconButton
              size="small"
              className="material-table__toolbar-button"
            >
              <PodcastIcon />
            </IconButton>
          </Tooltip>
        </a>
        <a href={linkAll} target="_blank" rel="noreferrer noopener">
          <Tooltip title={`Listeners Also Subscribed To ${podcast.title}`}>
            <IconButton
              size="small"
              className="material-table__toolbar-button"
            >
              <AccountMultipleIcon />
            </IconButton>
          </Tooltip>
        </a>
      </span>);
  });

  const youtubeChannelsContent = youTubeChannels.map(channel =>
    generateIcons(channel, channel.youtubeUrl, 'Open YouTube', (<YoutubeIcon />)));

  const instagramChannelsContent = instagramChannels.map(channel => generateIcons(
    channel,
    `https://www.instagram.com/${channel.instagramHandle}`,
    'Open Instagram',
    (<InstagramIcon />),
  ));

  const websiteChannelsContent = websiteChannels.map(channel =>
    generateIcons(channel, channel.website, `Open website: ${channel.website}`, (<WebIcon />)));

  const twitterChannelsContent = twitterChannels.map(channel => generateIcons(
    channel,
    `https://twitter.com/${channel.twitterHandle}`,
    'Open Twitter',
    (<TwitterIcon />),
  ));
  const facebookChannelsContent = facebookChannels.map(channel => generateIcons(
    channel,
    `https://www.facebook.com/${channel.facebookHandle}`,
    'Open Facebook',
    (<FacebookIcon />),
  ));
  const googleChannelsContent = googleChannels.map(channel => generateIcons(
    channel,
    channel.googleUrl,
    'Open Google',
    (<GooglekIcon />),
  ));
  return (
    <div>
      {values}
      {youtubeChannelsContent}
      {instagramChannelsContent}
      {websiteChannelsContent}
      {twitterChannelsContent}
      {facebookChannelsContent}
      {googleChannelsContent}
    </div>
  );
};
