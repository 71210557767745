import ActionsInList from './ActionsInList';
import PasswordInEdit from './PasswordInEdit';
import NameInList from './NameInList';
import EmailInList from './EmailInList';

export default {
  edit: {
    password: PasswordInEdit,
  },
  list: {
    fullName: NameInList,
    email: EmailInList,
  },
  actions: ActionsInList,
};
