import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const ButtonsInList = (props) => {
  const { record } = props;
  const editLink = `/brand-resources/edit/${record.params._id}`;
  return (
    <div>
      <Link to={editLink}>
        <button className="btn btn-primary btn-sm">Edit</button>
      </Link>
    </div>
  );
};
ButtonsInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ButtonsInList;
