/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import {
  FormGroup,
} from 'reactstrap';
import ApiClient from 'ApiClient';
import CurrencyField from '../../../../../../../shared/components/form/CurrencyField';

export default (props) => {
  const { record, updateRecordFunction } = props;
  const [disabled, setDisabled] = useState(false);
  const [value] = useState(record.params.askingAmount);

  const updateAmount = async (event) => {
    if (event && event.target && event.target.value) {
      setDisabled(true);
      const data = new FormData();
      data.append('askingAmount', parseFloat(event.target.value.replace(/[^\d.]/g, '')));
      const api = new ApiClient();
      api.recordAction({
        resourceId: 'ResearchCreator',
        recordId: record.params['researchCreator._id'],
        actionName: 'edit',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        setDisabled(false);
        if (updateRecordFunction) updateRecordFunction(record.id, { askingAmount: parseFloat(event.target.value.replace(/[^\d.]/g, '')) });
      });
      return true;
    }
    return false;
  };

  const defaultValue = parseFloat(value);

  return (
    <FormGroup className="pb-0 mb-0 mr-3" style={{ paddingTop: '14px' }} >
      <CurrencyField
        type="text"
        className="form-control"
        disabled={disabled}
        defaultValue={defaultValue}
        onBlur={updateAmount}
      />
    </FormGroup>
  );
};
