import React from 'react';
import PropTypes from 'prop-types';

const getTitle = (record) => {
  if (record.mediaUrl && record.title) {
    // eslint-disable-next-line react/jsx-no-target-blank
    return (<a target="_blank" rel="noreferrer noopener" href={record.mediaUrl}>{record.title}</a>);
  } else if (record.title) {
    return (record.title);
  }
  return null;
};

const TitleInList = ({ record }) => (
  <span>
    {record.thumbnail && (
      <img style={{ float: 'left', maxWidth: 100, marginRight: 10 }} src={record.thumbnail} alt="" />
    )}
    {!record.thumbnail && (
      <img style={{ float: 'left', maxWidth: 100, marginRight: 10 }} src="/images/brands/thumbnail-default.png" alt="" />
    )}
    {getTitle(record)}
  </span>
);
TitleInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default TitleInList;

