import TypeInList from './TypeInList';
import ActionsInList from './ActionsInList';
import InfluencerInList from './InfluencerInList';

export default {
  edit: {

  },
  list: {
    'mediaContent.type': TypeInList,
    'influencer.email': InfluencerInList,
    // eslint-disable-next-line no-nested-ternary
    isFTCCompliant: ({ record }) => (record.params.isFTCCompliant !== null && record.params.isFTCCompliant !== undefined ? (record.params.isFTCCompliant ? 'Yes' : 'No') : ''),
    totalScore: ({ record }) => (record.params.totalScore === 0 ? 'N/A' : record.params.totalScore),
  },
  actions: ActionsInList,
};
