import { useDispatch } from 'react-redux';
import { loadTableData } from '../../redux/reducers/TableReducerDefault';

const useReloadTableHook = (url = null) => {
  const dispatch = useDispatch();
  const loadPage = () => dispatch(loadTableData(url));
  return loadPage;
};

export default useReloadTableHook;
