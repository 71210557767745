import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { CustomizerProps, ThemeProps, RTLProps } from '../../shared/prop-types/ReducerProps';

const MainWrapper = ({ theme, customizer, children, rtl }) =>  {
  const wrapperClass = classNames({
    wrapper: true,
    'squared-corner-theme': customizer.squaredCorners,
    'blocks-with-shadow-theme': customizer.withBoxShadow,
    'top-navigation': customizer.topNavigation,
  });
  const direction = rtl.direction || 'ltr';
  return (
    <div className={`${theme.className} ${direction}-support`} dir={direction}>
      <div className={wrapperClass}>
        {children}
      </div>
    </div>
  );
}
MainWrapper.propTypes = {
  customizer: CustomizerProps.isRequired,
  theme: ThemeProps.isRequired,
  rtl: RTLProps.isRequired,
  children: PropTypes.element.isRequired,
};

export default connect(state => ({
  theme: state.theme,
  rtl: state.rtl,
  customizer: state.customizer,
}))(MainWrapper);
