/* eslint react/prop-types: 0 */
import React from 'react';
import Select from 'react-select';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import MagnifyPlusOutlineIcon from 'mdi-react/MagnifyPlusOutlineIcon';
import PropertyInFilter from '../../ui/property-in-filter';

class Filter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleInputChange(event) {
    const { onChange, property } = this.props;
    onChange(property.name, event.target.value);
  }

  handleSelectChange(selected) {
    const { onChange, property } = this.props;
    const value = selected ? selected.value : '';
    onChange(property.name, value);
  }

  renderInput() {
    const { property, filter } = this.props;
    const filterKey = `filter-${property.name}`;
    const value = filter[property.name] || '';
    if (property.availableValues) {
      const selected = property.availableValues.find(av => av.value === value);
      return (
        <div className="control has-icons-left">
          <Select
            value={typeof selected === 'undefined' ? '' : selected}
            isClearable
            options={property.availableValues}
            onChange={this.handleSelectChange}
          />
        </div>
      );
    }
    return (
      <Input
        type="text"
        id={property.name}
        name={filterKey}
        onChange={this.handleInputChange}
        value={value}
        placeholder={property.label}
      />
    );
  }

  render() {
    const { property } = this.props;
    return (
      <PropertyInFilter property={property}>
        {property.availableValues ? this.renderInput() :
          (
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText><MagnifyPlusOutlineIcon size="15" /></InputGroupText>
              </InputGroupAddon>
              {this.renderInput()}
            </InputGroup>
          )
        }
      </PropertyInFilter>
    );
  }
}
/*
 * <React.Fragment>
        <span className="icon is-small is-right">
          <i className="fas fa-search" />
        </span>
        <StyledInput
          type="text"
          className="input filter"
          name={filterKey}
          onChange={this.handleInputChange}
          value={value}
        />
      </React.Fragment>
 */
export default Filter;
