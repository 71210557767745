import React from 'react';
import PropTypes from 'prop-types';
import mapMediaTypeIcons from '../../../../admin/Scorecard/MediaContent/components/custom/mapMediaTypeIcons';

const mapType = mapMediaTypeIcons();

const TypeInList = ({ record }) => {
  const { type, mediaUrl } = record;
  return (
    <div>
      <a href={mediaUrl} target="_blank" rel="noreferrer">
        <img
          style={{ width: '30px' }}
          src={`/images/mediacontent/${mapType[type]}.png`}
          alt={type}
          title={type}
        />
      </a>
    </div>
  );
};
TypeInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default TypeInList;
