/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { unflatten } from 'flat';
import ApiClient from 'ApiClient';
import { parseUrl, processUrlSearch } from '../../shared/helpers/WVUtilities';
import { addNotice } from '../../containers/App/store/store';


/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const tableReducer = createSlice({
  name: 'table',
  initialState: {
    records: [],
    page: 1,
    perPage: 10,
    total: 0,
    direction: 'asc',
    sortBy: '_id',
    selectedRecords: [],
    selectedRecordsIds: [],
    loading: false,
    lastFetched: (new Date()).toString(),
    selectedRecordsIdsMultiselect: [],
    useMultiPageCheckboxes: false,
    selectedRecordsObject: [],
    modals: {},
    tableActions: {},
    tableFilterUrl: null,
    tablesProperties: [],
    resourceId: null,
    pageLocation: null,
    loadingPageLocation: null,
    urlModify: null,
    previousTableFilterUrl: null,
  },
  reducers: {
    setRecords(state, action) {
      const {
        records,
      } = action.payload;
      state.records = records;
      state.selectedRecords = [];
      state.selectedRecordsIds = [];
      state.selectedRecordsIdsMultiselect = [];
      state.selectedRecordsObject = [];
      state.loading = false;
      state.lastFetched = (new Date()).toString();
    },
    setLoading(state, action) {
      const loading = action.payload;
      state.loading = !!loading;
    },
    setPageLocation(state, action) {
      const location = action.payload;
      state.pageLocation = location;
      state.records = [];
    },
    setUrlParams(state, action) {
      const {
        page, perPage, direction, sortBy,
      } = action.payload;
      console.log(action.payload, 'state url');
      state.perPage = Number(perPage);
      state.page = Number(page);
      state.direction = direction;
      state.sortBy = sortBy;
    },
    setState(state, action) {
      const {
        records, page, perPage, total, direction, sortBy, useMultiPageCheckboxes, pageLocationStart,
      } = action.payload;
      // console.log(state.pageLocation, window.location.pathname, 'LOCATION');
      if ((pageLocationStart && pageLocationStart === window.location.pathname) || (!pageLocationStart && state.pageLocation === window.location.pathname)) {
        state.records = records;
        state.perPage = perPage;
        state.page = page;
        state.total = total;
        state.direction = direction;
        state.sortBy = sortBy;
        state.selectedRecords = [];
        state.selectedRecordsIds = [];
        state.useMultiPageCheckboxes = useMultiPageCheckboxes;
        state.selectedRecordsIdsMultiselect = useMultiPageCheckboxes ? state.selectedRecordsIdsMultiselect : [];
        state.loading = false;
      }
    },
    setSelectedRecord(state, action) {
      const { selectedItemNumber, record } = action.payload;
      if (!record.checkboxDisable) {
        const selectedRecordIds = state.selectedRecords;
        // console.log(selectedRecordIds, selectedItemNumber);
        if (!selectedRecordIds.includes(selectedItemNumber)) {
          selectedRecordIds.push(selectedItemNumber);
        }
        state.selectedRecords = selectedRecordIds;
        state.selectedRecordsIds = state.selectedRecords.map(k => state.records[k].id);
        const { selectedRecordsIdsMultiselect } = state;
        if (!selectedRecordsIdsMultiselect.includes(state.records[selectedItemNumber].id)) {
          selectedRecordsIdsMultiselect.push(state.records[selectedItemNumber].id);
          state.selectedRecordsObject.push(record);
        }
      }
    },
    removeSelectedRecord(state, action) {
      console.log('REMOVE SELECTED');
      const selectedItemNumber = action.payload;
      const { selectedRecords } = state;
      const index = selectedRecords.indexOf(selectedItemNumber);
      console.log([selectedItemNumber, index, selectedRecords.map(p => p.toString())]);
      const ID = state.records[selectedItemNumber].id;
      if (index > -1) {
        selectedRecords.splice(index, 1);
      }
      console.log([selectedItemNumber, index, selectedRecords.map(p => p.toString())]);
      const multiselect = state.selectedRecordsIdsMultiselect;
      const objects = state.selectedRecordsObject;
      const indexId = multiselect.indexOf(ID);
      if (indexId > -1) {
        multiselect.splice(indexId, 1);
        objects.splice(indexId, 1);
      }
      console.log([indexId, ID]);
      state.selectedRecords = selectedRecords;
      state.selectedRecordsIds = state.selectedRecords.map(k => state.records[k].id);
      state.selectedRecordsIdsMultiselect = multiselect;
      state.selectedRecordsObject = objects;
    },
    selectAll(state) {
      const ids = [];
      state.records.forEach((r, i) => {
        if (!r.checkboxDisable) ids.push(i);
      });
      state.selectedRecords = ids;
      state.selectedRecordsIds = state.selectedRecords.map(k => state.records[k].id);

      const { selectedRecordsIdsMultiselect } = state;
      state.records.forEach((r) => {
        if (!selectedRecordsIdsMultiselect.includes(r.id) && !r.checkboxDisable) {
          state.selectedRecordsIdsMultiselect.push(r.id);
          state.selectedRecordsObject.push(r);
        }
      });
    },
    diselectAll(state) {
      state.selectedRecords = [];
      state.selectedRecordsIds = [];
      state.selectedRecordsIdsMultiselect = [];
      state.selectedRecordsObject = [];
    },
    setTableActions(state, action) {
      const { filterName, value } = action.payload;
      // const keys = Object.keys(state.tablesActions);
      let filtersNow = null;
      if (typeof value === 'string') filtersNow = value;
      if (typeof value === 'object') filtersNow = { ...state.tableActions[filterName], ...value };
      state.tableActions[filterName] = filtersNow;
      state.page = 1;
      // state.modals[id].isShow = true;
      // state.modals[id].data = data;
    },
    setTableProperty(state, action) {
      const {
        property,
      } = action.payload;
      const { tablesProperties } = state;
      const newProperties = [];
      tablesProperties.forEach((p) => {
        if (p.filterName === property.filterName) {
          newProperties.push({ ...p, ...property });
        } else {
          newProperties.push(p);
        }
      });
      const existsProperty = state.tablesProperties.findIndex(p => p.filterName === property.filterName);
      if (existsProperty === -1) {
        newProperties.push(property);
      }

      state.tablesProperties = newProperties;
    },

    setTableProps(state, action) { // prefill filters from url
      const {
        filters, properties, resourceId, tableProps, urlModify,
      } = action.payload;
      const filtersNew = {};
      const { tablesProperties } = state;
      const newProperties = [];
      tablesProperties.forEach((p) => {
        const prop = properties.find(pr => pr.filterName === p.filterName); // new Filter what exists in tables properties

        if (prop) {
          newProperties.push({ ...p, ...prop });
        } else {
          newProperties.push(p);
        }
      });
      properties.forEach((p) => {
        const prop = newProperties.find(pr => pr.filterName === p.filterName); // new Filter what exists in tables properties
        if (!prop) {
          newProperties.push(p);
        }
      });

      newProperties.forEach((prop) => {
        const { initialState, func, filterName } = prop;
        const value = func({
          initialState, filters, filterName, property: prop,
        });
        console.log(func, value, 'TRY');
        if (!filtersNew[filterName]) filtersNew[filterName] = {};
        filtersNew[filterName] = value;
      });

      state.tableActions = filtersNew;
      state.tablesProperties = newProperties;
      state.tableFilterUrl = null;
      state.resourceId = resourceId;
      state.urlModify = urlModify || null;

      if (tableProps.page) {
        state.page = Number(tableProps.page);
      }
      if (tableProps.perPage) {
        state.perPage = Number(tableProps.perPage);
      }
      if (tableProps.direction) {
        state.direction = String(tableProps.direction);
      }
      if (tableProps.sortBy) {
        state.sortBy = String(tableProps.sortBy);
      }
      // state.modals[id].isShow = true;
      // state.modals[id].data = data;
    },
    clearFilterPropertyState(state, action) {
      const items = action.payload;
      const filtersNew = {};
      console.log(items);
      items.forEach((filterName) => {
        const property = state.tablesProperties.find(a => a.filterName === filterName);
        if (property) {
          console.log(property.filterName, 'change Value');
          // const item = state.tableActions[property];
          filtersNew[filterName] = property.initialState;
        }
      });
      state.tableActions = filtersNew;
      // state.modals[id].isShow = true;
      // state.modals[id].data = data;
    },

    setTableURLFilters(state) {
      let search = new URLSearchParams('');
      Object.keys(state.tableActions).forEach((filterName) => {
        const item = state.tableActions[filterName];
        const property = state.tablesProperties.find(a => a.filterName === filterName);
        const customFunction = property?.funcToUrl;
        if (customFunction) {
          search = customFunction({ search, item, filterName });
        }
        if (item && typeof item === 'object') {
          if (customFunction) {
            search = customFunction({
              search, item, filterName, property,
            });
          } else {
            search = processUrlSearch(search, item, filterName);
          }
        }
        if (typeof item === 'string') {
          if (item) {
            search.set(`filters.${filterName}`, item);
          } else {
            search.delete(`filters.${filterName}`);
          }
        }
      });
      // perPage=10&page=1&direction=desc&sortBy=createdAt
      search.set('perPage', state.perPage);
      search.set('page', state.page);
      search.set('direction', state.direction);
      search.set('sortBy', state.sortBy);
      console.log(state.urlModify, 'stateurl');

      search = state.urlModify ? state.urlModify(search) : search;
      state.previousTableFilterUrl = state.tableFilterUrl;
      state.tableFilterUrl = state.tablesProperties?.length > 0 ? search.toString() : null;
    },
    showModal(state, action) {
      const { id, data } = action.payload;
      state.modals[id] = { isShow: true, data };
      // state.modals[id].isShow = true;
      // state.modals[id].data = data;
    },
    hideModal(state, action) {
      const { id } = action.payload;
      state.modals[id].isShow = false; // = { isShow: false, data: {} };
    },
    clearModal(state, action) {
      const { id } = action.payload;
      state.modals[id] = null;
    },
  },
});

export const {
  setUrlParams,
  setState,
  setLoading,
  setRecords,
  removeSelectedRecord,
  setSelectedRecord,
  selectAll,
  diselectAll,
  showModal,
  hideModal,
  clearModal,
  setTableActions,
  setTableURLFilters,
  setTableProps,
  clearFilterPropertyState,
  setPageLocation,
  setTableProperty,
} = tableReducer.actions;

const fetchData = async (url, resourceId) => {
  const api = new ApiClient();
  const query = new URLSearchParams(url);
  console.log(query.toString(), 'urlModify');
  try {
    const response = await api.resourceAction({
      actionName: 'list',
      resourceId,
      params: query,
    });
    const listActionReponse = response.data;
    let recordsToSet = listActionReponse.records;
    const recordIds = [];
    recordsToSet = listActionReponse.records.map((item) => {
      recordIds.push({ id: item.id });
      return unflatten(item);
    });

    return {
      records: recordsToSet,
      page: listActionReponse.meta.page,
      perPage: listActionReponse.meta.perPage,
      total: listActionReponse.meta.total,
      direction: listActionReponse.meta.direction,
      sortBy: listActionReponse.meta.sortBy,
      selectedRecords: [],
      loading: false,
    };
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
};


export const showModalById = (id, data) => async (dispatch) => {
  try {
    dispatch(showModal({ id, data }));
    // dis
  } catch (err) {
    console.log(err);
  }
};
export const hideModalById = id => async (dispatch) => {
  try {
    dispatch(hideModal({ id }));
    setTimeout(() => dispatch(clearModal({ id })), 500);
    // dis
  } catch (err) {
    console.log(err);
  }
};

export const setFilterProperty = (filterName, value) => async (dispatch, getState) => {
  try {
    const { table } = getState();
    const property = table.tablesProperties.find(p => p.filterName === filterName);
    if (!property) {
      throw new Error('No Action Property Defined');
    }
    dispatch(setTableActions({ filterName, value }));
    dispatch(setTableURLFilters());
    // dis
  } catch (err) {
    console.log(err);
  }
};

export const clearFilterProperty = items => (dispatch) => {
  try {
    dispatch(clearFilterPropertyState(items));
    dispatch(setTableURLFilters());
    // dis
  } catch (err) {
    console.log(err);
  }
};
export const preLoadFilters = (properties, { resourceId, initialState, urlModify }) => (dispatch) => {
  try {
    const query = new URLSearchParams(window.location.search);
    const tableProps = {
      page: Number(query.get('page')) || initialState.page || 1,
      perPage: Number(query.get('perPage')) || initialState.perPage || 10,
      direction: query.get('direction') || initialState.direction,
      sortBy: query.get('sortBy') || initialState.sortBy,

    };
    const filters = parseUrl();
    dispatch(setTableProps({
      properties, filters, resourceId, tableProps, urlModify,
    }));
    dispatch(setTableURLFilters());
    // dis
  } catch (err) {
    console.log(err);
  }
};

export const loadTableData = () => async (dispatch, getState) => {
  try {
    const { table } = getState();
    if (table.resourceId) {
      dispatch(setLoading(true));
      console.log('RUN FETCH DATA');
      try {
        const data = await fetchData(table.tableFilterUrl, table.resourceId);
        dispatch(setState(data));
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(setRecords({ records: [] }));
        dispatch(addNotice({
          message: e.message || 'Unable to get data',
          type: 'error',
          duration: 10,
        }));
        // console.error(e);
      }
    }
    // dis
  } catch (err) {
    console.log(err);
  }
};

export const setUrlParamsForTable = params => async (dispatch) => {
  try {
    dispatch(setUrlParams(params));
    dispatch(setTableURLFilters());
    // dispatch(loadTableData());
    // dis
  } catch (err) {
    console.log(err);
  }
};

export const updateRecordFunction = (newRecord, data) => async (dispatch, getState) => {
  const formattedNewRecord = newRecord;
  console.log('UPDATE', formattedNewRecord);
  let newData = [];
  const { table } = getState();
  if (typeof formattedNewRecord !== 'string' && formattedNewRecord?.id && !data) {
    newData = table.records.map((item) => {
      const newItem = { ...item };
      if (
        (item?.id === formattedNewRecord.id)
      ) {
        // newItem[name].record = newRecord;
        console.log('SET', formattedNewRecord);
        return formattedNewRecord;
      }
      return newItem;
    });
  } else if (data && formattedNewRecord) {
    newData = table.records.map((item) => {
      const newItem = { ...item };
      if (
        (item?.id === formattedNewRecord)
      ) {
        console.log('SET', formattedNewRecord);
        console.log('WAS', newItem);
        console.log('data', data);
        console.log('NOW', { ...newItem, params: { ...newItem.params, ...data } });
        return { ...newItem, params: { ...newItem.params, ...data } };
      }
      return newItem;
    });
  }
  dispatch(setRecords({
    records: newData,
  }));
};
export default tableReducer.reducer;
