import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import {
  ButtonDropdown, DropdownToggle, DropdownMenu,
  Button, Row, Col,
} from 'reactstrap';
import { uid } from 'react-uid';
import { splitIntoEqualCollumns } from '../helpers/Filter';

const useStyles = makeStyles({
  formFields: {
    padding: '5px 20px',
    margin: 0,
  },
});

const buttonColors = {
  primary: 'primary',
  secondary: 'secondary',
};

const CheckboxManyFilter = ({
  filterProp,
  filterTitle,
  filterAction,
  style,
  classname,
  customStyle,
}) => {
  const [dropdownOpen, setOpen] = useState(false);
  const [buttonColor, setButtonColor] = useState(buttonColors.primary);
  const [filterState, setFilterState] = useState(filterProp);
  const checkBoxFilters = useMemo(() => splitIntoEqualCollumns(filterState, 5), [filterState]);
  const classes = useStyles();

  useEffect(() => {
    if (Object.values(filterState).some(filterValue => filterValue === false)) {
      setButtonColor(buttonColors.secondary);
    }
  }, [filterState]);

  const toggle = () => setOpen(!dropdownOpen);

  const handleChange = (event) => {
    setFilterState({ ...filterState, [event.target.name]: event.target.checked });
  };

  const handleBulkSelectionClick = (select) => {
    const filtersObj = {};
    Object.keys(filterState).map((key) => {
      filtersObj[key] = select;
      return filtersObj;
    });
    setFilterState({ ...filtersObj });
  };

  const applyFilters = (event) => {
    event.preventDefault();
    filterAction({ data: filterState });
    setOpen(false);
    if (Object.values(filterState).some(v => !v)) {
      setButtonColor(buttonColors.secondary);
    } else {
      setButtonColor(buttonColors.primary);
    }
  };

  const menuWidth = customStyle?.menu || 200;

  return (
    <ButtonDropdown className={classname || 'm-1 btn-sm'} isOpen={dropdownOpen} toggle={toggle} style={style}>

      <DropdownToggle className="btn-sm" color={buttonColor} style={customStyle?.button || {}} caret>
        {filterTitle}
      </DropdownToggle>

      <DropdownMenu>

        <div className={classes.formFields} style={{ width: `${menuWidth * checkBoxFilters.length}px` }}>
          <FormControl component="fieldset">
            <FormGroup>
              <Row>
                { filterState && checkBoxFilters.map((filtersChunk, index) => (
                  <Col key={uid(index)}>
                    { Object.keys(filtersChunk).map(key => (
                      <FormControlLabel
                        key={key}
                        control={(
                          <Checkbox
                            checked={filtersChunk[key]}
                            onChange={handleChange}
                            name={key}
                            color="primary"
                          />
                        )}
                        label={key}
                        style={{ marginRight: '20px' }}
                      />
                      ))
                    }
                  </Col>
                  ))
                }
              </Row>
            </FormGroup>
          </FormControl>
          <FormControl style={Object.keys(filterState).length < 7 ? { display: 'block', textAlign: 'center' } : { display: 'block', textAlign: 'right' }} >
            <Button
              className="btn-sm mb-2"
              color="primary"
              onClick={applyFilters}
              style={Object.keys(filterState).length > 7 ? { marginRight: '15px' } : {}}
            >
              Apply
            </Button>
            {Object.keys(filterState).length > 7 && (
              <div style={{ float: 'right' }}>
                <Button
                  className="btn-sm"
                  color="primary"
                  onClick={() => { handleBulkSelectionClick(false); }}
                  style={{ marginRight: '15px' }}
                >
                  Clear
                </Button>
                <Button
                  className="btn-sm"
                  color="primary"
                  onClick={() => { handleBulkSelectionClick(true); }}
                  style={{ marginRight: '15px' }}
                >
                  All
                </Button>
              </div>
            )}
          </FormControl>
        </div>

      </DropdownMenu>
    </ButtonDropdown>
  );
};

CheckboxManyFilter.propTypes = {
  filterProp: PropTypes.objectOf(PropTypes.bool).isRequired,
  filterTitle: PropTypes.string.isRequired,
  filterAction: PropTypes.func.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  customStyle: PropTypes.objectOf(PropTypes.any),
  classname: PropTypes.string,
};

CheckboxManyFilter.defaultProps = {
  style: {},
  customStyle: {},
  classname: '',
};

export default CheckboxManyFilter;
