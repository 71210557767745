import { unflatten } from 'flat';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ApiClient from 'ApiClient';

const DisplayMessage = ({ record }) => {
  const key = 'displayMessage';

  const aValues = {
    0: 'Hide',
    1: 'Show',
  };

  const recordUnflatten = unflatten(record);
  const rowData = recordUnflatten.params;
  const val = (typeof rowData !== 'undefined' && typeof rowData[key] !== 'undefined') ? (+rowData[key]).toString() : '0';
  const [value, setValue] = useState(val);
  const options = Object.keys(aValues).map(k => ({ value: k, label: aValues[k] }));

  const handleChange = async (option) => {
    setValue(option.value);
    const data = new FormData();
    data.append(key, option.value);
    const api = new ApiClient();
    api.recordAction({
      resourceId: 'PortalNotification',
      recordId: record.id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return true;
  };

  return (
    <Select
      value={options.filter(option => option.value === value)}
      options={options}
      onChange={handleChange}
      placeholder="Display?"
      menuPlacement="auto"
      menuPortalTarget={document.body}
    />
  );
};
DisplayMessage.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default DisplayMessage;
