/* eslint react/prop-types: 0 */
import React from 'react';
import { unflatten } from 'flat';
import _ from 'lodash';
import CopyToClipboard from '../../../../../../shared/components/CopyToClipboard';

export default React.memo((props) => {
  const record = unflatten(props.record);
  const { params: { email } } = record;

  if (email) {
    return (
      <CopyToClipboard
        value={email}
      >
        {email}
      </CopyToClipboard>
    );
  }
  return '';
}, (a, a1) => _.isEqual(a.record, a1.record));
