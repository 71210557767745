// import { configureStore, combineReducers } from '@reduxjs/toolkit';
// import thunk from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';
import sidebarReducer from '../../../redux/reducers/sidebarReducer';
import themeReducer from '../../../redux/reducers/themeReducer';
import customizerReducer from '../../../redux/reducers/customizerReducer';
import rtlReducer from '../../../redux/reducers/rtlReducer';
import tableReducer from '../../../redux/reducers/TableReducer';
import TableReducerDefault from '../../../redux/reducers/TableReducerDefault';
import ModalsReducer from '../../../redux/reducers/ModalsReducer';

export const initializeSession = (data = {}) => ({
  type: 'SESSION_INITIALIZE',
  data,
});

export const addNotice = (data = { message: '' }) => ({
  type: 'ADD_NOTICE',
  data: {
    message: data.message,
    id: Math.random().toString(36).substr(2, 9),
    type: data.type || 'success',
    progress: 0,
    duration: data.duration || 3,
  },
});

export const setNoticeProgress = ({ noticeId, progress }) => ({
  type: 'SET_NOTICE_PROGRESS',
  data: { noticeId, progress },
});

export const dropNotice = noticeId => ({
  type: 'DROP_NOTICE',
  data: { noticeId },
});

const resourcesReducer = (state = [], action) => {
  switch (action.type) {
    case 'RESOURCES_INITIALIZE':
      return action.data;
    default: return state;
  }
};

const pagesReducer = (
  state = [],
  action,
) => {
  switch (action.type) {
    case 'PAGES_INITIALIZE':
      return action.data;
    default: return state;
  }
};

const brandingReducer = (state = {}, action) => {
  switch (action.type) {
    case 'BRANDING_INITIALIZE':
      return action.data;
    default: return state;
  }
};

const pathsReducer = (
  state = [],
  action,
) => {
  switch (action.type) {
    case 'PATHS_INITIALIZE':
      return action.data;
    default: return state;
  }
};

const dashboardReducer = (state = {}, action) => {
  switch (action.type) {
    case 'DASHBOARD_INITIALIZE':
      return action.data;
    default: return state;
  }
};

const sessionReducer = (
  state = {},
  action,
) => {
  switch (action.type) {
    case 'SESSION_INITIALIZE':
      return action.data;
    default: return state;
  }
};

const versionsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'VERSIONS_INITIALIZE':
      return {
        admin: action.data.admin,
        app: action.data.app,
      };
    default: return state;
  }
};


const noticesReducer = (state = [], action) => {
  switch (action.type) {
    case 'ADD_NOTICE': {
      const notices = [action.data];
      return notices;
    }
    case 'DROP_NOTICE': {
      return state.filter(notice => notice.id !== (action.data).noticeId);
    }
    case 'SET_NOTICE_PROGRESS': {
      return state.map(notice => ({
        ...notice,
        progress: notice.id === (action.data).noticeId
          ? action.data.progress
          : notice.progress,
      }));
    }
    default: return state;
  }
};
const assetsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'ASSETS_INITIALIZE':
      return action.data;

    default:
      return state;
  }
};
const localesReducer = (state = {
  language: 'en',
  translations: {},
}, action) => {
  switch (action.type) {
    case 'LOCALE_INITIALIZE':
      return action.data;

    default:
      return state;
  }
};

export {
  reduxFormReducer as form, // mounted under "form",
  resourcesReducer as resources,
  brandingReducer as branding,
  pathsReducer as paths,
  sessionReducer as session,
  dashboardReducer as dashboard,
  noticesReducer as notices,
  pagesReducer as pages,
  versionsReducer as versions,
  themeReducer as theme,
  rtlReducer as rtl,
  sidebarReducer as sidebar,
  customizerReducer as customizer,
  localesReducer as locale,
  assetsReducer as assets,
  tableReducer as table,
  TableReducerDefault as tableRedux,
  ModalsReducer as modals,
};
/* export default {
  form: reduxFormReducer, // mounted under "form",
  resources: resourcesReducer,
  branding: brandingReducer,
  paths: pathsReducer,
  session: sessionReducer,
  dashboard: dashboardReducer,
  notices: noticesReducer,
  pages: pagesReducer,
  versions: versionsReducer,
  theme: themeReducer,
  rtl: rtlReducer,
  sidebar: sidebarReducer,
  customizer: customizerReducer,
}; */
/* const reducer = combineReducers({

}); */

/* const middleware = [thunk];
const configuredStore = (initialState = {}) => configureStore({
  reducer,
  middleware,
  preloadedState: initialState,
  devTools: process.env.NODE_ENV === 'local', // Enables redux devtools. Allows to time-travel redux store.
}); */

// export default reducer;
