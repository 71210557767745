import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { unflatten } from 'flat';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const InviteBillCom = (props) => {
  const recordUnflatten = unflatten(props.influencer);
  if (!recordUnflatten.params || !recordUnflatten.params.payment) {
    return '';
  }

  const { payment } = recordUnflatten.params;
  if (payment.billCom.accountId) {
    return '';
  }

  return (
    <span>
      <Tooltip title="Invite to Bill.com">
        <Link to={`/billCom/invite/${props.influencer.id}/${props.type}`}>
          <IconButton
            size="small"
            className="material-table__toolbar-button"
          >
            <img src="/images/payments/billComGray.png" style={{ width: '13px' }} alt="Bill.com" />
          </IconButton>
        </Link>
      </Tooltip>
    </span>
  );
};

InviteBillCom.propTypes = {
  influencer: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.string,
};

InviteBillCom.defaultProps = {
  type: 'creator',
};

export default InviteBillCom;
