// import { unflatten } from 'flat';
import React, { useMemo } from 'react';
// import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MatTableList from '../../../../../shared/tables/materialTable/MatTableList';
import { formatIntNumber } from '../../../../../shared/helpers/WVFormatter';
import UserPropertyType from '../../../Prospects/components/custom';
import CreatorInList from './CreatorNameInList';
import TitleInList from './TitleInList';
import TypeInList from './TypeInList';
import ButtonsInList from './ButtonsInList';

// import TableCellContextMenu from './TableCellContextMenu';
// import ShieldComponent from './ShieldComponent';


const Table = React.memo(({
  action,
  resource,
  setTag,
  version,
  brand,
}) => {
  console.log('123');
  // const history = useHistory();
  return (
    <MatTableList
      action={action}
      resource={resource}
      setTag={setTag}
      version={version}
      UserPropertyType={UserPropertyType}
      showFilters={false}
      showTitle={false}
      sortBy="createdAt"
      direction="desc"
      useFlex
      v={2}
      noActions
      useUnflatten
      isResizable={false}
      columnsSettings={{
        name: {
          Header: () => 'Video Title',
          Cell: p => useMemo(() => (<TitleInList record={p.row.original.params} brand={brand} />), [p.row.original.id]),
          width: 250,
        },
        fullSearchName: {
          Header: () => 'Creator',
          Cell: p => useMemo(() => (<CreatorInList record={p.row.original.params} brand={brand} />), [p.row.original.id]),
          width: 250,
        },
        type: {
          Header: () => 'Type',
          Cell: p => useMemo(() => (<TypeInList record={p.row.original.params} brand={brand} />), [p.row.original.id]),
          width: 80,
        },
        'videostatistic.totalViews': {
          Header: () => (
            <span>
              Views
              {'  '}
              <Tooltip title="Total Views is for the first 6 months only">
                <IconButton size="small" style={{ marginTop: '-3px' }}>
                  <i className="fa fa-info-circle" style={{ fontSize: '0.8em' }} />
                </IconButton>
              </Tooltip>
            </span>
          ),
          Cell: p => useMemo(() => (p.row.original.params.videostatistic?.totalViews ? formatIntNumber(p.row.original.params.videostatistic.totalViews) : '0'), [p.row.original.id]),
          width: 80,
        },
        publishDate: {
          Header: () => 'Publish Date',
          Cell: p => useMemo(() => ((p.row.original.params.publishDate) ? moment.utc(p.row.original.params.publishDate).format('MM/DD/YY') : '—'), [p.row.original.id]),
          width: 150,
        },
        createdAt: {
          Header: () => 'Content Review Date',
          Cell: p => useMemo(() => ((p.row.original.params.createdAt) ? moment.utc(p.row.original.params.createdAt).format('MM/DD/YY') : ''), [p.row.original.id]),
          width: 150,
        },
        averageScore: {
          Header: () => 'Average Score',
          Cell: p => useMemo(() => ((p.row.original.params.averageScore) ? Math.round(p.row.original.params.averageScore) : ''), [p.row.original.id]),
          width: 100,
        },
        actions: {
          Header: () => '',
          Cell: p => useMemo(() => (<ButtonsInList record={p.row.original.params} brand={brand} />), [p.row.original.id]),
          disableSortBy: true,
        },
        isInternal: {
          Header: () => 'Type',
          accessor: p => p.params.isInternal,
          Cell: p => useMemo(() => {
            const message = p.value ? 'InfluenceLogic' : 'Standard';
            return (<div className="pl-3">{message}</div>);
          }, [p.row.original.id]),
          width: 100,
        },
      }}
    />
  );
}, (a, a1) => a.version === a1.version && a.searchTerms?.length === a1.searchTerms?.length);

Table.propTypes = {
  setTag: PropTypes.func.isRequired,
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  brand: PropTypes.objectOf(PropTypes.any).isRequired,
  version: PropTypes.string.isRequired,
};
export default Table;
