/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import ApiClient from 'ApiClient';
import Select from 'react-select';
import { unflatten } from 'flat';

export default (props) => {
  const { record, stage } = props;
  console.log(record.params, '_____');
  const { dealstages, [stage]: dealstage } = unflatten(record.params);
  const mapStatusArray = dealstages.map(ds => ({ value: ds.id, label: ds.label }));
  const [selected, setSelected] = useState({
    value: (dealstage?.toString() || dealstage),
    label: (mapStatusArray.find(f => f.value === dealstage) ? mapStatusArray.find(f => f.value === dealstage).label : ''),
  });

  useEffect(() => {
    if (dealstage) {
      setSelected({
        value: (dealstage?.toString() || dealstage),
        label: (mapStatusArray.find(f => f.value === dealstage) ? mapStatusArray.find(f => f.value === dealstage).label : ''),
      });
    }
  }, [dealstage]);
  console.log(dealstage, mapStatusArray.find(f => f.value === dealstage));
  const [loading, setLoading] = useState(false);
  console.log(record.params, '_____');
  const handleSelectChange = async (selectedRecord) => {
    setSelected(selectedRecord);
    setLoading(true);
    const value = selectedRecord ? selectedRecord.value : '';
    if (value === '') {
      return false;
    }
    const { id } = record;
    const data = new FormData();
    data.append(stage, value);
    const api = new ApiClient();
    try {
      await api.recordAction({
        resourceId: 'HubilDealPipeline',
        recordId: id,
        actionName: 'edit',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    return true;
  };

  return (
    <div className="px-2">
      <Select
        isDisabled={loading || record.checkboxDisable}
        value={selected}
        options={mapStatusArray}
        onChange={handleSelectChange}
        menuPlacement="auto"
        menuPortalTarget={document.body}
      />
    </div>
  );
};
