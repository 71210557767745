import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Icon from 'mdi-react/LinkIcon';
import Tooltip from '@material-ui/core/Tooltip';

const ConnectButton = (props) => {
  const { agent, type } = props;
  return (
    <span>
      <Tooltip title="Connect Account">
        <Link to={type === 'agent' ? `/resources/AgentUser/agent-bill-com/${agent.id}` : `/resources/Influencer/creator-bill-com/${agent.id}`} target="_blank">
          <button className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall">
            <Icon style={{ width: '15px', height: '15px' }} />
          </button>
        </Link>
      </Tooltip>
    </span>
  );
};

ConnectButton.propTypes = {
  agent: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired,
};
export default ConnectButton;
