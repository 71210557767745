/* eslint react/prop-types: 0 */
import React from 'react';
import ContextMenuInList from './ContextMenuInList';
import PaymentsStatus from './PaymentsStatus';
import formater from '../../../../../../shared/helpers/WVFormatter';

export default {
  show: {
  //  status: CampaignStatusInShow,
  },
  list: {
    amount: ({ record }) => formater.formatCurrency(Number(record.params.amount || 0)),
    amountDue: ({ record }) => formater.formatCurrency(Number(record.params.amountDue || 0)),
    createdAt: ({ record }) => formater.getServerDateTime(record.params.createdAt),
    invoiceNumber: ({ record }) => (<span>{record.params.invoiceNumber}{' '}{!record.params['dealObject._id'] && (<span className="form__form-group-error">Missing deal for invoice no. {record.params.invoiceNumber}.</span>)}</span>),
    paymentStatus: PaymentsStatus,
    'influencerObject.fullName': ({ record }) => (<a href={`/influencer/profile/${record.params['influencerObject._id']}`} target="_blank" rel="noreferrer">{record.params['influencerObject.fullName']}</a>),
  },
  actions: ContextMenuInList,
};
