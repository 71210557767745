import React from 'react';
import { FormGroup, Label, Modal, Spinner } from 'reactstrap';
import ApiClient from 'ApiClient';
import { unflatten } from 'flat';
import PropTypes from 'prop-types';
import SelectAsync from 'react-select/async';
import CurrencyField from '../../../../../../shared/components/form/CurrencyField';
import StyledButton from '../../../../../../shared/components/components/ui/styled-button';
import withNotice from '../../../../../App/store/with-notice';
import formater from '../../../../../../shared/helpers/WVFormatter';
import Alert from '../../../../../../shared/easydev2/shared/components/Alert';

class BillPaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiClient();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
    this.closeFormDialog = this.closeFormDialog.bind(this);
    this.state = {
      invoice: null,
      error: null,
      loading: {
        form: false,
      },
      payout: {
        params: {},
        errors: {},
        // populated: {},
      },
      apSelected: {},
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.modal === false && this.props.modal !== false) {
      this.fetchData();
    }
  }

  getTotalPaid() {
    let payoutAmount = 0;
    if (!this.state.invoice || !this.state.invoice.params.payments) {
      return 0;
    }
    this.state.invoice.params.payments.forEach((payout) => {
      if (payout.transactionStatus === 'failure') { return false; }
      payoutAmount += Number(payout.amount);
      return true;
    });
    return payoutAmount;
  }
  loadAPOptions = async (inputValue) => {
    const api = new ApiClient();
    const records = await api.searchRecords({
      resourceId: 'AccountsPayable',
      query: inputValue,
    });
    const res = [];
    records.forEach((record) => {
      res.push({
        value: record.id,
        label: record.params.name,
        params: record.params,
        record,
      });
    });
    return res;
  }
  static requiredData = [
    'amount',
    'ap',
  ];
  handleChange(propertyOrRecord, value) {
    if (typeof value === 'undefined' && (propertyOrRecord).params) {
      this.setState({
        payout: propertyOrRecord,
      });
    } else {
      this.setState(state => ({
        payout: {
          ...state.payout,
          params: {
            ...state.payout.params,
            [propertyOrRecord]: value,
          },
          errors: {
            ...state.payout.errors,
            [propertyOrRecord]: undefined,
          },
        },
      }));
    }
  }
  handleChangeAP = async (ap) => {
    this.setState({ apSelected: ap });
    this.handleChange('ap', ap.value);
  }
  async fetchData() {
    this.setState({
      payout: {
        params: {
          amount: undefined,
          notes: undefined,
        },
        errors: {},
      },
      loading: {
        form: true,
      },
    });
    const res = await this.api.recordAction({
      resourceId: 'Invoice',
      recordId: this.props.invoice.id,
      actionName: 'show',
    });
    this.setState({
      invoice: unflatten(res.data.record),
      loading: {
        form: false,
      },
    });
    return null;
  }
  handleChangeAmount(e, value) {
    const amount = Number(value);
    if ((amount + this.getTotalPaid()) > this.state.invoice.params.amount) {
      this.setState(state => ({
        payout: {
          ...state.payout,
          errors: {
            ...state.payout.errors,
            amount: 'Please Check amount Field',
          },
        },
      }));
    }
    this.handleChange('amount', amount);
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: null });
    const { payout, invoice } = this.state;
    let isValid = true;
    this.constructor.requiredData.forEach((item) => {
      if (!payout.params[item]) {
        isValid = false;
        this.setState(state => ({
          payout: {
            ...state.payout,
            errors: {
              ...state.payout.errors,
              [item]: 'This field is required',
            },
          },
        }));
      }
      if (item === 'amount') {
        if (payout.params.amount + this.getTotalPaid() > this.state.invoice.params.amount) {
          isValid = false;
          this.setState(state => ({
            payout: {
              ...state.payout,
              errors: {
                ...state.payout.errors,
                amount: `Amount should not be more than ${formater.formatCurrency(Number(this.state.invoice.params.amount || 0) - this.getTotalPaid())}`,
              },
            },
          }));
        }
      }
    });
    if (!isValid) {
      // this.props.addNotice({
      // message: 'Please Check form. Seems you did not fill some fields',
      // type: 'warning',
      // });
      return true;
    }
    const res = await this.api.client.post('/api/portal/influencer/post-add-payout-invoice', {
      amount: payout.params.amount,
      notes: payout.params.notes,
      invoiceId: invoice.id,
      accountsPayable: payout.params.ap,
    });
    if (res.data.success === false) {
      this.setState({ error: res.data.error });
    } else {
      this.closeFormDialog({ isUpdate: true });
      this.props.addNotice({
        message: 'New Payment processed successfully',
        type: 'Success',
      });
    }
    return false;
  }
  closeFormDialog({ isUpdate = false }) {
    this.setState({
      invoice: null,
    });
    const { closeDialog } = this.props;
    closeDialog(isUpdate);
  }
  render() {
    const { modalTitle, modal } = this.props;
    const {
      invoice,
      payout,
      apSelected,
    } = this.state;
    return (
      <Modal
        isOpen={modal}
        toggle={this.closeFormDialog}
      >
        <div className="modal__header">
          <h4 className="text-modal  modal__title">{modalTitle}</h4>
        </div>
        <div className="modal__body finance-form ltr-support">
          {this.state.error ? (
            <Alert color="danger" isOpen>
              <div className="alert__content">
                <p>{this.state.error}</p>
              </div>
            </Alert>
            ) : null}
          {this.state.loading.form &&
            (
              <div className="text-center"><Spinner color="primary" size="lg" /></div>
            )
          }
          {!this.state.loading.form &&
          (
            <form onSubmit={this.handleSubmit}>
              <FormGroup>
                <Label>Creator: {invoice && invoice.populated.influencer && invoice.populated.influencer.params.first_name}
                  {invoice && invoice.populated.influencer.params.last_name}
                </Label>
              </FormGroup>
              <FormGroup>
                <Label>Invoice No: {invoice && invoice.params.invoiceNumber}</Label>
              </FormGroup>
              <FormGroup>
                <Label>Amount: {invoice && formater.formatCurrency(invoice.params.amount)}</Label>
              </FormGroup>
              <FormGroup>
                <Label>Amount Due: {invoice && formater.formatCurrency(this.getTotalPaid() || 0)}</Label>
              </FormGroup>
              <FormGroup>
                <Label>Select Accounts Payable</Label>
                <SelectAsync
                  cacheOptions
                  value={apSelected}
                  defaultOptions
                  loadOptions={this.loadAPOptions}
                  onChange={this.handleChangeAP}
                  placeholder="Select"
                />
              </FormGroup>
              <FormGroup>
                <Label>Amount</Label>
                <CurrencyField
                  placeholder="Payout Amount"
                  disabled={false}
                  defaultValue={0}
                  onBlur={this.handleChangeAmount}
                />
                {payout.errors.amount && (<div className="form__form-group-error">{payout.errors.amount}</div>)}
                {invoice
                  && invoice.params.payments && invoice.params.payments.length > 0
                  && (
                    <span className="form__form-group-description">Max Amount: {formater.formatCurrency((invoice.params.amount || 0) - (this.getTotalPaid() || 0))}</span>
                  )}
              </FormGroup>
              <StyledButton
                type="submit"
                className="is-primary"
              >
                <i className="icomoon-save" />
                <span className="btn-text">Save</span>
              </StyledButton>
              &nbsp;&nbsp;
              <StyledButton
                type="button"
                className="btn-danger"
                onClick={this.closeFormDialog}
              >
                <i className="icomoon-save" />
                <span className="btn-text">Cancel</span>
              </StyledButton>
            </form>
          )
          }
        </div>
      </Modal>
    );
  }
}

BillPaymentForm.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  modal: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
  invoice: PropTypes.objectOf(PropTypes.any).isRequired,
};

BillPaymentForm.defaultProps = {};

export default withNotice(BillPaymentForm);
