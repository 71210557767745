import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BrandRoutes from './BrandRoutes';


const WrappedRoutes = ({ brandUser }) => (
  <div>
    {brandUser && brandUser._id ? <Route path="/" component={BrandRoutes} /> : <Redirect to="/login" />}
  </div>
);
WrappedRoutes.propTypes = {
  brandUser: PropTypes.objectOf(PropTypes.any),
};
WrappedRoutes.defaultProps = {
  brandUser: null,
};

export default connect(state => ({
  brandUser: state.session,
}))(WrappedRoutes);
