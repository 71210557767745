import DetailsInShow from './DetailsInShow';
import SubtypeInList from './SubtypeInList';

export default {
  show: {
    details: DetailsInShow,
    subtype: SubtypeInList,
  },
  list: {
    subtype: SubtypeInList,
  },
  edit: {
  },
};
