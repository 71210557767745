/* eslint-disable no-param-reassign */
import ApiClient from 'ApiClient';
import { createSlice } from '@reduxjs/toolkit';
import { addNotice } from '../../../containers/App/store/store';

/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const DashboardReducer = createSlice({
  name: 'dashboard',
  initialState: {
    fromDate: null,
    toDate: null,
    loading: false,
    reports: {
      contacts: 0,
      contracts: 0,
      content: 0,
      conversions: 0,
      creators: 0,
    },
    reportsTotal: {
      contacts: 0,
      contracts: 0,
      content: 0,
      conversions: 0,
      creators: 0,
    },
    lastFetched: new Date(),
  },
  reducers: {
    get5csStatsStart(state) {
      state.loading = true;
      state.reports = {
        contacts: 0,
        contracts: 0,
        content: 0,
        conversions: 0,
        creators: 0,
      };
    },
    get5csSuccess(state, action) {
      console.log(action);
      const {
        reports, reportsTotal,
      } = action.payload;
      state.loading = false;
      state.reports = reports;
      state.reportsTotal = reportsTotal;
    },

  },
});

const getRportsApi = async (startDate, endDate, show0) => {
  const api = new ApiClient();
  return api.client.post('/api/brand/dashboard/post-get5cs-stats', { startDate, endDate, show0 });
};

export const {
  get5csStatsStart,
  get5csSuccess,
} = DashboardReducer.actions;

export const get5csReports = (startDate, endDate, show0) => async (dispatch) => {
  try {
    dispatch(get5csStatsStart());
    const report = await getRportsApi(startDate, endDate, show0);
    console.log(report.data.reports);
    dispatch(get5csSuccess({ reports: report.data.reports, reportsTotal: report.data.reportsTotal }));
  } catch (err) {
    dispatch(get5csSuccess({
      contacts: 0,
      contracts: 0,
      content: 0,
      conversions: 0,
      creators: 0,
    }));
    dispatch(addNotice({
      message: 'There was an error fetching records, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export default DashboardReducer.reducer;
