import React from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import FormatListBulleted from 'mdi-react/FormatListBulletedIcon';
import moment from 'moment';
import StartDate from './StartDate';
import EndDate from './EndDate';
import DisplayMessage from './DisplayMessage';
import FormHeadlineText from './form/HeadlineText';
import FormNotificationText from './form/NotificationText';
import FormActionButtonText from './form/ActionButtonText';
import FormImageUrl from './form/ImageUrl';
import FormDisplayMessage from './form/DisplayMessage';
import FormStartDate from './form/StartDate';
import FormEndDate from './form/EndDate';
import { getServerDateTime } from '../../../../../../shared/helpers/WVFormatter';

export default {
  list: {
    createdAt: ({ record }) => getServerDateTime(moment(record.params.createdAt)),
    startDate: StartDate,
    endDate: EndDate,
    displayMessage: DisplayMessage,
    // eslint-disable-next-line
    actionsCustom: ({ record }) => (<Link to={`/resources/PortalNotification/creatorNotification/${record.params._id}`}><IconButton className="material-table__toolbar-button" size="small"><FormatListBulleted size="20" /></IconButton></Link>),
  },
  edit: {
    headlineText: FormHeadlineText,
    notificationText: FormNotificationText,
    actionButtonText: FormActionButtonText,
    imageUrl: FormImageUrl,
    displayMessage: FormDisplayMessage,
    startDate: FormStartDate,
    endDate: FormEndDate,
  },
};
