/* eslint react/prop-types: 0 */
import React from 'react';
import { Input } from 'reactstrap';
import PropertyInEdit from '../../../../../../../shared/components/components/ui/property-in-edit';

export default class ActionButtonText extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { onChange, property } = this.props;
    onChange(property.name, e.target.value);
  }

  render() {
    const { property, record } = this.props;
    const value = (record.params && typeof record.params[property.name] !== 'undefined')
      ? record.params[property.name]
      : '';
    const error = record.errors && record.errors[property.name];
    return (
      <div>
        <h4 className="il-color-deep-blue font-weight-bold mt-5">Action Button</h4>
        <hr className="mt-3 mb-4" />
        <PropertyInEdit property={property} error={error}>
          <Input
            type="text"
            id={property.name}
            name={property.name}
            onChange={this.handleChange}
            value={value}
          />
        </PropertyInEdit>
      </div>
    );
  }
}
