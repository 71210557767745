import styled from 'styled-components';
import theme from '../../../../theme';

const StyledInput = styled.input.attrs(props => ({
  className: props.className || 'input',
}))`
  background: ${theme.colors.inputBck};
  color: ${theme.colors.defaultText};
  height: auto;
  border-radius: 0px;
  border-color: ${theme.colors.border};
  box-shadow: none;
  &:hover{
    border-color: ${theme.colors.borderHover};
  }
`;

export default StyledInput;
