/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import TopbarNavLink from '../../../../admin/Layout/topbar_with_navigation/tobar_nav/TopbarNavLink';
import { getContentApprovalsFilter } from '../../../../../shared/helpers/Filter';
import AccessComponent from '../../../../../shared/components/auth/AccessComponent';

const TopbarNav = ({ creatorsLink, prospectsLink }) => {
  const startDate = moment().startOf('month').toISOString();
  const endDate = moment().endOf('day').toISOString();

  return (
    <nav className="topbar__nav topbar__nav__brand d-none d-lg-flex d-xl-flex">
      <Link className="topbar__nav-link" to="/">Overview</Link>
      {/* <Link className="topbar__nav-link" to="/">Home</Link> */}
      <Link className="topbar__nav-link" to={creatorsLink}>Creators</Link>
      <Link className="topbar__nav-link" to={prospectsLink}>Prospects</Link>
      <UncontrolledDropdown className="topbar__nav-dropdown">
        <DropdownToggle className="topbar__nav-dropdown-toggle">
          Content <DownIcon />
        </DropdownToggle>
        <DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
          <DropdownItem>
            <TopbarNavLink title="Published" route="/content" />
          </DropdownItem>
          <DropdownItem>
            <TopbarNavLink
              title="Approvals"
              route={`/content-approvals?${getContentApprovalsFilter('7_days')}`}
            />
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <AccessComponent showWhenRole={['owner']}>
        <UncontrolledDropdown className="topbar__nav-dropdown">
          <DropdownToggle className="topbar__nav-dropdown-toggle">
            YouTube Comments <DownIcon />
          </DropdownToggle>
          <DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
            <DropdownItem>
              <TopbarNavLink
                title="Comments"
                route={`/comments?page=1&range=current_month&filters.publishedAt~~from=${startDate}&filters.publishedAt~~to=${endDate}&publishedDateType=%3D&filters.sentiment=positive&filters.sentiment=negative`}
              />
            </DropdownItem>
            <DropdownItem>
              <TopbarNavLink
                title="Analysis"
                route="/youtube-comments-analysis"
              />
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </AccessComponent>
    </nav>
  );
};


TopbarNav.propTypes = {
  creatorsLink: PropTypes.string.isRequired,
  prospectsLink: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  creatorsLink: state.creators.creatorLink,
  prospectsLink: state.creators.prospectLink,
});
export default connect(mapStateToProps)(TopbarNav);
