import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from 'mdi-react/DeleteIcon';
import Tooltip from '@material-ui/core/Tooltip';


class MatTableDeleteButton extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    action: PropTypes.object.isRequired,
    clickYes: PropTypes.func.isRequired,
  };

  static defaultProps = {
    title: 'Are you Sure?',
    message: 'Do you want delete this item?',
    colored: false,
    header: false,
  };

  constructor() {
    super();
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
    this.remove = this.remove.bind(this);
  }

  toggle() {
    // const { modal } = this.state;
    // console.log(modal, 'IS OPEN');
    this.setState(prevState => ({ modal: !prevState.modal }));
  }
  remove() {
    const { clickYes, id } = this.props;
    this.toggle();
    clickYes(id);
  }

  render() {
    const {
      title, message, colored, header, action, id,
    } = this.props;
    const { modal } = this.state;
    let Icon;

    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div style={{ display: 'inline' }}>
        <Tooltip title={action.label}>
          <IconButton
            size="small"
            onClick={this.toggle}
            className="material-table__toolbar-button"
          >
            <DeleteIcon size="15" />
          </IconButton>
        </Tooltip>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          modalClassName="ltr-support"
          className={`modal-dialog--danger ${modalClass} ${id}`}
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="text-modal  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            {message}
          </div>
          <ButtonToolbar className="modal__footer">
            <Button className="modal_ok btn-sm" outline={colored} color="danger" onClick={this.remove}>Remove</Button>{' '}
            <Button className="modal_cancel btn-sm" onClick={this.toggle}>Cancel</Button>
          </ButtonToolbar>
        </Modal>
      </div>
    );
  }
}

export default MatTableDeleteButton;
