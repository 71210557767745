import React from 'react';
import styled from 'styled-components';
import theme from '../../../../theme';

const StyledWrapperBox = styled.section`;
  padding: ${theme.sizes.paddingLayout};
  color: ${theme.colors.defaultText};
  flex-grow: 1;
  border: ${props => (props.border ? `1px solid ${theme.colors.border}` : 'none')};
  background: ${props => (props.border ? theme.colors.bck : 'transparent')};

  & > h1 {
    font-size: 22px;
    margin-top: ${theme.sizes.padding};
    margin-bottom: ${theme.sizes.padding};
  }
`;

const WrapperBox = props => (<StyledWrapperBox {...props} />);


export default WrapperBox;
