import React from 'react';
import TransitionMaskedIcon from 'mdi-react/TransitionMaskedIcon';
import Tooltip from '@material-ui/core/Tooltip';

export default class ShowImpactActionOnImpactButton extends React.Component {
  showActionOnImpact = () => {
    // eslint-disable-next-line react/prop-types
    const { campaignId, actionId } = this.props;
    if (actionId) {
      const win = window.open(`https://app.impact.com/secure/lightbox/mp/getActionDetail.ihtml?${campaignId ? `cid=${campaignId}&` : ''}acid=${actionId}`, '_blank', 'noreferrer');
      win.focus();
    }
  };

  render() {
    return (
      <Tooltip title="Show ImpactAction on Impact">
        <button
          className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall"
          onClick={this.showActionOnImpact}
        >
          <TransitionMaskedIcon
            style={{ width: '15px', height: '15px' }}
          />
        </button>
      </Tooltip>
    );
  }
}

