import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from 'mdi-react/EditIcon';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteButton from './MatTableDeleteButton';
import { getDeleteMessage } from '../../../helpers/Model';
import SpyLogInButton from '../../../../shared/components/SpyLogInButton';
import ExecuteJobButton from '../../../../shared/components/ExecuteJobButton';
import ScheduledJobsShowLogsButton from '../../../../shared/components/ScheduledJobsShowLogsButton';
import ScheduledJobsNotificationSettingsButton from '../../../components/ScheduledJobsNotificationSettingsButton';
import EditJobRunFrequencyButton from '../../../../shared/components/EditJobRunFrequencyButton';
import ShowImpactActionOnImpactButton from '../../../../shared/components/ShowImpactActionOnImpactButton';
import ImpactActionItemShowAdditionalInfoButton from '../../../../shared/components/ImpactActionItemShowAdditionalInfoButton';
import ImpactClickShowAdditionalInfoButton from '../../../../shared/components/ImpactClickShowAdditionalInfoButton';
import InviteBillComButton from '../../../../shared/components/InviteBillCom';
import HubspotLinkIcon from '../../../../shared/components/HubspotLinkIcon';
import SendAgreementButton from '../../../../containers/admin/Influencer/ServiceAgreement/Form/SendButton';
import InfluencerAgreementsButton from '../../../../containers/admin/Influencer/ServiceAgreement/Button';
import AgentEditButton from '../../../../containers/admin/Agents/AgentUser/components/custom/EditButton';
import AgentCreatorsButton from '../../../../containers/admin/Agents/AgentCreators/components/IconButton';
import ConnectAgentButton from '../../../../containers/admin/Agents/AgentBillCom/connectButton';
import CreateDealButton from '../../../../containers/admin/Agents/DealEditor/CreateDealButton';
import DisConnectAgentButton from '../../../../containers/admin/Agents/AgentBillCom/disConnectButton';

const AdminActions = ({
  action, parentItem, deleteItemFunc, handleClickAction, meta,
}) => {
  switch (action.name) {
    case 'actionExecuteJob':
      return (
        <ExecuteJobButton
          identifier={parentItem.params.identifier}
          url="/api/portal/jobs/execute"
        />
      );
    case 'actionEditJobRunFrequency':
      return (
        <EditJobRunFrequencyButton
          record={parentItem}
        />
      );
    case 'actionShowLogs':
      return (
        <ScheduledJobsShowLogsButton
          jobIdentifier={parentItem.params.identifier}
        />
      );
    case 'actionNotificationSettings':
      return (
        <ScheduledJobsNotificationSettingsButton
          jobId={parentItem.id}
        />
      );
    case 'actionShowImpactActionOnImpact':
      return (
        <ShowImpactActionOnImpactButton
          campaignId={parentItem.params.campaignId}
          actionId={parentItem.params.actionId}
        />
      );
    case 'delete':
      if (parentItem.params.isDeleted) return '';
      return (
        <DeleteButton
          action={action}
          clickYes={deleteItemFunc}
          id={parentItem.id}
          message={getDeleteMessage(parentItem.params)}
        />
      );
    case 'influencerSpyLoginAction':
      return (
        <SpyLogInButton
          userId={parentItem.id}
          url="/api/portal/influencers/spy-login"
        />
      );
    case 'influencerSendAgreement':
      return (
        <SendAgreementButton
          influencer={parentItem}
        />
      );
    case 'influencerAgreements':
      return (
        <InfluencerAgreementsButton
          influencer={parentItem}
        />
      );
    case 'agentEditAction':
      return (
        <AgentEditButton
          agent={parentItem}
        />
      );
    case 'agentUserSpyLoginAction':
      return (
        <SpyLogInButton
          userId={parentItem.id}
          url="/api/portal/agent/spy-login"
        />
      );
    case 'inviteToBillComAction':
      return (
        <InviteBillComButton
          influencer={parentItem}
        />
      );
    case 'agentUserCreatorsAction':
      return (
        <AgentCreatorsButton
          agent={parentItem}
        />
      );
    case 'actionAdditionalInfo':
      return (
        <ImpactActionItemShowAdditionalInfoButton
          record={parentItem}
        />
      );
    case 'clickAdditionalInfo':
      return (
        <ImpactClickShowAdditionalInfoButton
          record={parentItem}
        />
      );
    case 'hubspotAction':
      return (
        <HubspotLinkIcon
          contact={parentItem}
        />
      );
    case 'connectBillComAction':
      return (
        <ConnectAgentButton
          agent={parentItem}
          type="agent"
        />
      );
    case 'disConnectBillComAction':
      return (
        <DisConnectAgentButton
          agent={parentItem}
          meta={meta}
          type="agent"
        />
      );
    case 'createDeal':
      return (
        <CreateDealButton
          agent={parentItem}
        />
      );
    default:
      return (
        <Tooltip title={action.label || ''}>
          <IconButton
            size="small"
            onClick={event => handleClickAction(action.name, parentItem.id, event)}
            className="material-table__toolbar-button"
          >
            {action.name === 'edit' ? (<EditIcon size="15" />) : ''}
            {action.name === 'show' ? (<InformationOutlineIcon size="15" />) : ''}
          </IconButton>
        </Tooltip>
      );
  }
};

AdminActions.propTypes = {
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  parentItem: PropTypes.objectOf(PropTypes.any),
  meta: PropTypes.objectOf(PropTypes.any),
  deleteItemFunc: PropTypes.func,
  handleClickAction: PropTypes.func,
};

AdminActions.defaultProps = {
  parentItem: {},
  deleteItemFunc: () => {},
  handleClickAction: () => {},
  meta: {},
};

export default AdminActions;
