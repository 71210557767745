import React from 'react';
import ScriptIcon from 'mdi-react/ScriptIcon';
import Tooltip from '@material-ui/core/Tooltip';

export default class ScheduledJobsShowLogsButton extends React.Component {
  showLogs = () => {
    // eslint-disable-next-line react/prop-types
    const { jobIdentifier } = this.props;
    if (jobIdentifier) {
      const win = window.open(`/resources/Log/actions/list?filters.jobIdentifier=${jobIdentifier}`, '_blank');
      win.focus();
    }
  };

  render() {
    return (
      <Tooltip title="Show Logs">
        <button
          className="MuiButtonBase-root MuiIconButton-root material-table__toolbar-button MuiIconButton-sizeSmall"
          onClick={this.showLogs}
        >
          <ScriptIcon
            style={{ width: '15px', height: '15px' }}
          />
        </button>
      </Tooltip>
    );
  }
}

