import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'reactstrap';
import Stats from './components/Stats';
import ContentTable from './components/ContentTable';
import Breadcrumb from '../../../shared/components/BreadCrumbs';

const index = ({ match }) => (
  <Container >
    <Row>
      <Col md={12}>
        <Breadcrumb
          links={[
            { title: 'Home', path: '/' },
            { title: 'Creators', path: '/creators/list' },
            { title: 'Profile', path: null },
          ]}
          isBackButton
          backButtomClassName=""
        />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <Stats match={match} />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <ContentTable match={match} />
      </Col>
    </Row>
  </Container>
);
index.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default index;
