import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { connect } from 'react-redux';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
  FormGroup,
} from 'reactstrap';
import { CKEditor } from 'ckeditor4-react';

const NotesModal = ({
  isOpen,
  closeFunc,
  setVersion,
  record,
}) => {
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState('');
  const [loadingNotes, setLoadingNotes] = useState(false);

  const fetchNotes = (editor) => {
    setLoadingNotes(true);
    axios({
      method: 'get',
      url: `/api/brand/creators/get-notes?id=${record.id}&notesType=brandNotes`,
    }).then((response) => {
      if (response.data.success === true) {
        if (editor) editor.setData(response.data.notes);
        setNotes(response.data.notes);
        setLoadingNotes(false);
      }
    }).catch((err) => {
      console.log(err);
      setLoadingNotes(false);
    });
  };
  useEffect(() => {
    if (isOpen && record) {
      fetchNotes();
    }
  }, [isOpen]);

  const handleInputChange = (e) => {
    const { editor } = e;
    setNotes(editor.getData());
  };

  // SUBMIT Data
  const submit = async () => {
    setLoading(true);
    axios({
      method: 'post',
      url: '/api/brand/creators/save-notes',
      data: {
        id: record.id,
        notes,
        notesType: 'brandNotes',
      },
    }).then((response) => {
      if (response.data.success) {
        setLoading(false);
        setVersion((new Date()).toString());
        setNotes('');
        closeFunc();
      }
      setLoading(false);
    });
    return true;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => { setNotes(''); closeFunc(); }}
      style={{ minWidth: '670px' }}
    >
      <ModalHeader
        toggle={() => {
          setNotes('');
          closeFunc();
        }}
        tag="h4"
        className="modal-title-statistics pl-0"
      >
        Brand Notes
        {loadingNotes && (
          <span>
            <div className="spinner-border il-text-gray spinner-border-sm" />
          </span>
        )}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            {record && (
              <FormGroup>
                <CKEditor
                  config={{versionCheck: false}}
                  readOnly={loadingNotes}
                  id="bodyEditor"
                  name="itemText"
                  activeClass="form-control"
                  initData={notes}
                  onChange={(e) => { handleInputChange(e); }}
                  onInstanceReady={({ editor }) => fetchNotes(editor)}
                />
              </FormGroup>
            )}
            {record && (
              <FormGroup>
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: notes }} />
              </FormGroup>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_ok btn-sm" color="primary" onClick={submit} disabled={loading} style={{ marginRight: '10px' }}>
          {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
          <span>Save</span>
        </Button>
        <Button className="modal_cancel btn-sm" onClick={() => { setNotes(''); closeFunc(); }}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};


NotesModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  record: state.creators.currentMediaContent,
  isOpen: state.creators.showBrandStatusNotesModal,
});

export default connect(mapStateToProps)(NotesModal);
