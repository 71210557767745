import LevelInList from './LevelInList';
import MetaInShow from './MetaInShow';

export default {
  edit: {},
  show: {
    meta: MetaInShow,
  },
  list: {
    level: LevelInList,
  },
};
