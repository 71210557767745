const moment = require('moment-timezone');
// const momentTimezone = require('moment-timezone');

const abbrs = {
  EET: 'Eastern Europe Time',
  EST: 'Eastern Standard Time',
  EDT: 'Eastern Daylight Time',
  CST: 'Central Standard Time',
  CDT: 'Central Daylight Time',
  MST: 'Mountain Standard Time',
  MDT: 'Mountain Daylight Time',
  PST: 'Pacific Standard Time',
  PDT: 'Pacific Daylight Time',
};
exports.currentTimezoneName = (momentO) => {
  const abbr = momentO.zoneAbbr();
  return abbrs[abbr] || abbr;
};

exports.currentTimezoneAggr = () => {
  const timezonesToChange = {
    EST: 'ET',
    EDT: 'ET',
    CST: 'CT',
    CDT: 'CT',
    MST: 'MT',
    MDT: 'MT',
    PST: 'PT',
    PDT: 'PT',
  };
  const abbr = moment.tz(moment.tz.guess()).zoneAbbr();
  return timezonesToChange[abbr] || abbr;
};


exports.Months = [
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

exports.momentUTCToLocalDate = momentDate => moment(momentDate.utc().format('YYYY-MM-DD'));
exports.momentLocaleToServerUTC = momentDate => moment.utc(momentDate.format('YYYY-MM-DD'));
exports.momentToUtcToServerUTC = momentDate => moment(momentDate.utc().format('YYYY-MM-DD'));

exports.momentServerUTCToDate = date => moment(moment.utc(date).format('YYYY-MM-DD'));

exports.getLast7Days = () => {
  const dateFrom = moment().subtract(7, 'day').startOf('day');
  const dateTo = moment().subtract(1, 'day').endOf('day');
  const url = (new URL(window.location));

  if (url.hostname.indexOf('portal') !== -1 || url.hostname.indexOf('brand') !== -1) {
    // most of admin portal is written to accept Date format
    return [dateFrom.toDate(), dateTo.toDate()];
  }
  // agent and creator portals are expecting unix
  return [dateFrom.unix(), dateTo.unix()];
};

exports.getLastNDays = (days, includeToday = false) => {
  const dateFrom = moment().subtract(includeToday ? days - 1 : days, 'day').startOf('day');
  const dateTo = includeToday ? moment().endOf('day') : moment().subtract(1, 'day').endOf('day');
  const url = (new URL(window.location));

  if (url.hostname.indexOf('portal') !== -1 || url.hostname.indexOf('brand') !== -1) {
    // most of admin portal is written to accept Date format
    return [dateFrom.toDate(), dateTo.toDate()];
  }
  // agent and creator portals are expecting unix
  return [dateFrom.unix(), dateTo.unix()];
};

exports.getLast7DaysIncludingToday = () => {
  const dateFrom = moment().subtract(6, 'day').startOf('day');
  const dateTo = moment().endOf('day');
  const url = (new URL(window.location));

  if (url.hostname.indexOf('portal') !== -1 || url.hostname.indexOf('brand') !== -1) {
    // most of admin portal is written to accept Date format
    return [dateFrom.toDate(), dateTo.toDate()];
  }
  // agent and creator portals are expecting unix
  return [dateFrom.unix(), dateTo.unix()];
};

exports.getLast6MonthsIncludingToday = () => {
  const dateFrom = moment().subtract(6, 'month').startOf('day');
  const dateTo = moment().endOf('day');
  const url = (new URL(window.location));

  if (url.hostname.indexOf('portal') !== -1 || url.hostname.indexOf('brand') !== -1) {
    // most of admin portal is written to accept Date format
    return [dateFrom.toDate(), dateTo.toDate()];
  }
  // agent and creator portals are expecting unix
  return [dateFrom.unix(), dateTo.unix()];
};

exports.getTimePeriodMinDate = () => moment('2021-08-01');

exports.numberRangeLabels = () => ([
  { value: '=', label: '=' },
  { value: '>', label: '>' },
  { value: '>=', label: '>=' },
  { value: '<', label: '<' },
  { value: '<=', label: '<=' },
  { value: 'Between', label: 'Between' },
]);

exports.dateRangeLabels = () => ([
  { value: '=', label: '=' },
  { value: '>', label: '>' },
  { value: '>=', label: '>=' },
  { value: 'Between', label: 'Between' },
]);

exports.addDays = (value, days) => {
  const res = new Date(value);
  res.setDate(value.getDate() + days);
  return res;
};
