import React from 'react';
import moment from 'moment';
import ContextMenuInList from './ContextMenuInList';
import TitleInList from './TitleInListApprovals';
import VideoDescription from './VideoDescription';
import mapMediaTypeIcons from './mapMediaTypeIcons';
import BrandStatus from './BrandStatus';

const mapType = mapMediaTypeIcons();

export default function TableColumnsApprovals(setVersion) {
  return [
    {
      Header: 'Video Details',
      accessor: p => (p.params?.title),
      id: 'title',
      Cell: record => (
        <div style={{ paddingRight: '10px' }}>
          <TitleInList
            record={record.row.original}
          />
        </div>
      ),
      disableSortBy: false,
      className: 'align-middle',
      width: 150,
    },
    {
      Header: 'Creator Name',
      accessor: p => (p.params?.creatorFullName),
      id: 'creatorFullName',
      Cell: record => (
        <div style={{ paddingRight: '10px' }}>
          {record.row.original.params?.creatorFullName || ''}
        </div>
      ),
      disableSortBy: false,
      className: 'align-middle',
      width: 150,
    },
    {
      Header: 'Video Description',
      accessor: () => null,
      id: 'contentText',
      width: 250,
      Cell: record => (
        <div style={{ width: '250px' }}>
          <VideoDescription record={record} />
        </div>
      ),
      // className: 'align-middle',
      disableSortBy: true,
    },
    {
      Header: 'Type',
      accessor: p => (p.params?.type),
      id: 'typeInList',
      width: 60,
      Cell: value => (
        <div>
          <a
            href={(value.row.original.params?.mediaUrl?.includes('https://') || value.row.original.params?.mediaUrl?.includes('http://')) ? value.row.original.params.mediaUrl : `https://${value.row.original.params?.mediaUrl}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              style={{ width: '30px' }}
              src={`/images/mediacontent/${mapType[value.row.original?.params?.type]}.png`}
              alt={value.row.original.params.type}
              title={value.row.original.params.type}
            />
          </a>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: true,
    },
    {
      Header: 'Brand Status',
      accessor: () => null,
      id: 'brandStatus',
      Cell: p => (
        <div>
          <BrandStatus
            record={p.row.original}
            setVersion={setVersion}
          />
        </div>
      ),
      className: 'align-middle text-center',
      disableSortBy: false,
      width: 200,
    },
    {
      Header: 'Submit Date',
      accessor: item => (item?.params.createdAt ? item?.params.createdAt.split('T')[0] : null),
      id: 'createdAt',
      Cell: value => (
        <div style={{ paddingRight: '10px' }}>
          <span>{value.value ? moment(value.value).format('MM/DD/YYYY') : '...'}</span>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: false,
      width: 160,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'actionsCustom',
      Cell: p => (
        <ContextMenuInList
          record={p.row.original}
        />
      ),
      disableSortBy: true,
      className: 'align-middle',
      width: 50,
    },
  ];
}
