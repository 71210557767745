/* eslint react/prop-types: 0 */
import React from 'react';
import {
  FormGroup,
} from 'reactstrap';
import CheckBoxField from '../../../../../../shared/components/CheckBox';

export default class UseWrappingInEdit extends React.Component {
  constructor(props) {
    super(props);
    const { record } = this.props;
    this.handleChange = this.handleChange.bind(this);
    let value = true;
    if ('_id' in record.params) {
      value = record.params.useWrapping === undefined ? true : record.params.useWrapping;
    }
    this.state = {
      value,
    };
  }

  handleChange(event) {
    let { value } = this.state;
    if (event && event.target) {
      const { checked } = event.target;
      value = checked;
    }
    const { onChange, enableSaveButton } = this.props;
    enableSaveButton(false);
    this.setState({ value });
    onChange('useWrapping', value);
    enableSaveButton(true);
  }

  render() {
    const {
      value,
    } = this.state;

    return (
      <FormGroup>
        <CheckBoxField
          name="useWrapping"
          label="Use InfluenceLogic wrapping"
          value={value}
          onChange={this.handleChange}
        />
      </FormGroup>
    );
  }
}

