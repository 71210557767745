// import { unflatten } from 'flat';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MatTableList from '../../../../../shared/tables/materialTable/MatTableList';
import UserPropertyType from '../../../Prospects/components/custom';
import getTableColumns from './TableColumnsApprovals';

const TableApprovals = React.memo(({
  action,
  resource,
  setTag,
  version,
  setVersion,
}) => {
  // const history = useHistory();
  const customColumns = useMemo(() => getTableColumns(setVersion), []);

  return (
    <MatTableList
      action={action}
      resource={resource}
      setTag={setTag}
      version={version}
      UserPropertyType={UserPropertyType}
      showFilters={false}
      showTitle={false}
      sortBy="createdAt"
      direction="desc"
      useFlex
      v={2}
      noActions
      useUnflatten
      isResizable={false}
      customColumns={customColumns}
    />
  );
}, (a, a1) => a.version === a1.version);

TableApprovals.propTypes = {
  setTag: PropTypes.func.isRequired,
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  version: PropTypes.string.isRequired,
  setVersion: PropTypes.func.isRequired,
};
export default TableApprovals;
