import keywords from './CampaignKeywordInList';
import InfluencerStatusInList from './PodcastUserStatusInList';
import InfluencerInList from './InfluencerInList';
import channelsList from './ItunesLinkInList';

export default {
  show: {
  //  status: CampaignStatusInShow,
  //  status: CampaignStatusInList,
  },
  list: {
    influencer: InfluencerInList,
    status: InfluencerStatusInList,
    campaigns: keywords,
    channels: channelsList,
  },
};
