/* eslint-disable react/jsx-no-target-blank */
/* eslint react/prop-types: 0 */
import React from 'react';
import { unflatten } from 'flat';

export default (props) => {
  const { record } = props;
  const params = unflatten(record.params);
  const dealname = (
    <a
      href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${params.hs_object_id}`}
      target="_blank"
    >{params.dealname}<br />
    </a>);
  return (
    dealname
  );
};
