import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import UserPropertyType from './custom';
import MatTableList from '../../../../shared/tables/materialTable/MatTableList';
import getTableColumns from './custom/TableColumns';

const Table = ({
  action, resource,
}) => {
  const customColumns = useMemo(() => getTableColumns(), []);

  return (
    <MatTableList
      action={action}
      resource={resource}
      date={new Date().toString()}
      UserPropertyType={UserPropertyType}
      customColumns={customColumns}
      v={2}
      hideToolbar
      showTitle={false}
      isResizable={false}
      noActions
      sortBy="publishedAt"
      direction="desc"
      useCheckboxes
      useUnflatten
    />
  );
};

Table.propTypes = {
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Table;
