import axios from 'axios';
import { useCallback } from 'react';

const useErrorHook = () => {
  const addError = useCallback((error, info) => {
    if (!(error instanceof Error)) throw new Error('error must be error');
    const errorMessage = `${error.message}  email: ${window.REDUX_STATE && window.REDUX_STATE.session ? window.REDUX_STATE.session.email : ''} url: ${window.location.href}`;
    axios.post(
      '/api/utilities/add-error',
      {
        portal: '',
        error: errorMessage,
        stack: error.stack,
        info,
        type: 'error',
        pageUrl: window.location.href,
      },
    ).catch((e) => {
      console.log(e);
    });
  });
  const addWarning = useCallback((message, info) => {
    const e = new Error();
    const errorMessage = `${message}  email: ${window.REDUX_STATE && window.REDUX_STATE.session ? window.REDUX_STATE.session.email : ''} url: ${window.location.href}`;
    axios.post(
      '/api/utilities/add-error',
      {
        portal: '',
        error: errorMessage,
        info,
        type: 'warning',
        pageUrl: window.location.href,
        stack: e.stack,
      },
    ).catch((e) => {
      console.log(e);
    });
  });
  const addInfo = useCallback((message, info) => {
    const e = new Error();
    const errorMessage = `${message}  email: ${window.REDUX_STATE && window.REDUX_STATE.session ? window.REDUX_STATE.session.email : ''} url: ${window.location.href}`;
    axios.post(
      '/api/utilities/add-error',
      {
        portal: '',
        error: errorMessage,
        info,
        type: 'info',
        pageUrl: window.location.href,
        stack: e.stack,
      },
    ).catch((e) => {
      console.log(e);
    });
  })

  return { addError, addWarning, addInfo };
};

export default useErrorHook;


