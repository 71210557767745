// import CampaignStatusInShow from './CampaignStatusInShow';
import CampaignPodcastsInList from './CampaignPodcastsInList';
import CampaignUsersInList from './CampaignUsersInList';
import CampaignStatusInList from './CampaignStatusInList';
import CampaignTitleInList from './CampaignTitleInList';

export default {
  show: {
  //  status: CampaignStatusInShow,
  //  status: CampaignStatusInList,
  },
  list: {
    status: CampaignStatusInList,
    podcasts: CampaignPodcastsInList,
    users: CampaignUsersInList,
    keyword: CampaignTitleInList,
  },
};
