/* eslint-disable filenames/match-exported */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
// import IconButton from '@material-ui/core/IconButton';
// import DownloadIcon from 'mdi-react/DownloadIcon';
import Tooltip from '@material-ui/core/Tooltip';
import csvHelper from '../../../shared/helpers/CSVHelper';


class DownloadCSVButton extends PureComponent {
  static propTypes = {
    csvArrayColumns: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    records: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    fileName: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    title: PropTypes.string,
  };
  static defaultProps = {
    title: 'Download CSV report',
  };

  constructor(props) {
    super(props);
    this.generateCSVReport = this.generateCSVReport.bind(this);
  }

  generateCSVReport() {
    const { csvArrayColumns, records, fileName } = this.props;
    const data = [csvArrayColumns.map(column => column.label)];
    records.forEach((record) => {
      const itemRow = [];
      csvArrayColumns.forEach((column) => {
        itemRow.push(column.func(record));
      });
      data.push(itemRow);
    });
    if (csvArrayColumns.find(c => c.total)) {
      const totals = [];
      csvArrayColumns.forEach((column, i) => {
        if (column.total === true) totals[i] = (data.slice(1)).reduce((sum, row) => Number(row[i] || 0) + sum, 0);
        else if (column.total?.toString().length > 0) totals[i] = column.total;
        else totals[i] = '';
      });
      data.push(totals);
      console.log(totals);
    }
    console.log(data);
    csvHelper.generateCSV(fileName, data);
  }
  render() {
    return (
      <Tooltip title={this.props.title}>
        <Button
          color="primary"
          onClick={this.generateCSVReport}
          className={`${this.props.className} btn btn-primary`}
        >
          {this.props.children}
        </Button>
      </Tooltip>
    );
  }
}

export default DownloadCSVButton;
