/* eslint react/prop-types: 0 */
import React from 'react';
import { Badge } from 'reactstrap';
import ApiClient from 'ApiClient';
import { uid } from 'react-uid';
import PlayCircleIcon from 'mdi-react/PlayCircleIcon';
import StopCircleIcon from 'mdi-react/StopCircleIcon';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';


class EnabledStatusInList extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.api = new ApiClient();
    this.state = {
      loading: false,
      enabled: this.props.record.params.enabled,
    };
  }

  handleChange() {
    this.setState({ loading: true });
    const { resource, record } = this.props;
    const { enabled } = this.state;
    const value = !enabled;
    const data = new FormData();
    data.append('enabled', value);
    this.api.recordAction({
      resourceId: resource.id,
      recordId: record.id,
      actionName: 'edit',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => {
      this.setState({ loading: false });
      if (response && response.data && response.data.success) {
        this.setState({ enabled: !enabled });
      }
    });
    return true;
  }

  render() {
    const { loading, enabled } = this.state;
    const { record: { params: { _id } } } = this.props;
    const status = enabled ? 'Running' : 'Stopped';
    const backgroundColor = enabled ? '#4be2b7' : '#eb2970';
    return (
      <div>
        { loading
          ? (
            <div
              className="spinner-border"
              style={{
                width: '18px',
                height: '18px',
                marginTop: '4px',
                marginLeft: '3px',
                marginRight: '8px',
                color: '#646777',
              }}
            />
          ) : (
            <Tooltip
              title={`Set to ${enabled ? 'Stopped' : 'Running'}`}
              key={`statusButton${uid(_id)}`}
              style={{ float: 'left' }}
            >
              <IconButton
                size="small"
                className="material-table__toolbar-button"
                onClick={this.handleChange}
              >
                {enabled
                    ? (
                      <StopCircleIcon size="32" />
                    ) : (
                      <PlayCircleIcon size="32" />
                    )
                  }
              </IconButton>
            </Tooltip>
          )
        }
        <Badge
          style={{
            padding: '7px',
            marginTop: '5px',
            borderRadius: '15px',
            backgroundColor,
          }}
        >
          {status}
        </Badge>
      </div>
    );
  }
}

export default props => (<div key={props.record.id}><EnabledStatusInList {...props} /></div>);
