import React from 'react';
import Date from '../../../../../../../shared/components/table/CellDateUtcToLocal';
import { getName } from '../../../../../../../shared/helpers/Model';

export default {
  list: {
    creator: ({ record }) => {
      const creator = record?.populated?.creator?.params;
      return creator ? (
        <a href={`/influencer/profile/${creator._id}`} target="_blank" rel="noreferrer">
          {getName(record.populated.creator.params)}
        </a>
      ) : '[Not Assigned]';
    },
    activityDatetime: Date,
  },
};
