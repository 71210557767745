/* eslint react/prop-types: 0 */
import React from 'react';
import PropertyInEdit from '../../ui/property-in-edit';
import StyledInput from '../../ui/styled-input';

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { onChange, property } = this.props;
    onChange(property.name, event.target.value);
  }

  render() {
    const { property, record } = this.props;
    const value = (
      record.params
      && typeof record.params[property.name] !== 'undefined'
      && record.params[property.name] !== null
    )
      ? record.params[property.name]
      : '';
    const error = record.errors && record.errors[property.name];
    return (
      <PropertyInEdit property={property} error={error}>
        <StyledInput
          as="textarea"
          className="input"
          rows={(value.match(/\n/g) || []).length + 1}
          id={property.name}
          name={property.name}
          onChange={this.handleInputChange}
          value={value}
          disabled={property.isDisabled}
        />
      </PropertyInEdit>
    );
  }
}

export default Edit;
