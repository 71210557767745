import { useDispatch } from 'react-redux';
import { hideModalById, showModalById } from '../../redux/reducers/ModalsReducer';

const useShowModalHook = (mId) => {
  const dispatch = useDispatch();
  // const modalInfo = useSelector(state => state.table.modals[mId]);

  const hideModal = () => dispatch(hideModalById(mId));
  const showModal = data => dispatch(showModalById(mId, data));
  return [showModal, hideModal];
};

export default useShowModalHook;
