/* eslint react/prop-types: 0 */
import React from 'react';
import {
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

export default class EmailAddressInEdit extends React.Component {
  constructor(props) {
    super(props);
    const { record } = this.props;
    // console.log(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      value: record.params.emailAddress || 'it-support@influencelogic.com',
    };
  }

  handleChange(event) {
    const { value } = event.target;
    const { onChange, enableSaveButton } = this.props;
    enableSaveButton(false);
    this.setState({ value });
    if (value) {
      // eslint-disable-next-line no-useless-escape
      if (/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@influencelogic.com$/.test(value)) {
        this.setState({ error: '' });
        onChange('emailAddress', value);
        enableSaveButton(true);
      } else {
        enableSaveButton(false);
        this.setState({ error: 'The email domain must be @influencelogic.com' });
      }
    } else {
      enableSaveButton(false);
      this.setState({ error: 'From Email is required' });
    }
  }

  render() {
    const {
      value,
      error,
    } = this.state;
    const helpBlockClassName = error ? 'help-block color-danger' : 'help-block';
    const displayHelpBlock = error ? 'block' : 'none';

    return (
      <FormGroup>
        <Label for="emailAddress">From Email</Label>
        <Input
          id="emailAddress"
          name="emailAddress"
          value={value}
          onChange={this.handleChange}
          style={{ width: '100%' }}
        />
        <span
          className={helpBlockClassName}
          style={{ display: displayHelpBlock }}
        >
          {error}
        </span>
      </FormGroup>
    );
  }
}

