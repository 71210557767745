/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React, { useState } from 'react';
import { Modal, Button, ModalHeader, ModalBody, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import withNotice from '../../../containers/App/store/with-notice';
import Hook from '../../hooks';


export const modalId = 'ConfirmModalDefault';

const DeleteModal = ({ addNotice }) => {
  const { addError } = Hook.useErrorHook();
  const [loading, setLoading] = useState(false);
  const { data, showModal: isShow, close } = Hook.useModalHook(modalId);
  if (!data || !data.title) return false;
  const {
    title, message, deleteButton, processRecord, errorCatch, confirmButtonCaption, size,
  } = data;
  const deleteRecordApi = async () => {
    setLoading(true);
    try {
      await processRecord(data, addNotice);
      close();
    } catch (e) {
      console.error(e);
      if (errorCatch) errorCatch(e, addNotice);
      close();
      addError(e, 'Error In Confirm Modal');
    }
    setLoading(false);
  };
  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      size={size || 'sm'}
      style={size ? {} : { minWidth: '385px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        {title}
      </ModalHeader>
      <ModalBody>
        {message}
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        {deleteButton || (<Button color="primary" onClick={deleteRecordApi} disabled={loading}>{confirmButtonCaption || 'Yes'}</Button>)}
        &nbsp;&nbsp;
        <Button color="secondary" onClick={close}>Cancel</Button>

      </ButtonToolbar>
    </Modal>
  );
};

DeleteModal.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

DeleteModal.defaultProps = {

};

export default withNotice(DeleteModal);
