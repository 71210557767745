/* eslint react/prop-types: 0 */
import React from 'react';
import { Badge } from 'reactstrap';

export default (props) => {
  const { record } = props;
  let color = 'secondary';
  switch (record.params.level) {
    case 'verbose':
      color = 'success';
      break;
    case 'info':
      color = 'info';
      break;
    case 'warning':
      color = 'warning';
      break;
    case 'error':
      color = 'danger';
      break;
    default:
      color = 'secondary';
  }
  return (
    <Badge color={color}>{record.params.level.toUpperCase()}</Badge>
  );
};
