import isPilot from './isPilot';
import isDisplayInUi from './IsDisplayInUi';
import inactive from './Inactive';

export default {
  edit: {},
  list: {
    isPilot,
    isDisplayInUi,
    inactive,
  },
};
