/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import withNotice from '../../../containers/App/store/with-notice';
import Hook from '../../hooks';


export const modalId = 'SuccessModal';

const SuccessModal = () => {
  const { data, showModal: isShow, close } = Hook.useModalHook(modalId);
  if (!data || !data.title) return false;
  const { title, message, size, width, closeModal } = data;
  const closeDialog = () => {
    if(closeModal) closeModal();
    close()
  }
  return (
    <Modal
      isOpen={isShow}
      toggle={closeDialog}
      className="delete-modal theme-light ltr-support"
      size={size || 'sm'}
      style={(size && !width) ? {} : { minWidth: width || '385px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        {title}
      </ModalHeader>
      <ModalBody>
        {message}
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-sm"
          color="secondary"
          onClick={closeDialog}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

SuccessModal.propTypes = {
};

SuccessModal.defaultProps = {

};

export default withNotice(SuccessModal);
