/* eslint react/prop-types: 0 */
import React from 'react';
import { CKEditor } from 'ckeditor4-react';
import PropertyInEdit from '../../../../../../../shared/components/components/ui/property-in-edit';

export default class NotificationText extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { onChange, property } = this.props;
    const { editor } = e;
    onChange(property.name, editor.getData());
  }

  render() {
    const { property, record } = this.props;
    const value = (record.params && typeof record.params[property.name] !== 'undefined')
      ? record.params[property.name]
      : '';
    const error = record.errors && record.errors[property.name];

    return (
      <PropertyInEdit property={property} error={error}>
        <CKEditor
          config={{versionCheck: false}}
          initData={value}
          onChange={this.handleChange}
        />
      </PropertyInEdit>
    );
  }
}
