import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from 'reactstrap';
import { axios } from 'ApiClient';
import RefreshModal from './UpdateAllModal';
import UpdateStatusModal from './UpdateStatusModal';
import Table from './Table';
import Actions from './Actions';
import NotesModal from './NotesModal';
import { updateTopMenuProspectLink } from '../../../../../redux/reducers/brand/CreatorsReducer';
import useDidMountEffect from '../../../../../shared/hooks/useDidMountEffect';
import { parseUrl } from '../../../../../shared/helpers/WVUtilities';

const typeFilterOptions = [
  { label: 'YouTube', value: 'youtube' },
  { label: 'Podcast', value: 'podcast' },
  { label: 'Instagram', value: 'instagram' },
  { label: 'TikTok', value: 'tiktok' },
  { label: 'Twitch', value: 'twitch' },
  { label: 'Twitter', value: 'twitter' },
  { label: 'Facebook Post', value: 'facebook' },
  { label: 'Website', value: 'website' },
];

const getBrandInitial = () => {
  const filters = parseUrl();
  const initialBrandFilters = {
    Approved: false,
    'In Review': true,
    Hold: false,
    Rejected: false,
    'In Appeals': true,
  };
  if (filters?.approvalStatusbrandstatus) {
    Object.keys(initialBrandFilters).forEach((key) => {
      initialBrandFilters[key] = filters?.approvalStatusbrandstatus.includes(key);
    });
  }
  return initialBrandFilters;
};
const getCreatorTypeInitial = () => {
  const filters = parseUrl();
  const initialFilters = {
    Standard: true,
    InfluenceLogic: true,
  };
  if (filters?.creatorType) {
    Object.keys(initialFilters).forEach((key) => {
      initialFilters[key] = filters?.creatorType?.includes(key);
    });
  }
  return initialFilters;
};
const getContentTypeInitial = () => {
  const filters = parseUrl();
  const initialFilters = {
    YouTube: true,
    Podcast: true,
    Instagram: true,
    TikTok: true,
    Twitch: true,
    Twitter: true,
    Facebook: true,
    Website: true,
  };
  if (filters?.contentType) {
    Object.keys(initialFilters).forEach((key) => {
      initialFilters[key] = filters?.contentType?.includes(key.toLowerCase());
    });
  }
  return initialFilters;
};

const ProspectContainer = ({
  setTag,
  action,
  resource,
  history,
  dispatch,
}) => {
  const filters = parseUrl();
  const [version, setVersion] = useState((new Date()).toString());
  const [brandFilters, setBrandFilters] = useState(getBrandInitial());
  // const [programFilters, setProgramFilters] = useState(getProgramInitial());
  const [creatorTypeFilters, setCreatorType] = useState(getCreatorTypeInitial());
  // const [matchFilters, setMatchFilters] = useState(getMatchInitial());
  const [contentTypesFilters, setContentTypesFilters] = useState(getContentTypeInitial());
  const [searchValue, onSearchChange] = useState(filters.fullSearchName || '');
  const [showRefreshModal, setShowRefreshModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [showBulkEditModal, setShowBulkEditModal] = useState(false);
  const [searchTerms, setSearchTerms] = useState([]);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [currentProspect, setCurrentProspect] = useState({});
  const [portal, setPortal] = useState('influenceLogic');
  const [disabledNotes, setDisabledNotes] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  const prospectContainerRefresh = async (id) => {
    try {
      await axios.post('/api/brand/prospects/post-fetch-data', {
        prospectsIds: [id],
        identity: Math.random(),
      });
      setVersion((new Date()).toString());
    } catch (e) {
      // console.log(e);
    }
    return true;
  };
  useEffect(async () => {
    try {
      const data = await axios.get('/api/brand/prospects/get-search-terms');
      // console.log(data);
      setSearchTerms(data.data.data || []);
    } catch (e) {
      // console.log(e);
    }
    return true;
  }, []);
  const getUrlSearch = () => {
    const search = new URLSearchParams(window.location.search);
    if (searchValue) {
      search.set('filters.fullSearchName', searchValue);
    } else {
      search.delete('filters.fullSearchName');
    }
    if (brandFilters && Object.keys(brandFilters).length > 0) {
      search.delete('filters.approvalStatusbrandstatus');
      Object.keys(brandFilters).forEach((key) => {
        if (!brandFilters[key]) return false;
        search.append('filters.approvalStatusbrandstatus', key);
        return true;
      });
    }
    if (creatorTypeFilters && Object.keys(creatorTypeFilters).length > 0) {
      search.delete('filters.creatorType');
      Object.keys(creatorTypeFilters).forEach((key) => {
        if (!creatorTypeFilters[key]) return false;
        search.append('filters.creatorType', key);
        return true;
      });
    }
    if (contentTypesFilters && Object.keys(contentTypesFilters).length > 0) {
      search.delete('filters.contentType');
      Object.keys(contentTypesFilters).forEach((key) => {
        if (!contentTypesFilters[key]) return false;
        search.append('filters.contentType', key.toLowerCase());
        return true;
      });
    }

    /* if (programFilters && Object.keys(programFilters).length > 0) {
      search.delete('filters.program');
      Object.keys(programFilters).forEach((key) => {
        if (!programFilters[key]) return false;
        search.append('filters.program', key);
        return true;
      });
    } */
    // if (matchFilters && Object.keys(matchFilters).length > 0) {
    //   search.delete('filters.existStatus');
    //   Object.keys(matchFilters).forEach((key) => {
    //     if (!matchFilters[key]) return false;
    //     search.append('filters.existStatus', compare[key]);
    //     return true;
    //   });
    // }
    // console.log(search.toString());
    setSearchQuery(search.toString());
    return search;
  };
  const setUrl = (setFirstPage = false) => {
    const search = getUrlSearch();
    if (setFirstPage) {
      search.set('page', '1');
    }
    history.push(`${history.location.pathname}?${search.toString()}`);
  };

  useDidMountEffect(() => {
    setUrl(true);
    setLoading(false);
    const search = getUrlSearch();
    dispatch(updateTopMenuProspectLink(`/prospects?${search.toString()}`));
  }, [
    brandFilters,
    searchValue,
    creatorTypeFilters,
    contentTypesFilters,
  ]);

  useEffect(() => { // first Run after component Initiated
    setUrl(false);
    setLoading(false);
    const search = getUrlSearch();
    dispatch(updateTopMenuProspectLink(`/prospects?${search.toString()}`));
  }, []);
  // console.log(selectedIds);
  return (
    <Container className="dashboard">
      <Row>
        <Col md="3">
          <h2 style={{ marginBottom: '25px' }}>Prospects</h2>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Actions
            brandFilters={brandFilters}
            setBrandFilters={setBrandFilters}
            // programFilters={programFilters}
            // setProgramFilters={setProgramFilters}
            creatorTypeFilters={creatorTypeFilters}
            setCreatorType={setCreatorType}
            // matchFilters={matchFilters}
            // setMatchFilters={setMatchFilters}
            onSearchChange={onSearchChange}
            setShowBulkEditModal={setShowBulkEditModal}
            setShowRefreshModal={setShowRefreshModal}
            contentTypesFilters={contentTypesFilters}
            setContentTypesFilters={setContentTypesFilters}
            typeFilterOptions={typeFilterOptions}
            searchQuery={searchQuery}
            searchValue={searchValue}
          />
          {/*
          <Row>
            <Col md={6}>
              <div className="float-left my-1" style={{ width: '1px' }} />
              <CheckboxFilter
                filterProp={ilFilters}
                filterTitle="InfluenceLogic Status"
                filterAction={({ data }) => setILFilters(data)}
              />
              <CheckboxFilter
                filterProp={brandFilters}
                filterTitle="Brand Status"
                filterAction={({ data }) => setBrandFilters(data)}
              />
              <CheckboxFilter
                filterProp={matchFilters}
                filterTitle="InfluenceLogic Contact"
                filterAction={({ data }) => setMatchFilters(data)}
              />
            </Col>
            <Col>
              <UncontrolledButtonDropdown className="mt-2 float-right">
                <DropdownToggle caret className="mr-2" color="primary">
                  Actions
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setShowBulkEditModal(true)}>Update Status</DropdownItem>
                  <DropdownItem onClick={() => setShowRefreshModal(true)}>Refresh Statistics</DropdownItem>
                  <DropdownItem onClick={() => window.open(`/resources/prospects/download-csv?${getUrlSearch().toString()}`, '_blank').focus()}>Download CSV</DropdownItem>

                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <InputGroup>
                <Input
                  className="form-control-sm form-control ml-3"
                  name="search"
                  type="text"
                  autoComplete="off"
                  placeholder="Search First Name, Last Name, Email"
                  value={searchValue}
                  onChange={p => onSearchChange(p.target.value)}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i
                      className="fa fa-search"
                      aria-hidden="true"
                      style={{ color: '#70bbfd', fontSize: 12 }}
                    />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
  */}
        </CardBody>
      </Card>
      <Row>
        {!loading && (
          <Col>
            <Table
              setTag={setTag}
              action={action}
              resource={resource}
              version={version}
              setSelectedIds={setSelectedIds}
              prospectContainerRefresh={prospectContainerRefresh}
              searchTerms={searchTerms}
              setCurrentProspect={setCurrentProspect}
              setShowNotesModal={setShowNotesModal}
              setPortal={setPortal}
              setDisabledNotes={setDisabledNotes}
            />
          </Col>
        )}
      </Row>
      <RefreshModal isOpen={showRefreshModal} closeFunc={() => setShowRefreshModal(false)} setVersion={setVersion} selectedIds={selectedIds} />
      <UpdateStatusModal isOpen={showBulkEditModal} closeFunc={() => setShowBulkEditModal(false)} setVersion={setVersion} selectedIds={selectedIds} />
      <NotesModal isOpen={showNotesModal} closeFunc={() => setShowNotesModal(false)} setVersion={setVersion} prospect={currentProspect} disabled={disabledNotes} portal={portal} />
    </Container>
  );
};


ProspectContainer.propTypes = {
  setTag: PropTypes.func.isRequired,
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({

});

export default connect(mapStateToProps)(ProspectContainer);
