/* eslint react/prop-types: 0 */
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
// import { StyledLink } from '../../../src/shared/components/components/ui/index';
export default (props) => {
  const { record } = props;
  const link = `/resources/PodcastUser/actions/list?filters.campaigns=${record.id}`;
  return (
    <Link to={link}>
      <Button
        color="primary"
        outline
        size="sm"
        target="_blank"
        rel="noreferrer noopener"
      >
        Show Podcasters
      </Button>
    </Link>
  );
};
