import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody, Spinner, Modal, ModalHeader, ButtonToolbar, Button, InputGroup, Input, InputGroupText, InputGroupAddon } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getCreator, getCreatorContent, closeScorecardAnswersDialog } from '../../../../redux/reducers/brand/CreatorsReducer';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import CheckboxFilter from '../../../../shared/components/CheckboxManyFilter';
import ScorecardsAnswersForm from '../../../admin/Scorecard/ScorecardAnswersSheet/components/ScorecardsAnswersForm';
// import { addNotice } from '../../../App/store/store';

import ButtonsInList from './ButtonsInList';
// import CreatorNameInList from './custom/CreatorNameInList';
import TypeInList from './TypeInList';
// import TitleInList from './custom/TitleInList';
import TitleInList from './TitleInList';

const CreatorContentListContainer = ({
  match, dispatch, loading, creatorContent, currentCreator, showScorecardAnswersDialog, loadingScorecardData, scorecardData,
}) => {
  const initialChannelsFilters = {
    YouTube: true,
    'YouTube Description Text': true,
    Podcast: true,
    'Podcast Script Text': true,
    'Instagram Story': true,
    'Instagram Post': true,
    'Instagram TV Video': true,
    TikTok: true,
    'Facebook Post': true,
    'Facebook Watch Video': true,
    'Twitter Post': true,
    LinkTree: true,
    Website: true,
    Email: true,
    Text: true,
  };
  const [channelsFilters, setChannelsFilters] = useState(initialChannelsFilters);
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [dateSearch, setDateSearch] = useState(null);
  const getData = () => {
    if (match) {
      dispatch(getCreatorContent(match.params.creatorId, true));
      dispatch(getCreator(match.params.creatorId));
    }
  };

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      setSearchValue(search);
    }, 1000));
  }, [search]);
  const filteredContent = useMemo(() => {
    const channelsFiltersArray = Object.keys(channelsFilters).filter(c => channelsFilters[c]);
    const data = creatorContent.filter((c) => {
      const filter = channelsFiltersArray.includes(c.type);
      const searchString = search.toLowerCase();
      let isSearch = true;
      if (searchString) {
        isSearch = (`${c.title} ${c.name}`.toLowerCase().includes(searchString));
      }
      return filter && isSearch;
    });
    return data;
  }, [creatorContent, channelsFilters, searchValue]);
  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withPagination: true,
    manualPageSize: [10, 20, 30, 40],
    withSearchEngine: false,
    sortBy: 'name',
    direction: 'desc',
    useFlex: true,
  };
  useEffect(() => {
    getData();
  }, [match.params.creatorId]);


  const columns = useMemo(() => [{
    Header: () => 'Content Details',
    id: 'details',
    accessor: p => p.title,
    Cell: p => useMemo(() => (<TitleInList record={p.row.original} />), [p.row.original._id]),
  }, {
    Header: () => 'Type',
    id: 'name',
    accessor: p => p.type,
    Cell: p => useMemo(() => (<TypeInList record={p.row.original} />), [p.row.original._id]),
  }, {
    Header: () => 'Publish Date',
    id: 'PublishDate',
    accessor: p => p.publishDate,
    Cell: p => useMemo(() => (p.value ? moment(p.value).format('MM/DD/YY') : '---'), [p.row.original._id]),
  }, {
    Header: () => 'Avg. Score',
    id: 'AvgScore',
    accessor: (record) => {
      if (typeof record.answers !== 'undefined' && record.answers !== null && record.answers.length > 0) {
        return Math.round(record.answers.reduce((avg, answer, _, { length }) => (avg + (Number(answer.totalScore) / length)), 0));
      }
      return '';
    },
    Cell: p => useMemo(() => p.value, [p.row.original._id]),
  }, {
    Header: () => '',
    id: 'actions',
    accessor: (record) => {
      if (typeof record.answers !== 'undefined' && record.answers !== null && record.answers.length > 0) {
        return Math.round(record.answers.reduce((avg, answer, _, { length }) => (avg + (Number(answer.totalScore) / length)), 0));
      }
      return '';
    },
    Cell: p => useMemo(() => (<ButtonsInList record={p.row.original} />), [p.row.original._id]),
    disableSortBy: true,
  }]);
  const isFTCCompliant = scorecardData !== null && typeof scorecardData.data !== 'undefined' ? scorecardData.data.isFTCCompliant : null;
  const includesPaidPromotion = scorecardData !== null && typeof scorecardData.data !== 'undefined' ? scorecardData.data.includesPaidPromotion : null;


  return (
    <>
      <Row>
        <Col md="12">
          <h2 className="my-3" >{`${currentCreator.first_name} ${currentCreator.last_name}`} Content</h2>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <div className="float-left my-1" style={{ width: '3px' }} />
                  <CheckboxFilter
                    filterProp={channelsFilters}
                    filterTitle="Content Type"
                    filterAction={({ data }) => setChannelsFilters(data)}
                  />
                </Col>
                <Col>
                  <InputGroup className="mt-2">
                    <Input
                      className="form-control-sm form-control ml-3"
                      name="search"
                      type="search"
                      autoComplete="off"
                      placeholder="Search Title, Content Name"
                      value={search}
                      onChange={p => setSearch(p.target.value)}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <i
                          className="fa fa-search"
                          aria-hidden="true"
                          style={{ color: '#70bbfd', fontSize: 12 }}
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <Row>
                <Col sm="12" className="pb-5">
                  {loading && (<div className="text-center"><Spinner color="primary" size="lg" /></div>)}
                  {!loading ? (
                    <ReactTableBase
                      key="billComAccounts"
                      columns={columns}
                      data={filteredContent}
                      tableConfig={tableConfig}
                      notFoundProps={{
                        title: creatorContent.length > 0 ? 'No content found' : `${currentCreator.first_name} ${currentCreator.last_name} has no scored content.`,
                        styles: { marginTop: '25px' },
                      }}
                    />) : null}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={showScorecardAnswersDialog}
        toggle={() => dispatch(closeScorecardAnswersDialog())}
        style={{ minWidth: '750px' }}
      >
        <ModalHeader toggle={() => dispatch(closeScorecardAnswersDialog())} tag="h4" className="modal-title-statistics">Scorecard Preview</ModalHeader>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="modal__body">
          {!loadingScorecardData && showScorecardAnswersDialog && scorecardData && (
            <ScorecardsAnswersForm
              questions={scorecardData.data.scorecard}
              answers={scorecardData}
              view="modal"
              isFTCCompliant={isFTCCompliant}
              includesPaidPromotion={includesPaidPromotion}
              type={scorecardData.data.mediaContent.type}
              mediaContentText={scorecardData.data.mediaContent.contentText}
              disableNotes
            />
          )}
          {loadingScorecardData && showScorecardAnswersDialog && !scorecardData && (
            <div className="text-center"><Spinner color="primary" size="lg" /></div>
          )}
        </div>
        <ButtonToolbar className="modal__footer">
          <Button color="primary" className="btn btn-sm" onClick={() => dispatch(closeScorecardAnswersDialog())}>Close</Button>
        </ButtonToolbar>
      </Modal>
    </>
  );
};

CreatorContentListContainer.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  creatorContent: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  currentCreator: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  scorecardData: PropTypes.objectOf(PropTypes.any),
  loadingScorecardData: PropTypes.bool,
  showScorecardAnswersDialog: PropTypes.bool.isRequired,
};
CreatorContentListContainer.defaultProps = {
  loadingScorecardData: null,
  scorecardData: null,
};
const mapStateToProps = state => ({
  loading: state.creators.loading.creatorContent,
  creatorContent: state.creators.creatorContent,
  currentCreator: state.creators.currentCreator,
  showScorecardAnswersDialog: state.creators.showScorecardAnswersDialog,
  loadingScorecardData: state.score.loading.fetchScorecardAnswersContent,
  scorecardData: state.score.answers,
});

export default connect(mapStateToProps)(withRouter(CreatorContentListContainer));
