import DescriptionInList from './DescriptionInList';
import ActionsInList from './ActionsInList';

export default {
  edit: {

  },
  list: {
    description: DescriptionInList,
  },
  actions: ActionsInList,
};
