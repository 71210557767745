import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ButtonToolbar,
  ModalBody,
  Button,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import { hideModalById } from '../../../../../../../redux/reducers/TableReducer';
import withNotice from '../../../../../../App/store/with-notice';

export const modalId = 'refreshYoutubeDialogComments';

const RefreshYoutubeDialog = ({
  afterSaveFunc,
  addNotice,
}) => {
  const [loading, setLoading] = useState(false);
  const modalInfo = useSelector(state => state.table.modals[modalId]);
  const dispatch = useDispatch();
  const close = () => dispatch(hideModalById(modalId));
  if (!modalInfo) return false;
  const { data: { record }, isShow: showDeleteDialog } = modalInfo;
  console.log(modalInfo, 'modalInfo');
  const deleteRecord = async () => {
    try {
      setLoading(true);
      const res = await axios.post('/api/portal/compliance/post-refresh-media-content-youtube-comments', {
        id: record.id,
      });
      setLoading(false);
      close();
      if (!res.data.success) throw new Error(res.data.error);
      addNotice({
        message: 'The Media Content has been updated successfully',
        type: 'success',
      });
      afterSaveFunc();
    } catch (e) {
      addNotice({
        message: e.message || 'Unable to update Media Cotnent',
        type: 'error',
      });
      console.log(e);
      setLoading(false);
    }
  };
  return (
    <Modal
      size="sm"
      isOpen={showDeleteDialog}
      toggle={close}
      style={{ minWidth: '385px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Refresh YouTube Comments?
      </ModalHeader>
      <ModalBody>
        Are you sure you want to refresh Comments for <strong>{'"'}{record?.params?.name || ''}{'"'}</strong>?<br />
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button color="primary" onClick={deleteRecord} disabled={loading || !record}>Refresh</Button>&nbsp;&nbsp;
        <Button color="secondary" onClick={close}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};


RefreshYoutubeDialog.propTypes = {
  afterSaveFunc: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};
export default withNotice(RefreshYoutubeDialog);
