/* eslint react/prop-types: 0 */
import React, { PureComponent } from 'react';
import { uid } from 'react-uid';
import ReplyIcon from 'mdi-react/ReplyIcon';
import LaunchIcon from 'mdi-react/LaunchIcon';
import FlightIcon from 'mdi-react/AirplaneAlertIcon';
import EventIcon from 'mdi-react/EventIcon';
import LocationOnIcon from 'mdi-react/LocationOnIcon';
import { Card, CardBody, Col, Row } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { unflatten } from 'flat';
import '../../../../../../src/scss/component/table_tt_leads.scss';
import FollowWithSequenceInList from './custom/FollowWithSequenceInList';

const getAddress = (record) => {
  if (record.params && record.params.request && record.params.request.location && record.params.request.location.city && record.params.request.location.state && record.params.request.location.zipCode) {
    return `${record.params.request.location.city}, ${record.params.request.location.state} ${record.params.request.location.zipCode}`;
  }
  return 'N/A';
};

const getReplyInfo = (r) => {
  let replyInfo = 'Not yet replied';
  let color = 'red';
  if (r && r.params) {
    if (r.params.repliedByUs === false) {
      replyInfo = 'Not yet replied';
      color = '#fd0000';
    } else {
      if (r.params.replyStatus === 'ourself') { // template
        color = '#70bbfd';
        replyInfo = 'TT Auto-Responder Bot sent an automated Default response';
        if (r.params.repliedByUsAt) {
          replyInfo = `${replyInfo} at ${moment(r.params.repliedByUsAt).local().format('h:mm a, MM/DD/yyyy')}`;
        }
      }
      if (r.params.replyStatus === 'user') { // slack
        color = '#1bc428';
        replyInfo = 'Replied in Slack';
        if (r.params.assignedInSlackTo) {
          replyInfo = `${replyInfo} by '${r.params.assignedInSlackTo}'`;
        }
        if (r.params.assignedInSlackAt) {
          replyInfo = `${replyInfo} at ${moment(r.params.assignedInSlackAt).local().format('h:mm a, MM/DD/yyyy')}`;
        }
      }
      if (r.params.replyStatus === 'adminUser') { // portal
        color = '#1bc428';
        replyInfo = 'Replied';
        if (r.params.repliedByAdminUser) {
          replyInfo = `${replyInfo} by ${r.params.repliedByAdminUser.firstName} ${r.params.repliedByAdminUser.lastName}`;
        }
        if (r.params.repliedByAdminUserAt) {
          replyInfo = `${replyInfo} at ${moment(r.params.repliedByAdminUserAt).local().format('h:mm a, MM/DD/yyyy')}`;
        }
      }
      if (r.params.replyStatus === 'sequencer') { // sequencer
        color = '#17a2b8';
        replyInfo = 'Follow up sequence was sent';
        if (r.params.followUpSequenceWasCreatedAt) {
          replyInfo = `${replyInfo} on ${moment(r.params.followUpSequenceWasCreatedAt).local().format('MM/DD/yyyy')}`;
        }
      }
    }
  }
  return { replyInfo, color };
};

const getDetails = (detailsArray) => {
  let result = '';
  detailsArray.forEach((detail) => {
    if (detail && detail.question && detail.answer) {
      if (result !== '') {
        result = `${result}<br>`;
      }
      result = `${result}${detail.question}: <em>${detail.answer}</em>`;
    }
  });

  // eslint-disable-next-line react/no-danger
  return (<p style={{ color: '#212529', marginTop: '0' }} dangerouslySetInnerHTML={{ __html: result }} />);
};

const getReceivedOn = (record) => {
  if (record.params && record.params.createTimestamp) {
    return `Received on ${moment.unix(record.params.createTimestamp).format('MM/DD/yyyy')} ${moment.unix(record.params.createTimestamp).format('h:mma')}`;
  }
  if (record.params && record.params.createdAt) {
    return `Received on ${moment(record.params.createdAt).local().format('MM/DD/yyyy')} ${moment(record.params.createdAt).local().format('h:mma')}`;
  }
  return 'Received on N/A';
};


class ThumbTackLeadCard extends PureComponent {
  constructor(props) {
    super(props);
    this.openThumbTack = this.openThumbTack.bind(this);
    this.openMessages = this.openMessages.bind(this);
  }

  openThumbTack = (requestID) => {
    try {
      if (requestID) {
        const win = window.open(`https://www.thumbtack.com/request/${requestID}`, '_blank');
        win.focus();
      }
    } catch (error) {
      console.log(error);
    }
  };

  openMessages = (lead) => {
    try {
      if (lead) {
        const win = window.open(`/thumbtack/messages/?lead=${lead}`, '_blank');
        win.focus();
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      record,
      resource,
    } = this.props;

    const recordUnflattened = unflatten(record);
    const { replyInfo, color } = getReplyInfo(recordUnflattened);
    return (
      <Card>
        <CardBody>
          <div
            style={{ width: '100%', display: 'inline-block' }}
          >
            <span
              name="title"
              style={{ fontWeight: 'bold', float: 'left', fontSize: '150%' }}
            >
              {recordUnflattened.params.request.title ? recordUnflattened.params.request.title : 'N/A'}
            </span>
            <div
              style={{ float: 'right' }}
            >
              { recordUnflattened.id && (
              <Tooltip
                title="Go to Messages"
                key={`messagesButton${uid(recordUnflattened.id)}`}
                style={{ float: 'left' }}
              >
                <IconButton
                  size="small"
                  className="material-table__toolbar-button"
                  onClick={() => this.openMessages(recordUnflattened.id)}
                >
                  <ReplyIcon />
                </IconButton>
              </Tooltip>
              )}
              <Tooltip
                title="Open on ThumbTack"
                key={`openButton${uid(recordUnflattened.id)}`}
                style={{ float: 'right' }}
              >
                <IconButton
                  size="small"
                  className="material-table__toolbar-button"
                  onClick={() => this.openThumbTack(recordUnflattened.params.request.requestID)}
                >
                  <LaunchIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div
            style={{
              position: 'relative',
              height: '20px',
              paddingBottom: '8px',
              marginTop: '-8px',
            }}
          >
            <span
              name="receivedOn"
              style={{ width: '100%', textAlign: 'left', position: 'absolute' }}
            >
              {getReceivedOn(recordUnflattened)}
            </span>
            <span
              name="leadStatus"
              style={{
                width: '100%',
                fontWeight: 'bold',
                textAlign: 'right',
                position: 'absolute',
              }}
            >
              {recordUnflattened.params.repliedByUs === false ? 'New Lead' : 'Replied'}
            </span>
          </div>
          <hr style={{ marginTop: '8px', marginBottom: '8px' }} />
          <div
            style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}
          >
            <div
              style={{ width: '50%' }}
            >
              <Row>
                <Col md={4}>
                  <span
                    name="customerNameTitle"
                    style={{ fontWeight: 'bold' }}
                  >
                    Category
                  </span>
                </Col>
                <Col md={8}>
                  <span
                    name="customerName"
                  >
                    {recordUnflattened.params.request.category ? recordUnflattened.params.request.category : 'N/A'}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <span
                    name="customerNameTitle"
                    style={{ fontWeight: 'bold' }}
                  >
                    Customer Name
                  </span>
                </Col>
                <Col md={8}>
                  <span
                    name="customerName"
                  >
                    {recordUnflattened.params.customer.name ? recordUnflattened.params.customer.name : 'N/A'}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <span
                    name="priceTitle"
                    style={{ fontWeight: 'bold' }}
                  >
                    Price
                  </span>
                </Col>
                <Col md={8}>
                  <span
                    name="price"
                  >
                    {recordUnflattened.params.price || 'N/A'}
                  </span>
                </Col>
              </Row>
            </div>
            <div
              style={{
                width: '50%',
                textAlign: 'right',
                display: 'grid',
              }}
            >
              {!recordUnflattened.params.repliedByUs && (
                <Tooltip
                  title="Go to Messages"
                  key={`messagesButton${uid(recordUnflattened.id)}`}
                  style={{ float: 'right' }}
                >
                  <IconButton
                    size="small"
                    className="material-table__toolbar-button"
                    onClick={() => this.openMessages(recordUnflattened.id)}
                    style={{ color }}
                  >
                    <ReplyIcon />
                    <span style={{ fontWeight: 'bold', float: 'right', color }}>
                      {replyInfo}
                    </span>
                  </IconButton>
                </Tooltip>
              )}
              {recordUnflattened.params.repliedByUs && (
                <div
                  style={{
                    paddingBottom: '8px',
                    fontWeight: 'bold',
                    float: 'right',
                    color,
                  }}
                >
                  {replyInfo}
                </div>
              )}
              <FollowWithSequenceInList
                resource={resource}
                record={record}
                cardView
              />
            </div>
          </div>
          <span
            name="descriptionTitle"
            style={{ fontWeight: 'bold', display: 'block', marginTop: '10px' }}
          >
            Description
          </span>
          <span
            name="description"
          >
            {recordUnflattened.params.request.description ? recordUnflattened.params.request.description : 'N/A'}
          </span>
          { recordUnflattened.params.request.details && recordUnflattened.params.request.details.length > 0 && (
          <div>
            <span
              name="detailsTitle"
              style={{ fontWeight: 'bold', display: 'block', marginTop: '10px' }}
            >
              Details
            </span>
            { getDetails(recordUnflattened.params.request.details) }
          </div>
                      )}
          <div className="tt_leads_wrapper">
            <div className="tt_leads_box tt_leads_airplaneIcon">
              <FlightIcon />
            </div>
            <div className="tt_leads_box tt_leads_travelPreferences">
              {recordUnflattened.params.request.travelPreferences ? recordUnflattened.params.request.travelPreferences : 'N/A'}
            </div>
            <div className="tt_leads_box tt_leads_dateIcon">
              <EventIcon />
            </div>
            <div className="tt_leads_box tt_leads_date">
              {recordUnflattened.params.request.schedule ? recordUnflattened.params.request.schedule : 'N/A'}
            </div>
            <div className="tt_leads_box tt_leads_locationIcon">
              <LocationOnIcon />
            </div>
            <div className="tt_leads_box tt_leads_location">
              {getAddress(recordUnflattened)}
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default ThumbTackLeadCard;

