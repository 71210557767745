import { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { preLoadFilters, loadTableData, setInitial } from '../../redux/reducers/TableReducerDefault';
import { parseUrl } from '../helpers/WVUtilities';



const usePrefillHook = ({
  resourceId, initialState, urlModify, loadTableUrl,
}) => {
  const state = useSelector(s => s.tableRedux.tableFilterUrl);
  const statePrevious = useSelector(s => s.tableRedux.previousTableFilterUrl);
  const pageLocation = useSelector(s => s.tableRedux.pageLocation);
  // const pageLocation = useSelector(s => s.table.pageLocation);
  console.log(window.location.pathname, '1');
  const dispatch = useDispatch();
  if (!pageLocation || pageLocation !== window.location.pathname) {
    dispatch(setInitial());
    console.log('INITIAL');
  }
  const setState = properties => dispatch(preLoadFilters(properties, { resourceId, initialState, urlModify }));
  const loadPage = () => dispatch(loadTableData(loadTableUrl));
  const changedParams = useMemo(() => {
    const search = new URLSearchParams(state);
    const searchPrev = new URLSearchParams(statePrevious);
    const filters = parseUrl(state);
    const filtersPrev = parseUrl(statePrevious);
    /* console.log(filters, filtersPrev, 'filtersfiltersfiltersfilters');
    console.log(state, 'filtersfiltersfiltersfilters');
    console.log(statePrevious, 'filtersfiltersfiltersfilters');
    console.log(_.isEqual(filters, filtersPrev), 'filtersfiltersfiltersfilters'); */
    return {
      page: search.get('page') !== searchPrev.get('page'),
      perPage: search.get('perPage') !== searchPrev.get('perPage'),
      direction: search.get('direction') !== searchPrev.get('direction'),
      sortBy: search.get('sortBy') !== searchPrev.get('sortBy'),
      filters: !_.isEqual(filters, filtersPrev),
    };
  }, [state]);
  const stateToReturn = (!pageLocation || pageLocation !== window.location.pathname) ? null : state;
  const Reload = ({ loadFunc, loadDebounceFunc, setSearchUrl }) => {
    const [isInitialNonNullSearchUrl, setIsInitialNonNullSearchUrl] = useState(false);
    useEffect(() => {
      if (stateToReturn === null) return false;
      setSearchUrl();
      if (!changedParams.filters || isInitialNonNullSearchUrl === false) {
        setIsInitialNonNullSearchUrl(true);
        return loadFunc();
      }
      return loadDebounceFunc();
    }, [stateToReturn, changedParams.filters]);
  };
  return [
    stateToReturn,
    setState,
    loadPage,
    changedParams,
    Reload,
    // ({ loadFunc, loadDebounceFunc, setSearchUrl }) => useLoadOrDebounce({ searchUrl: stateToReturn, isPageChanged: changedParams.filters, loadFunc, loadDebounceFunc, setSearchUrl })
  ];
};

export default usePrefillHook;
