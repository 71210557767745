/* eslint react/prop-types: 0 */
import React from 'react';
import { Input } from 'reactstrap';
import ApiClient from 'ApiClient';
import { uid } from 'react-uid';
import { axios } from 'ApiClient';
import withNotice from '../../../../../App/store/with-notice';

class FollowWithSequenceInList extends React.Component {
  constructor(props) {
    super(props);
    this.fetchSequenceTemplateData = this.fetchSequenceTemplateData.bind(this);
    this.selectFollowWithSequence = this.selectFollowWithSequence.bind(this);
    this.api = new ApiClient();
    this.state = {
      loading: false,
      followWithSequence: props.record.params.followWithSequence,
      sequenceCountDaysFrom: props.record.params.sequenceCountDaysFrom,
      resolution: props.record.params.resolution,
      message: null,
    };
    if (props.record.params.followWithSequence) {
      this.fetchSequenceTemplateData();
    }
  }

  selectFollowWithSequence(e) {
    const { value } = e.target;
    if (value) {
      const { resource, record } = this.props;
      const data = new FormData();
      let localFollowWithSequence = false;
      switch (value) {
        case 'disabled':
          data.append('followWithSequence', false);
          data.append('sequenceCountDaysFrom', 'create');
          data.append('resolution', '');
          break;
        case 'underage':
        case 'notHired':
        case 'hired':
          data.append('followWithSequence', false);
          data.append('sequenceCountDaysFrom', 'create');
          data.append('resolution', value);
          break;
        default:
          localFollowWithSequence = true;
          data.append('followWithSequence', true);
          data.append('sequenceCountDaysFrom', value);
          data.append('resolution', '');
          if (record && record.params && record.params.followWithSequence === false) {
            data.append('maxSequenceDaysMessageSent', 0);
          }
      }

      this.setState({ loading: true });
      this.api.recordAction({
        resourceId: resource.id,
        recordId: record.id,
        actionName: 'edit',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        this.setState({ loading: false });
        if (response) {
          if (response.data && response.data.notice) {
            this.props.addNotice({
              message: response.data.notice.message,
              type: response.data.notice.type,
            });
          }
          if (response.status && response.status === 200) {
            switch (value) {
              case 'disabled':
                this.setState({ followWithSequence: false, sequenceCountDaysFrom: 'create', resolution: '' });
                break;
              case 'underage':
              case 'notHired':
              case 'hired':
                this.setState({ followWithSequence: false, sequenceCountDaysFrom: 'create', resolution: value });
                break;
              default:
                this.setState({ followWithSequence: true, sequenceCountDaysFrom: value, resolution: '' });
            }
            if (localFollowWithSequence) {
              this.fetchSequenceTemplateData();
            } else {
              this.setState({ message: null });
            }
          }
        }
      });
      return true;
    }
    return false;
  }

  fetchSequenceTemplateData() {
    const { record } = this.props;
    axios({
      method: 'post',
      url: '/api/portal/thumbtack/leads/next-sequence-template-data',
      data: {
        id: record.params._id,
      },
    }).then((response) => {
      if (response.data.success === true) {
        this.setState({ message: response.data.message });
      } else {
        console.error(response.data.error);
      }
    }).catch((error) => {
      console.error(error);
      this.props.addNotice({
        message: 'There was an error fetching Sequence Template data. Check out console to see more information.',
        type: 'error',
      });
    });
    return true;
  }

  render() {
    const {
      loading,
      followWithSequence,
      sequenceCountDaysFrom,
      message,
      resolution,
    } = this.state;
    const { record: { params: { _id } }, cardView } = this.props;
    let valueSelected = 'disabled';
    if (followWithSequence && typeof sequenceCountDaysFrom !== 'undefined') valueSelected = sequenceCountDaysFrom;
    if (!followWithSequence && resolution) valueSelected = resolution;
    const cardViewStyleOuter = { justifySelf: 'end' };
    return (
      <div style={cardView ? cardViewStyleOuter : {}} >
        <Input
          key={`followWithSequenceSelect${uid(_id)}`}
          type="select"
          name="followWithSequenceSelect"
          id="followWithSequenceSelect"
          bsSize="sm"
          onChange={this.selectFollowWithSequence}
          value={valueSelected}
          disabled={loading}
        >
          <option value="disabled" key="disabled">DO NOT FOLLOW WITH SEQUENCE</option>
          <option value="underage" key="underage">UNDERAGE CHILD (DO NOT)</option>
          <option value="notHired" key="notHired">NOT HIRED (DO NOT)</option>
          <option value="hired" key="hired">HIRED (DO NOT)</option>
          <option value="create" key="create">Count from lead creation time</option>
          <option value="incoming" key="incoming">Count from last incoming message</option>
          <option value="outgoing" key="outgoing">Count from last sent message</option>
        </Input>

        {message && (<p style={{ marginTop: '8px' }}>{message}</p>)}
      </div>
    );
  }
}

export default withNotice(FollowWithSequenceInList);
