import { unflatten } from 'flat';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { modalId as notesModalId } from './NotesModal';
import {
  setShowStatusHistoryDialog,
  showChangeMediaContentStatusDialog,
  showDeleteMediaContentDialog,
} from '../../../../../../redux/reducers/admin/ScoringReducer';
import TableCellContextMenu from './TableCellContextMenu';
import TranscriptTextModal from './TranscriptTextModal';
import { parseVideoId } from '../../../../../../shared/helpers/WVUtilities';
import { showModalById } from '../../../../../../redux/reducers/TableReducer';
import { modalId } from './modals/RefreshYoutubeDialog';

const ContextMenuInList = ({ record, dispatch }) => {
  const [showTranscriptText, setShowTranscriptText] = useState(false);

  const history = useHistory();

  const recortUnflatten = unflatten(record);
  const { _id, hubilDeal/* , scorecards */ } = recortUnflatten.params;
  const hsLink = typeof hubilDeal !== 'undefined' && typeof hubilDeal[0] !== 'undefined' ? `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${hubilDeal[0].hs_object_id}` : null;
  const editLink = `/scorecard/media-content/edit/${_id}`;

  const handleClickViewDeal = () => {
    if (hsLink) {
      window.open(hsLink, '_blank').focus();
    }
  };

  const handleClickViewNotes = () => {
    dispatch(showModalById(notesModalId, { record }))
  };

  const handleClickViewTranscriptText = () => {
    setShowTranscriptText(true);
  };
  const handleClickEditContent = () => {
    history.push(editLink);
  };

  const clickDeleteIcon = () => {
    dispatch(showDeleteMediaContentDialog(true, record));
  };

  const clickStatusHistory = () => {
    dispatch(setShowStatusHistoryDialog(true, recortUnflatten));
  };

  const clickChangeStatusIcon = () => {
    const data = {
      id: record.id,
      status: record.params.status,
      notes: record.params.notes,
    };
    dispatch(showChangeMediaContentStatusDialog(true, data));
  };

  const options = [
    { label: 'View Deal', handler: handleClickViewDeal },
    { label: 'View Notes', handler: handleClickViewNotes },
    { label: 'View Transcript', handler: handleClickViewTranscriptText },
    { label: 'Edit Content', handler: handleClickEditContent },
    { label: 'Delete', handler: clickDeleteIcon },
    { label: 'Change History', handler: clickStatusHistory },
  ];
  if (record.params.status === 'New' || record.params.status === 'Pending Edits' || record.params.status === 'Rescore') {
    options.push({ label: (record.params.status === 'New' || record.params.status === 'Rescore') ? 'Pending Edits' : 'Rescore', handler: clickChangeStatusIcon });
  }
  const videoId = parseVideoId(record.params?.mediaUrl);
  options.push({ label: 'Refresh YouTube Data', handler: () => { dispatch(showModalById(modalId, { record })); }, disabled: !videoId });

  return (
    <div className="text-right">
      <TableCellContextMenu options={options} />
      <TranscriptTextModal
        record={record}
        showTranscriptText={showTranscriptText}
        setShowTranscriptText={setShowTranscriptText}
      />
    </div>
  );
};
ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  showDeleteDialog: state.score.showDeleteDialog,
});
export default connect(mapStateToProps)(ContextMenuInList);
