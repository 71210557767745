import InfluencerInList from './InfluencerInList';
import OfferInList from './OfferInList';
import ContextMenuInList from './ContextMenuInList';

export default {
  list: {
    creatorName: InfluencerInList,
    offerName: OfferInList,
  },
  actions: ContextMenuInList,
};
