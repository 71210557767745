// import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent, TabPane
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideModalById } from '../../../../../../redux/reducers/TableReducer';
import classnames from 'classnames';

export const modalId = 'mediaContentAllNotes';

const NotesModal = () => {
  const [activeTab, setActiveTab] = useState('ilNotes');
  const dispatch = useDispatch();
  const modalInfo = useSelector(state => state.table.modals[modalId]);
  if (!modalInfo) return false;
  // console.log(modalInfo);

  const { data: { record }, isShow } = modalInfo;
  const close = () => {
    setActiveTab('ilNotes');
    dispatch(hideModalById(modalId))
  };

  return (
    <Modal
      isOpen={isShow}
      toggle={close}
      className="delete-modal"
      size="sm"
      style={{ minWidth: '800px' }}
    >
      <ModalHeader toggle={close} tag="h4">
        Notes
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col className="tabs">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'ilNotes' })}
                  onClick={() => {
                    setActiveTab('ilNotes');
                  }}
                >
                  InfluenceLogic Notes
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'brandNotes' })}
                  onClick={() => {
                    setActiveTab('brandNotes');
                  }}
                >
                  Brand Notes
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="ilNotes">
                {/* eslint-disable-next-line react/no-danger */}
                <div
                  style={{ margin: '15px 10px 0px 10px' }}
                  dangerouslySetInnerHTML={{ __html: record.params.notes !== 'null' ? record.params.notes : '' }}
                />
              </TabPane>
              <TabPane tabId="brandNotes">
                {/* eslint-disable-next-line react/no-danger */}
                <div
                  style={{ margin: '15px 10px 0px 10px' }}
                  dangerouslySetInnerHTML={{ __html: record.params.brandNotes !== 'null' ? record.params.brandNotes : '' }}
                />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-sm" color="secondary" onClick={close}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

// NotesModal.propTypes = {
//   record: PropTypes.objectOf(PropTypes.any).isRequired,
//   showNotes: PropTypes.bool.isRequired,
//   setShowNotes: PropTypes.func.isRequired,
// };
export default NotesModal;
