/* eslint react/prop-types: 0 */
import React from 'react';

export default (props) => {
  const { record } = props;
  return (
    <div
      className="d-flex"
    >
      <img
        src={record.params.thumbnailUrl}
        alt={record.params.title}
        style={{ width: '50px', height: '50px' }}
      />
      <div
        style={{ marginLeft: '8px', paddingTop: '4px' }}
      >
        <div
          className="d-flex"
        >
          <span
            className="bold-text"
          >
            {record.params.title}
            <a
              href={record.params.url}
              target="_blank"
              rel="noreferrer"
              style={{ marginLeft: '4px' }}
            >
              <img
                src="/images/mediacontent/{record.params.platform}.png"
                alt="YouTube"
                title="YouTube"
                style={{ width: '20px' }}
              />
            </a>
          </span>
        </div>
        <span >
          {record.params['researchCreator.genre'] || ''}
          {record.params['researchCreator.genre'] && record.params['researchCreator.country'] && (' | ')}
          {record.params['researchCreator.country'] || ''}
        </span>
      </div>
    </div>
  );
};
