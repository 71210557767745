import React from 'react';
import { unflatten } from 'flat';
import { Modal, ButtonToolbar, Button, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { currentTimezoneAggr } from '../../../../../shared/helpers/Dates';

const StatusHistoryModal = ({
  modal,
  setModalVisible,
  record,
}) => {
  const prospect = unflatten(record.params);
  return (
    <Modal
      isOpen={modal}
      toggle={() => setModalVisible(false)}
    >
      <ModalHeader toggle={() => setModalVisible(false)} tag="h4" className="pl-0">Brand Status History</ModalHeader>
      <div className="mt-3">
        {prospect?.approvalStatus.brand?.history.length > 0 ? (
          <>
            {prospect?.approvalStatus.brand?.history.map((item, index, array) => {
              const previousValue = array[index - 1]?.selectedStatus;
              const currentValue = array[index]?.selectedStatus;
              // eslint-disable-next-line prefer-template
              const adminUser = record?.populated['approvalStatus.brand.history.' + index + '.brandUser'] || record?.populated['approvalStatus.brand.history.' + index + '.adminUser'] || {};
              return (
                <div key={moment(item.dateModified).toString() + moment.now().toString()}>
                  <div><strong>{moment(item.dateModified).format('M/D/YYYY h:mm A')} {currentTimezoneAggr()}</strong></div>
                  <div className="pl-3">{adminUser?.params?.firstName || ''} {adminUser?.params?.lastName || ''} - {previousValue || ''} changed to {currentValue}</div>
                </div>);
            })}
          </>
        ) : null}
      </div>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_cancel btn-sm" color="secondary" onClick={() => setModalVisible(false)}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

StatusHistoryModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

StatusHistoryModal.defaultProps = {

};

export default StatusHistoryModal;
