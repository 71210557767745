import { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { showModalById } from '@/redux/reducers/ModalsReducer';
import { addNotice } from '@/containers/App/store/store';

const showNotice = (level, message, dispatch) => {
  dispatch(addNotice({
    message,
    type: level,
  }));
};

const showExpiredModal = (dispatch, message = null) => {
  dispatch(showModalById('SuccessModal', { title: 'Session Expired', message: ('Your session has expired. You will be redirected to the login page.'), closeModal: () => window.location.assign('/login') }));
};

export default () => {
    const dispatch = useDispatch();
    useEffect(() => {
        const handleVariableChange = (event) => {
          const { response } = event.detail;
          showExpiredModal(dispatch, response.data?.error)
        };
        const handleNoticeChange = (event) => {
          
          const { level, message } = event.detail;
          console.log(level, message, 'notice')
          showNotice(level, message, dispatch)
        };
    
        window.addEventListener('InvalidLoginSession', handleVariableChange);
        window.addEventListener('ShowNoticeEvent', handleNoticeChange);
    
        return () => {
          window.removeEventListener('InvalidLoginSession', handleVariableChange);
          window.removeEventListener('ShowNoticeEvent', handleNoticeChange);
        };
    }, [])

    return <></>

}