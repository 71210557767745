import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../../../brand/Layout/index';
// import CreatorsList from '../../../brand/Creators/CreatorsList';
// import CreatorContentList from '../../../brand/CreatorContent/CreatorContentList';
import CreatorDashboard from '../../../brand/CreatorDashboard';
import Settings from '../../../brand/Settings';
import ContentList from '../../../brand/MediaContent/index';
import ContentApprovalsList from '../../../brand/MediaContent/Approvals';
import ProspectList from '../../../brand/Prospects/ProspectsList';
import Dashboard from '../../../brand/Dashboard';
import YouTubeVideoComment from '../../../brand/YouTubeVideoComment';
import CommentReport from '../../../brand/CommentReport';
import Report5cs from '../../../brand/CompanyReport';
import AccessComponent from '../../../../shared/components/auth/AccessComponent';

export default () => (
  <div>
    <Layout />
    <div className="container__wrap no_left_menu">
      <Switch>
        <Route
          path="/"
          exact
          component={Report5cs}
        />
        <Route
          path="/creators/list"
          exact
          component={Dashboard}
        />
        <Route
          path="/creator/:creatorId"
          exact
          component={CreatorDashboard}
        />
        <Route
          path="/content"
          exact
          component={ContentList}
        />
        <Route
          path="/content-approvals"
          exact
          component={ContentApprovalsList}
        />
        <Route
          path="/settings"
          exact
          component={Settings}
        />
        <Route
          path="/prospects"
          exact
          component={ProspectList}
        />
        <Route
          path="/company-report"
          exact
          component={Report5cs}
        />
        <AccessComponent showWhenRole={['owner']}>
          <Route
            path="/comments"
            exact
            component={YouTubeVideoComment}
          />
          <Route
            path="/youtube-comments-analysis"
            exact
            component={CommentReport}
          />
        </AccessComponent>
      </Switch>
    </div>
  </div>
);
