const mapMediaTypeIcons = () => ({
  YouTube: 'youtube',
  Youtube: 'youtube',
  'YouTube Description Text': 'youTubeDescriptionText',
  Podcast: 'podcast',
  'Podcast Script Text': 'podcastScriptText',
  'Instagram Story': 'instagram',
  'Instagram Post': 'instagramPost',
  'Instagram TV Video': 'instagramTVVideo',
  'Facebook Post': 'facebookPost',
  'Facebook Watch Video': 'facebookWatchVideo',
  'Twitter Post': 'twitterPost',
  LinkTree: 'linktree',
  Website: 'website',
  Email: 'email',
  Text: 'text',
  TikTok: 'tiktok',
});

export function isTextType(type) {
  return type === 'YouTube Description Text'
  || type === 'Podcast Script Text'
  || type === 'Twitter Post'
  || type === 'LinkTree'
  || type === 'Text';
}

export default mapMediaTypeIcons;
