/* eslint react/prop-types: 0 */
import React from 'react';
import { FormGroup, Label } from 'reactstrap';
// import styled from 'styled-components';
// import { FormGroup, Label } from 'reactstrap';
// import theme from '../../../../theme';
// import Label from './label';

/* const Property = styled.div`
  margin-bottom: ${theme.sizes.paddingLayout};

  & input {
    border-radius: 0;
    border-color: ${theme.colors.border};
    box-shadow: none;
    &:focus {
      border-color: ${theme.colors.primary};
    }
  }

  & .control > input[type=text]{
    height: 40px;
  }
`; */
/**
 * Wrapps input with label and optional error
 *
 * @component
 * @example <caption>Standard property</caption>
 * const property = {
 *   label: 'My amazing property',
 *   name: 'myAmazingProperty',
 * }
 * const error = { message: 'and there is an error' }
 * return (
 *   <WrapperBox>
 *     <PropertyInEdit property={property} error={error}>
 *       <input className="input" />
 *     </PropertyInEdit>
 *   </WrapperBox>
 * )
 *
 * @example <caption>With an icon</caption>
 * const property = {
 *   label: 'My amazing property',
 *   name: 'myAmazingProperty',
 * }
 * // It is based on the bulma classes
 * return (
 *   <WrapperBox>
 *     <PropertyInEdit property={property}>
 *       <div className="control has-icons-right">
 *         <input className="input" />
 *         <span className="icon is-small is-right">
 *           <i className="fa fa-bomb" />
 *         </span>
 *       </div>
 *     </PropertyInEdit>
 *   </WrapperBox>
 *   <FormGroup>
        <Label>
          -
          {where}
          :
        </Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText><CalendarIcon size="15" /></InputGroupText>
          </InputGroupAddon>
          <input
            type="text"
            ref={this.pickerRef[key]}
            className="input filter form-control"
            name={`${filterKey}${PARAM_SEPARATOR}${key}`}
          />
        </InputGroup>
      </FormGroup>

 * )
 */
const PropertyInEdit = (props) => {
  const { children, property, error } = props;
  return (
    <FormGroup>
      <Label>{property.label}</Label>
      {children}
      {error && (
        <div className="color-danger">{error.message}</div>
      )}
    </FormGroup>
  );
};
export default PropertyInEdit;
/*
 *
    <Property>
      <Label htmlFor={property.name}>{property.label}</Label>
      <div className="control">
      </div>
    </Property>

  <div className="form__form-group">
      <span className="form__form-group-label">{property.label}</span>
      <div className="form__form-group-field">
        {children}
      </div>
      {error && (
        <div className="help is-danger">{error.message}</div>
      )}
    </div>
 */
